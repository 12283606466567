import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';


@Component({
  selector: 'sm-activity-cases',
  templateUrl: './activity-cases.component.html',
  styleUrls: ['./activity-cases.component.scss']
})
export class ActivityCasesComponent implements OnInit {

  profile
  currentCourse
  submitted = false;
  newSolution: UntypedFormGroup;
  newCase: UntypedFormGroup;
  get xf() { return this.newSolution.controls; }
  editorConfigs = {
    useSearch: false,
    language: "ar",
    enter: "br",
    direction: "rtl",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };

  loading = false;
  answers = [];

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newSolution.patchValue({
      body: val
    });
    this._editorData = val;
  }

  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityCasesComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.currentCourse = this.data.courseId;

  }

  ngOnInit(): void {
    this.newCase = this.formBuilder.group({
      id: null,
      courseId: this.currentCourse,
      type: ActivityType.DecisionMaking,
      decision: [''],
      // title: [{ value: '', disabled: this.loading }],
      // title: [{ value: '', disabled: this.loading }],
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: null,
      addedAt: moment(),
      fromDate: moment(),
      toDate: moment(),
      openCase: true,
      title: [{ value: '', disabled: this.loading }],
      body: [{ value: '', disabled: this.loading }],
      description: [{ value: '', disabled: this.loading }],
      courseId: this.currentCourse,
      userId: parseInt(this.profile.userId),
      addedBy: parseInt(this.profile.userId),
      solution: ['']
    });
    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }


  modify(dataItem) {

    this.newCase = this.formBuilder.group({
      id: dataItem.id | dataItem.Id,
      Id: dataItem.id | dataItem.Id,
      courseId: this.currentCourse,
      type: ActivityType.DecisionMaking,
      decision: dataItem.decision,
      title: dataItem.decision.title,
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: dataItem.decision.id | dataItem.decision.Id,
      Id: dataItem.decision.id | dataItem.decision.Id,
      addedAt: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      openCase: true,
      title: dataItem.decision.title,
      body: dataItem.decision.body,
      description: [''],
      courseId: this.currentCourse,
      userId: parseInt(this.profile.userId),
      activityId: dataItem.decision.activityId,
      addedBy: (dataItem.decision.addedBy) ? dataItem.decision.addedBy : parseInt(this.profile.userId),
    })
  }


  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    // this.newSolution.patchValue({
    //   title: this.newCase.get('title').value
    // })



    this.newSolution.patchValue({
      sentences: this.answers
    });
    this.newCase.patchValue({
      decision: this.newSolution.value,
      title: this.newSolution.get('title').value
    })
    // console.log(this.newCase.value);
    // return;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    this._course.addActivty(this.newCase.value).subscribe((data) => {

      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {
        this.dialogRef.close({ data: true })
      })
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + ' ' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }


}
