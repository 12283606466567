import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, SettingsService, NotfiyService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';


export interface QuizzDialogData {
  isNew: boolean
  courseId: number;
  allUnitesList: any[]
  oldQuiz: any
}



@Component({
  selector: 'sm-newmaiquizz',
  templateUrl: './newmaiquizz.component.html',
  styleUrls: ['./newmaiquizz.component.scss']
})
export class NewmaiquizzComponent implements OnInit {


  public profile: any = {};
  submitted = false;
  courseId = 0;
  newCourse: UntypedFormGroup;
  loading = false;
  allUnitesList = [];
  button = ''
  isNew = true;
  oldQuiz: any;



  constructor(private _router: Router, private _courses: CoursesService, private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: QuizzDialogData, private dialogRef: MatDialogRef<NewmaiquizzComponent>,
    private _dialog: MatDialog, private _report: ReportsService, private _store: Store<any>, private _settings: SettingsService,
    private ngNoty: NotfiyService, private _translate: TranslateService,
    private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }

    this.cd.markForCheck();
    this.button = this._translate.instant('DIALOGS.SAVE');
    this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });

  }



  statusOfExams = [{
    id: 1,
    name: "Message"
  },
  {
    id: 2,
    name: "Questions"
  },
  {
    id: 3,
    name: "QuestionsAndGivenAnswers"
  },
  {
    id: 4,
    name: "QuestionsAndGivenAnswersAndScore"
  },
  {
    id: 5,
    name: "QuestionsAndGivenAnswersAndScoreAndCorrectAnswers"
  }]



  setBeforeStart(e) {
    if (e.target.checked) {
      this.newCourse.patchValue({
        beforeStart: true
      });
    } else {
      this.newCourse.patchValue({
        beforeStart: false
      });
    }
  }



  setOptional(e) {
    if (e.target.checked) {
      this.newCourse.patchValue({
        optional: true
      });
    } else {
      this.newCourse.patchValue({
        optional: false
      });
    }
  }




  ngOnInit(): void {

    this.isNew = this.data.isNew;
    this.courseId = this.data.courseId;
    this.allUnitesList = this.data.allUnitesList;
console.log('data',this.allUnitesList);


    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      courseId: this.courseId,
      unitId: null,
      parentx: [{ value: '', disabled: this.loading }],
      timelimit: 0,
      password: { value: '', disabled: this.loading },
      shuffled: { value: '', disabled: this.loading },
      shuffle: false,
      optional: { value: false, disabled: this.loading },
      beforeStart: { value: false, disabled: this.loading },
      ifpassed: { value: '', disabled: this.loading },
      iffailed: { value: '', disabled: this.loading },
      notes: { value: '', disabled: this.loading },
      repetition: 0,
      minScore: { value: '', disabled: this.loading },
      history: 0,
      addedDate: new Date(),
      updateAt: new Date(),
      addedBy: parseInt(this.profile.userId),
    });
    this.loading = true;
    if (!this.isNew) {
      this.oldQuiz = this.data.oldQuiz;
      this.modify(this.oldQuiz);
      this.button = this._translate.instant('QUIZZES.MODIFYQUIZZ');
    }

    this.onChanges();

  }



  onChanges(): void {
    this.newCourse.get('shuffled').valueChanges.subscribe(val => {
      // console.log(val);

      if (val === 'Shuffle') this.newCourse.patchValue({ shuffle: true });
      if (val === 'Sorted') this.newCourse.patchValue({ shuffle: false });
    });

  }


  get xf() { return this.newCourse.controls; }
  progress: number = 0;
  onSubmit() {

    // console.log(this.newCourse.value);
    // return ;

    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }

    this.cd.markForCheck();



    var selectedUnit = this.newCourse.get('parentx').value;
    if (selectedUnit !== this._translate.instant('QUIZZES.FINALQUIZZ'))
      this.newCourse.patchValue({
        unitId: this.allUnitesList.find(x => x.name.includes(selectedUnit)).id | this.allUnitesList.find(x => x.name.includes(selectedUnit)).Id
      });

    this._courses.AddQuizzHeaderOnly(this.newCourse.value)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            this.cd.markForCheck();
            // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            this.dialogRef.close({ data: true })

        }
      })
    this.loading = true;
    // this._courses.addOrModifyUnite(this.fileToUpload, this.newCourse.value).subscribe((data: any) => {
    //   this._course.updateAllUnites((this.currentCourse.id|this.currentCourse.Id), data.result.id);
    //   this.uploader.removeUpload();
    //   this.newCourse.reset();
    //   this.button = 'إضافة الوحدة'
    // });
  }


  modify(dataItem) {
    this.button = this._translate.instant('QUIZZES.MODIFYQUIZZ')
    // console.log(dataItem);
    // console.log(this.statusOfExams.find(x => x.id == dataItem.ifFailed));
    // console.log(dataItem);
    if (dataItem.shuffle) {
      this.newCourse.patchValue({
        'shuffled': 'Shuffle' 
      })
    } else {
      this.newCourse.patchValue({
        'shuffled': 'Sorted' 
      })
    }

    this.newCourse.patchValue({
      id: dataItem.id | dataItem.Id,
      name: dataItem.name,
      courseId: dataItem.courseId,
      unitId: dataItem.unitId,
      parentx: (dataItem.unitId) ? (this.allUnitesList.find(x => x.id == dataItem.unitId)).name : this._translate.instant('QUIZZES.FINALQUIZZ'),
      timelimit: dataItem.timeLimit,
      password: dataItem.password,
      shuffle: dataItem.shuffle,
      optional: dataItem.optional,
      beforeStart: dataItem.beforeStart,
      ifpassed: this.statusOfExams.find(x => x.id == dataItem.ifPassed).name,
      iffailed: this.statusOfExams.find(x => x.id == dataItem.ifFailed).name,
      notes: dataItem.notes,
      repetition: dataItem.repetition,
      minScore: dataItem.minScore,
      history: dataItem.history,
      addedDate: new Date(dataItem.addedDate),
      updateAt: new Date(dataItem.updateAte),
      addedBy: dataItem.addedBy,
    });

  }




}
