import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { FormGroup, FormControl } from '@angular/forms';
import { GlossaryDTO } from '../../../../../src/app/core/_models';
import { AlertType } from '../../../../../src/app/core/_models/system';

@Component({
  selector: 'sm-newglossary-dialog',
  templateUrl: './newglossary-dialog.component.html',
  styleUrls: ['./newglossary-dialog.component.css']
})
export class NewglossaryDialogComponent implements OnInit{
  profile: any;
  submitted = false;
  selectedFile: boolean = false;
  selectedFileName
  loading = false;

  newConcept = new FormGroup({
    // Define 'parentx' form control with validation
  
    id: new FormControl(""),
    concept: new FormControl(this.data?.concept??''),
    definition: new FormControl(this.data?.definition??''),
    keywords: new FormControl(this.data?.keywords??''),
    attachment: new FormControl(''),
    fileId: new FormControl(this.data?.fileId??''),
    glossaryId: new FormControl( this.data?.id??''),
    date: new FormControl(this.createDateAsUTC(new Date())),
    approved: new FormControl(""),
    userId: new FormControl(this.data?.userId??''),
  });
  editorConfigs: any = {
    'uploader': { 'insertImageAsBase64URI': true },
    'language': 'ar'
  }
  createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }


  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newConcept.patchValue({
      definition: val
    });
    this._editorData = val;
  }
  

  get xs() { return this.newConcept.controls; }


  constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<NewglossaryDialogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));

      this.newConcept.patchValue({
        userId: +this.profile.userId,
      })      
    }
  }
  ngOnInit(): void {
    
  }



  onSubmitConcept() {



    this.submitted = true;
    if (this.newConcept.invalid) {
      return;
    }
    // console.log(this.newConcept.value);
    // return ;

    this.loading = true;
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });

    // console.log(this.newConcept.);
    // return ;
    this._course.addOrModifyGlossaryConcept(this.newConcept.value).subscribe((data) => {

      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.CONCEPTADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {        
        this.dialogRef.close();
        this.newConcept.reset();
        this.cd.markForCheck();          
      
    })
      this.submitted = false;

      this.selectedFile = true;
      this.selectedFileName = null;
      this._course.getGlossaryById(this.data.id | this.data.Id).subscribe((data: GlossaryDTO) => {
        this.dialogRef.close(data)
        this.cd.markForCheck();
      })
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + ' ' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
    // console.log(this.newConcept.value);

  }

  close(){
    this.dialogRef.close()
  }
}
