import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CerDetails, DetailsPosition } from '../../../../app/core/_models';
import { CoursesService } from '../../../../app/core/services';
import { AlertType } from '../../../../app/core/_models/system';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';



export interface CertificateDetailData {
  certificateId: number;
  oldItem: any;
  isNew: boolean;
  type: number;
}

@Component({
  selector: 'sm-certificatedetails',
  templateUrl: './certificatedetails.component.html',
  styleUrls: ['./certificatedetails.component.scss']
})
export class CertificatedetailsComponent implements OnInit {


  editorConfigs = {
    uploader: { 'insertImageAsBase64URI': true },
    // useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    // readonly: false,
    // showCharsCounter: false,
    // showWordsCounter: false,
    // showXPathInStatusbar: false,
    // askBeforePasteHTML: true,
    // askBeforePasteFromWord: true,
    // inline: false,
    // "allowResizeY": false,
    // "height": 250,
    // disablePlugins:
    //   "video,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    // showPlaceholder: false,
    // buttons:
    //   "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,center,right,left,fontsize,cut,copy,paste,link,undo,redo, image  ",
    // "toolbar": true,
  };

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newTopic.patchValue({
      body: val
    });
    this._editorData = val;
  }


  newTopic: UntypedFormGroup;
  get xs() { return this.newTopic.controls; }
  loading = false;
  profile
  certificateId = 0;
  submitted = false;


  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    private _store: Store<any>, public dialogRef: MatDialogRef<CertificatedetailsComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CertificateDetailData) {
    // this.currentCourse = data.courseId;
    this.certificateId = data.certificateId;
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }



  ngOnInit(): void {
    var type = CerDetails.Image;
    var position = DetailsPosition.Center;
    switch (this.data.type) {
      case 4:
        type = CerDetails.NameOfCourse;
        break;

      case 1:
        type = CerDetails.Signature;
        position = DetailsPosition.Left
        break;
      case 2:
        type = CerDetails.Signature;
        position = DetailsPosition.Right
        break;

      case 3:
        type = CerDetails.PositionOfTrainer;
        break;

      default:
        break;
    }
    this.newTopic = this.formBuilder.group({
      id: null,
      certificateId: this.data.certificateId,
      type: type,
      position: position,
      order: 0,
      name: [{ value: '', disabled: this.loading }],
      body: [{ value: '', disabled: this.loading }],
      fileId: null,
    });
    if (!this.data.isNew) {
      this.modify(this.data.oldItem)
    }
  }


  onSubmitConcept() {



    this.submitted = true;
    if (this.newTopic.invalid) {
      return;
    }
    // console.log(this.newTopic.value);
    // return ;

    this.loading = true;
    // this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });


    this._course.addOrModifyCertifcateDetails(this.newTopic.value).subscribe((data) => {

      // this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      const diafor = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      diafor.afterClosed().subscribe((data) => {
        this.dialogRef.close({ data: true });
      })

    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })

  }


  modify(dataItem) {
    // console.log(dataItem);

    this.newTopic = this.formBuilder.group({
      id: dataItem.id | dataItem.Id,
      certificateId: this.certificateId,
      type: dataItem.type,
      position: dataItem.position,
      order: dataItem.order,
      name: dataItem.name,
      body: dataItem.body,
      fileId: dataItem.fileId,
    });
  }

}
