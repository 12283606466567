import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoursesService } from '../../../../../src/app/core/services';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertType } from '../../../../../src/app/core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from '../alertconfirmmessage/alertconfirmmessage.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sm-offer-dialog',
  templateUrl: './offer-dialog.component.html',
  styleUrls: ['./offer-dialog.component.css']
})
export class OfferDialogComponent implements OnInit {
  @ViewChild('ngSelectRef2') ngSelectRef2: any;
  selectedBuyProductIds: number[] = [304, 301, 299]
  
  announcechoVal:number=this.data?this.data.type:1
  discountType:number=2
  selectedValueBuy:string=this.data?.buyget?.allToBuy?"2":"1"
  selectedValueGet:string=this.data?.buyget?.allToGet?"2":"1"
  slectionTypeVal:number = this.data?this.data?.amount?.typeOfProducts:1 
  selectedControl: string = 'minToPay'; // Default control
  selectedControlType: string = 'amount'; // Default control
  selectedCourses = []
  selectedCoursesBuy = []
  selectedCoursesGet = []
  courses:any
  coursesSelected:any
  OfferType:{value:string,id:number, title:string}[]=[
    {value:'Buy1get1',id:1,title:'اذا اشترى العميل xيحصل على Y'},
    {value:'FixedAmount',id:2,title:'مبلغ ثابت من قيمه مشتريات العميل'},
    {value:'Percentage',id:3,title:'نسبه من قيمه مشتريات العميل'},
  ]
  OfferSelectionType:{value:number,id:number, title:string}[]=[
    {value:1,id:1,title:'الكل'},
    {value:2,id:2,title:'كورسات مختاره'},
    {value:3,id:3,title:'تصنيفات مختاره'},
    {value:4,id:4,title:'باقات مختاره'},
  ]
  offers: FormGroup= new FormGroup({
    id:  new FormControl(this.data?this.data.id:null),
    title:  new FormControl(this.data?this.data.title:'', Validators.required),
    fromDate:  new FormControl(this.data?this.data.fromDate:new Date().toISOString().slice(0, 16), Validators.required),
    toDate:  new FormControl(this.data?this.data.toDate:new Date().toISOString().slice(0, 16), Validators.required),
    isActive: new FormControl(this.data?this.data.isActive:false, Validators.required),
    type:  new FormControl(this.data?this.data.type:1 , Validators.required),
    buyget : new FormGroup({
      id: new FormControl(this.data?.buyget?.id || null),
      totalToBuy: new FormControl(this.data?.buyget?.totalToBuy || '', Validators.required),
      totalToOffer: new FormControl(this.data?.buyget?.totalToOffer || '', Validators.required),
      discountType: new FormControl(this.data?.buyget?.discountType || 2, Validators.required),
      percentage: new FormControl(this.data?.buyget?.percentage || '', Validators.required),
      buyProductsIds:new FormControl(null,Validators.required),
      getProductsIds:new FormControl(null,Validators.required)

    }),
    amount:new FormGroup({
      id: new FormControl(this.data?.amount?.id || null),
      amount: new FormControl(this.data?.amount?.amount || ''),
      typeOfProducts: new FormControl(this.data?.amount?.typeOfProducts || 1),
      minToPay: new FormControl(this.data?.amount?.minToPay || null),
      acceptCoupon: new FormControl(this.data?.amount?.acceptCoupon || false),
      products: new FormControl(this.data?.amount?.products || []),
      getItemIds:new FormControl( null),
    }),
    discount: new FormGroup({
      id: new FormControl(this.data?.discount?.id || null),
      typeOfProducts: new FormControl(this.data?.discount?.typeOfProducts ||1),
      totalPercent: new FormControl(this.data?.discount?.totalPercent ||null),
      maxDiscount: new FormControl(this.data?.discount?.maxDiscount ||''),
      acceptCoupon: new FormControl(this.data?.discount?.acceptCoupon ||false),
      products: new FormControl(this.data?.discount?.products ||[]),
      getItemIds:new FormControl( null),
    })

  });;



  constructor(private _translate: TranslateService,private _dialog: MatDialog,private _coursesService:CoursesService , public dialogRef: MatDialogRef<OfferDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any ){
      if(this.announcechoVal)

        
        // ✅ Detect changes when user selects a different radio button
// Handle form changes
  if(this.announcechoVal==1){
    this.offers.removeControl('amount');
    this.offers.removeControl('discount');
  }else if(this.announcechoVal==2){
    this.offers.removeControl('buyget');
    this.offers.removeControl('discount');
  
  }else{
    this.offers.removeControl('buyget');
    this.offers.removeControl('amount');
  }


this.offers.get('type')?.valueChanges.subscribe(value => {  
  this.announcechoVal = value;
      // Reset the ng-select component
      if (this.ngSelectRef2) {
        this.ngSelectRef2.clearModel();
      }  // Remove all dynamic groups before adding the new one
  this.offers.removeControl('buyget');
  this.offers.removeControl('amount');
  this.offers.removeControl('discount');

  if (value == 1) {
    this.offers.addControl('buyget', new FormGroup({
      id: new FormControl(this.data?.buyget?.id || null),
      totalToBuy: new FormControl(this.data?.buyget?.totalToBuy || '', Validators.required),
      totalToOffer: new FormControl(this.data?.buyget?.totalToOffer || '', Validators.required),
      discountType: new FormControl(this.data?.buyget?.discountType || 2),
      percentage: new FormControl(this.data?.buyget?.percentage || '', Validators.required),
      buyProductsIds:new FormControl(this.data?this.selectedCoursesBuy:null),
      getProductsIds:new FormControl(this.data?this.selectedCoursesGet:null)
    }));

    
  } else if (value == 2) {
    this.selectedControlType = "amount"
    this.offers.addControl('amount', new FormGroup({
      id: new FormControl(this.data?.amount?.id || null),
      amount: new FormControl(this.data?.amount?.amount || '' ,Validators.required),
      typeOfProducts: new FormControl(this.data?.amount?.typeOfProducts || 1 ,Validators.required),
      minToPay: new FormControl(this.data?.amount?.minToPay || null ,Validators.required),
      acceptCoupon: new FormControl(this.data?.amount?.acceptCoupon || false),
      products: new FormControl(this.data?.amount?.products || [] ),
      getItemIds:new FormControl( null ,Validators.required),

    }));
    
  } else if (value == 3) {
    this.selectedControlType = "discount"

    this.offers.addControl('discount', new FormGroup({
      typeOfProducts: new FormControl(1, Validators.required),
      totalPercent: new FormControl(null, Validators.required),
      maxDiscount: new FormControl('', Validators.required),
      acceptCoupon: new FormControl(false, Validators.required),
      products: new FormControl([]),
      getItemIds:new FormControl( null),

    }));
  }
});
        this.offers.get('buyget')?.get('discountType').valueChanges.subscribe(value => {
          this.discountType=value
          const buygetGroup = this.offers.get('buyget') as FormGroup;

          if (value == 1) {
            if (buygetGroup.get('percentage')) {
              buygetGroup.removeControl('percentage');
            }
          } else {
            // Add 'percentage' control back if discountType is not 1
            if (!buygetGroup.get('percentage')) {
              buygetGroup.addControl('percentage', new FormControl(''));
            }
          }
        });
  }

  ngOnInit(): void {
    const amountGroup = this.offers.get('amount') as FormGroup;
    const discountGroup = this.offers.get('discount') as FormGroup;

    console.log('data==' ,this.data);
    console.log('data==' ,this.slectionTypeVal);
    

    if(this.data){
      this.offers.controls['type'].disable();
      if(this.data.buyget.allToGet ||this.data.buyget.allToBuy ){
        this.slectionTypeVal = 0
      }else{
        this.slectionTypeVal = 2
        console.log(this.slectionTypeVal);
        
      }

    }else{
      this.getAllCourses();
    }



    if (this.slectionTypeVal==1){
      amountGroup?.removeControl('getItemIds');
      discountGroup?.removeControl('getItemIds');

    }else{
      amountGroup?.addControl('getItemIds', new FormControl('',Validators.required)); // Default value
      discountGroup?.addControl('getItemIds', new FormControl('',Validators.required)); // Default value

    }
    if(this.slectionTypeVal==2){
      this.getAllCourses()
    }else if(this.slectionTypeVal==3){
      this.getAllCategory()
    }else{
      this.getCurriculas()
    }
  }

  onSelectionChangeBuy(event: Event) {
     this.selectedValueBuy = (event.target as HTMLSelectElement).value;
    const buygetGroup = this.offers.get('buyget') as FormGroup;
    
    if (this.selectedValueBuy == '1') {
      this.getAllCourses()
      buygetGroup.addControl('buyProductsIds', new FormControl(null,Validators.required)); // Initialize as an empty array

      if (buygetGroup.get('allToBuy')) {
        buygetGroup.removeControl('allToBuy');
      }
    } else {
      buygetGroup.removeControl('buyProductsIds');
      buygetGroup.removeControl('getProductsIds');

      // Add 'percentage' control back if discountType is not 1
      if (!buygetGroup.get('allToBuy')) {
        buygetGroup.addControl('allToBuy', new FormControl(true));
      }
    }
  }

  onSelectionChangeGet(event: Event) {
    this.selectedValueGet = (event.target as HTMLSelectElement).value;
   const buygetGroup = this.offers.get('buyget') as FormGroup;
   if (this.selectedValueGet == '1') {
    this.getAllCourses()
    buygetGroup.addControl('getProductsIds', new FormControl(this.data?this.selectedCoursesGet:null,Validators.required)); // Initialize as an empty array

     // Remove the 'percentage' control if discountType is 1
     if (buygetGroup.get('allToGet')) {
       buygetGroup.removeControl('allToGet');
     }
   } else {
     // Add 'percentage' control back if discountType is not 1
     if (!buygetGroup.get('allToGet')) {
       buygetGroup.addControl('allToGet', new FormControl(true));
       buygetGroup.removeControl('getProductsIds');

     }
   }
 
 }
 selectedCoursesAmount

  getAllCourses() {
    this._coursesService.AllCourses().subscribe((res) => {
      this.courses = res || [];
      
      // Ensure selectedCourses is initialized correctly
      if(this.data){
        this.selectedCoursesBuy = this.data?.buyget?.buyProducts?.map(p => p.courseId) || [];
        this.selectedCoursesGet = this.data?.buyget?.getProducts?.map(p => p.courseId) || [];
        this.selectedCoursesAmount = this.data?.amount?.products?.map(p => p.courseId||p.packageId||p.categoryId) || [];
       
        const buygetGroup = this.offers.get('buyget') as FormGroup;
        const amountGroup = this.offers.get('amount') as FormGroup;
      if(buygetGroup){
        // Check if the control already exiss
        buygetGroup.patchValue({
          buyProductsIds:this.selectedCoursesBuy,
          getProductsIds:this.selectedCoursesGet
        })   
      }
      if(amountGroup){
        // Check if the control already exiss
        amountGroup.patchValue({
          getItemIds:this.selectedCoursesAmount,
        })   
      }
   
      }

      // addControl('buyProducts', new FormControl([])); // Initialize as an empty array
    
      this.coursesSelected = res;
    }, (error) => {
      console.error('Error fetching courses:', error);
      this.courses = []; // Fallback in case of an error
    });
  
  
}
 getAllCategory(){
  this._coursesService.AllCategories().subscribe((res)=>{
    this.coursesSelected=res
    this.selectedCoursesAmount = this.data?.amount?.products?.map(p => p.courseId||p.packageId||p.categoryId) || [];
    const amountGroup = this.offers.get('amount') as FormGroup;

    if(amountGroup){
      // Check if the control already exiss
      amountGroup.patchValue({
        getItemIds:this.selectedCoursesAmount,
      })   
    }
    
  })
}
getCurriculas(){
  this._coursesService.getCurriculas().subscribe((res)=>{
    this.coursesSelected=res
  })
}
onSelectionChangeBuyProducts(selectedValues: any) {
  
  if (this.selectedValueBuy == '1') {
    const selectedCourses = selectedValues.map((item: any) => ({ courseId: item.id }));
    const selectedCoursesIds = selectedValues.map((item: any) => ( item.id ));
    this.selectedCoursesBuy = selectedCoursesIds
    const buygetGroup = this.offers.get('buyget') as FormGroup;
  
    // Check if the control already exists
    if (!buygetGroup.get('buyProducts')) {
      buygetGroup.addControl('buyProducts', new FormControl([])); // Initialize as an empty array
    }
  
    // Update the control value
    buygetGroup.get('buyProducts')?.setValue(selectedCourses);
    buygetGroup.get('buyProductsIds')?.setValue(selectedCoursesIds);
  }
  

}
onSelectionChangeChangeCourses(selectedValues: any) {
  // Map selected values based on selection type
  const item = selectedValues.map((item: any) => ( item.id ))
  if (this.slectionTypeVal == 2) {
    this.selectedCourses = selectedValues.map((item: any) => ({ courseId: item.id }));
  } else if (this.slectionTypeVal == 3) {
    this.selectedCourses = selectedValues.map((item: any) => ({ packageId: item.id }));
  } else {
    this.selectedCourses = selectedValues.map((item: any) => ({ categoryId: item.id }));
  }


  const amountGroup = this.offers.get('amount') as FormGroup;
  const discountGroup = this.offers.get('discount') as FormGroup;

  if (amountGroup) {
    if (!amountGroup.contains('products')) {
      amountGroup.addControl('products', new FormControl([]));
    }
    amountGroup.get('products')?.setValue([...this.selectedCourses]);
    amountGroup.get('getItemIds')?.setValue([...item]);
    const selectedCoursesIds = selectedValues.map((item: any) => ( item.id ));
    this.selectedCoursesAmount = selectedCoursesIds
  }

  if (discountGroup) {
    if (!discountGroup.contains('products')) {
      discountGroup.addControl('products', new FormControl([]));
    }
    discountGroup.get('products')?.setValue([...this.selectedCourses]);
  }

}


onSelectionChangeGetProducts(selectedValues: any) {
  if (this.selectedValueGet == '1') {
    const selectedCourses = selectedValues.map((item: any) => ({ courseId: item.id }));
    const selectedCoursesIds = selectedValues.map((item: any) => ( item.id ));
    this.selectedCoursesGet= selectedCoursesIds
    const buygetGroup = this.offers.get('buyget') as FormGroup;

    if (buygetGroup) {
      // Ensure 'getProducts' control exists before modifying
      if (!buygetGroup.contains('getProducts')) {
        buygetGroup.addControl('getProducts', new FormControl([]));
      }
      buygetGroup.get('getProducts')?.setValue(selectedCourses);
    }
  }
}


onDropdownChange(event: any) {
  const selectedValue = event.target.value;
  const amountGroup = this.offers.get('amount') as FormGroup;

  // Remove both fields before adding the selected one
  amountGroup.removeControl('minToPay');
  amountGroup.removeControl('minToBuy');

  if (selectedValue == '1') {
    amountGroup.addControl('minToPay', new FormControl(this.data?this.data?.amount.minToBuy:2)); // Default value
    this.selectedControl = 'minToPay';
  } else if (selectedValue == '2') {
    amountGroup.addControl('minToBuy', new FormControl(''));
    this.selectedControl = 'minToBuy';
  }}

  onSelectionType(selectedValues){
    this.selectedCourses = [];
    const amountGroup = this.offers.get('amount') as FormGroup;
    const discountGroup = this.offers.get('discount') as FormGroup;


    this.slectionTypeVal = selectedValues
    if (this.slectionTypeVal==1){
      amountGroup.removeControl('getItemIds');
      discountGroup.removeControl('getItemIds');

    }else{
      amountGroup?.addControl('getItemIds', new FormControl('',Validators.required)); // Default value
      discountGroup?.addControl('getItemIds', new FormControl('',Validators.required)); // Default value

    }
    
    if(this.slectionTypeVal==2){
      this.getAllCourses()
    }else if(this.slectionTypeVal==3){
      this.getAllCategory()
    }else{
      this.getCurriculas()
    }
      // Reset the ng-select component
  if (this.ngSelectRef2) {
    this.ngSelectRef2.clearModel();
  }    
  }


  onsubmit(infoData:FormGroup){    
    const buygetGroup = this.offers.get('buyget') as FormGroup;
    const amountGroup = this.offers.get('amount') as FormGroup;
    if(buygetGroup){
      if(this.selectedCoursesBuy.length&&buygetGroup?.get('allToBuy')?.value!=true){
        const selectedCourses = this.selectedCoursesBuy.map((item: any) => ({ courseId: item }));
        buygetGroup.addControl('buyProducts', new FormControl(selectedCourses)); // Initialize as an empty array
      }else{
        buygetGroup.removeControl('buyProducts')
        buygetGroup.removeControl('buyProductsIds')

      }
      if(this.selectedCoursesGet.length&&buygetGroup?.get('allToGet')?.value!=true){
        const selectedCourses = this.selectedCoursesGet.map((item: any) => ({ courseId: item }));
        buygetGroup.addControl('getProducts', new FormControl(selectedCourses)); // Initialize as an empty array

      }else{
        buygetGroup.removeControl('getProducts')

      }
      buygetGroup.removeControl('getProductsIds')
      buygetGroup.removeControl('buyProductsIds')
    }

    if(amountGroup){
      amountGroup.removeControl('getItemIds')

    }


    
    this._coursesService.addOffer(infoData.value).subscribe((res=>{
      this.closeModal()
    }))
  }

  closeModal(){
    this.dialogRef.close()
  }
  deleteAdd() {
    const dialogRef = this._dialog.open(AlertconfirmmessageComponent, {
      height: '200px',
      width: '400px'
    });
  
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._coursesService.deletOffer(this.data.id).subscribe(() => {
          var alertData = { 
            title: this._translate.instant('DIALOGS.DONE'), 
            body: this._translate.instant('DIALOGS.ELEMENTDELTED'), 
            type: AlertType.Warning 
          };
  
          const alertDialogRef = this._dialog.open(AlertMessageComponent, {
            data: alertData,
            height: '200px',
            width: '400px'
          });
  
          // Close modal after alert dialog is closed
          alertDialogRef.afterClosed().subscribe(() => {
            this.closeModal();
          });
        });
      }
    });
  }
  
}
