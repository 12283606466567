import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';




export interface AffiliateDialog {
  affiliateProfile: any;
}




@Component({
  selector: 'sm-preview-affiliate',
  templateUrl: './preview-affiliate.component.html',
  styleUrls: ['./preview-affiliate.component.scss']
})
export class PreviewAffiliateComponent implements OnInit {



  selectedCourseCard: any;

  constructor(public dialogRef: MatDialogRef<PreviewAffiliateComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AffiliateDialog) {
    this.selectedCourseCard = data.affiliateProfile;
    this.cd.markForCheck();
  }


  ngOnInit(): void {
  }

}
