import { Component, Input, OnInit, ViewEncapsulation, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import * as Chart from 'chart.js';
// declare var Chart: any;


@Component({
  selector: 'sm-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PiechartComponent implements OnInit, AfterViewInit {

  @Input() amount: any = '';
  @Input() unitname: any = '';
  @Input() data: any[] = [];
  @Input() labels: any[] = [];
  @Input() colors: any[] = [];


  constructor(private cd: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    var donutChart1: any = document.getElementById("donutChartx");


    // var lables = this.data.map(c => c.)
    var data1 = {
      labels: this.labels,
      datasets: [{
        data: this.data,
        backgroundColor: this.colors,
        hoverBackgroundColor: this.colors,
        borderWidth: 6,
        hoverBorderColor: 'transparent'
      }]
    };

    // -----------------
    // init donut chart
    // -----------------
    new Chart(donutChart1, {
      type: 'doughnut',
      data: data1,
      options: {
        
        responsive: true,
        legend: {
          display: false,
          align: 'end',
          position: 'bottom'
        },
        title: {
          display: true,
          fontColor: '#1a49be',
          text: this.unitname
        },
        tooltips: {
          titleFontColor: '#1a49be',
        },
        animation: {
          animateScale: true
        },
        cutoutPercentage: 80
      }
    });
    this.cd.markForCheck();
  }

  ngOnInit(): void {


  }






}
