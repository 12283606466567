import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ForumsDTO } from '../../../../../src/app/core/_models';
import { AlertType } from '../../../../../src/app/core/_models/system';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { GlossaryCertificateComponent } from '../glossary-certificate/glossary-certificate.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'sm-forum-dailog',
  templateUrl: './forum-dailog.component.html',
  styleUrls: ['./forum-dailog.component.css']
})
export class ForumDailogComponent {
  profile: any;
  submitted = false;
  get xf() { return this.newCourse.controls; }
  editorConfigs
  _editorData = '';
  loading = false;

  currentCourse
  
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      description: val
    });
    this._editorData = val;
  }
  newCourse = new FormGroup({
    // Define 'parentx' form control with validation
    name: new FormControl( ),
    description: new FormControl(),
    id: new FormControl(),
    courseId: new FormControl(),
    displayDescription: new FormControl(),
    userId: new FormControl(),
  });

  // id: null,
  // name: [{ value: '', disabled: this.loading }],
  // description: [{ value: '', disabled: this.loading }],
  // courseId: this.currentCourse,
  // displayDescription: [{ value: '', disabled: this.loading }],
  // userId: parseInt(this.profile.userId)
  constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<GlossaryCertificateComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      this.newCourse.patchValue({
        userId:+this.profile.userId
      })
    }
  }
  ngOnInit(): void {

    // console.log(this.data);
    
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });


    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    var url = window.location.href;
    var xurl = url.split('/');
    this.currentCourse = + xurl[5];
    this.newCourse.patchValue({
      courseId:this.currentCourse,
      id:this.data
    })

  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.newCourse.value);
    
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    this._course.addOrModifyNewForum(this.newCourse.value).subscribe((data: ForumsDTO) => {
      // console.log(data)

      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog =this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {        
        this.dialogRef.close();
        this.newCourse.reset();
        this.cd.markForCheck();          
      
    })
      this.submitted = false;

      // this.forum = data;


    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }

}
