import { UserService } from './../../core/services';
import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

//declare var LocalStorage: Storage;

@Injectable()
export class AuthGuard implements CanActivate {

  // , @Inject(LocalStorage) private localStorage
  constructor(private router: Router, private _account: UserService, private authenticationService: AuthenticationService) { }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

  //   if (typeof window !== 'undefined') {
  //     if (localStorage.getItem('mprofile')) {
  //       // logged in so return true

  //       this._account.test().subscribe((data) => {
  //         return true;
  //       });

  //       return true;
  //     }
  //   }



  //   // not logged in so redirect to login page with the return url
  //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //   return false;
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    const user = this.authenticationService.userValue;


    if (user) {
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

}
