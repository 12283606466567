import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LecturecontentdialogComponent } from './lecturecontentdialog/lecturecontentdialog.component';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LoaderModule } from '../loader/loader.module';
import { VideobankModule } from '../videobank/videobank.module';

import { JoditAngularModule } from 'jodit-angular';
import { DragulaModule } from 'ng2-dragula';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SortablejsModule } from 'ngx-sortablejs';
import { CoreModule } from '../../core/core.module';
import { CoursesEffect } from '../../core/state/_courses/course.effect';
import { ComponentsModule } from '../components/components.module';
import { PlayerModule } from '../components/player/player.module';
import { SelecfiledialogComponent } from './selecfiledialog/selecfiledialog.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './alertconfirmmessage/alertconfirmmessage.component';
import { NewtagdialogComponent } from './newtagdialog/newtagdialog.component';
import { NewcoursedialogComponent } from './newcoursedialog/newcoursedialog.component';
import { NewcurriculadialogComponent } from './newcurriculadialog/newcurriculadialog.component';
import { NewconsultantdialogComponent } from './newconsultantdialog/newconsultantdialog.component';
import { NewconsultancydialogComponent } from './newconsultancydialog/newconsultancydialog.component';
import { NewtrainerdialogComponent } from './newtrainerdialog/newtrainerdialog.component';
import { CommissiondialogComponent } from './commissiondialog/commissiondialog.component';
import { PreviewAffiliateComponent } from './preview-affiliate/preview-affiliate.component';
import { AffiliateCommissionRequestsComponent } from './affiliate-commission-requests/affiliate-commission-requests.component';
import { B2bprofiledialogComponent } from './b2bprofiledialog/b2bprofiledialog.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { B2bcommunicationdialogComponent } from './b2bcommunicationdialog/b2bcommunicationdialog.component';
import { B2bcontractdialogComponent } from './b2bcontractdialog/b2bcontractdialog.component';
import { QuizzquestioneditComponent } from './quizzquestionedit/quizzquestionedit.component';
import { UserCourseProgressComponent } from './user-course-progress/user-course-progress.component';
import { NewcoupondialogComponent } from './newcoupondialog/newcoupondialog.component';
import { ActivityquizzmanageComponent } from './activityquizzmanage/activityquizzmanage.component';
import { ActivityquizquestionComponent } from './activityquizquestion/activityquizquestion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivityquestionanswerComponent } from './activityquestionanswer/activityquestionanswer.component';
import { ActivityquestionanswerfillinComponent } from './activityquestionanswerfillin/activityquestionanswerfillin.component';
import { QuizzquestionanswerComponent } from './quizzquestionanswer/quizzquestionanswer.component';
import { QuizzquizquestionComponent } from './quizzquizquestion/quizzquizquestion.component';
import { QuizzquizzmanageComponent } from './quizzquizzmanage/quizzquizzmanage.component';
import { CourseinfodialogComponent } from './courseinfodialog/courseinfodialog.component';
import { CourseunitdialogComponent } from './courseunitdialog/courseunitdialog.component';
import { NewCategoriesComponent } from './categories/newcategories.component';
import { NewcoursetocurriculumComponent } from './newcoursetocurriculum/newcoursetocurriculum.component';
import { NewmaiquizzComponent } from './newmaiquizz/newmaiquizz.component';
import { ManagemaiquizzComponent } from './managemaiquizz/managemaiquizz.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ManagelivestreamComponent } from './managelivestream/managelivestream.component';
import { ActivityMatchingComponent } from './activity-matching/activity-matching.component';
import { ActivityMatchingSentenceComponent } from './activity-matching-sentence/activity-matching-sentence.component';
import { ActivityLogicalComponent } from './activity-logical/activity-logical.component';
import { ActivityLogicalSentenceComponent } from './activity-logical-sentence/activity-logical-sentence.component';
import { ActivityQuizzComponent } from './activity-quizz/activity-quizz.component';
import { ActivityFillspaceComponent } from './activity-fillspace/activity-fillspace.component';
import { ActivityDecisionComponent } from './activity-decision/activity-decision.component';
import { ActivityDecisionAnswersComponent } from './activity-decision-answers/activity-decision-answers.component';
import { ActivityCasesComponent } from './activity-cases/activity-cases.component';
import { AssignmentDialogComponent } from './assignment-dialog/assignment-dialog.component';
import { QuizAttendanceReviewComponent } from './quiz-attendance-review/quiz-attendance-review.component';
import { QuizReviewDialogComponent } from './quiz-review-dialog/quiz-review-dialog.component';
import { FormtopicdialogComponent } from './formtopicdialog/formtopicdialog.component';
import { CertificatedetailsComponent } from './certificatedetails/certificatedetails.component';
import { CertificatesignatureComponent } from '../certificates/certificatesignature/certificatesignature.component';
import { TrainersDialogComponent } from './trainers-dialog/trainers-dialog.component';
import { CertifiedDialogComponent } from './certified-dialog/certified-dialog.component';
import { RulesDialogComponent } from './rules-dialog/rules-dialog.component';
import { AnnouncDialogComponent } from './announc-dialog/announc-dialog.component';
import { GlossaryCertificateComponent } from './glossary-certificate/glossary-certificate.component';
import { NewglossaryDialogComponent } from './newglossary-dialog/newglossary-dialog.component';
import { CaseDialogComponent } from './case-dialog/case-dialog.component';
import { ForumDailogComponent } from './forum-dailog/forum-dailog.component';
import { NewWikiDailogComponent } from './new-wiki-dailog/new-wiki-dailog.component';
import { UpdateWikiDailogComponent } from './update-wiki-dailog/update-wiki-dailog.component';

@NgModule({
  declarations: [
    LecturecontentdialogComponent,
    SelecfiledialogComponent,
    AlertMessageComponent,
    AlertconfirmmessageComponent,
    NewtagdialogComponent,
    NewcoursedialogComponent,
    NewcurriculadialogComponent,
    NewconsultantdialogComponent,
    NewconsultancydialogComponent,
    NewtrainerdialogComponent,
    CommissiondialogComponent,
    PreviewAffiliateComponent,
    AffiliateCommissionRequestsComponent,
    B2bprofiledialogComponent,
    B2bcommunicationdialogComponent,
    B2bcontractdialogComponent,
    QuizzquestioneditComponent,
    UserCourseProgressComponent,
    NewcoupondialogComponent,
    ActivityquizzmanageComponent,
    ActivityquizquestionComponent,
    ActivityquestionanswerComponent,
    ActivityquestionanswerfillinComponent,
    QuizzquestionanswerComponent,
    QuizzquizquestionComponent,
    QuizzquizzmanageComponent,
    CourseinfodialogComponent,
    CourseunitdialogComponent,
    NewCategoriesComponent,
    NewcoursetocurriculumComponent,
    NewmaiquizzComponent,
    ManagemaiquizzComponent,
    CertificatesignatureComponent,
    ManagelivestreamComponent,
    ActivityMatchingComponent,
    ActivityMatchingSentenceComponent,
    ActivityLogicalComponent,
    ActivityLogicalSentenceComponent,
    ActivityQuizzComponent,
    ActivityFillspaceComponent,
    ActivityDecisionComponent,
    ActivityDecisionAnswersComponent,
    ActivityCasesComponent,
    AssignmentDialogComponent,
    QuizAttendanceReviewComponent,
    QuizReviewDialogComponent,
    FormtopicdialogComponent,
    CertificatedetailsComponent,
    TrainersDialogComponent,
    CertifiedDialogComponent,
    RulesDialogComponent,
    AnnouncDialogComponent,
    GlossaryCertificateComponent,
    NewglossaryDialogComponent,
    CaseDialogComponent,
    ForumDailogComponent,
    NewWikiDailogComponent,
    UpdateWikiDailogComponent
  ],
  imports: [
    CommonModule, MatCardModule, MatButtonModule, MatDividerModule, MatDialogModule, MatRadioModule, MatCheckboxModule,
      NgSelectModule, DropzoneModule,  NgxPaginationModule,
    ComponentsModule,  MatCardModule, MatButtonModule, MatDialogModule, NgxIntlTelInputModule,
     CoreModule, PlayerModule, SortablejsModule, OverlayModule,
     TranslateModule, NgxPermissionsModule.forChild(),
    MatTableModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSortModule, MatProgressBarModule,
    FormsModule, ReactiveFormsModule, LoaderModule, NgxDaterangepickerMd,
    DragulaModule, JoditAngularModule,
  ],
  providers: [TranslateService],
  exports: [
    LecturecontentdialogComponent, SelecfiledialogComponent, AlertMessageComponent, AlertconfirmmessageComponent
  ]
})
export class DialogsModule { }
