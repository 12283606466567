import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from './../../../../environments/environment';
import { AuthenticationService } from '../../../../../src/app/core/auth/authentication.service';

@Component({
  selector: 'sm-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidemenuComponent implements OnInit {


  profile: any;
  loading;
  lang = '';
  b2bModule = environment.B2BModule

  website = environment.SITE;


  constructor(private cd: ChangeDetectorRef, private _store: Store<any>,private auth :AuthenticationService) { }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    this.lang = localStorage.getItem('language');
    // console.log(this.lang);

    this.cd.markForCheck();
  }

  logOut(){
    this.auth.logout()
  }

}
