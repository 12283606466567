
import { PreviewComponent } from './views/courses/preview/preview.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { BaseComponent } from './base/base.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth/auth.guard';
// Angular
import { NgModule, Component } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { LoginComponent } from './views/components/login/login.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ResetComponent } from './views/components/reset/reset.component';
import { ConfirmedComponent } from './views/components/confirmed/confirmed.component';
import { ResetformComponent } from './views/components/resetform/resetform.component';
import { ChangepasswordComponent } from './views/components/changepassword/changepassword.component';
import { TwofactorComponent } from './views/components/twofactor/twofactor.component';
import { NewAffliateComponent } from './views/affiliates/new-affliate/new-affliate.component';
import {} from '@angular/material/slide-toggle';

export function testPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  return ['SuperAdmin',
    'PreviewCourses', 'AddCourses', 'ModifyCourses', 'DeleteCourses', 'ReportCourses', 'SuperCourses',
    'PreviewCourseManagement', 'AddCourseManagement', 'ModifyCourseManagement', 'DeleteCourseManagement', 'ReportCourseManagement', 'SuperCourseManagement',
    'PreviewCoursePurchasers', 'AddCoursePurchasers', 'ModifyCoursePurchasers', 'DeleteCoursePurchasers', 'ReportCoursePurchasers', 'SuperCoursePurchasers',
    'PreviewCourseAttendees', 'AddCourseAttendees', 'ModifyCourseAttendees', 'DeleteCourseAttendees', 'ReportCourseAttendees', 'SuperCourseAttendees',
    'PreviewCourseReports', 'AddCourseReports', 'ModifyCourseReports', 'DeleteCourseReports', 'ReportCourseReports', 'SuperCourseReports']
}
export function userPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  return ['SuperAdmin',
    'PreviewUsers', 'AddUsers', 'ModifyUsers', 'DeleteUsers', 'SuperUsers',
    'PreviewTerminating', 'AddTerminating', 'ModifyTerminating', 'DeleteTerminating', 'SuperTerminating',
    'PreviewAddUser', 'AddAddUser', 'ModifyAddUser', 'DeleteAddUser', 'SuperAddUser',
    'PreviewSuspension', 'AddSuspension', 'ModifySuspension', 'DeleteSuspension', 'SuperSuspension',
    'PreviewUserCourses', 'AddUserCourses', 'ModifyUserCourses', 'DeleteUserCourses', 'SuperUserCourses',
    'PreviewSearching', 'AddSearching', 'ModifySearching', 'DeleteSearching', 'SuperSearching',
  ]
}

let permission = '';

const routes: Routes = [
  {
    path: 'preview/:id',
    component: PreviewComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  { path: '2facheck', component: TwofactorComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },

  {
    path: '',
    component: BaseComponent,

    children: [
      {
        path: 'new-affliate',
        loadChildren: () => import('./views/new-affliite/new-affliite.module').then(m => m.NewAffliiteModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },

      { path: 'changepassword', component: ChangepasswordComponent },

      {
        path: 'courses',
        loadChildren: () => import('./views/courses/courses.module').then(m => m.CoursesModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: testPermissions,
            redirectTo: '/unauthorized'
          }
        }
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'ReportCourses', 'SuperCourses',
              'ReportCourseManagement', 'SuperCourseManagement',
              'ReportCoursePurchasers', 'SuperCoursePurchasers',
              'ReportCourseAttendees', 'SuperCourseAttendees',
              'ReportCourseReports', 'SuperCourseReports',
              'ReportCoupons', 'SuperCoupons',
              'ReportCouponManagement', 'SuperCouponManagement',
              'ReportCouponReports', 'SuperCouponReports',
              'ReportCourseFiles', 'SuperCourseFiles',
              'ReportTrainers', 'SuperTrainers',
              'ReportTrainersApplicationsExport', 'SuperTrainersApplicationsExport',
              'ReportTrainersApplications', 'SuperTrainersApplications',
              'ReportCertificates', 'SuperCertificates',
              'ReportCertificatesReports', 'SuperCertificatesReports',
              'ReportCurricula', 'SuperCurricula',
              'ReportAffiliates', 'SuperAffiliates',
              'ReportAffiliatesSubscriptions', 'SuperAffiliatesSubscriptions',
              'ReportAffiliatesReports', 'SuperAffiliatesReports',
              'ReportBusiness', 'SuperBusiness',
              'ReportBusinessReports', 'SuperBusinessReports',
              'ReportBusinessCRM', 'SuperBusinessCRM',
              'ReportAddSubscription', 'SuperAddSubscription',
              'ReportManageSubscriptions', 'SuperManageSubscriptions',
              'ReportProgramPolicy', 'SuperProgramPolicy',
              'ReportSettings', 'SuperSettings',
              'ReportTicketSystem', 'SuperTicketSystem',
              'ReportGeneralSettings', 'SuperGeneralSettings',
              'ReportConsultancies', 'SuperConsultancies',
              'ReportConsultantsManagement', 'SuperConsultantsManagement',
              'ReportConsultancyManagement', 'SuperConsultancyManagement',
              'ReportConsultancyPurchasers', 'SuperConsultancyPurchasers',
              'ReportConsultancyAttendees', 'SuperConsultancyAttendees',
              'ReportConsultancyReport', 'SuperConsultancyReport',
              'ReportUsers', 'SuperUsers',
              'ReportTerminating', 'SuperTerminating',
              'ReportAddUser', 'SuperAddUser',
              'ReportSuspension', 'SuperSuspension',
              'ReportUserCourses', 'SuperUserCourses',
              'ReportSearching', 'SuperSearching',
            ],
            redirectTo: '/unauthorized'
          }
        }
      },
      {
        path: 'email',
        loadChildren: () => import('./views/mail/mail.module').then(m => m.MailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'coupons',
        loadChildren: () => import('./views/coupons/coupons.module').then(m => m.CouponsModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewCoupons', 'AddCoupons', 'ModifyCoupons', 'DeleteCoupons', 'ReportCoupons', 'SuperCoupons',
              'PreviewCouponManagement', 'AddCouponManagement', 'ModifyCouponManagement', 'DeleteCouponManagement', 'ReportCouponManagement', 'SuperCouponManagement',
              'PreviewCouponReports', 'AddCouponReports', 'ModifyCouponReports', 'DeleteCouponReports', 'ReportCouponReports', 'SuperCouponReports',

            ],
            redirectTo: '/unauthorized'
          }
        }
      },
      {
        path: 'videobank',
        loadChildren: () => import('./views/videobank/videobank.module').then(m => m.VideobankModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewCourses', 'AddCourses', 'ModifyCourses', 'DeleteCourses', 'ReportCourses', 'SuperCourses',
              'PreviewCourseManagement', 'AddCourseManagement', 'ModifyCourseManagement', 'DeleteCourseManagement', 'ReportCourseManagement', 'SuperCourseManagement',
              'PreviewCourseFiles', 'AddCourseFiles', 'ModifyCourseFiles', 'DeleteCourseFiles', 'ReportCourseFiles', 'SuperCourseFiles',

            ],
            redirectTo: '/unauthorized'
          }
        }
      },
      // {
      //   path: 'system',
      //   loadChildren: () => import('./views/system/system.module').then(m => m.SystemModule),
      //   canActivate: [AuthGuard, NgxPermissionsGuard],
      //   data: {
      //     permissions: {
      //       only: ['SuperAdmin',
      //         'PreviewSettings', 'AddSettings', 'ModifySettings', 'DeleteSettings', 'SuperSettings',
      //         'PreviewTicketSystem', 'AddTicketSystem', 'ModifyTicketSystem', 'DeleteTicketSystem', 'SuperTicketSystem',
      //         'PreviewGeneralSettings', 'AddGeneralSettings', 'ModifyGeneralSettings', 'DeleteGeneralSettings', 'SuperGeneralSettings',
      //       ],
      //       redirectTo: '/unauthorized'
      //     }
      //   },
      // },
      {
        path: 'trainers',
        loadChildren: () => import('./views/trainers/trainers.module').then(m => m.TrainersModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewTrainers', 'AddTrainers', 'ModifyTrainers', 'DeleteTrainers', 'ReportTrainers', 'SuperTrainers',
              'PreviewTrainersApplicationsExport', 'AddTrainersApplicationsExport', 'ModifyTrainersApplicationsExport', 'DeleteTrainersApplicationsExport', 'ReportTrainersApplicationsExport', 'SuperTrainersApplicationsExport',
              'PreviewTrainersApplications', 'AddTrainersApplications', 'ModifyTrainersApplications', 'DeleteTrainersApplications', 'ReportTrainersApplications', 'SuperTrainersApplications',
            ],
            redirectTo: '/unauthorized'
          }
        }
      },
      {
        path: 'certificates',
        loadChildren: () => import('./views/certificates/certificates.module').then(m => m.CertificatesModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewCourses', 'AddCourses', 'ModifyCourses', 'DeleteCourses', 'ReportCourses', 'SuperCourses',
              'PreviewCourseManagement', 'AddCourseManagement', 'ModifyCourseManagement', 'DeleteCourseManagement', 'ReportCourseManagement', 'SuperCourseManagement',
              'PreviewCertificates', 'AddCertificates', 'ModifyCertificates', 'DeleteCertificates', 'ReportCertificates', 'SuperCertificates',
              'PreviewCertificatesReports', 'AddCertificatesReports', 'ModifyCertificatesReports', 'DeleteCertificatesReports', 'ReportCertificatesReports', 'SuperCertificatesReports',
            ],
            redirectTo: '/unauthorized'
          }
        }
      },
      {
        path: 'curricula',
        loadChildren: () => import('./views/curriculum/curriculum.module').then(m => m.CurriculumModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewCurricula', 'AddCurricula', 'ModifyCurricula', 'DeleteCurricula', 'SuperCurricula',
            ],
            redirectTo: '/unauthorized'
          }
        },
      },
      {
        path: 'affiliates',
        loadChildren: () => import('./views/affiliates/affiliates.module').then(m => m.AffiliatesModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewAffiliates', 'AddAffiliates', 'ModifyAffiliates', 'DeleteAffiliates', 'SuperAffiliates',
              'PreviewAffiliatesSubscriptions', 'AddAffiliatesSubscriptions', 'ModifyAffiliatesSubscriptions', 'DeleteAffiliatesSubscriptions', 'SuperAffiliatesSubscriptions',
              'PreviewAffiliatesReports', 'AddAffiliatesReports', 'ModifyAffiliatesReports', 'DeleteAffiliatesReports', 'SuperAffiliatesReports',
            ],
            redirectTo: '/unauthorized'
          }
        },
      },
      
      // No need 
      {
        path: 'b2b',
        loadChildren: () => import('./views/b2b/b2b.module').then(m => m.B2bModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewBusiness', 'AddBusiness', 'ModifyBusiness', 'DeleteBusiness', 'SuperBusiness',
              'PreviewBusinessReports', 'AddBusinessReports', 'ModifyBusinessReports', 'DeleteBusinessReports', 'SuperBusinessReports',
              'PreviewBusinessCRM', 'AddBusinessCRM', 'ModifyBusinessCRM', 'DeleteBusinessCRM', 'SuperBusinessCRM',
              'PreviewAddSubscription', 'AddAddSubscription', 'ModifyAddSubscription', 'DeleteAddSubscription', 'SuperAddSubscription',
              'PreviewManageSubscriptions', 'AddManageSubscriptions', 'ModifyManageSubscriptions', 'DeleteManageSubscriptions', 'SuperManageSubscriptions',
              'PreviewProgramPolicy', 'AddProgramPolicy', 'ModifyProgramPolicy', 'DeleteProgramPolicy', 'SuperProgramPolicy',
            ],
            redirectTo: '/unauthorized'
          }
        },
      },
      {
        path: 'myprofile',
        loadChildren: () => import('./views/myprofile/myprofile.module').then(m => m.MyprofileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewSettings', 'AddSettings', 'ModifySettings', 'DeleteSettings', 'SuperSettings',
              'PreviewTicketSystem', 'AddTicketSystem', 'ModifyTicketSystem', 'DeleteTicketSystem', 'SuperTicketSystem',
              'PreviewGeneralSettings', 'AddGeneralSettings', 'ModifyGeneralSettings', 'DeleteGeneralSettings', 'SuperGeneralSettings',
            ],
            redirectTo: '/unauthorized'
          }
        },
      },
      {
        path: 'reviews',
        loadChildren: () => import('./views/oobf/oobf.module').then(m => m.OobfModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewReviews','ReportReviews',
              'PreviewSettings', 'AddSettings', 'ModifySettings', 'DeleteSettings', 'SuperSettings',
              'PreviewTicketSystem', 'AddTicketSystem', 'ModifyTicketSystem', 'DeleteTicketSystem', 'SuperTicketSystem',
              'PreviewGeneralSettings', 'AddGeneralSettings', 'ModifyGeneralSettings', 'DeleteGeneralSettings', 'SuperGeneralSettings',
            ],
            redirectTo: '/unauthorized'
          }
        },
      },
      {
        path: 'consultancy',
        loadChildren: () => import('./views/consultancy/consultancy.module').then(m => m.ConsultancyModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['SuperAdmin',
              'PreviewConsultancies', 'AddConsultancies', 'ModifyConsultancies', 'DeleteConsultancies', 'SuperConsultancies',
              'PreviewConsultantsManagement', 'AddConsultantsManagement', 'ModifyConsultantsManagement', 'DeleteConsultantsManagement', 'SuperConsultantsManagement',
              'PreviewConsultancyManagement', 'AddConsultancyManagement', 'ModifyConsultancyManagement', 'DeleteConsultancyManagement', 'SuperConsultancyManagement',
              'PreviewConsultancyPurchasers', 'AddConsultancyPurchasers', 'ModifyConsultancyPurchasers', 'DeleteConsultancyPurchasers', 'SuperConsultancyPurchasers',
              'PreviewConsultancyAttendees', 'AddConsultancyAttendees', 'ModifyConsultancyAttendees', 'DeleteConsultancyAttendees', 'SuperConsultancyAttendees',
              'PreviewConsultancyReports', 'AddConsultancyReport', 'ModifyConsultancyReport', 'DeleteConsultancyReport', 'SuperConsultancyReport',
            ],
            redirectTo: '/unauthorized'
          }
        },
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: userPermissions,
            redirectTo: '/unauthorized'
          }
        },
      },
       
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' }
    ]
  },
  { path: 'reset', component: ResetComponent },
  { path: 'confirmed/token/:id/userid/:id2', component: ConfirmedComponent },
  { path: 'resetpassword/token/:id/userid/:id2', component: ResetformComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'callback', component: CallbackComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
    
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
