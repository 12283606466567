import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { TimezonePickerService } from '../../../core/services/timezones.service';
import { NotfiyService, CoursesService } from '../../../core/services';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'sm-schedulemeeting',
  templateUrl: './schedulemeeting.component.html',
  styleUrls: ['./schedulemeeting.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulemeetingComponent implements OnInit {

  submitted = false;
  newCourse: UntypedFormGroup;
  button: string = '';
  profile: any;
  @Input() materialId;
  loading
  fHour
  allTimezones: any[] = [];
  selectedTimeZone
  public format = 'MM/dd/yyyy HH:mm';
  time = new FormControl(this.getCurrentTime()); // Initialize with a default value

  constructor(private cd: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, public ngNoty: NotfiyService, private _translate: TranslateService, private _store: Store<any>,
    @Inject('API_URL') private originUrl: string, private sanitizer: DomSanitizer, private _router: Router, private dragulaService: DragulaService, private _timezones: TimezonePickerService,
    private route: ActivatedRoute, private _course: CoursesService) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.button = this._translate.instant('COURSES.ADDCONTENT');
    this.allTimezones = _timezones.getZones();
  }

  get xf() { return this.newCourse.controls; }


  ngOnInit(): void {

    
    this.newCourse = this.formBuilder.group({
      id: null,
      nameOfMeeting: [{ value: '', disabled: this.loading }],
      meetingName: [{ value: '', disabled: this.loading }],
      type: 'GoToMeeting',
      roomId: 0,
      meetingId: null,
      date: new Date(),
      isPublic: null,
      isRecoreded: null,
      timezone: null,
      meetingID: null,
      password: null,
      attendeePW: null,
      moderatorPW: null,
      fileId: null,
      isFree: false,
      materialId: this.materialId,
    });
  }



  onSubmit() {    
    this.newCourse.patchValue({
      date:this.isoDateTime
    })
    this._course.addMeetingContentToMaterial(this.newCourse.value).subscribe((data) => {
      // console.log(data);
      
    })
  } 

  get isoDateTime(): string | null {
    const date = this.newCourse.get('date')?.value; // e.g., Sun Dec 01 2024
    const time = this.time.value; // e.g., 14:51
    
    if (date && time) {
      const [hours, minutes] = time.split(':');
      const combinedDate = new Date(date);
      combinedDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0); // Set hours and minutes
  
      // Adjust to UTC by subtracting the timezone offset
      const adjustedDate = new Date(combinedDate.getTime() - combinedDate.getTimezoneOffset() * 60000);
  
      return adjustedDate.toISOString(); // ISO 8601 format
    }
  
    return null; // Return null if either field is empty
  }
  

  getCurrentTime(): string {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

}
