import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from '../../../../app/core/services';
import { AlertType } from '../../../../app/core/_models/system';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';



export interface UnitDialog {
  courseId: number,
  hasVideo: boolean,
  hasLiveMeeting: boolean,
  id: number
  isNew: boolean
  oldItem: any
}


@Component({
  selector: 'sm-courseunitdialog',
  templateUrl: './courseunitdialog.component.html',
  styleUrls: ['./courseunitdialog.component.scss']
})
export class CourseunitdialogComponent implements OnInit {

  submitted = false;
  currentCourse
  newCourse: UntypedFormGroup;
  get xf() { return this.newCourse.controls; }
  loading = false;

  fileToUpload: File = null;
  fileToUpload2: File = null;
  @ViewChild(FileuploaderComponent, { static: false }) uploader: FileuploaderComponent;
  public profile: any = {};



  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    private _store: Store<any>, public dialogRef: MatDialogRef<CourseunitdialogComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UnitDialog) {
    this.currentCourse = data.courseId;
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }


  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      courseId: this.currentCourse | this.currentCourse,
      image: [{ value: '', disabled: this.loading }],
      video: [{ value: '', disabled: this.loading }],
      description: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
      order: 0,
      addedDate: new Date()
    });
    if(!this.data.isNew){
      this.modify(this.data.oldItem);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;
    // console.log(this.newCourse.value);

    var courseData = {
      files: this.fileToUpload,
      course: this.newCourse.value
    }
    this._store.dispatch({ type: '[Unites] Add New Unites', unitData: courseData });
    // this.uploader.removeUpload(0);
    this.newCourse.reset();
    this.submitted = false;

    this.newCourse.patchValue({
      courseId: (this.currentCourse.Id | this.currentCourse.id),
      addedBy: parseInt(this.profile.userId),
      addedDate: new Date()
    })
    this.dialogRef.close({data: true});
  }


  modify(dataItem) {



    this.newCourse.patchValue({
      id: (dataItem.id) ? dataItem.id : dataItem.id | dataItem.Id,
      name: dataItem.name,
      courseId: (this.currentCourse | this.currentCourse),
      image: dataItem.image,
      video: dataItem.video,
      description: dataItem.description,
      addedBy: dataItem.enteredBy,
    });

  }



}
