import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { TimezonePickerService } from '../../../core/services/timezones.service';
import { NotfiyService, CoursesService } from '../../../core/services';

@Component({
  selector: 'sm-schedulemeeting',
  templateUrl: './schedulemeeting.component.html',
  styleUrls: ['./schedulemeeting.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulemeetingComponent implements OnInit {

  submitted = false;
  newCourse: UntypedFormGroup;
  button: string = '';
  profile: any;
  @Input() materialId;
  loading
  fHour
  allTimezones: any[] = [];
  selectedTimeZone
  public format = 'MM/dd/yyyy HH:mm';

  constructor(private cd: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, public ngNoty: NotfiyService, private _translate: TranslateService, private _store: Store<any>,
    @Inject('API_URL') private originUrl: string, private sanitizer: DomSanitizer, private _router: Router, private dragulaService: DragulaService, private _timezones: TimezonePickerService,
    private route: ActivatedRoute, private _course: CoursesService) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.button = this._translate.instant('COURSES.ADDCONTENT');
    this.allTimezones = _timezones.getZones();
  }

  get xf() { return this.newCourse.controls; }


  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      nameOfMeeting: [{ value: '', disabled: this.loading }],
      meetingName: [{ value: '', disabled: this.loading }],
      type: 'GoToMeeting',
      roomId: 0,
      meetingId: null,
      date: new Date(),
      isPublic: null,
      isRecoreded: null,
      timezone: null,
      meetingID: null,
      password: null,
      attendeePW: null,
      moderatorPW: null,
      fileId: null,
      isFree: false,
      materialId: this.materialId,
    });
  }



  onSubmit() {
    console.log(this.newCourse.value);
    this._course.addMeetingContentToMaterial(this.newCourse.value).subscribe((data) => {
      console.log(data);
      
    })
  } 

}
