import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, NotfiyService, SettingsService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';


interface CourseToCurriculumData {
  curriculumId: number;
  allInCourses: any[]
  allCourses: any[]
}

@Component({
  selector: 'sm-newcoursetocurriculum',
  templateUrl: './newcoursetocurriculum.component.html',
  styleUrls: ['./newcoursetocurriculum.component.scss']
})
export class NewcoursetocurriculumComponent implements OnInit {


  public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  curriculumId = 0;
  allCourses = []
  allInCourses = []
  button = ''


  constructor(private _service: CoursesService, private cd: ChangeDetectorRef, private _router: Router, @Inject(MAT_DIALOG_DATA) public data: CourseToCurriculumData,
    private _dialogRef: MatDialogRef<NewcoursetocurriculumComponent>, private _dialog: MatDialog, private _translate: TranslateService, private _report: ReportsService,
    private _store: Store<any>, private _settings: SettingsService, private ngNoty: NotfiyService, private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.curriculumId = data.curriculumId;
    this.allInCourses = data.allInCourses;
    this.allCourses = data.allCourses ;
    this.button = this._translate.instant('DIALOGS.SAVE');
  }


  ngOnInit(): void {
    let self = this;

    this.newCourse = this.formBuilder.group({
      id: null,
      curriculaId: this.curriculumId,
      thecourses: '',
      courseId: null,
      required: [{ value: false, disabled: this.loading }],
    });
    this.onChanges();

    // this._service.AllCourses().subscribe({
    //   next(value) {
    //     self.allCourses = value;
    //   },
    //   complete() {

    //   },
    //   error(err) {

    //   },
    // })
  }


  onChanges(): void {

    this.newCourse.get('thecourses').valueChanges.subscribe(val => {

      // console.log(val);


      if (val) {
        // console.log(val);
        
        var course = this.allCourses.find(x => x.name == val);
        // console.log(course);
        
        this.newCourse.patchValue({
          'courseId': course.id | course.Id
        })
      }
    })

  }
  get xf() { return this.newCourse.controls; }


  onSubmit() {

    let self = this;
    var courseName = this.newCourse.get('thecourses').value;
    var coursRequired = this.newCourse.get('required').value;
    if (courseName) {
      // console.log(courseName);
      
      var course = this.allCourses.find(x => x.name == courseName);
     
      
      this.newCourse.patchValue({
        'courseId': course.id | course.Id
      })
    }
    // console.log(course);
    // console.log(this.allInCourses.find(x => x.Id == this.newCourse.get('courseId').value || x.id == this.newCourse.get('courseId').value));


    if (this.allInCourses.find(x => x.courseId == this.newCourse.get('courseId').value ))
      return;

    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    var attr = [
      this.newCourse.value
    ];
    // console.log(this.newCourse.value);
    this.submitted = true;
    this._service.addCourseToCurriculas(attr).subscribe({
      next(value) {
        self._dialogRef.close({ data: true });
      },
      complete() {

      },
      error(err) {

      },
    })

  }


}
