import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderComponent } from './loader.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule, NgxSpinnerModule
  ],
  exports: [LoaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoaderModule { }
