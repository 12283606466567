import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sm-mobilemenu',
  templateUrl: './mobilemenu.component.html',
  styleUrls: ['./mobilemenu.component.css']
})
export class MobilemenuComponent implements OnInit {





  profile: any;
  loading;
  lang = '';

  constructor(private cd: ChangeDetectorRef, private _store: Store<any>,) { }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    this.lang = localStorage.getItem('language');
    // console.log(this.lang);

    this.cd.markForCheck();
  }
}
