import { SettingsService } from '../../core/services';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../_models/system';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';

//declare var LocalStorage: Storage;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {



  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;


  constructor(private http: HttpClient, @Inject('API_URL') private originUrl: string,
    private _settings: SettingsService, private permissionsService: NgxPermissionsService,
    private _router: Router, private _store: Store<any>) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();

  }

  public get userValue(): User {
    if (this.userSubject.value) {
      var profile = {
        avatar: this.userSubject.value.avatar,
        name: this.userSubject.value.name,
        email: this.userSubject.value.email,
        userId: this.userSubject.value.userId,
        groupId: this.userSubject.value.groupId,
      }
      const sv = {
        tk: this.userSubject.value.jwtToken,
        rt: this.userSubject.value.refreshToken
      }

      localStorage.setItem('mprofile', JSON.stringify(profile));
      localStorage.setItem('sv', JSON.stringify(sv));
      // this._store.dispatch({ type: '[Reports] Get My Coupons', userId: this.userSubject.value.userId });
      this._store.dispatch({ type: '[Profile] User Is Logged In' })
    }
    return this.userSubject.value;
  }



  //, @Inject(LocalStorage) private localStorage
  login(loginName: string, password: string) {
    return this.http.post<any>(this.originUrl + '/account/authenticatea', { loginName: loginName, password: password }, { withCredentials: true })
    // .pipe(map(user => {
    //   this.userSubject.next(user);
    //   this.startRefreshTokenTimer();
    //   return user;
    // }));
    // login successful if there's a jwt token in the response
  }


  login2fa(attrs) {
    return this.http.post<any>(this.originUrl + '/account/2facheck', attrs, { withCredentials: true })
      .pipe(map(user => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
    // login successful if there's a jwt token in the response
  }

  logout() {
    this.http.post<any>(this.originUrl + '/account/revoke-token', {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    localStorage.removeItem('permis')
    localStorage.removeItem('mprofile')
    localStorage.removeItem('sv')
    this.userSubject.next(null);
    this._router.navigate(['/login']);
  }

  public timeOut = 0;


  refreshToken() {
    const rt: any = JSON.parse(localStorage.getItem('sv'))
    const profile: any = JSON.parse(localStorage.getItem('mprofile'))
    if (rt) {
      const jwtToken = JSON.parse(atob(rt.tk.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.timeOut = timeout;
      if (timeout > 1000 && profile.name) {
        let user: any = {
          avatar: profile.avatar,
          name: profile.name,
          email: profile.email,
          userId: profile.userId,
          groupId: profile.groupId,
          jwtToken: rt.tk,
          refreshToken: rt.rt
        }
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return new Observable<User>(user).pipe(map((user) => {
          return user;
        }))
      }
    }

    return this.http.post<any>(this.originUrl + '/account/refresh-token', {}, { withCredentials: true })
      .pipe(map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);

    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  load() {
    return
  }

  getMyPermissions() {
    this._settings.getMyPermissions().subscribe((data: any[]) => {
      const roles = data.map(a => a.module);
      localStorage.setItem('permis', JSON.stringify(roles))
      this.permissionsService.loadPermissions(roles);
    })
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  register(model: any) {

    return this.http.post(this.originUrl + '/account/register', model);

  }

  reset(model: any) {

    return this.http.post(this.originUrl + '/account/reset', model);

  }


  private jwt() {
    // create authorization header with jwt token
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('mprofile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }



}
