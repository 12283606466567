import { AlertType } from './../../../../core/_models/system';
import { AlertMessageComponent } from './../../../dialogs/alert-message/alert-message.component';
import { Constants } from './../../../../core/services/constants';
import { MeetingType } from './../../../../core/_models/settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { TimezonePickerService } from '../../../../core/services/timezones.service';
import { NotfiyService, CoursesService } from '../../../../core/services';
import * as moment from 'moment';
declare var Noty: any;
declare var $: any;
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface MeetinData {
  courseId: number;
  isNew?: boolean;
  oldItem?: any;
}


@Component({
  selector: 'sm-bbbmeeting',
  templateUrl: './bbbmeeting.component.html',
  styleUrls: ['./bbbmeeting.component.scss'],
  providers: [Constants],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BbbmeetingComponent implements OnInit {

  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    // return this.invalidDates.some(d => d.isSame(m, 'day'))
    return   m.isBefore(moment().get('day'))
      // this.invalidDates.some(d => d.isBefore(m, 'day'))
  }
  today = moment();

  locale = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'rtl', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'موافق', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'فترة مخصصة',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: 0 // first day is monday
  }

  submitted = false;
  newCourse: UntypedFormGroup;
  button: string = '';
  profile: any;
  courseId;
  loading
  fHour
  allTimezones: any[] = [];
  selectedTimeZone
  datetime;
  public format = 'MM/dd/yyyy HH:mm';
  isNew = true;
  oldItem

  constructor(private cd: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, public ngNoty: NotfiyService, private _translate: TranslateService, private _store: Store<any>, private _constants: Constants,
    public dialogRef: MatDialogRef<BbbmeetingComponent>, @Inject(MAT_DIALOG_DATA) public data: MeetinData, private _dialog: MatDialog, @Inject('API_URL') private originUrl: string, private sanitizer: DomSanitizer, private _router: Router, private dragulaService: DragulaService, private _timezones: TimezonePickerService,
    private route: ActivatedRoute, private _course: CoursesService) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.button = this._translate.instant('MEETINGS.ADDNEWMEETING');
    this.allTimezones = _timezones.getZones();
    this.courseId = data.courseId;
  }

  get xf() { return this.newCourse.controls; }


  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    this.newCourse = this.formBuilder.group({
      id: null,
      meetingId: null,
      meetId: null,
      duration: [{ value: '', disabled: this.loading }, Validators.required],
      password: this._constants.randomStringGenerator(8),
      timezone: [{ value: '', disabled: this.loading }, Validators.required],
      nameOfMeeting: [{ value: '', disabled: this.loading }, Validators.required],
      meetingName: [{ value: '', disabled: this.loading }],
      date: new Date(),
      isPublic: null,
      isRecoreded: null,
      meetingID: null,
      attendeePW: null,
      moderatorPW: null,
      fileId: null,
      isFree: false,
    });
    
    if (!this.data.isNew) {
      this.isNew = this.data.isNew
      this.oldItem = this.data.oldItem;
      this.modify(this.oldItem);
    }
  }


  modify(dataItem) {
    // console.log(dataItem);
    dataItem = dataItem.bbbMeeting;
    // console.log(dataItem);
    this.datetime = dataItem.date;
    this.newCourse.patchValue({
      id: dataItem.id,
      meetingId: dataItem.meetingId,
      meetId: dataItem.meetId,
      duration: dataItem.duration,
      password: dataItem.password,
      timezone: dataItem.timezone,
      nameOfMeeting: dataItem.nameOfMeeting,
      meetingName: dataItem.meetingName,
      date: dataItem.date,
      isPublic: dataItem.isPublic,
      isRecoreded: dataItem.isRecoreded,
      meetingID: dataItem.meetingID,
      attendeePW: dataItem.attendeePW,
      moderatorPW: dataItem.moderatorPW,
      fileId: dataItem.fileId,
      isFree: dataItem.isFree
    })
    // console.log(this.newCourse.value);

  }



  onSubmit() {

    var datex = moment(this.datetime);
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this.newCourse.patchValue({
      start_time: datex.utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss')
    })
    var meeting;

    if (!this.isNew) {
      // console.log(this.oldItem);
      
      meeting = {
        id: this.oldItem.id ,
        type: MeetingType.BBB,
        courseId: this.courseId,
        addedBy: this.oldItem.addedBy,
        addedAt: this.oldItem.addedAt,
        bbbMeeting: this.newCourse.value
      };
    } else {
      meeting = {
        type: MeetingType.BBB,
        courseId: this.courseId,
        addedBy: this.profile.userId,
        addedAt: moment().utc().toDate(),
        bbbMeeting: this.newCourse.value
      };
    }

    // console.log(meeting);
    
    this._course.addMeeting(meeting).subscribe((data) => {
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.MEETINGADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe(() => {
        this.dialogRef.close({ data: true })
      })
    }, error => {
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })

    })
  }


}
