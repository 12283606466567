import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AlertType } from '../../../core/_models/system';
import { CoursesService, NotfiyService, AffiliateService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';
import { ActivityquestionanswerComponent } from '../activityquestionanswer/activityquestionanswer.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from '../alertconfirmmessage/alertconfirmmessage.component';
import { QuizzquestionanswerComponent } from '../quizzquestionanswer/quizzquestionanswer.component';



export interface QuestionAnswers {
  id: number;
  quizzId: number;
  selectedQuestion: any
}




@Component({
  selector: 'sm-quizzquizzmanage',
  templateUrl: './quizzquizzmanage.component.html',
  styleUrls: ['./quizzquizzmanage.component.scss']
})
export class QuizzquizzmanageComponent implements OnInit, AfterViewInit {

  loading = false;

  lang = 'ar'
  profile;


  allAnswers = [];
  selectedQuestion
  newFillin: UntypedFormGroup;


  //New Table
  dataSource: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  resultsLength = 0;
  //End New Table
  questionWords = []
  private questionbody = '';
  get question() {
    return this.questionbody;
  }
  set question(text: any) {
    this.questionbody = text;
    if (text) {
      this.questionWords = text.split(" ");
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private _course: CoursesService, private _router: Router, public ngNoty: NotfiyService,
    @Inject(MAT_DIALOG_DATA) public data: QuestionAnswers, private dialogRef: MatDialogRef<QuizzquizzmanageComponent>,
    private _dialog: MatDialog, private _courses: CoursesService, private cd: ChangeDetectorRef, private _translate: TranslateService, private _store: Store<any>, private _report: ReportsService
    , private _affiliate: AffiliateService, private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
    this.selectedQuestion = data.selectedQuestion;
  }



  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {

    this.loadData();
    this.newFillin = this.formBuilder.group({
      id: this.selectedQuestion.id,
      question: null,
      questionType: 'Text',
      score: null,
      optional: null,
      quizzId: this.data.quizzId,
      body: [{ value: '', disabled: this.loading }],
      stripped: [{ value: '', disabled: this.loading }],
      words: ['']
    });

  }

  selectedWords = [];


  select(i) {
    // console.log(i);

    var div: any = document.getElementById("word-" + i);
    div.style.border = '1px solid green';
    div.style.background = 'green';
    div.style.color = 'white';
    var word = this.questionWords[i];
    // console.log(this.selectedWords);
    var isSelected = this.selectedWords.find(x => x.word == word);
    if (isSelected) {
      // console.log('The Word Is Selected');

      div.style.border = '1px solid black';
      div.style.background = 'black';
      div.style.color = 'white';
      this.selectedWords = this.selectedWords.filter((x) => { return x != isSelected });
    } else {
      // console.log('not exstid');

      var wordToAdd = {
        order: i,
        word: word
      };
      this.selectedWords.push(wordToAdd);
    }
    this.cd.markForCheck();
  }


  submitted = false;

  onSubmitFillIn() {
    this.submitted = true;
    if (this.newFillin.invalid) {
      return;
    }
    var stripped = '';
    var questionInWords = this.question.split(' ');


    for (let i = 0; i < questionInWords.length; i++) {
      const element = questionInWords[i];
      if (this.selectedWords.findIndex(x => x.order == i) != -1) {

      } else {
        stripped += element + " "
      }
    }

    this.loading = true;


    this.newFillin.patchValue({
      // title: this.newFillin.get('title').value,
      body: this.question,
      stripped: stripped
    })

    var words = [];
    this.selectedWords.forEach(element => {
      words.push({
        questionId: this.selectedQuestion.id,
        order: element.order,
        word: element.word
      })
    });
    // console.log(this.selectedWords);


    this.newFillin.patchValue({
      words: words
    });


    // this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });

    // console.log(this.newSolution.value);
    this.newFillin.patchValue({
      id: this.selectedQuestion.id,
      question: this.selectedQuestion.question,
      questionType: 'Text',
      score: this.selectedQuestion.score,
      optional: this.selectedQuestion.optional,
      quizzId: this.data.quizzId,
    })
    // console.log(this.newFillin.value);
    // return;
    this._course.addFillinQuestionToQuizz(this.newFillin.value).subscribe((data) => {
      this.dialogRef.close({ result: true });
    })

  }


  loadData() {
    this.dataSource = []
    this.resultsLength = 0;
    this.cd.markForCheck();
    this._course.GetQuestionById(this.data.id).subscribe((data: any) => {
      // console.log(data);
      if (data.questionType == 3) {
        this.question = data.body;
        setTimeout(() => {
          data.words.forEach(element => {
            this.select(element.order);
            this.cd.markForCheck();
          });
        }, 500);

      }
      if (data.answer.length != 0) {
        this.allAnswers = data.answer;
        this.cd.detectChanges()
      } else {
        this.allAnswers = data.pairs;
        this.cd.detectChanges()
      }
      // console.log(this.allAnswers);

      this.dataSource = new MatTableDataSource(this.allAnswers)
      this.resultsLength = this.allAnswers.length;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.cd.markForCheck();
    })

  }

  openNewAnswer() {
    const dialog = this._dialog.open(QuizzquestionanswerComponent, {
      width: '90%',
      minHeight: '55%',
      disableClose: true,
      data: { isNew: true, oldAnswer: null, quizzId: this.data.quizzId, selectedQuestion: this.selectedQuestion }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data && data.result) {
        this.loadData();
      }
    })
  }


  modifyTheAnswer(dataItem) {


    const dialog = this._dialog.open(QuizzquestionanswerComponent, {
      width: '90%',
      minHeight: '55%',
      data: { isNew: false, oldAnswer: dataItem, quizzId: this.data.quizzId, selectedQuestion: this.selectedQuestion }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data && data.result) {
        this.loadData();
      }
    })



  }



  deleteContactx(dataItem) {



    let self = this;
    const dialogRef = this._dialog.open(AlertconfirmmessageComponent, {
      height: '200px',
      width: '400px'
    })
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var id = dataItem.id | dataItem.Id;


        if (self.selectedQuestion.questionType == 6) {
          self._course.deletePairOfAnswers(id).subscribe({

            next(value) {
              var alertData = { title: self._translate.instant('DIALOGS.DONE'), body: self._translate.instant('DIALOGS.ELEMENTDELTED'), type: AlertType.Success }
              self._dialog.open(AlertMessageComponent, {
                data: alertData,
                height: '200px',
                width: '400px'
              })
            },
            complete() {
              self.loadData();
            },
            error(err) {

            },
          })

        } else {
          self._course.DeleteAnswer(id).subscribe({
            next(value) {
              var alertData = { title: self._translate.instant('DIALOGS.DONE'), body: self._translate.instant('DIALOGS.ELEMENTDELTED'), type: AlertType.Success }
              self._dialog.open(AlertMessageComponent, {
                data: alertData,
                height: '200px',
                width: '400px'
              })
            },
            complete() {
              self.loadData();
            },
            error(err) {

            },
          })
        }

      } else {
      }
    })




  }


}
