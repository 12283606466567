import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RTL } from '@progress/kendo-angular-l10n';
import { DragulaService } from 'ng2-dragula';
import { B2BService, NotfiyService, SystemService } from '../../../core/services';
declare var Noty: any;
declare var $: any;
import Bounce from 'bounce.js';
import * as moment from 'moment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { B2BProfile, B2BStatus, B2BCommunicationLogDTO, B2BTimelineDTO } from '../../../core/_models';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';

export interface b2bContractata {
  companyId: number,
  oldItem: any
}


@Component({
  selector: 'sm-b2bcontractdialog',
  templateUrl: './b2bcontractdialog.component.html',
  styleUrls: ['./b2bcontractdialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class B2bcontractdialogComponent implements OnInit {


  submitted = false;
  newContract: UntypedFormGroup;
  profile
  loading = false;
  hasError = false;
  errorMsg = '';
  get xfx() { return this.newContract.controls; }
  lang = 'ar';
  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'This Year': [moment().startOf('year'), moment().endOf('year')],
    'Next Year': [moment().add(1, 'year').startOf('year'), moment().add(1, 'year').endOf('year')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  locale = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'rtl', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'موافق', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'فترة مخصصة',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: 0 // first day is monday
  }


  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private cd: ChangeDetectorRef,
    private _translate: TranslateService, private ngNoty: NotfiyService, private _store: Store<any>, private _service: B2BService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private dragulaService: DragulaService
    , public dialogRef: MatDialogRef<B2bcontractdialogComponent>, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: b2bContractata
  ) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
  }

  ngOnInit(): void {
    this.newContract = this.formBuilder.group({
      id: null,
      userId: this.profile.userId,
      companyId: this.data.companyId,
      selected: [{ value: '' }],
      liveDiplomas: false,
      recordedDiplomas: false,
      date: moment.utc(new Date()).toDate(),
      fromDate: [{ value: '', disabled: this.loading }],
      toDate: [{ value: '', disabled: this.loading }],
      numberOfUsers: [{ value: '', disabled: this.loading }, Validators.required],
      numberOfCourses: [{ value: '', disabled: this.loading }, Validators.required],
      numberOfConsultancies: [{ value: '', disabled: this.loading }, Validators.required],
      numberOfPackages: [{ value: '', disabled: this.loading }, Validators.required],
      totalPayment: [{ value: '', disabled: this.loading }, Validators.required],
    });

  }

  submittedx
  onSubmitContract() {


    if (this.newContract.invalid) {
      return;
    }
    this.newContract.patchValue({
      fromDate: this.newContract.get('selected').value.startDate.format('LLL'),
      toDate: this.newContract.get('selected').value.endDate.format('LLL')
    })

    this.submittedx = true;
    this.loading = true;
    this._service.addContractToB2BCustomer(this.newContract.value).subscribe((data) => {
      this.dialogRef.close();
    })
  }

}
