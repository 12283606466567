import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, SettingsService, UserService } from '../../../../../src/app/core/services';
import { TrainersDialogComponent } from '../trainers-dialog/trainers-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReportsService } from '../../../../../src/app/core/services/reports.service';
import { Observable, Subscription } from 'rxjs';
import { Course, Materials } from '../../../../../src/app/core/_models';
import * as _reducer from '../../../core/state/_courses/course.reducer'

@Component({
  selector: 'sm-certified-dialog',
  templateUrl: './certified-dialog.component.html',
  styleUrls: ['./certified-dialog.component.css']
})
export class CertifiedDialogComponent implements OnInit {
  newCourse = new FormGroup({
    // Define 'parentx' form control with validation
    courseId: new FormControl(0 ),
    id: new FormControl(null, ),
    certificate: new FormControl('', [Validators.required]),
    status: new FormControl(1, ),
    certificateId: new FormControl(0, ),

  });
  submitted = false;
  allCountries = [];
  AssignmentsReport = [];
  noassignments = 0;
  country;
  public allTypes: any[] = [];
  courseSubscription = new Subscription();
  course$: Observable<Course>;
  currentCourse: any;
  allCertificates: Materials[] = [];


  constructor(private _course: CoursesService,private _settings: SettingsService,private _report: ReportsService,  private _translate: TranslateService,private _courses: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<CertifiedDialogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {
  
      // this.userId = this.profile.ident;
    }


  }
  ngOnInit(): void {
    this.getAllCertifiect()
    this.course$ = this._store.pipe(select(_reducer.getSelectedCourse));
    this.courseSubscription = this.course$.subscribe((data) => {
      this.currentCourse = data;
      this.newCourse.patchValue({
        courseId: this.currentCourse.Id | this.currentCourse.id
      })
    })
  }
  get xf() { return this.newCourse.controls; }

getAllCertifiect(){
  this._course.getAllCertificates().subscribe((data: any[]) => {
    this.allTypes = data;
    // this.handle(data);
  })
}

  onSubmit() {
    this.submitted = true;

    if (this.newCourse.invalid) {
      return;
    }
    var certificateName = this.newCourse.get('certificate').value;
    if (certificateName) {
      var certificate = this.allTypes.find(x => x.name == certificateName);
    }
    if (certificate) {
      this.newCourse.patchValue({
        certificateId: certificate.id | certificate.Id
      })
    }

    this._course.assignCertificateToCourse(this.newCourse.value).subscribe((data) => {
      this._course.getCertificatesForCourse(this.currentCourse.id | this.currentCourse.Id).subscribe((data: any[]) => {
        this.allCertificates = data;
        this.dialogRef.close( data );

        this.newCourse.reset();
        this.submitted = false;
        this.cd.markForCheck();
      });
    })
  }
  onClose(): void {
    // Close dialog and send data back to parent
    this.dialogRef.close();
  }
}
