import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Course, CourseTrainers } from '../../../../../src/app/core/_models';
import * as _reducer from '../../../core/state/_courses/course.reducer'
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, UserService } from '../../../../../src/app/core/services';

@Component({
  selector: 'sm-trainers-dialog',
  templateUrl: './trainers-dialog.component.html',
  styleUrls: ['./trainers-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TrainersDialogComponent implements OnInit {
  submitted = false;
  allStates:any[]=[]
  courseSubscription = new Subscription();
  course$: Observable<Course>;
  currentCourse: any;
  loading = false;
  allTrainers: CourseTrainers[] = [];
  button: string = '';
  progress: number = 0;

  public profile: any = {};

  newCourse = new FormGroup({
    // Define 'parentx' form control with validation
    parentx: new FormControl('', [Validators.required]),
    trainerId: new FormControl(0),
    id: new FormControl(null),
    courseId: new FormControl(0)
  });
constructor( private _translate: TranslateService,private _courses: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<TrainersDialogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

  if (typeof window !== 'undefined') {

    this.profile = JSON.parse(localStorage.getItem('mprofile'));
    // this.userId = this.profile.ident;
  }
}
  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });

    this.course$ = this._store.pipe(select(_reducer.getSelectedCourse));
    this.courseSubscription = this.course$.subscribe((data) => {
      this.currentCourse = data;
      this.newCourse.patchValue({
        courseId: this.currentCourse.Id | this.currentCourse.id
      })
      
    })
    
    this.getAllTranee()
  }
n
getAllTranee(){
  this._user.getAllTrainers().subscribe((data: any[]) => {
    this.allStates = data;
    this.cd.markForCheck();
  })
}

onSubmit() {
  this.submitted = true;

  if (this.newCourse.invalid) {
    return;
  }
  this.loading = true;
  var category = this.newCourse.get('parentx').value;
  if (category != '' && category != null && category != 'null') {
    this.newCourse.patchValue({
      trainerId: this.allStates.find(x => x.name == category).id | this.allStates.find(x => x.name == category).Id
    });

    // console.log(this.newCourse.value);
    
  } else {
    this.submitted = false;
    return;
  }
  if (this.allTrainers.find(x => x.trainerId == this.newCourse.get('trainerId').value)) {
    this.submitted = false;
    return;
  }
  this._courses.addTrainerToCourse(this.newCourse.value)

    .subscribe((data) => {
      this.newCourse.reset();
      this.submitted = false;
      this.button = this._translate.instant('COURSES.ADDTRAINER');
      this.newCourse.patchValue({
        courseId: this.currentCourse.Id | this.currentCourse.id
      })
      this._courses.getCourseTrainers(this.currentCourse.Id | this.currentCourse.id).subscribe((data: CourseTrainers[]) => {
       
        this.allTrainers = data;
        this.dialogRef.close( data );
        this.cd.markForCheck();
      });
    })


    
  // console.log(this.newCourse.value);

}
onClose(): void {
  // Close dialog and send data back to parent
  this.dialogRef.close();
}
}
