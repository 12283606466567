// China
export const locale = {
  lang: 'en',
  data: {
    MENU: {
      CREATE_POST: 'Welcome',
      HOME: 'Home',
      COURSES: 'Courses',
      CATEGORIES: 'Categories',
      EMAILS: 'Emails',
      COUPONS: 'Coupons',
      AFFILIATES: 'Affiliate System',
      B2B: 'B2B Customers',
      SETTINGS: 'Settings',
      USERS: 'Users',
      CURRICULUMS: 'Curriculas',
      VIDEOBANK: 'Files Bank',
      COURSECARD: 'Course Card',
      TRAINERS: 'Instructors',
      PUBLICVIDEOS: 'Published Videos',
      CERTIFICATES: 'Certificates',
      CONSULTANCY: 'Consultancies'
    },
    WIDGETS: {
      QUICKREPORTS: 'Quick Reports',
      TOTALUNPUBLISHEDCOURSES: 'Unpublished Courses',
      TOTALPUBLISHEDCOURSES: 'Published Courses',
      TOTALTRAINEES: 'Trainees',
      TOTALVIDEOSLENGTH: 'Videos Length',
      TOTALUNCONFIRMEDUSERS: 'Unconfirmed Users',
      TOTALCONFIRMEDUSERS: 'Confirmed Users',
      TOTALVIDEOS: 'Videos',
      TOTALPAIDORDERS: 'Confirmed Invoices',
      TOTALPAYMENTS: 'Total Payment',
      THEMOSTPOPULARCOURSE: 'Most Popular Course',
      HOURS: 'H'
    },
    PAYMENT: {
      STRIPE: 'Stripe'
    },
    SETTINGS: {
      SETTINGS: 'Settings',
      AWS: 'Amazon',
      AWSACCESSKEYID: 'Access Key ID',
      AWSSECRECTACCESSKEY: 'Access Key Secrey',
      ACTIVECAMPAIGN: 'Active Campaign',
      ACCOUNTID: 'Account Id',
      KEY: 'Key',
      STRIPE: 'Stripe',
      APIKEY: 'API Key',
      ACTIVE: 'Active',
      EMAILSETTINGS: 'Email Settings',
      MAILINGLISTS: 'Mailing Lists',
      SMSSETTINGS: 'SMS Settings',
      WEBCONFERENCESETTINGS: 'WebConference Settings',
      PAYMENTGATEWAYSETTINGS: 'Payment Gateway Settings',
    },
    ACTIVECAMPAIGN: {
      LISTS: 'Mailing Lists',
      LISTNAME: 'List Name',
      URLSTRING: 'URL String',
      SENDERURL: 'Sender Url',
      SENDERREMINDER: 'Sender Reminder'
    },
    CONSULTANCY: {
      CONSULTANCY: 'Consultancies',
      ACTIVE: 'Active',
      NEWCONSULTANCY: 'New Consultancy',
      ADDCONSULTANCY: 'Add Consultancy',
      MODIFYCONSULTANCY: 'Modify Consultancy',
      REQUIRED: 'Required',
      NAME: 'Name',
      CONSULTANT: 'Consultant',
      CONSULTANTS: 'Consultants',
      CONSULTANCIES: 'Consultancies',
      BODY: 'Consultancy Body',
      TIME: 'Consultancy Time',
      PRICE: 'Consultancy Price',
      IMAGE: 'Image',
      UPDATECALENDAR: 'Update Calendar',
      CALENDAR: 'Calendar',
      TYPE: 'Consultancy Type'
    },
    CONSULTANT: {
      NEW: 'New Consultant',
      NAME: 'Name',
      EMAIL: 'Email',
      AVATAR: 'Avatar',
      PHONE: 'Phone Number',
      NOTES: 'Notes',
      ADDCONSULTANT: 'Add Consultant',
      MODIFYCONSULTANT: 'Modify Consultant',
      MOREINFO: 'More Info',
      INFO: 'CV',
    },
    CERTIFICATES: {
      NEW: 'New Certificate',
      NAME: 'Certificate Name',
      DESCRIPTION: 'Certificate Description',
      SETTINGS: 'Certificate Details',
      NUMBEROFLOGOS: 'Number Of Logos',
      NUMBEROFTRAINERS: 'Number Of Trainers',
      ENDINGDATE: 'Course Ending Date',
      STARTINGDATE: 'Course Starting Date',
      DESIGN: 'Certificate Design',
      DETAILS: 'Certificate Details',
      TYPE: 'Type',
      SAVE: 'Save',
      CERTIFICATES: 'Manage Certificates',


      EXPORTTEST: 'Export A test Version'

    },
    FILEBANK: {
      MANAGE: 'Media Management',
      WIDGETS: 'Quick Widgets',
      UPLOAD: 'Upload',
      DRAGORCLICK: 'Click or drag files here to upload',
      SELECTFILE: 'Select File'
    },
    CATEGORIES: {
      NAME: 'Category Name',
      REQUIRED: 'Required',
      PARENT: 'Parent Category',
      DESCRIPTION: 'Category Description',
      KEYWORDS: 'Keywords',
      NOTES: 'Additional Notes',
      CATEGORIES: 'Categories',
      SELECTPARENTCATEGORY: 'Select Parent Category',
      ADDCATEGORY: 'Add Category',
      NEWCATEGORY: 'Add Category',
      UPDATECATEGORY: 'Update Category'
    },
    UNITES: {
      IMAGE: 'Unite Cover',
      NAME: 'Unite Name ',
      NOTES: 'Notes',
      NEW: 'Unites Management',
      DESCRIPTION: 'Unite Description',
    },
    MATERIALS: {
      NAME: 'Lecture Name',
      VIDEO: 'Video',
      CONTENTS: 'Lecture Contents',
      FILEBANK: 'Select From Media Bank',
      NEW: 'New Lecture',
      SUBMITQUESTION: 'Add Question',
      FALLBACKMINUTE: 'Fallback To Time',
    },
    MATERIAL: {
      SUBMITQUESTION: 'Submit Question',
      FALLBACKMINUTE: 'In Case Of Incorrect Question Fallback To'
    },
    CONTENTS: {
      AVAILABILITY: 'Available For Public',
      TYPE: 'Content Type'
    },
    CONTENT: {
      AVAILABILITY: 'Available For Public',
    },
    QUIZZES: {
      NEW: 'New Quizz',
      REQUIRED: 'Required',
      NAME: 'Name Of Quizz',
      PASSWORD: 'Password',
      TIMELIMIT: 'Allowed Timelimit',
      REPETITION: 'Number Of Repeat',
      IFPASSED: 'If Passed',
      IFFAILED: 'If Failed',
      UNITE: 'Unite',
      SHUFFLE: 'Random',
      SORTED: 'Sorted ',
      NOTES: 'Notes',
      NEWQUESTION: 'New Question',
      QUESTIONTYPE: 'Type Of Question',
      QUESTIONNAME: 'Question Name',
      QUESTIONNAMEREQUIRED: 'Required',
      FIRSTOFPAIR: 'First Part Of Sentence',
      SECONDOFPAIR: 'Second Part Of Sentence',
      ANSWER: 'Answer',
      EXPLANATION: 'Explanation',
      ISCORRECTANSWER: 'Correct Answer',
      MANAGE: 'Quizz Management',
      FINALQUIZZ: 'Final Quizz',
      ADDQUIZZ: 'Add Quizz',
      MODIFYQUIZZ: 'Modify Quizz',
      QUESTION: 'Question',
      ADDQUESTION: 'Add Question',
      MANAGEANSWERS: 'Manage Answers',
      SELECTQUESTIONTYPE: 'Select Question Type',
      ADDANSWER: 'Add Answer',
      OPTIONAL: 'Optional',
      BEFORESTART: 'Pre-Start Quizz',
      QUESTIONMARKREQUIRED: 'Required',
      QUESTIONMARK: 'Question Score',

    },
    VIDEOBANK: {
      MANAGE: 'Media Bank'
    },
    VIDEO: {
      NEW: 'New Video',
      ACTIVE: 'Active',
      HEADER: 'Header',
      BODY: 'Body'
    },
    FORM: {
      FILTER: 'Filter',
      SEARCHANDFILTER: 'You Can Search For Anything',
    },
    SYSTEM: {
      FROMDATE: 'From Date',
      TODATE: 'To Date',
      GETREPORT: 'Browse Report',
      COMMERCEREPORT: 'Commercial Report',
      NOTFOUND: 'Not Found',
      ADDIMAGE: 'Add File',
      DRAGIMAGE: 'You Can Drag and Drop A file Here',
      AREYOUSURE: 'Are You Sure',
      YES: 'Yes',
      LOGINFORM: 'Welcome...',
      ACTIVATE: 'Activate',
      EXPORTTEST: 'Export Test',
      SAVE: 'Save',
      NO: 'No',
      ACADEMYWELCOME: 'Welcome To Smart Panel',
      USERNAME: 'Username',
      PASSWORD: 'Password',
      LOGIN: 'Login',
      USERNAMEREQUIRED: 'Username is required',
      PASSWORDREUIRED: 'Password is required',
      QUICKWIDGETS: 'Quick Widgets',
      TOTALPAYMENT: 'Total Payment',
      CONFIRMEDUSERS: 'Confirmed Users',
      AVAILABLECOURSES: 'Available Courses',
      TOTALCOURSES: 'Total Courses',
      UNCONFIRMEDUSERS: 'Unconfimed Users',
      MOSTCOURSE: 'Best Courses',
      TOTALFILES: 'Total Uploaded Files',
      UPLOADFILE: 'Upload File',
      FILENAME: 'File name ',
      FILETYPE: 'File type',
      SELECTFILE: 'Select file',
      SELECT: 'Select',
      FILESIZE: 'File size',
      SAVEORDER: 'Save Order',
      GENERATEREPORT: 'Generate Report',
      SENDEMAIL: 'Send Email',
      ADDCERTIFICATE: 'Add Certificate',
      CERTIFICATENAME: 'Certificate Name',
      DESCRIPTION: 'Certificate Description',
      MANAGECERTIFICATE: 'Manage Certificate',
      UPDAETSETTINGS: 'Update Certificate Settings',
      SEARCHFORUSER: 'Search For User',
      CONFIGURE: 'Configure',
      CONFIGUREC: 'Design',
      DEACTIVATE: 'Deactivate',
      ERROR: 'Error',
      SUCCESS: 'Successed!',
      WELCOME: 'Welcome',
      ADMINISTRATOR: 'Administrator',
      HEADER: 'Control Panel',
      QUICKLINKS: 'Quick Widgets',
      SEARCHFORCUSTOMER: 'Search For User',
      COMMENTSONVIDEOS: 'Comments On Videos',
      COURSENAME: 'Course Name',
      UNITNAME: 'Unit Name',
      LECTURENAME: 'Lecture Name',
      MESSAGE: 'Message',
      SUBMIT: 'Save',
      CLOSE: 'Close',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNSDAY: 'Wednsday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      FROMHOUR: 'From Hour',
      TOHOUR: 'To Hour',
      HOME: 'Home',
      VIDEOBANK: 'File Bank',
      COURSES: 'Courses',
      CATEGORIES: 'Categories',
      CURRICULUM: 'Curricula',
      CONSULTANCIES: 'Consultancies',
      COUPONS: 'Coupons',
    },
    COURSES: {
      CONTENT: 'Lecture Content',
      NAME: 'Name Of Course',
      REQUIRED: 'Required',
      CERTIFICATES: 'Certificates',
      CERTIFICATE: 'Certificate',
      ADDCERTIFICATE: 'Add Certificate',
      DESCRIPTION: 'Course Description',
      MAILINGLIST: 'Mailing List',
      SQUARECOVER: '1024X1024 Cover',
      CATEGORY: 'Category',
      ISDIPLOMA: 'Is A Diploma',
      COURSELINK: 'Course Short Link',
      VIDEO: 'Course Video Cover ',
      SELECTTRAINER: 'Choose A Trainer',
      TRAINERS: 'Instructors',
      TRAINER: 'Instructor',
      ADDCOURSE: 'Add A Course',
      MODIFY: 'Modify Course',
      CONFIGUERCOURSE: 'Configure Course',
      KEYWORDS: 'Keywords',
      NOTES: 'Notes',
      RULES: 'Course Completetion Rules',
      IMAGE: 'Course Cover',
      ASSIGNMENTS: 'Assignments',
      GENERALREPORT: 'General Report',
      FROMDATE: 'From Date',
      TODATE: 'To Date',
      COURSES: 'Courses Management',
      SELECTCATEGORY: 'Select Category',
      INFO: 'Course Info',
      DISCOUNT: 'Discount',
      YES: 'Yes',
      NO: 'No',
      COUPON: 'Coupon',
      INSTRUCTORS: 'Instructors',
      UNITES: 'Course Unites',
      QUIZZES: 'Course Quizzes',
      PRICELISTS: 'Price List',
      COMPLETETIONRULES: 'Completetion Rules',
      ADDINFO: 'Add Info',
      ASSIGNEMENTS: 'Course Assignments',
      MODIFYINFO: 'Modify Info',
      INSTRUCTOR: 'Instructor',
      ADDTRAINER: 'Add Instructor',
      CONTENTS: 'Contents',
      ADDUNITE: 'Add Unite',
      MODIFYUNITE: 'Modify Unite',
      ADDLECTURE: 'Add Lecture',
      MODIFYLECTURE: 'Modify Lecture',
      ADDCONTENT: 'Add Content',
      SELECTUNIT: 'Select Unit',
      ADDRULE: 'Add Rule',
      SELECTCERTIFICATE: 'Select Certificate',
      CERTIFICATENAME: 'Certificate Name',
      SEARCHFORFILE: 'Search For File',
      ENTERWORD: 'Enter file name or part of it',
      CREATENEWCOURSE: 'Create New Course',
      PRICE: 'Current Price',
      TOTALPAYMENTS: 'Total Payments',
      TOTALTRAINEES: 'Total Trainees',
      TOTALORDERS: 'Total Orders',
      TOTALGRADUATED: 'Total Graduated',
      CONFIGURATIONLIST: 'Configuration List',
      TOTALINFO: 'Total Info',
      TOTALUNITS: 'Total Units',
      FIRSTTRAINER: 'First Trainer',
      TOTALASSIGNMENTS: 'Total Assignments',
      TOTALTRAINERS: 'Total Trainer',
      MEDIA: 'Media',
      TOTALLECTURES: 'Total Lectures',
      TOTALVIDEOS: 'Total Videos',
      FIRSTCERTIFICATE: 'First Certificate',
      TOTALCERTIFICATES: 'Total Certificates',
      TOTALRULES: 'Total Rules',
      TOTALPRICES: 'Total Prices',
      ORIGINALPRICE: 'Original Price',
      CURRENTPRICE: 'Current Price',
      TOTALTESTS: 'Total Quizzes',
      QUESTION: 'Question',
      QUESTIONTIME: 'Question Time',
      FALLBACK: 'Fallback To',
      NUMBEROFWORDS: 'Total Words',
      ADDGLOSSARY: 'Add Glossary',
      ADDCASESTUDY: 'Add Casestudy',
      ANNOUCEMENTS: 'Announcemenets',
      ADDNEWANNOUNCEMENT: 'New Announcemenet',
      USERGRADEBOOK: 'User Gradebook',
      CASESTUDIES: 'Casestudies',
      FORUM: 'Discussions',
      GRADEBOOK: 'Gradebook',
      CERTIFICATESAMPLE: 'Add Certificate Sample',
      ADDFORUM: 'Add Forum',
      WIKI: 'Course Wiki',
      ADDWIKI: 'Add Wiki',
      FILELIST: 'Filelist',
      NEWCOURSE: 'New Course',
      COURSETYPE: 'Course Type',
      COURSEMEDIA: 'Course Media',
      GLOSSARY: 'Glossary'
    },
    ASSIGNMENTS: {
      NAME: 'Assignment Text',
      RULES: 'Acceptance Rules',
      AVAILABLEFROM: 'Available From',
      AVAILABLETO: 'Avaiable To',
      NOTES: 'Notes',
      ADDASSIGNMENT: 'Add Assignment',
      FILE: 'File',
      TRAINEENAME: 'Trainee\'s name ',
      TRAINEEEMAIL: 'Trainee\'s email ',
      UPLOADDATE: 'Uploading Date ',
      REPORT: 'Submission Report',
      BODY: 'Assignment Body',
      DATE: 'Date',
      STATUS: 'Status',
      NOTACCEPTED: 'Not Accepted',
      ACCEPTED: 'Accepted',
      REFUSE: 'Declined',
      ACCEPT: 'Accept',
      EVALUATE: 'Evaluate Assignments',
      EMAIL: 'Trainee Email',
      ATTACHFILE: 'Attach File',
      SEARCH: 'Browse Assignment Answers',

    },
    RULES: {
      CONDITION: 'Condition',
      RELATION: 'Relation To Next Rule',
      TIME: 'Time',
      ADDRULE: 'Add Rule',
      AND: 'And',
      OR: 'Or'
    },
    COURSE: {
      PRICELIST: 'Course Price List',
      INFO: 'Course Description',
      PUBLISH: 'Publish Course',
    },
    INFO: {
      HEADER: "Header",
      BODY: "Info Body"
    },
    PRICE: {
      NAME: 'Name Of Price',
      PRICE: 'Price',
      STARTINGFROM: 'Starting From Date',
      ENDINGAT: 'Starting To Date',
      ADDPRICE: 'Add Price',
      ISMAINPRICE: 'Make A Primary Price'
    },
    CURRICULUMS: {
      CURRICULUMS: 'Curricula',
      MANAGE: 'Curricula Managenment',
      NAME: 'Name Of Curriculum',
      REQUIRED: 'Required Course',
      FREQUIRED: 'Required',
      CATEGORY: 'Category',
      PRICE: 'Price',
      COURSELINK: 'Curricula Link',
      NOTES: 'Notes',
      IMAGE: 'Curriculum Cover',
      COURSES: 'Courses Included',
      SELECTCOURSEORMORE: 'Select Course',
      ADDCURRICULUMS: 'Add Curriculum',
      PUBLISH: 'Publish',
      PRICELIST: 'Prices List',
      MANAGECOURSES: 'Manage Courses',
      COMPLETETIONRULES: 'Completetion Rules',
      CERTIFICATES: 'Certificates',
      PRICES: 'Prices',
      ADDCERTIFICATE: 'Add Certificate',
      ADDCOURSE: 'Add Course'

    },
    FOOTER: {
      COPYRIGHT: 'Developed With ♥ In Istanbul',
      NAME: 'Smart Step ISP',
      ALLRIGHTS: 'All Rights Reserved',
      SUPPORT: 'Techincal Support',
      TERMS: 'Terms Of Use',
      PRIVECY: 'Privecy Policy'
    },
    EMAIL: {
      SENDPUBLICEMAIL: 'Send Publich Email',
      SUBJECT: 'Subject',
      BODY: 'Message Body',
      GROUPS: 'Groups'
    },
    COUPONS: {
      COUPONS: 'Coupons',
      NAME: 'Name Of Coupon',
      CODE: 'Coupon Code',
      CODEREQUIRED: 'Required',
      DISCOUNTTYPE: 'Type Of Discount',
      AFFILIATE: 'Affiliate',
      LIMITIATION: 'Usage Limits',
      NUMBEROFORDERS: 'Number Of Orders',
      NUMBEROFORDERSREQUIRED: 'Required',
      LIMITNO: 'Number Of Usage',
      LIMITNOREQUIRED: 'Required',
      AMOUNT: 'Amount',
      PERCENT: 'Percent',
      PERCENTREQUIRED: 'Percent Required',
      SHIPPINGINCLUDED: 'Shipping Included',
      ISACTIVE: 'Active',
      STARTINGDATE: 'Starting From Date',
      ENDINGDATE: 'Ending At Date',
      CATEGORIES: 'Categories',
      INCLUSIVECOURSES: 'Exculded Courses',
      ADDCOUPON: 'Add Coupon',
      ALLPRODUCTS: 'All Products',
      PUBLICCOUPON: 'Public Coupon',
      MODIFYCOUPON: 'Modify Coupon',
      USAGE: 'Usage',
      TOTALUSAGE: 'Total Usage',
      UPDATEDATES: 'Update Availability Dates',
      PRODUCTS: 'Products',
      DATES: 'Availability',
      CONFIGURE: 'Configurations',
      ADDCOURSE: 'Add Course',
      ADDCONSULTANCY: 'Add Consultancy',
      ADDCURRICULA: 'Add Curricula',
    },
    AFFILIATES: {
      AFFILIATES: 'Affiliate System',
      AFFILIATERS: 'Affiliates Management',
      SUBSCRIBE: 'Affiliate Subscription',
      NAME: 'Your Name',
      EMAIL: 'Email',
      PHONE: 'Phone',
      ADDRESS: 'Address',
      BANKINFO: 'Bank Info',
      BANKNAME: 'Bank Name',
      ACCOUNTNAME: 'Account Name',
      ACCOUNTNUMBER: 'Account Number',
      STATUS: 'Status',
      ACTIVATE: 'Activate Account',
      MANAGEACCOUNT: 'Manage Account',
      BANKSWIFT: 'SWIFT',
      BANKADDRESS: 'Bank Address',
      ACCOUNTIBAN: 'Account Iban',
      ACCOUNTADDRESS: 'Account Holder Address',
      MANAGEAFFILIATES: 'Manage Affiliates',
      MANAGECOMMISSIONS: 'Manage Commissions',
      COURSE: 'Courses',
      ADDAFFILIATE: 'Add Affiliate',
      ADDCOMMISSION: 'Add Commission',
      COMMISSION: 'Comission',
    },
    ACCOUNT: {
      LOGOUT: 'Logout'
    },
    TOPBAR: {
      HI: 'Hello'
    },
    B2B: {
      B2B: 'B2B',
      SUBSCRIBE: 'Subscription',
      COMPANYNAME: 'Company Name',
      COMPANYPHONE: 'Company Phone',
      COMPANYEMAIL: 'Company Email',
      CONTACTPHONE: 'Responsible Phone',
      CONTACTEMAIL: 'Responsible Email',
      DETAILS: 'Details',
      CONTACTNAME: 'Responsible Name',
      ADDRESS: 'Address',
      MANAGESUBSCRIPTIONS: 'Subscriptions Management',
      NUMBEROFENROLLMENTS: 'Number Of Users',
      STARTINGAT: 'Starting From',
      ENDINGAT: 'Ending At',
      ISACTIVE: 'Activate',
      IMPORTUSERS: 'Import Users',
      SUBSCRIPTIONSMANAGEMENT: 'Subscriptions Management',
      MANAGEUSERS: 'Manage Users',
      IMPORTDEPARTMENTS: 'Import Departments',
      DEPARTMENTS: 'Departments',
      COURSES: 'Available Courses',
      MANAGECOURSES: 'Manage Availabe Courses',
      SELECTCATEGORY: 'Select Category',
      NEWSUBSCRIBE: 'New Subscription',
      SELECTCUSTOMER: 'Select A Customer To Manage',
      INCLUSIVECOURSES: 'Exculded Courses',
    },
    TRAINERS: {
      TRAINERS: 'Instructors',
      IMAGE: 'Instructor Avatar',
      AVATAR: 'Instructor Avatar',
      NAME: 'Instrucotr Name',
      EMAIL: 'Email',
      PHONE: 'Phone',
      MOREINFO: 'More Info',
      ADDTRAINER: 'Add Instructor',
      MODIFYTRAINER: 'Modify Instructor',
      INFO: 'CV',
      NOTES: 'Notes',

      TRAINER: 'Manage Instructors',
    },
    TABLE: {
      CELLDELETE: 'Delete',
      CELLMANAGE: 'Manage',
      SELECTFROMLIST: 'Select From The List',
      UPDAECELL: 'Modify'
    },
    REPORTS: {
      NAME: 'Name',
      TOTALASSIGNMENTS: 'Total Assignments',
      COUPON: 'Coupon',
      FREECOURSE: 'Free Course',
      FEES: 'Process Fees',
      PRUCHASEDATE: 'Purchase Date',
      USER: 'User Email',
      ORDERID: 'Order Id',
      TOTALPAID: 'Total Paid',
      STRIPEORDERID: 'Payment Id',
      PRODUCTNAME: 'Product Name',
      ORDERSTATUS: 'Order Status',
      ID: '#',
      GRANTCERTIFICATE: 'Grant Certificate',
      CODE: 'Certificate Code',
      GRANTEDCERTIFICATES: 'Granted Certificates',
      DATE: 'Granting Date',
      COURSENAME: 'Course Name',
      ISSUEDBY: 'IssuedBy'
    },
    ORDERS: {
      PENDING: 'Pending',
      COMPLETE: 'Complete',
      DIDNTCOMPLETE: 'Incomplete',
      BANKPROBLEM: 'Bank Problem',
      DONE: 'Done',
      NOPAYMENTATTEMPT: 'No Payment Attempt',
    },
    FORUM: {
      NAME: 'Fourm Name',
      DESCRIPTION: 'Description',
      DISPLAYDESCRIPTION: 'Display Description',
      ADDTOPIC: 'New Topic',
      DATE: 'Date',
      ATTACHMENT: 'Attachments',
      TOPIC: 'Topic',
      TOPICNAME: 'Topic Name',
    },
    WIKI: {
      NAME: 'Wiki Title',
      DESCRIPTION: 'Wiki Description',
      PUBLIC: 'Public',
      ADDARTICLE: 'New Article',
      ARTICLE: 'Article',
      ARTICLENAME: 'Article Title',
      DATE: 'Date'
    },
    GRADEBOOK: {
      TOTALLECTURES: 'Total Lectures',
      TOTALCONTENTS: 'Total Contents',
      NONCONTENT: 'No Contents'
    },
    CASESTUDY: {
      CASEHEADER: 'Casestduy Title',
      ISOPENCASE: 'Open Casestudy',
      ANSWER: 'Answer',
      BACKTOALL: 'Back To Casestudies',
      USER: 'Trainee Email',
      NOTYET: 'Not Marked Yet',
      NOTES: 'Evalutaion',
      EVALUATE: 'Evaluate Answer',
      EXPLANATION: 'Explanation',
      SELECTCASE: 'Select',
      ISCORRECT: 'Correct Answer',
      ADDANSWER: 'New Answer',
      CASEBODY: 'Casestudy Body',
    },
    ANNOUNCEMENTS: {
      BODY: 'Announcement Details',
      REQUIRED: 'Required',
      HEADER: 'Announcement Header',
    },
    GLOSSARY: {
      NAME: 'Glossary Name',
      REQUIRED: 'Required',
      DESCRIPTION: 'Glossary Description',
      PUBLIC: 'Public',
      DISPLAYDESCRIPTION: 'Display Description',
      APPROVED: 'Approval Required',
      ALLOWEDITING: 'Allow Modifying',
      DUPLICATES: 'Allow Duplicates',
      COMMENTS: 'Allow Comments',
      ADDCONCEPT: 'New Concept',
      CONCEPTNAME: 'Concept Name',
      CONCEPTS: 'Concepts',
      DATE: 'Date',
      CONCEPT: 'Concept',
      KEYWORDS: 'Keywords',
      DEFINITION: 'Definitions'
    },
    CONSULTANCIES: {
      REQUESTID: 'Request ID',
      USEREMAIL: 'Useremail',
      DATE: 'Date',
      EARLYSESSION: 'Do You Want To Start The Session Earlier ?',
      SENDREMINDER: 'Send Reminder',
      ENDSESSION: 'End Session',
      DONE: 'Session Has Ended',
      DAY: 'Day',
      TIMELEFT: 'Session Will Start In',
      EMAIL: 'User Email',
      HOUR: 'Hour',
      HOURS: 'Hour',
      DAYS: 'Day',
      TIMEZONE: 'Timezone',
      STARTNOW: 'Start Session Now',
      MINUTES: 'Minute',
      SECONDS: 'Second',
      CONFIGURE: 'Configure Session',
      ACCEPT: 'Approve',
      DELAY: 'Delay',
      TOTALINFO: 'Description Info',
      TOTALSESSIONS: 'Session',
      INFO: 'Session Info',
      SESSIONS: 'Session',

    },
  }
};
