import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { SystemService } from './../../../core/services';
import { UserService } from './../../../core/services/users.service';

@Component({
  selector: 'sm-resetform',
  templateUrl: './resetform.component.html',
  styleUrls: ['./resetform.component.scss']
})
export class ResetformComponent implements OnInit, OnDestroy {

  id: string;
  private sub: any;

  id2: number;
  private sub2: any;


  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;



  constructor(private route: ActivatedRoute, private _account: UserService,
    private router: Router,
    private _acount: UserService,
    private cd: ChangeDetectorRef,
    private _system: SystemService,
    private authenticationService: AuthenticationService,
    private _store: Store<any>, private _router: Router) { }

  ngOnInit(): void {


    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      // console.log(this.id);
      this.sub2 = this.route.params.subscribe(params => {
        this.id2 = params['id2']; // (+) converts string 'id' to a number
        // console.log(this.id2);
      });
    });
  }



  // changepassword


  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }


  reset() {
    this.loading = true;

    // console.log(this.model);
    this.model.userId = this.id2;
    this.model.id = this.id;
    console.log(this.model);

    this._account.changepasswordx(this.model).subscribe((data) => {
      this._router.navigate(['/']);
    })

  }


}
