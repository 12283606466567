export class COUCategories {
    id ?: number ;
    parentId  ?: number ;
    parentname : string ; 
    name  : string ;
    image  : string ;
    promo  : string ;
    meta  : string ;
    keywords  : string ;
    description  : string ;
    notes  : string ;
    addedBy  : number ; 
}