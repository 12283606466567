import { Trainer } from './../../_models';
import { createReducer, on, Action, createSelector, createFeatureSelector, ActionReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as trainersActions from './courses.actions';
import { appState } from '../app.state';


export interface trainersState extends EntityState<Trainer> {
  selectedTrainerId?: number;
  isLoading: boolean;
}

export function selectItemId(a: Trainer): string {
  //In this case this would be optional since primary key is id
  return a.id.toString();
}


export function sortByName(a: Trainer, b: Trainer): number {
  return a.name.localeCompare(b.name);
}

export const trainersAdapter: EntityAdapter<Trainer> = createEntityAdapter<Trainer>();

// export const defaultItems: cartState = {
//   ids: [],
//   entities: {},
//   selectedItemId: null,
//   total: 0,
// }

const initialState = trainersAdapter.getInitialState({
  selectId: selectItemId,
  sortComparer: sortByName,
  isLoading: false,
  selectedTrainerId: null,
});


const _reducer = createReducer(
  initialState,
  on(trainersActions.updateTrainer, (state, { trainer }) => {
    return trainersAdapter.updateOne(trainer, state);
  }),
  on(trainersActions.LoadTrainersSuccess, (state, { trainers }) => {
    return trainersAdapter.setAll(trainers, state)
  }),
  on(trainersActions.addTrainer, (state, { trainer }) => {
    return trainersAdapter.setOne(trainer, state)
  }),
  on(trainersActions.removeTrainer, (state, { id }) => {
    return trainersAdapter.removeOne(id, state);
  }),
)





export const trainersReducerFeatureKey = 'trainers';
export const trainersFeature = createFeatureSelector<trainersState>(trainersReducerFeatureKey);


export const getSeletedItemId = (state: trainersState) => state.selectedTrainerId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = trainersAdapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectEntities;

// select the total user count
export const selectUserTotal = selectTotal;


export const getTrainers = createSelector(
  trainersFeature, selectAll
)


export const loadingStatus = createSelector(
  trainersFeature,
  (state: trainersState) => state.isLoading
)



export const totalItems = createSelector(
  trainersFeature,
  (state: trainersState) => {
    const allItems = Object.values(state.entities);
    return allItems.length
  })


export function persistStateReducer(_reducer: ActionReducer<trainersState>) {
  const localStorageKey = '__trainers';
  return (state: trainersState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}


export function trainersReucer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<trainersState>) {
  return (state: trainersState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}


