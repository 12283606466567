import { Component, Input, OnInit, ViewChild, AfterViewInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import * as Vimeo from "@vimeo/player";
declare var Vimeo: any;

@Component({
  selector: 'elt-vimeoplayer',
  templateUrl: './vimeoplayer.component.html',
  styleUrls: ['./vimeoplayer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VimeoplayerComponent implements OnInit, AfterViewInit {


  @Input() link = '';
  @ViewChild('elt_video_player') playerContainer;
  videoLink;
  @Output() currentTime = new EventEmitter<number>();
  @Input() questions: any[] = [];

  constructor(private sanitizer: DomSanitizer) {

  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {

    let links = "https://player.vimeo.com/video/" + this.link + "?h=dcea312e16&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=232616"
    var iframe = document.querySelector('iframe');
    this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(links);
    let self = this;
    setTimeout(() => {
      let videoplayer = new Vimeo.Player('elt_player');

      console.log(this.questions);

      // videoplayer.setVolume(0);
      videoplayer.on('play', function () {
        console.log('Played the first video');
      });

      videoplayer.on('ended', function (data) {
        // console.log(data);
      });
      videoplayer.on('timeupdate', function (data) {
        // console.log(data.seconds);

        self.currentTime.emit(data.seconds);
      });
      videoplayer.getCurrentTime().then(function (seconds) {

      });
      videoplayer.getEnded().then(function (ended) {
        // console.log(ended);
      });

    }, 500);

  }

}
