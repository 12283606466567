import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from '../../../../app/core/services';
import { AlertType } from '../../../../app/core/_models/system';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';


export interface ForumTopicData {
  forumId: number,
  id: number
  isNew: boolean
  oldItem: any
}



@Component({
  selector: 'sm-formtopicdialog',
  templateUrl: './formtopicdialog.component.html',
  styleUrls: ['./formtopicdialog.component.css']
})
export class FormtopicdialogComponent implements OnInit {

  editorConfigs = {
    useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 250,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newTopic.patchValue({
      topic: val
    });
    this._editorData = val;
  }
 

  newTopic: UntypedFormGroup;
  get xs() { return this.newTopic.controls; }
  loading = false;
  profile
  forumId = 0;
  submitted = false;


  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    private _store: Store<any>, public dialogRef: MatDialogRef<FormtopicdialogComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ForumTopicData) {
    // this.currentCourse = data.courseId;
    this.forumId = data.forumId;
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }



  ngOnInit(): void {
    this.newTopic = this.formBuilder.group({
      id: null,
      topic: [{ value: '', disabled: this.loading }],
      attachment: [{ value: '', disabled: this.loading }],
      forumId: this.forumId,
      date: new Date(),
      userId: parseInt(this.profile.userId)
    });
    if(!this.data.isNew){
      this.modify(this.data.oldItem)
    }
  }


  onSubmitConcept() {



    this.submitted = true;
    if (this.newTopic.invalid) {
      return;
    }
    // console.log(this.newConcept.value);
    // return ;

    this.loading = true;
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });

    // console.log(this.newConcept.);
    // return ;
    this._course.addOrModifyTopic(this.newTopic.value).subscribe((data) => {

      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      const diafor = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      diafor.afterClosed().subscribe((data) => {
        this.dialogRef.close({ data: true });
      })

    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })

  }


  modify(dataItem) {
    this.newTopic = this.formBuilder.group({
      id: dataItem.id,
      topic: dataItem.topic,
      attachment: dataItem.attachment,
      forumId: dataItem.forumId,
      date: dataItem.date,
      userId: dataItem.userId
    });
  }


}
