import { createAction, props } from '@ngrx/store';
import { routesMap } from './../../../core/_models/system';



export const GetBaseURL = createAction('[SYSTEM] Get URI Map', props<{ url: string }>());

export const SetNewURILevel1 = createAction('[SYSTEM] Navigate To New Destination Level1', props<{ url: string, name: string }>());
export const SetNewURILevel2 = createAction('[SYSTEM] Navigate To New Destination Level2', props<{ url: string, name: string }>());
export const SetNewURILevel3 = createAction('[SYSTEM] Navigate To New Destination Level3', props<{ url: string, name: string }>());
export const SetNewURILevel4 = createAction('[SYSTEM] Navigate To New Destination Level4', props<{ url: string, name: string }>());
export const SetNewURILevel5 = createAction('[SYSTEM] Navigate To New Destination Level5', props<{ url: string, name: string }>());
export const SetNewURILevel6 = createAction('[SYSTEM] Navigate To New Destination Level6', props<{ url: string, name: string }>());
export const SetNewURILevel7 = createAction('[SYSTEM] Navigate To New Destination Level7', props<{ url: string, name: string }>());


export const GetLevel1URL = createAction('[SYSTEM] Get Level1 URL', props<{ url: string }>());
export const GetLevel2URL = createAction('[SYSTEM] Get Level2 URL', props<{ url: string }>());
export const GetLevel3URL = createAction('[SYSTEM] Get Level3 URL', props<{ url: string }>());
export const GetLevel4URL = createAction('[SYSTEM] Get Level4 URL', props<{ url: string }>());
export const GetLevel5URL = createAction('[SYSTEM] Get Level5 URL', props<{ url: string }>());
export const GetLevel6URL = createAction('[SYSTEM] Get Level6 URL', props<{ url: string }>());
export const GetLevel7URL = createAction('[SYSTEM] Get Level7 URL', props<{ url: string }>());



export const ResetLevel1URL = createAction('[SYSTEM] Reset Level1 URL');
export const ResetLevel2URL = createAction('[SYSTEM] Reset Level2 URL');
export const ResetLevel3URL = createAction('[SYSTEM] Reset Level3 URL');
export const ResetLevel4URL = createAction('[SYSTEM] Reset Level4 URL');
export const ResetLevel5URL = createAction('[SYSTEM] Reset Level5 URL');
export const ResetLevel6URL = createAction('[SYSTEM] Reset Level6 URL');
export const ResetLevel7URL = createAction('[SYSTEM] Reset Level7 URL');



export const InLoading = createAction('[SYSTEM] We Are On The Load');
export const OutLoading = createAction('[SYSTEM] We Are Out Of Loading');



export const SetConsultantId = createAction('[SYSTEM] Set ConsultantId ', props<{ id: any }>());



export const GetMyPermissions = createAction('[SYSTEM] Get My Permissions', props<{ userId: number }>());
export const GetMyPermissionsSuccess = createAction('[SYSTEM] Get My Permissions Success', props<{ permissions: any[] }>());