import { NewtagdialogComponent } from './../../dialogs/newtagdialog/newtagdialog.component';
import { ActivecampaginlistsComponent } from './../../settings/activecampaginlists/activecampaginlists.component';
import copy from 'fast-copy';
import { ReportsService } from './../../../core/services/reports.service';
import { Trainer } from './../../../core/_models/trainers';
import { COUCategories } from './../../../core/_models/categories';
import { CoursesService } from './../../../core/services/courses.service';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnDestroy, AfterViewInit, Inject } from '@angular/core';

import { Course } from './../../../core/_models';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { loadStylesHead, NotfiyService, SettingsService } from './../../../core/services';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
declare var Noty: any;
import Bounce from 'bounce.js';
declare var $: any;
import * as _reducer from '../../../core/state/_courses/course.reducer'
import * as _trainersReducer from '../../../core/state/_courses/trainers.reducer'
import * as _categoriesreducer from '../../../core/state/_courses/categories.reducer'
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as _systemReducer from './../../../core/state/_system/system.reducer';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';


export interface CourseDialogData {
  isNew: boolean
  oldCourse: any
  tags: any[]
}

@Component({
  selector: 'sm-newcoursedialog',
  templateUrl: './newcoursedialog.component.html',
  styleUrls: ['./newcoursedialog.component.scss'],
  
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewcoursedialogComponent implements OnInit, OnDestroy, AfterViewInit {


  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  locale = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'rtl', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'موافق', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'فترة مخصصة',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: 0 // first day is monday
  }



  allStates: any[] = [];

  isDated = false;
  alLTrainers: any[] = [];
  allCategories: COUCategories[] = [];
  allLists = [];

  public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;


  button: string = '';

  categories$: Observable<COUCategories[]>
  trainers$: Observable<Trainer[]>
  allTrainees = [];
  searchTextt = '';
  courseTypes = [{ Id: 1, name: 'Recorded' }, { Id: 2, name: 'Live' }, { Id: 3, name: 'RecordedDated' }, { Id: 4, name: 'Mixed' }, { Id: 5, name: 'Internal' }];
  editorConfigs = {
    useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      description: val
    });
    this._editorData = val;
  }

  isNew = true;
  oldCourse;

  constructor(private _router: Router, private _courses: CoursesService, private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: CourseDialogData, private dialogRef: MatDialogRef<NewcoursedialogComponent>,
    private _dialog: MatDialog, private _report: ReportsService, private _store: Store<any>, private _settings: SettingsService,
    private ngNoty: NotfiyService, private _translate: TranslateService,
    private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }

    this.cd.markForCheck();
    this.button = this._translate.instant('COURSES.ADDCOURSE');
    this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });

  }

  ngAfterViewInit(): void {

  }
  selectedCategories = [];
  categoriesSubscription = new Subscription()
  trainersSubscription = new Subscription()
  ngOnDestroy(): void {
    this.categoriesSubscription.unsubscribe();
    this.trainersSubscription.unsubscribe();
  }

  loading = false;

  activateRTL(editor) {
    editor.format('align', 'right')
    editor.format('direction', 'rtl')
  }

  ngOnInit() {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });



    this._store.dispatch({ type: '[Categories] Load All Categories' });
    this._store.dispatch({ type: '[Trainers] Load All Trainers' });


    this.categories$ = this._store.pipe(select(_categoriesreducer.getCOUCategoriess));
    this.trainers$ = this._store.pipe(select(_trainersReducer.getTrainers));
    this.allLists = this.data.tags;
    this.categoriesSubscription = this.categories$.subscribe((data) => {
      this.allCategories = data;
      // this.subSta = [];
      this.allStates = [];
      // this.handle(this.allCategories);
      this.cd.markForCheck();
    })
    // this.courseValidity = this.formBuilder.group({
    //   userId: this.profile.userId,
    //   courseId: 0,
    //   email: ['']
    // });
    // public bool? showInListing { get; set; }
    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      parentx: [{ value: '', disabled: this.loading }],
      parents: [{ value: '', disabled: this.loading }],
      featured: [{ value: '', disabled: this.loading }],
      isDiploma: false,
      categoryId: null,
      type: [{ value: '', disabled: this.loading }, Validators.required],
      active: false,
      showInListing: false,
      showInCoursesListing: false,
      tagId: '',
      image: [{ value: '', disabled: this.loading }],
      fromDate: [{ value: '', disabled: this.loading }],
      toDate: [{ value: '', disabled: this.loading }],
      selected: [{ value: '', disabled: this.loading }],
      banner: [{ value: '', disabled: this.loading }],
      squareImage: [{ value: '', disabled: this.loading }],
      linkName: [{ value: '', disabled: this.loading }, Validators.required],
      promo: [{ value: '', disabled: this.loading }],
      keywords: [{ value: '', disabled: this.loading }],
      description: [{ value: '', disabled: this.loading }],
      notificationEmail: [{ value: '', disabled: this.loading }],
      meta: [{ value: '', disabled: this.loading }],
      notes: [{ value: '', disabled: this.loading }],
      appleId: [{ value: '', disabled: this.loading }],
      googleId: [{ value: '', disabled: this.loading }],
      categories: this.selectedCategories,
      addedBy: parseInt(this.profile.userId),
    });
    this.onChanges();
    this.isNew = this.data.isNew;
    if (!this.isNew) {
      this.oldCourse = this.data.oldCourse;
      this.modify(this.oldCourse);
      this.button = this._translate.instant('COURSES.MODIFY');
    }
  }



  linkName = '';

  onChanges() {

    // console.log('changing');

    this.newCourse.get('linkName').valueChanges.subscribe((val: string) => {
      if (val) {
        val = val.replace(/ /g, "_");
        this.linkName = val;
      }
    });
    this.newCourse.get('parents').valueChanges.subscribe((val: string) => {
      if (val) {
        if (val === this._translate.instant('COURSES.NEWTAGE')) {
          const dialogRef = this._dialog.open(NewtagdialogComponent, {
            'height': '20%',
            'width': '50%',
            'direction': 'rtl'
          })
          dialogRef.afterClosed().subscribe((data) => {

            if (data) {
              // console.log(data);

              this.newCourse.patchValue({
                parents: (data != null) ? data.data.tag : null,
                tagId: (data != null) ? data.data.tagId : null
              })
            }
          })
        } else {
          var id = this.allLists.find(x => x.tag == val);
          this.newCourse.patchValue({
            tagId: (id != null) ? id.tagId : null
          })
        }

      }
    });
    this.newCourse.get('type').valueChanges.subscribe((val: any) => {
      if (val === 'Live' || val === 'RecordedDated') {
        this.isDated = true;
      } else {
        this.isDated = false;
      }
    })
    this.newCourse.get('parentx').valueChanges.subscribe((val: any) => {

    })

    this.newCourse.get('selected').valueChanges.subscribe((val) => {
      // console.log(val);
      if (val) {
        this.newCourse.patchValue({
          fromDate: this.newCourse.get('selected').value.startDate.format('LLL'),
          toDate: this.newCourse.get('selected').value.endDate.format('LLL')
        })
      }
    })
  }

  get xf() { return this.newCourse.controls; }


  onSubmit() {

    // return
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;
    var categories = this.newCourse.get('parentx').value;
    if (categories != '' && categories != null && categories != 'null') {

      // console.log(categories);
      categories.forEach(cateogry => {
        var category = this.allCategories.find(x => x.name == cateogry);
        this.selectedCategories.push({
          courseId: this.newCourse.get('id').value,
          categoryId: category.id
        })
        this.newCourse.patchValue({
          categories: this.selectedCategories
        })
      });

    } else {
      this.newCourse.patchValue({
        categoryId: null
      });
    }
    this.newCourse.patchValue({
      linkName: this.linkName
    })
    var list = this.newCourse.get('parents').value;
    if (list) {
      // console.log(list);

      var id = this.allLists.find(x => x.tag == list);
      // console.log(id);

      this.newCourse.patchValue({
        tagId: (id != null) ? id.id : null
      })
    } else {
      this.newCourse.patchValue({
        tagId: null
      })
    }
    var linkName = this.newCourse.get('linkName').value;
    if (linkName) {
      linkName = linkName.replace(/ /g, "_");
      this.linkName = linkName;
    }
    var files = [];
    var courseData = {
      files: files,
      course: this.newCourse.value
    }
    // console.log(files);
    // console.log(this.newCourse.value);
    // return ;

    // console.log(this.newCourse.value);
    // return;

    if (this.isUpdateing) {

      this._store.dispatch({ type: '[Courses] Update Course', course: { id: this.newCourse.get('id').value, changes: this.newCourse.value } });
      // var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.COURSESUPDATEDSUCCESSFULLY'), type: AlertType.Success }
      // this._dialog.open(AlertMessageComponent, {
      //   data: alertData,
      //   height: '200px',
      //   width: '400px'
      // })
      // this.cd.markForCheck();
      // this.dialogRef.close();
      // return;
    }

    this.loading = true;
    this._courses.addOrModifyCourse(files, this.newCourse.value).subscribe((data) => {
      // console.log(data);

      this.isUpdateing = false;
      this._store.dispatch({ type: '[Courses] Add New Course Successed', course: data });

      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.COURSEADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe(() => { this.dialogRef.close({ data: true }) });
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
    this.button = this._translate.instant('COURSES.ADDCOURSE')

  }


  isDiploma
  modify(dataItem) {
    // console.log(dataItem);

    this.button = this._translate.instant('COURSES.MODIFY')
    const dates = (dataItem.fromDate) ? { startDate: moment(dataItem.fromDate), endDate: moment(dataItem.toDate) } : null;
    this.isDiploma = dataItem.isDiploma;
    this.isUpdateing = true;
    let parents = [];
    if (dataItem.categories.length != 0) {

      dataItem.categories.forEach(category => {
        // console.log(category);
        
        const item = this.allCategories.find(x => x.id == category.categoryId);
        if(item)
        parents.push(item.name);
      });
    }
    this.newCourse.patchValue({
      id: dataItem.id | dataItem.Id,
      name: dataItem.name,
      // categories: this.selectedCategories,
      categoryId: dataItem.categoryId,
      trainerId: (dataItem.trainer != null) ? dataItem.trainerId : null,
      image: dataItem.image,
      promo: dataItem.promo,
      keywords: dataItem.keywords,
      notificationEmail: dataItem.notificationEmail,
      isDiploma: dataItem.isDiploma,
      showInListing: dataItem.showInListing,
      showInCoursesListing: dataItem.showInCoursesListing,
      type: this.courseTypes.find(x => x.Id == dataItem.type).name,
      description: dataItem.description,
      active: dataItem.active,
      meta: dataItem.meta,
      fromDate: (dataItem.fromDate) ? dataItem.fromDate : '',
      toDate: (dataItem.toDate) ? dataItem.toDate : '',
      selected: dates,
      featured: dataItem.featured,
      certificateSample: dataItem.certificateSample,
      banner: dataItem.banner,
      squareImage: dataItem.squareImage,
      linkName: dataItem.linkName,
      appleId: dataItem.appleId,
      googleId: dataItem.googleId,

      notes: dataItem.notes,
      addedBy: dataItem.addedBy,
      tagId: dataItem.tagId,
      parentx: (parents.length != 0) ? parents : null,
      parents: (dataItem.tagId) ? (this.allLists.find(x => x.Id == dataItem.tagId || x.id == dataItem.tagId)) ? this.allLists.find(x => x.Id == dataItem.tagId || x.id == dataItem.tagId).tag : null : null,
      trainerx: (dataItem.trainer != null) ? this.alLTrainers.find(x => x.id == dataItem.trainerId).name : null,
    });
    this.cd.markForCheck();

  }



  isUpdateing = false;





  setPublic() {
    this.isDiploma = !this.isDiploma;
    this.newCourse.patchValue({
      isDiploma: this.isDiploma
    })
  }


  loadTags() {
    this._settings.getAllActiveCampaginTags().subscribe((data: any[]) => {
      // console.log(data);

      this.allLists = [];
      if (data) {
        this.allLists.push({
          id: 0, tag: this._translate.instant('COURSES.NEWTAGE')
        });
        data.forEach(element => {
          this.allLists.push(element);
        });
      } else {
        this.allLists.push({
          id: 0, tag: this._translate.instant('COURSES.NEWTAGE')
        });
      }
      this.cd.markForCheck();
    })
  }

}

