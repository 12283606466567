import { TweenMax, TimelineLite, TimelineMax } from 'gsap';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sm-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, AfterViewInit {



  @Input() question: any;
  numberOfWords: number = 0;
  answers: any[] = [];
  private isCorrect: boolean = false;
  result = '';
  @Output() public didAnswer = new EventEmitter<boolean>();

  constructor() {

  }
  ngAfterViewInit(): void {
    if (this.question) {
      console.log(this.question);

      this.numberOfWords = this.question.answers.length;
    }
  }

  ngOnInit(): void {
  }


  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev) {
    // console.log(ev);

    ev.dataTransfer.setData("text", ev.target.id);
  }

  drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
    var id = data.split('-')[1];
    var idx = ev.target.id.split('-')[1];

    this.answers.push(((id != idx)) ? false : true);
    if (this.numberOfWords == this.answers.length) {
      // console.log(this.answers);

      if (this.answers.find(x => !x) != null) {
        this.isCorrect = false;
        TweenMax.set('.sm-question-header', { css: { background: '#910000' } });
        TweenMax.to('.sm-video-question', 0.05, { x: "+=10", yoyo: true, repeat: 5 });
        TweenMax.to('.sm-video-question', 0.05, { x: "-=10", yoyo: true, repeat: 5 });
        this.result = 'الإجابات خاطئة';
        setTimeout(() => {
          this.didAnswer.emit(false);
        }, 500);
      } else {
        TweenMax.set('.sm-question-header', { css: { background: '#076e15' } });
        this.result = 'ممتاز! الإجابات صحيحة';
        setTimeout(() => {
          this.didAnswer.emit(true);
        }, 500);
      }
    }
  }


  public questionTag: any;
  public answersTag: any;


  repeatQuestion() {
    // console.log(this.questionContainer);
    document.getElementById('questionTag').innerHTML = this.questionTag;
    for (let i = 0; i < this.questionTag.length; i++) {
      var element: any = this.questionTag[i];
      // console.log(element);

      element.draggable = true;
    }
    document.getElementById('answersTag').innerHTML = this.answersTag;
    // console.log(document.getElementById('questionTag').innerHTML);
    // console.log(document.getElementById('answersTag').innerHTML);


  }
}
