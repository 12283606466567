import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sm-tooltipcomponent',
  templateUrl: './tooltipcomponent.component.html',
  styleUrls: ['./tooltipcomponent.component.css']
})
export class TooltipcomponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
