import { FileBankDTO } from './../../_models/materials';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Course, COUCategories, Trainer, Unites, Materials, Curriculum } from '../../_models';






//Currricula
export const AllCurricula = createAction('[Curricula] Load All Curricula');
export const LoaCurriculaFail = createAction('[Curricula] Load All Curricula Fail', props<{ error: any }>());
export const LoadCurriculaSuccess = createAction('[Curricula] Load All Curricula Success', props<{ curricula: Curriculum[] }>());
export const GetSelectedCurricula = createAction('[Curricula] Load Current Curricula', props<{ curricula: Curriculum }>());
export const SelectCurricula = createAction('[Curricula] Select And Update Curricula', props<{ curriculaId: number }>());
export const SelectedCurriculaError = createAction('[Curricula] Failed To Get Selected Curricula', props<{ error: any }>());
export const updateCurricula = createAction('[Curricula] Update Single Curricula', props<{ curricula: Update<Curriculum> }>());
export const removeCurricula = createAction('[Curricula] Remove Single Curricula', props<{ id: string }>());
export const removeCurriculaSuccess = createAction('[Curricula] Remove Single Curricula Success', props<{ id: string }>());
export const addCurricula = createAction('[Curricula] Add New Curricula', props<{ curricula: any }>());
export const addCurriculaSuccess = createAction('[Curricula] Add New Curricula Successed', props<{ curricula: any }>());
export const removeCourseFromCurricula = createAction('[Curricula] Remove Course From Curricula', props<{ id: string }>());





//Files Bank
export const AllFiles = createAction('[Files] Load All Files');
export const LoadFilesFail = createAction('[Files] Load All Files Fail', props<{ error: any }>());
export const LoadFilesSuccess = createAction('[Files] Load All Files Success', props<{ files: FileBankDTO[] }>());
export const GetSelectedFile = createAction('[Files] Load Current Files', props<{ file: FileBankDTO }>());
export const SelectFile = createAction('[Files] Select And Update Files', props<{ fileId: number }>());
export const SelectedFileError = createAction('[Files] Failed To Get Selected Files', props<{ error: any }>());
export const updateFile = createAction('[Files] Update Single Files', props<{ file: Update<FileBankDTO> }>());
export const removeFile = createAction('[Files] Remove Single Files', props<{ id: string }>());
export const removeFileSuccess = createAction('[Files] Remove Single Files Success', props<{ id: string }>());
export const addFile = createAction('[Files] Add New Files', props<{ fileData: any }>());
export const addFileSuccess = createAction('[Files] Add New Files Successed', props<{ files: any }>());



//Lectures
export const AllLectures = createAction('[Lectures] Load All Lectures', props<{ unitId: number }>());
export const LoadLecturesFail = createAction('[Lectures] Load All Lectures Fail', props<{ error: any }>());
export const LoadLecturesSuccess = createAction('[Lectures] Load All Lectures Success', props<{ lectures: Materials[] }>());
export const GetSelectedLecture = createAction('[Lectures] Load Current Lectures', props<{ lecture: Materials }>());
export const SelectLecture = createAction('[Lectures] Select And Update Lectures', props<{ lectureId: number }>());
export const SelectedLectureError = createAction('[Lectures] Failed To Get Selected Lectures', props<{ error: any }>());
export const updateLecture = createAction('[Lectures] Update Single Lectures', props<{ lecture: Update<Materials> }>());
export const removeLecture = createAction('[Lectures] Remove Single Lectures', props<{ id: string }>());
export const removeLectureSuccess = createAction('[Lectures] Remove Single Lectures Success', props<{ id: string }>());
export const addLecture = createAction('[Lectures] Add New Lectures', props<{ lectureData: any }>());
export const addLectureSuccess = createAction('[Lectures] Add New Lectures Successed', props<{ lectures: any }>());
export const clearSelectedLecture = createAction('[Lectures] Clear Selected Item');




//Unites
export const AllUnites = createAction('[Unites] Load All Unites', props<{ courseId: number }>());
export const LoadUnitesFail = createAction('[Unites] Load All Unites Fail', props<{ error: any }>());
export const LoadUnitesSuccess = createAction('[Unites] Load All Unites Success', props<{ unites: Unites[] }>());
export const GetSelectedUnit = createAction('[Unites] Load Current Unites', props<{ unit: Unites }>());
export const SelectUnit = createAction('[Unites] Select And Update Unites', props<{ unitId: number }>());
export const SelectedUnitError = createAction('[Unites] Failed To Get Selected Unites', props<{ error: any }>());
export const updateUnit = createAction('[Unites] Update Single Unites', props<{ unit: Update<Unites> }>());
export const removeUnit = createAction('[Unites] Remove Single Unites', props<{ id: string }>());
export const removeUnitSuccess = createAction('[Unites] Remove Single Unites Success', props<{ id: string }>());
export const addUnit = createAction('[Unites] Add New Unites', props<{ unitData: any }>());
export const addUnitSuccess = createAction('[Unites] Add New Unites Successed', props<{ unit: Unites }>());
export const clearSelectedUnit = createAction('[Unites] Clear Selected Item');




//Trainers
export const AllTrainers = createAction('[Trainers] Load All Trainers');
export const LoadTrainersFail = createAction('[Trainers] Load All Trainers Fail', props<{ error: any }>());
export const LoadTrainersSuccess = createAction('[Trainers] Load All Trainers Success', props<{ trainers: Trainer[] }>());
export const GetSelectedTrainers = createAction('[Trainers] Load Current Trainer', props<{ trainer: Trainer }>());
export const SelectTrainer = createAction('[Trainers] Select And Update Trainer', props<{ trainerId: number }>());
export const SelectedTrainerError = createAction('[Trainers] Failed To Get Selected Trainer', props<{ error: any }>());
export const updateTrainer = createAction('[Trainers] Update Single Trainer', props<{ trainer: Update<Trainer> }>());
export const removeTrainer = createAction('[Trainers] Remove Single Trainer', props<{ id: string }>());
export const addTrainer = createAction('[Trainers] Add New Trainer', props<{ trainer: Trainer }>());
export const clearSelectedTrainer = createAction('[Trainers] Clear Selected Item');


//Categories
export const AllCategories = createAction('[Categories] Load All Categories');
export const LoadCategoriesFail = createAction('[Categories] Load All Categories Fail', props<{ error: any }>());
export const LoadCategoriesSuccess = createAction('[Categories] Load All Categories Success', props<{ categories: COUCategories[] }>());
export const GetSelectedCategories = createAction('[Categories] Load Current Category', props<{ category: COUCategories }>());
export const SelectCategory = createAction('[Categories] Select And Update Category', props<{ categoryId: number }>());
export const SelectedCategoryError = createAction('[Categories] Failed To Get Selected Category', props<{ error: any }>());
export const updateCategory = createAction('[Categories] Update Single Category', props<{ category: Update<COUCategories> }>());
export const removeCategory = createAction('[Categories] Remove Single Category', props<{ id: string }>());
export const addCategory = createAction('[Categories] Add New Category', props<{ category: COUCategories }>());
export const clearSelectedCategory = createAction('[Categories] Clear Selected Item');
export const UpdateCertificate = createAction('[Categories] Update Category', props<{ category: Update<COUCategories> }>());

// End Categories




//Courses
export const AllCourses = createAction('[Courses] Load All Courses');
export const LoadCorusesFail = createAction('[Courses] Load All Courses Fail', props<{ error: any }>());
export const LoadCorusesSuccess = createAction('[Courses] Load All Courses Success', props<{ courses: Course[] }>());
export const GetSelectedCourse = createAction('[Courses] Load Current Course', props<{ course: Course }>());
export const SelectCourse = createAction('[Courses] Select Course', props<{ courseId: number }>());
export const SelectedCourseError = createAction('[Courses] Failed To Get Selected Course', props<{ error: any }>());
export const updateCourse = createAction('[Courses] Update Single Course', props<{ course: Update<Course> }>());
export const removeCourse = createAction('[Courses] Remove Single Course', props<{ id: string }>());
export const removeCourseSuccess = createAction('[Courses] Remove Single Course Success', props<{ id: string }>());
export const addCourseSuccess = createAction('[Courses] Add New Course Successed', props<{ course: Course }>());
export const addCourse = createAction('[Courses] Add New Course', props<{ courseData: any }>());
export const clearSelectedCourse = createAction('[Courses] Clear Selected Item');
export const UpdateCourse = createAction('[Courses] Update Course', props<{ course: Update<Course> }>());
// End Courses

