import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    //  console.log(items,searchText);

    return items.filter(it => {
      if (typeof (it.name) === 'undefined')
        it.name = '';


      if (typeof (it.curdsd) === 'undefined')
        it.curdsd = '';

        if (typeof (it.productName) === 'undefined')
        it.productName = '';

      if (typeof (it.ticket) === 'undefined')
        it.ticket = '';

      if (typeof (it.concept) === 'undefined')
        it.concept = '';

      if (typeof (it.definition) === 'undefined')
        it.definition = '';

      if (typeof (it.header) === 'undefined')
        it.header = '';

      if (typeof (it.first) === 'undefined')
        it.first = '';
      if (typeof (it.second) === 'undefined')
        it.second = '';
      if (typeof (it.answer) === 'undefined')
        it.answer = '';
      if (typeof (it.explanation) === 'undefined')
        it.explanation = '';


      if (typeof (it.body) === 'undefined')
        it.body = '';



      if (typeof (it.email) === 'undefined')
        it.email = '';



      if (typeof (it.fileName) === 'undefined')
        it.fileName = '';

      if (typeof (it.day) === 'undefined')
        it.day = '';

      if (typeof (it.details) === 'undefined' || it.details == null )
        it.details = '';

      if (typeof (it.notes) === 'undefined' || it.notes == null)
        it.notes = '';

      if (typeof (it.theday) === 'undefined')
        it.theday = '';


      if (typeof (it.code) === 'undefined')
        it.code = '';


      if (typeof (it.cashier) === 'undefined' || it.cashier == null || typeof it.cashier === 'number')
        it.cashier = '';

      if (typeof (it.account) === 'undefined')
        it.account = '';
      if (typeof (it.exchange) === 'undefined')
        it.exchange = '';
      if (typeof (it.account1) === 'undefined')
        it.account1 = '';
      if (typeof (it.account2) === 'undefined')
        it.account2 = '';
      if (typeof (it.notes1) === 'undefined')
        it.notes1 = '';
      if (typeof (it.notes2) === 'undefined')
        it.notes2 = '';
      if (typeof (it.notes_for_profit) === 'undefined')
        it.notes_for_profit = '';

      if (typeof (it.date) === 'undefined')
        it.date = new Date();

      if (typeof (it.amount) === 'undefined')
        it.amount = 0;

      if (typeof (it.currency) === 'undefined' || it.currency == null)
        it.currency = '';

      if (typeof (it.activityId) === 'undefined')
        it.activityId = 0;

      if (typeof (it.id) === 'undefined')
        it.id = 0;


      if (typeof (it.current_total) === 'undefined')
        it.current_total = 0;


      if (typeof (it.account_id) === 'undefined')
        it.account_id = 0;


      if (typeof (it.numberOfAssignments) === 'undefined')
        it.numberOfAssignments = 0;




      if (typeof (it.ticketStatus) === 'undefined')
        it.ticketStatus = '';
      if (typeof (it.ticketType) === 'undefined')
        it.ticketType = '';
      if (typeof (it.ticketPriority) === 'undefined')
        it.ticketPriority = '';




      return it.name.includes(searchText) ||
        it.amount == parseFloat(searchText) ||
        it.activityId == parseFloat(searchText) ||
        it.account_id == parseInt(searchText) ||
        it.id == parseInt(searchText) ||
        it.first.includes(searchText) ||
        it.second.includes(searchText) ||
        it.answer.includes(searchText) ||
        it.explanation.includes(searchText) ||
        it.numberOfAssignments == parseInt(searchText) ||
        it.current_total == parseFloat(searchText) ||
        it.date >= new Date(searchText) ||
        it.currency.includes(searchText) ||
        it.day.includes(searchText) ||
        it.details.includes(searchText) ||
        it.header.includes(searchText) ||
        it.ticketType.includes(searchText) ||
        it.ticketPriority.includes(searchText) ||
        it.ticketStatus.includes(searchText) ||
        it.definition.includes(searchText) ||
        it.body.includes(searchText) ||
        it.notes.includes(searchText) ||
        it.theday.includes(searchText) ||
        it.fileName.includes(searchText) ||
        it.account1.includes(searchText) ||
        it.code.includes(searchText) ||
        it.productName.includes(searchText) ||
        it.concept.includes(searchText) ||
        it.ticket.includes(searchText) ||
        it.account2.includes(searchText) ||
        it.email.includes(searchText) ||
        it.notes1.includes(searchText) ||
        it.notes2.includes(searchText) ||
        it.notes_for_profit.includes(searchText) ||
        it.cashier.includes(searchText) ||
        it.account.includes(searchText) ||
        it.exchange.includes(searchText) ||
        it.curdsd.includes(searchText);
    });
  }
}
