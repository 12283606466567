import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class TrainersService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string,) {
  }


  addPolicy(attrs) {
    return this.http.post(this.apiUrl + '/api/trainers/policy', attrs, this.jwt());
  }


  resortPolicies(attrs) {
    return this.http.post(this.apiUrl + '/api/trainers/sortpolicies', attrs, this.jwt());
  }


  getAllTrainersApplications() {
    return this.http.get(this.apiUrl + '/api/trainers/trainerapplications', this.jwt());
  }

  deletePolicy(attrs) {
    return this.http.delete(this.apiUrl + '/api/trainers/policy/' + attrs, this.jwt());
  }

  getAllPolicies() {
    return this.http.get(this.apiUrl + '/api/trainers/policies', this.jwt());
  }






  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    console.log(token);

    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


}
