import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sm-alertconfirmmessage',
  templateUrl: './alertconfirmmessage.component.html',
  styleUrls: ['./alertconfirmmessage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertconfirmmessageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertconfirmmessageComponent>,) { }

  ngOnInit(): void {
  }


  confirmDelete() {
    this.dialogRef.close(true);
  }

}
