import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { TrainersDialogComponent } from '../trainers-dialog/trainers-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CaseStudiesDTO } from '../../../../../src/app/core/_models';
import { AlertType } from '../../../../../src/app/core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

@Component({
  selector: 'sm-case-dialog',
  templateUrl: './case-dialog.component.html',
  styleUrls: ['./case-dialog.component.css']
})
export class CaseDialogComponent implements OnInit {
  profile:any
  loading = false;
  submitted = false;
  answers: any[] = [];
  isOpenCase: boolean;
  caseStudies: any = [];
 
  currentCourse: any;
  newCase = new FormGroup({
    // Define 'parentx' form control with validation
    id: new FormControl( ),
    case: new FormControl( ),
    addedAt: new FormControl(),
    fromDate: new FormControl(),
    toDate: new FormControl(),
    openCase: new FormControl(),
    caseHeader: new FormControl(),
    caseBody: new FormControl(),
    description: new FormControl(),
    courseId: new FormControl(),
    userId: new FormControl(),
    solution: new FormControl(),
  });
  newSolution = new FormGroup({
    // Define 'parentx' form control with validation
    id: new FormControl(),
    caseStudyId: new FormControl(),
    answers: new FormControl(),

  });
  newAnswer = new FormGroup({
    // Define 'parentx' form control with validation
    id: new FormControl(),
    solutionId: new FormControl(),
    answer: new FormControl('', Validators.required),
    explanation: new FormControl(),
    isCorrect: new FormControl(),

  });
  updateStatus = new FormGroup({
    // Define 'parentx' form control with validation
    caseId: new FormControl(),
    status: new FormControl(),
    explanation: new FormControl(),
    note: new FormControl('', Validators.required),


  });

  solutionId: any;


  editorConfigs: any = {
    'uploader': { 'insertImageAsBase64URI': true },
    'language': 'ar'
  }
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newAnswer.patchValue({
      answer: val
    });
    this._editorData = val;
  }
  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newAnswer.patchValue({
      explanation: val
    });
    this._editorData1 = val;
  }
  _editorData2 = '';
  get editorData2() {
    return this._editorData2;
  }

  set editorData2(val) {
    this.updateStatus.patchValue({
      explanation: val
    });
    this._editorData2 = val;
  }

  _editorData3 = '';
  get editorData3() {
    return this._editorData3;
  }

  set editorData3(val) {
    this.newCase.patchValue({
      case: val
    });
    this._editorData3 = val;
  }


  get xf() { return this.newCase.controls; }
  get xs() { return this.newSolution.controls; }
  get xd() { return this.newAnswer.controls; }

  constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<CaseDialogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {
  
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      console.log('profile' , this.profile);
      
      // this.userId = this.profile.ident;
      this.newCase.patchValue({
        userId:+this.profile.userId
      })
    }
  }
  ngOnInit(): void {
    console.log(this.data);
    this.answers = this.data.solution.answers;
    this.newCase.patchValue({
      id: this.data.id | this.data.Id,
      addedAt: this.data.addedAt,
      fromDate: this.data.fromDate,
      toDate: this.data.toDate,
      openCase: this.data.openCase,
      caseHeader: this.data.caseHeader,
      caseBody: this.data.caseBody,
      description: this.data.description,
      courseId: this.data.courseId,
      userId: parseInt(this.data.userId),
      solution: this.data.solution
    });

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    var url = window.location.href;
    var xurl = url.split('/');
    this.currentCourse = + xurl[5];
    this.newCase.patchValue({
      courseId:this.currentCourse
    })
  }

  
  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    this.newSolution.patchValue({
      answers: this.answers
    });
    if (!this.isOpenCase)
      this.newCase.patchValue({
        solution: this.newSolution.value
      })

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    console.log('this.newCase' ,this.newCase.value);

    this._course.addOrModifyCasestudy(this.newCase.value).subscribe((data) => {
      console.log(data);
      
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.isOpenCase = false;
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.CASESTUDYADDEDSUCCESSFULLY'), type: AlertType.Success }
     const dialog =  this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })    
      dialog.afterClosed().subscribe((data) => {        
        this.dialogRef.close();
      this.newSolution.reset();
      this.newCase.reset();
      this.newAnswer.reset();
        this.cd.markForCheck();          
      
    })
  
      this._course.getCasestudiesForCourse(this.currentCourse).subscribe((data: CaseStudiesDTO[]) => {
        this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
        // console.log(data);
        this.dialogRef.close(data);
        console.log(data);
        
        if (data) {
          this.caseStudies = data;

          this.cd.markForCheck();
        }
      })


      this.submitted = false;
      // $('#newCase').modal('toggle')
      // if ($('#newCase').hasClass('in')) {
      //   $('#newCase').modal('toggle');
      // }
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }
  setOpenCase(e) {
    console.log('e.target.checked' , e.target.checked);
    
    if (e.target.checked) {
      this.isOpenCase = true;
      this.cd.markForCheck();
    } else {
      this.isOpenCase = false;
      this.cd.markForCheck();
    }

    console.log(this.isOpenCase);
    this.newCase.patchValue({
      openCase:this.isOpenCase
    })
    
  }


  onSubmitAnswerS() {
    if (this.newAnswer.invalid) return;
    this.answers.push(this.newAnswer.value);
    this.newAnswer.reset();
    this.newAnswer.patchValue({
      isCorrect: false,
      solutionId: this.solutionId
    })
    // console.log(this.answers);
  }

  deleteAnswer( dataItem ) {
    var id = dataItem.id | dataItem.Id;
    this.answers = this.answers.filter(x => x != dataItem);
    this.cd.markForCheck();
    console.log(this.answers);
    this._course.deleteCasestudySolutionAnswer(id).subscribe((data) => { });

  }

  modifyAnswer( dataItem ) {
console.log(dataItem);

   }

   close(){
    this.dialogRef.close()
  }
}
