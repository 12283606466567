import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityLogicalSentenceComponent } from '../activity-logical-sentence/activity-logical-sentence.component';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

@Component({
  selector: 'sm-activity-quizz',
  templateUrl: './activity-quizz.component.html',
  styleUrls: ['./activity-quizz.component.scss']
})
export class ActivityQuizzComponent implements OnInit {

  public profile: any = {};
  submitted = false;
  newActivity: UntypedFormGroup;
  newCourse: UntypedFormGroup;
  loading = false;
  progress = 0;
  get xf() { return this.newCourse.controls; }
  currentCourse
  activityId
  selectedActiviyId

  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityQuizzComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }



    this.currentCourse = this.data.courseId;
    this.selectedActiviyId = this.data.parentId;
    this.isUpdating = this.data.isNew;
  }

  ngOnInit(): void {

    this.newActivity = this.formBuilder.group({
      id: this.activityId,
      Id: this.activityId,
      courseId: this.currentCourse,
      type: ActivityType.Quizz,
      quizz: [''],
      title: [{ value: '', disabled: this.loading }],
      isMarked: false,
      mark: 0,
    });


    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      activityId: null,
      title: [{ value: '', disabled: this.loading }],
      addedAt: moment(),
      addedBy: parseInt(this.profile.userId),
      questions: ['']
    });
    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }


  isUpdating

  onSubmit() {

    // console.log(this.newCourse.value);
    // return ;

    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }

    this.cd.markForCheck();


    this.newCourse.patchValue({
      title: this.newCourse.get('name').value
    })
    if (!this.isUpdating) {

      this._course.updateAcitivtyQuizzName({ quizzId: this.newCourse.get('id').value, name: this.newCourse.get('name').value }).subscribe((data) => {
        this.dialogRef.close({ data: true });
      })


    } else {
      this.newActivity.patchValue({
        id: (this.selectedActiviyId) ? this.selectedActiviyId : null,
        Id: (this.selectedActiviyId) ? this.selectedActiviyId : null,
        quizz: this.newCourse.value,
        title: this.newCourse.get('name').value
      })
      // console.log(this.newActivity);
      // return;

      this._course.addActivty(this.newActivity.value).subscribe((data) => {
        this.dialogRef.close({ data: true });
      })
      this.loading = true;
    }

  }




  modify(dataItem) {
    let selectedActiviyId = dataItem.id | dataItem.Id;
    this.newActivity.patchValue({
      id: selectedActiviyId,
      Id: selectedActiviyId,
      courseId: this.currentCourse,
      type: ActivityType.Quizz,
      quizz: dataItem.quizz,
      title: dataItem.quizz.title,
      isMarked: dataItem.isMarked,
      mark: 0,
    });


    this.newCourse = this.formBuilder.group({
      id: dataItem.quizz.id | dataItem.quizz.Id,
      Id: dataItem.quizz.id | dataItem.quizz.Id,
      name: dataItem.quizz.name,
      activityId: selectedActiviyId,
      title: dataItem.quizz.title,
      addedAt: dataItem.quizz.addedAt,
      addedBy: dataItem.quizz.addedBy,
      questions: dataItem.quizz.questions
    });

  }



}
