import { Consultants, Consultancy } from '../_models/consultancy';
import { Curriculum, CURCourses, CURRules } from '../_models/curriculums';
import { Assignments } from '../_models/assignments';
import { Rules } from '../_models/rules';
import { Quizz } from '../_models/quizzes';
import { Materials, questionWithAnswers, FileBankDTO } from '../_models/materials';
import { Unites } from '../_models/unites';
import { Course } from '../_models/courses';
import { AppConfig } from './app.config';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError, EMPTY } from 'rxjs';
import { COUCategories } from '../_models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { observe } from '@progress/kendo-angular-grid/dist/es2015/utils';

@Injectable()
export class ReportsService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }





  getTotalCourses() {
    return this.http.get(this.apiUrl + '/api/report/totalcourses', this.jwt());
  }

  getTheMostPopularCourse() {
    return this.http.get(this.apiUrl + '/api/report/popularcourse', this.jwt());
  }

  getTotalUnpublishedCourses() {
    return this.http.get(this.apiUrl + '/api/report/unpublishedcourses', this.jwt());
  }

  getTotalPublishedCourses() {
    return this.http.get(this.apiUrl + '/api/report/publishedcourses', this.jwt());
  }

  getTotalNoOfVideos() {
    return this.http.get(this.apiUrl + '/api/report/totalvideos', this.jwt());
  }

  getTotalVideosLength() {
    return this.http.get(this.apiUrl + '/api/report/totalvideolength', this.jwt());
  }

  getTotalUnconfirmedUsers() {
    return this.http.get(this.apiUrl + '/api/report/unconfirmedusers', this.jwt());
  }

  getTotalConfirmedUsers() {
    return this.http.get(this.apiUrl + '/api/report/confirmedusers', this.jwt());
  }




  getAllCommentsOnVideos() {
    return this.http.get(this.apiUrl + '/api/report/messages', this.jwt());
  }



  getAllCommentsOnVideosForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/report/coursemessages/' + courseId, this.jwt());
  }

  getCourseValidityForUser(attrs) {
    return this.http.post(this.apiUrl + '/api/report/coursevalidity', attrs, this.jwt());
  }




  getSubmissionsOfAssignments(assignmentId) {
    return this.http.get(this.apiUrl + '/api/report/submissions/' + assignmentId, this.jwt());
  }

  searchForFileOldWay(getfile) {
    return this.http.get(this.apiUrl + '/api/report/getfile/' + getfile, this.jwt());
  }

  searchForFile(attrs) {
    return this.http.post(this.apiUrl + '/api/report/getfile/', attrs, this.jwt());
  }

  getTotalTraineesC(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totaltraineesc/' + courseId, this.jwt());
  }
  getTotalPaymentsForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalpaymentsc/' + courseId, this.jwt());
  }
  getFileUsageByFileid(fileId) {
    return this.http.get(this.apiUrl + '/api/report/fileusage/' + fileId, this.jwt());
  }

  getListOfFilesUserInCourseByName(courseId) {
    return this.http.get(this.apiUrl + '/api/report/filelist/' + courseId, this.jwt());
  }
  getTotalOrdersForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalordersc/' + courseId, this.jwt());
  }


  getTotalGraduatedForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalgraduatesc/' + courseId, this.jwt());
  }


  getSuccessfulOrdersReportCourse(attrs) {
    return this.http.post(this.apiUrl + '/api/report/courseorders', attrs, this.jwt());
  }
  getSuccessfulOrdersReportCoupon(attrs) {
    return this.http.post(this.apiUrl + '/api/report/couponfullreport', attrs, this.jwt());
  }


  getSuccessfulOrdersReportCurricula(attrs) {
    return this.http.post(this.apiUrl + '/api/report/packageorders', attrs, this.jwt());
  }


  getPeriodChartReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/monthlycharts', attrs, this.jwt());
  }




  getTotalTrainees() {
    return this.http.get(this.apiUrl + '/api/report/totaltrainees', this.jwt());
  }

  getTotalPayments() {
    return this.http.get(this.apiUrl + '/api/report/totalpayments', this.jwt());
  }

  getTotalUsedCoupons() {
    return this.http.get(this.apiUrl + '/api/report/usedcoupons', this.jwt());
  }
  getTotalFilesUsed() {
    return this.http.get(this.apiUrl + '/api/report/totalfiles', this.jwt());
  }

  getTotalAmountOfCouponDiscount() {
    return this.http.get(this.apiUrl + '/api/report/couponsdiscount', this.jwt());
  }

  getTotalPaidOrders() {
    return this.http.get(this.apiUrl + '/api/report/totalpaid', this.jwt());
  }

  getActiveTrainees() {
    return this.http.get(this.apiUrl + '/api/report/activetrainees/', this.jwt());
  }

  getTotalCertificates() {
    return this.http.get(this.apiUrl + '/api/report/totalcertificates/', this.jwt());
  }
  mostGraduatedCourse() {
    return this.http.get(this.apiUrl + '/api/report/topcoursecertified/', this.jwt());
  }
  getTotalFreeEnrollment(courseId?: number) {
    return this.http.get(this.apiUrl + '/api/report/totalenrollfree/' + courseId, this.jwt());
  }

  getGeneralCourseReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/courseg', attrs, this.jwt());
  }


  TotalB2BApplications() {
    return this.http.get(this.apiUrl + '/api/report/totalbapplications', this.jwt());
  }
  TotalAffiliates() {
    return this.http.get(this.apiUrl + '/api/report/totalaffiliates', this.jwt());
  }
  TotalConsultanciesRequestsForMonth() {
    return this.http.get(this.apiUrl + '/api/report/totalconsultancies', this.jwt());
  }
  TotalTrainersApplications() {
    return this.http.get(this.apiUrl + '/api/report/totaltapplications', this.jwt());
  }

  getCourseToBeExportedCertificatesByAssignments(attrs) {
    return this.http.post(this.apiUrl + '/api/report/toprintexport', attrs, this.jwt());
  }


  exportprinted(id) {

    // const options = { headers: this.jwt(), responseType: 'blob' };
    return this.http.post(this.apiUrl + '/api/courses/exportprinted/', id, this.pdfJWT());

  }


  changeNameForTraineeByEmail(attrs) {
    return this.http.post(this.apiUrl + '/api/users/changenameyemail', attrs, this.jwt());
  }


  getAllAssignmentsByusersEmailNotEvaluated(attrs) {
    return this.http.post(this.apiUrl + '/api/report/allinassingments', attrs, this.jwt());
  }


  resetPasswordForUser(attrs) {
    return this.http.post(this.apiUrl + '/Account/resetpasswordba', attrs, this.jwt());
  }



  // getAllGroups(){
  //   // return this.http.post(this.apiUrl + '/api/')


  // }




  removeFromList(attrs) {
    return this.http.post(this.apiUrl + '/api/report/removefromlist', attrs, this.jwt());
  }

  assignToList(attrs) {
    return this.http.post(this.apiUrl + '/api/report/assigntolist', attrs, this.jwt());
  }
  commerceReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/commercereport', attrs, this.jwt());
  }


  totalpaymentschart() {
    return this.http.get(this.apiUrl + '/api/report/totalpaymentschart', this.jwt());
  }

  sendEmail(attrs) {
    return this.http.post(this.apiUrl + '/api/report/sendemail', attrs, this.jwt());
  }

  getListOfEmails(courseId?: number) {
    return this.http.get(this.apiUrl + '/api/report/emailist/' + courseId, this.jwt());
  }

  getTraineesOfCourse(courseId?: number) {
    return this.http.get(this.apiUrl + '/api/report/traineesincourse/' + courseId, this.jwt());
  }


  getTraineesOfBundle(courseId?: number) {
    return this.http.get(this.apiUrl + '/api/report/traineesinbundle/' + courseId, this.jwt());
  }
  getAllEmails() {
    return this.http.get(this.apiUrl + '/api/report/allemails/', this.jwt());
  }
  allUsersInList(listId) {
    return this.http.get(this.apiUrl + '/api/report/inlistusers/' + listId, this.jwt());
  }







  totalinfo(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalinfo/' + courseId, this.jwt());
  }

  totalunits(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalunits/' + courseId, this.jwt());
  }
  totallectures(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totallectures/' + courseId, this.jwt());
  }
  totalvideos(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalvideos/' + courseId, this.jwt());
  }
  totaltrainers(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totaltrainers/' + courseId, this.jwt());
  }
  coursetrainer(courseId) {
    return this.http.get(this.apiUrl + '/api/report/coursetrainer/' + courseId, this.jwt());
  }
  totalcerts(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalcerts/' + courseId, this.jwt());
  }
  coursecertificate(courseId) {
    return this.http.get(this.apiUrl + '/api/report/coursecertificate/' + courseId, this.jwt());
  }
  totalrules(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalrules/' + courseId, this.jwt());
  }
  totalprices(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalprices/' + courseId, this.jwt());
  }
  courseoprice(courseId) {
    return this.http.get(this.apiUrl + '/api/report/courseoprice/' + courseId, this.jwt());
  }
  coursecprice(courseId) {
    return this.http.get(this.apiUrl + '/api/report/coursecprice/' + courseId, this.jwt());
  }
  totaltests(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totaltests/' + courseId, this.jwt());
  }

  totalassignments(courseId) {
    return this.http.get(this.apiUrl + '/api/report/totalassignments/' + courseId, this.jwt());
  }

  getCourseMedia(courseId) {
    return this.http.get(this.apiUrl + '/api/report/media/' + courseId, this.jwt());
  }




  //Curricula Reports

  getGradeBookOfCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/report/gradebook/' + courseId, this.jwt());
  }


  getAllTraineesEmailsForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/trainees_by_course_id/' + courseId, this.jwt());
  }


  getGradeBookOfEmail(attrs) {
    return this.http.post(this.apiUrl + '/api/report/traineegradebook', attrs, this.jwt());
  }
  getTotalTraineesP(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totaltraineesp/' + curriculaId, this.jwt());
  }
  getTotalPaymentsForCurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalpaymentsp/' + curriculaId, this.jwt());
  }
  getTotalOrdersForCurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalordersp/' + curriculaId, this.jwt());
  }


  getTotalGraduatedForCurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalgraduatesp/' + curriculaId, this.jwt());
  }

  // getSuccessfulOrdersReportCurricula(curriculaId) {
  //   return this.http.get(this.apiUrl + '/api/report/packageorders/' + curriculaId, this.jwt());
  // }





  totalcoursesForPackage(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalcourses/' + curriculaId, this.jwt());
  }

  totalcertsforcurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalcertsp/' + curriculaId, this.jwt());
  }

  curriculacertificate(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/certificatep/' + curriculaId, this.jwt());
  }

  totalrulesp(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalrulesp/' + curriculaId, this.jwt());
  }

  totalpricesp(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/totalpricesp/' + curriculaId, this.jwt());
  }


  curriculaoprice(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/packageoprice/' + curriculaId, this.jwt());
  }

  curriculacprice(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/packagecprice/' + curriculaId, this.jwt());
  }


  getCurriculaMedia(curriculaId) {
    return this.http.get(this.apiUrl + '/api/report/packagemedia/' + curriculaId, this.jwt());
  }


  getConsultantCommerceReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/consultantcommerce/', attrs, this.jwt());
  }




  getTotalPercentOfCoupon(couponId) {
    return this.http.get(this.apiUrl + '/api/report/totalpercent/' + couponId, this.jwt());
  }
  getTotalUsegeOfCoupon(couponId) {
    return this.http.get(this.apiUrl + '/api/report/totaluseage/' + couponId, this.jwt());
  }
  getDetailedCouponReport(couponId) {
    return this.http.get(this.apiUrl + '/api/report/couponreport/' + couponId, this.jwt());
  }



  getAllCouponsForManagament() {
    return this.http.get(this.apiUrl + '/api/report/allcouponsformanagement', this.jwt());
  }


  getMyCoupons(userId) {
    return this.http.get(this.apiUrl + '/api/report/mycoupons/' + userId, this.jwt());
  }

  getMyTickets(userId) {
    return this.http.get(this.apiUrl + '/api/settings/mytickets/' + userId, this.jwt());
  }

  getCoursesSellPercentage() {
    return this.http.get(this.apiUrl + '/api/report/coursescharts', this.jwt());
  }


  getCoursesQuickRatings() {
    return this.http.get(this.apiUrl + '/api/report/course-ratings', this.jwt());
  }

  getAllCoursesAssignedForCertificate(certificateId) {
    return this.http.get(this.apiUrl + '/api/report/certificatecourses/' + certificateId, this.jwt());
  }


  getAllGratnedCertificates(certificateId) {
    return this.http.get(this.apiUrl + '/api/report/grantedcertificates/' + certificateId, this.jwt());
  }


  grantCertificateForUser(certificateId) {
    return this.http.post(this.apiUrl + '/api/report/grantcertificate', certificateId, this.jwt());
  }



  GetSalesSummaryReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/salesreport', attrs, this.jwt());
  }
  GetProductsSellingReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/productsreport', attrs, this.jwt());
  }
  GetCouponSellingReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/couponsellingreport', attrs, this.jwt());
  }
  GetCategoriesSellingReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/categoriessellingreport', attrs, this.jwt());
  }
  GetMostSellingProductsReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/mostsellingproductsreport', attrs, this.jwt());
  }
  GetAbandonedCartsReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/abandonedcartsreport', attrs, this.jwt());
  }
  GetCustomersReport(attrs) {
    return this.http.post(this.apiUrl + '/api/report/customersreport', attrs, this.jwt());
  }


  GetTotalGraduates(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totalgraduates', attrs, this.jwt());
  }

  GetMostLearners(attrs) {
    return this.http.post(this.apiUrl + '/api/report/mostlearner', attrs, this.jwt());
  }
  GetMostAchiever(attrs) {
    return this.http.post(this.apiUrl + '/api/report/mostachiever', attrs, this.jwt());
  }
  GetLoyalClients(attrs) {
    return this.http.post(this.apiUrl + '/api/report/loyalclients', attrs, this.jwt());
  }
  GetMostBuyers(attrs) {
    return this.http.post(this.apiUrl + '/api/report/mostbuyers', attrs, this.jwt());
  }
  GetAbandonedCarts(attrs) {
    return this.http.post(this.apiUrl + '/api/report/abandonedcarts', attrs, this.jwt());
  }
  GetTotalTrainees(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totaltrainees', attrs, this.jwt());
  }


  GetTotalAffiliatesApplications(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totalaffiliatesapplications', attrs, this.jwt());
  }
  GetTotalAcceptedAffiliatesApplications(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totalapprovedaffiliates', attrs, this.jwt());
  }
  GetTotalRefusedAffiliatesApplications(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totalrefusedaffiliates', attrs, this.jwt());
  }
  GetMostSellingAffiliates(attrs) {
    return this.http.post(this.apiUrl + '/api/report/mostsellingaffiliates', attrs, this.jwt());
  }
  GetMostSellingCourseAffiliate(attrs) {
    return this.http.post(this.apiUrl + '/api/report/mostsellingcourseaffiliate', attrs, this.jwt());
  }

  TheActiveAffiliates(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totalactiveaffiliates', attrs, this.jwt());
  }

  TheProAffiliates(attrs) {
    return this.http.post(this.apiUrl + '/api/report/totalproaffiliates', attrs, this.jwt());
  }






  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private pdfJWT() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      responseType: 'blob' as 'blob',
      headers: headers
    };
    return httpOptions;
  }


}
