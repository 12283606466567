import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sm-confirmed',
  templateUrl: './confirmed.component.html',
  styleUrls: ['./confirmed.component.scss']
})
export class ConfirmedComponent implements OnInit, OnDestroy {


  id: string;
  private sub: any;

  id2: number;
  private sub2: any;


  model: any = {};


  constructor(private route: ActivatedRoute, private _store: Store<any>, private _router: Router) { }

  ngOnInit(): void {
    this._router.navigate(['/']);
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }

}
