import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { TrainersDialogComponent } from '../trainers-dialog/trainers-dialog.component';
import { AlertType, TicketPriority, TicketType } from '../../../../../src/app/core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'sm-announc-dialog',
  templateUrl: './announc-dialog.component.html',
  styleUrls: ['./announc-dialog.component.css']
})
export class AnnouncDialogComponent implements OnInit{
  submitted:boolean = false
  loading = false;

  currentCourse: any;
  profile: any;f
  newCase = new FormGroup({
    // Define 'parentx' form control with validation
    id: new FormControl(null),
    header: new FormControl(null),
    type: new FormControl(TicketType.Site),
    courseId: new FormControl( null),
    priority: new FormControl(TicketPriority.Normal),
    userId: new FormControl(null),
    groupId: new FormControl(7),
    openCase: new FormControl(false),
    date: new FormControl(this.createDateAsUTC(new Date())),
    body: new FormControl(null),
  });
  editorConfigs: any = {
    'uploader': { 'insertImageAsBase64URI': true },
    'language': 'ar'
  }
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCase.patchValue({
      body: val
    });
    this._editorData = val;
  }


constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<AnnouncDialogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));

      this.newCase.patchValue({
        userId: +this.profile.userId
      })
    }
  }
  ngOnInit(): void {

    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });

    this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
    // this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    var url = window.location.href;
    var xurl = url.split('/');
    this.currentCourse = + xurl[5];
    this.newCase.patchValue({
      courseId: this.currentCourse
    })
  }
  createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  get xf() { return this.newCase.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    // return;
    this._course.addNewAnnouncementToCourse(this.newCase.value).subscribe((data) => {
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ANNOUNCEMENTADDEDSUCCESSFULLY'), type: AlertType.Success }
      console.log(alertData);
      
      const dialog =   this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {        
          this.dialogRef.close();
          this.newCase.reset();
          this.cd.markForCheck();          
        
      })
  
      this.submitted = false;


      
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }

  onClose(): void {
    // Close dialog and send data back to parent
    this.dialogRef.close();
  }

}
