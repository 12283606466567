import { Component, OnInit, ViewEncapsulation, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { TweenMax } from 'gsap';
import { courseCard, priceCard } from './../../../core/_models';
import { CoursesService } from './../../../core/services';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewComponent implements OnInit, OnDestroy {

  sub = new Subscription();
  id = 0;
  profile ;

  
  constructor(private _route: ActivatedRoute, private _store: Store<any>, private _course: CoursesService) {

    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    this.sub = this._route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
      console.log(this.id);
      this._course.getCourseCardById(this.id).subscribe((data: courseCard) => {
        console.log(data);

      })

    });
  }



}
