import { NewconsultancydialogComponent } from './../../dialogs/newconsultancydialog/newconsultancydialog.component';
import { ConsultancyService } from './../../../core/services/consultancy.service';
import { Consultancy, Consultants } from './../../../core/_models/consultancy';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, AfterViewInit, Inject } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { COUCategories, Trainer } from './../../../core/_models';

import { Router } from '@angular/router';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { environment } from './../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
declare var Noty: any;
import Bounce from 'bounce.js';
import { Store } from '@ngrx/store';
import { SettingsService, NotfiyService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';


interface ConsultancyDialog {
  isNew: boolean;
  oldItem: any;
  consultantId: number;
  consultants: any[];
}

@Component({
  selector: 'sm-newconsultantdialog',
  templateUrl: './newconsultantdialog.component.html',
  styleUrls: ['./newconsultantdialog.component.scss']
})
export class NewconsultantdialogComponent implements OnInit {

  editorConfigs
  newCourse: UntypedFormGroup;
  profile
  button
  loading
  submitted

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      info: val
    });
    this._editorData = val;
  }

  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newCourse.patchValue({
      moreInfo: val
    });
    this._editorData1 = val;
  }
  
  constructor(private _service: ConsultancyService, private cd: ChangeDetectorRef, private _router: Router,
    private _dialogRef: MatDialogRef<NewconsultantdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConsultancyDialog, private _dialog: MatDialog, private _translate: TranslateService, private _report: ReportsService, private _store: Store<any>, private _settings: SettingsService,
    private ngNoty: NotfiyService,
    private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.button = this._translate.instant('CONSULTANT.ADDCONSULTANT');
  }


  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      Id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      profileImage: [{ value: '', disabled: this.loading }],
      phone: [{ value: '', disabled: this.loading }],
      email: [{ value: '', disabled: this.loading }],
      info: [{ value: '', disabled: this.loading }],
      moreInfo: [{ value: '', disabled: this.loading }],
      groupId: 6,
      notes: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
      addedDate: new Date()
    });
    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }

  get xf() { return this.newCourse.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this._service.addOrModifyConsultant(this.fileToUpload, this.newCourse.value).subscribe((data) => {


      this.button = this._translate.instant('CONSULTANT.ADDCONSULTANT');
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.CONSULTANTADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialogRef = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialogRef.afterClosed().subscribe((data) => {
        this._dialogRef.close();
      })
    }, e => {
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + ' ' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    });
  }

  modify(dataItem) {
    $('#profile').css('background-image', 'url(' + dataItem.profileImage + ')').addClass('hasImage');
    this.button = this._translate.instant('CONSULTANT.MODIFYCONSULTANT');
    this.newCourse.patchValue({
      Id: (dataItem.Id) ? dataItem.Id : dataItem.id | dataItem.Id,
      name: dataItem.name,
      profileImage: dataItem.profileImage,
      info: dataItem.info,
      moreInfo: dataItem.moreInfo,
      phone: dataItem.phone,
      email: dataItem.email,
      notes: dataItem.notes,
      addedBy: parseInt(this.profile.userId),
    });
    this.cd.markForCheck();

  }


  upload() {
    $('#mediaFile').click();
  }

  fileToUpload

  addFile(e) {
    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(reader.result);
        $('#profile').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }

}
