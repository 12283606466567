import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityLogicalSentenceComponent } from '../activity-logical-sentence/activity-logical-sentence.component';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';



@Component({
  selector: 'sm-activity-fillspace',
  templateUrl: './activity-fillspace.component.html',
  styleUrls: ['./activity-fillspace.component.scss']
})
export class ActivityFillspaceComponent implements OnInit {

  private selectedWords: any[] = [];

  profile: any;
  submitted = false;
  newCase: UntypedFormGroup;
  newSolution: UntypedFormGroup;
  loading = false;
  get xf() { return this.newCase.controls; }
  get xs() { return this.newSolution.controls; }
  private questionbody = '';
  isUpdateing = false;
  currentCourse
  answers = [];
  solutionId
  questionWords = [];

  get question() {
    return this.questionbody;
  }
  set question(text: any) {
    this.questionbody = text;
    if (text) {
      this.questionWords = text.split(" ");
    }
  }


  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityFillspaceComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.currentCourse = this.data.courseId;

  }

  select(i) {


    var div: any = document.getElementById("word-" + i);
    div.style.border = '1px solid green';
    div.style.background = 'green';
    div.style.color = 'white';
    var word = this.questionWords[i];

    var isSelected = this.selectedWords.find(x => x.word == word);
    if (isSelected) {
      div.style.border = '1px solid black';
      div.style.background = 'black';
      div.style.color = 'white';
      this.selectedWords = this.selectedWords.filter((x) => { return x != isSelected });
    } else {
      // console.log('not exstid');

      var wordToAdd = {
        order: i,
        word: word
      };
      this.selectedWords.push(wordToAdd);
    }
    this.cd.markForCheck();
  }

  ngOnInit(): void {

    this.newCase = this.formBuilder.group({
      id: null,
      courseId: this.currentCourse,
      type: ActivityType.FillSpace,
      filling: [''],
      title: [{ value: '', disabled: this.loading }, Validators.required],
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: null,
      addedAt: moment(),
      activityId: null,
      title: [{ value: '', disabled: this.loading }, Validators.required],
      body: [{ value: '', disabled: this.loading }],
      stripped: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
      words: ['']
    });
    if (!this.data.isNew) {
      this.isUpdateing = true;
      this.modify(this.data.oldItem);
    }
  }


  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    var stripped = '';
    var questionInWords = this.question.split(' ');


    for (let i = 0; i < questionInWords.length; i++) {
      const element = questionInWords[i];
      if (this.selectedWords.findIndex(x => x.order == i) != -1) {

      } else {
        stripped += element + " "
      }
    }

    this.loading = true;
    this.newSolution.patchValue({
      title: this.newCase.get('title').value,
      body: this.question,
      stripped: stripped
    })

    var words = [];
    // console.log(this.selectedWords);

    this.selectedWords.forEach(element => {
      words.push({
        questionId: null,
        order: element.order,
        word: element.word
      })
    });


    this.newSolution.patchValue({
      words: words
    });
    this.newCase.patchValue({
      filling: this.newSolution.value,
      title: this.newSolution.get('title').value
    })
    // console.log(this.newCase.value);
    // return;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    // this._course.addActivty(this.newCase.value).subscribe((data) => {

    // console.log(this.isUpdateing);
    // return;

    if (this.isUpdateing) {

      // console.log(this.newCase.value);
      // return
      this._course.updateFillSpaceActivity(this.newCase.value).subscribe((data) => {

        var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
        const dialog = this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
        dialog.afterClosed().subscribe((data) => {
          this.dialogRef.close({ data: true });
        })

      }, (e) => {
        this._store.dispatch({ type: '[Profile] Stop Loading' });
        var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPEND'), type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
      })
      return;
    } else {
      this._course.addActivty(this.newCase.value).subscribe((data) => {
        this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });

        var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
        const dialogd = this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
        dialogd.afterClosed().subscribe((data) => {
          this.dialogRef.close({ data: true });
        })

      }, (e) => {
        this._store.dispatch({ type: '[Profile] Stop Loading' });
        var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
      })
    }

  }



  modify(dataItem) {
    this.isUpdateing = true;
    this.answers.sort(this.dynamicSort("order"));
    this.solutionId = dataItem.filling.Id | dataItem.filling.id;
    this.question = dataItem.filling.body;
    setTimeout(() => {
      dataItem.filling.words.forEach(element => {
        this.select(element.order);
        this.cd.markForCheck();
      });
    }, 500);

    this.newCase = this.formBuilder.group({
      id: dataItem.id | dataItem.Id,
      courseId: this.currentCourse,
      type: ActivityType.FillSpace,
      filling: dataItem.filling,
      title: dataItem.filling.title,
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: dataItem.filling.id | dataItem.filling.Id,
      addedAt: dataItem.filling.addedAt,
      activityId: dataItem.filling.activityId,
      title: dataItem.filling.title,
      body: dataItem.filling.body,
      addedBy: dataItem.filling.addedBy,
      words: dataItem.filling.words
    })
  }


  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

}
