export const environment = {
  // production: true,
  // API: 'https://v1.elearning.technology',
  // AUTH: 'https://a1.elearning.technology',
  // SITE: 'https://demo.elearning.technology',
  // Panel: 'https://panel.elearning.technology',
  // CLIENTNAME: 'ELT Academy',
  // logoImage: 'https://public.eltgcc.com/eltlogo.png',
  // B2BModule: true,
  // .... Mastery 
  production: true,
  API: 'https://v1.emasteryacademy.com',
  AUTH: 'https://a1.emasteryacademy.com',
  B2BModule: true,
  SITE: 'https://www.emasteryacademy.com',
  Panel: 'https://panel.emasteryacademy.com',
  CLIENTNAME: 'Mastery Academy',
  logoImage: '/assets/logo.png',
  // ..... Maknoon 
  // API: 'https://v1.maknoonacademy369.com',
  // AUTH: 'https://a1.maknoonacademy369.com',
  // SITE: 'https://test.maknoonacademy369.com',
  // Panel: 'https://panel.maknoonacademy369.com',
  // logoImage: '/assets/logo.svg',
  // B2BModule: true,
  // CLIENTNAME: 'spaCodeClient',
  // ..... Jahez 
  // API: 'https://v1.jahezkw.com',
  // B2BModule: false,
  // AUTH: 'https://a1.jahezkw.com',
  // SITE: 'https://www.jahezkw.com',
  // Panel: 'https://panel.jahezkw.com',
  // CLIENTNAME: 'Jahez Academy',
  // logoImage: '/assets/jahezkw.png',
};
