import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityLogicalSentenceComponent } from '../activity-logical-sentence/activity-logical-sentence.component';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

@Component({
  selector: 'sm-activity-logical',
  templateUrl: './activity-logical.component.html',
  styleUrls: ['./activity-logical.component.scss']
})
export class ActivityLogicalComponent implements OnInit, OnDestroy, AfterViewInit {

  profile: any;
  submitted = false;
  newCase: UntypedFormGroup;
  newSolution: UntypedFormGroup;

  searchText = ''
  get xf() { return this.newCase.controls; }
  get xs() { return this.newSolution.controls; }

  loading = false;
  editorConfigs = {
    useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };
  currentCourse;
  orderList = [];
  answers: any[] = [];
  solutionId: number;

  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityLogicalComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }

    this.dragulaService.createGroup("DRAGULA_FACTS", {
      moves: function (el, container, handle) {
        return handle.classList.contains('handler');
      }
    });

    this.dragulaService.drop().subscribe(args => {
      this.orderList = [];
      for (let i = 0; i < this.answers.length; i++) {
        const element = this.answers[i];
        this.orderList.push({
          questionId: this.solutionId,
          id: element.Id | element.id,
          sentence: element.sentence,
          order: i
        })
      }
      this.answers = this.orderList;
      // console.log(this.answers);

      this.cd.markForCheck();
    });

    this.currentCourse = this.data.courseId;
  }



  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy('DRAGULA_FACTS');
  }



  ngOnInit(): void {

    this.newCase = this.formBuilder.group({
      id: null,
      courseId: this.currentCourse,
      type: ActivityType.LogicalOrder,
      logicalOrder: [''],
      title: [{ value: '', disabled: this.loading }, Validators.required],
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: null,
      addedAt: moment(),
      activityId: null,
      title: [{ value: '', disabled: this.loading }, Validators.required],
      addedBy: parseInt(this.profile.userId),
      sentences: ['']
    });

    if(!this.data.isNew){
      this.modify(this.data.oldItem);
    }

  }

  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    this.newSolution.patchValue({
      title: this.newCase.get('title').value
    })

    this.orderList = [];
    for (let i = 0; i < this.answers.length; i++) {
      const element = this.answers[i];
      this.orderList.push({
        questionId: this.solutionId,
        id: element.Id | element.id,
        sentence: element.sentence,
        order: i
      })
    }
    this.answers = this.orderList;
    this.orderList = [];

    // console.log(this.answers);

    this.newSolution.patchValue({
      sentences: this.answers
    });
    this.newCase.patchValue({
      logicalOrder: this.newSolution.value,
      title: this.newSolution.get('title').value
    })

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    this._course.addActivty(this.newCase.value).subscribe((data) => {
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      // this.loadData();
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      this.dialogRef.close({ data: true });
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }




  onSubmitInternally() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    this.newSolution.patchValue({
      title: this.newCase.get('title').value
    })

    this.orderList = [];
    for (let i = 0; i < this.answers.length; i++) {
      const element = this.answers[i];
      this.orderList.push({
        questionId: this.solutionId,
        id: element.Id | element.id,
        sentence: element.sentence,
        order: i
      })
    }
    this.answers = this.orderList;
    this.orderList = [];

    // console.log(this.answers);

    this.newSolution.patchValue({
      sentences: this.answers
    });
    this.newCase.patchValue({
      logicalOrder: this.newSolution.value,
      title: this.newSolution.get('title').value
    })

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    this._course.addActivty(this.newCase.value).subscribe((data) => {
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      // this.loadData();
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })

    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }




  modify(dataItem) {

    // console.log(dataItem);


    this.answers = dataItem.logicalOrder.sentences;
    this.answers.sort(this.dynamicSort("order"));
    this.solutionId = dataItem.logicalOrder.Id | dataItem.logicalOrder.id;

    this.newCase = this.formBuilder.group({
      id: dataItem.id | dataItem.Id,
      courseId: this.currentCourse,
      type: ActivityType.LogicalOrder,
      logicalOrder: dataItem.logicalOrder,
      title: dataItem.logicalOrder.title,
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: dataItem.logicalOrder.id | dataItem.logicalOrder.Id,
      addedAt: dataItem.logicalOrder.addedAt,
      activityId: dataItem.logicalOrder.activityId,
      title: dataItem.logicalOrder.title,
      addedBy: dataItem.logicalOrder.addedBy,
      sentences: dataItem.logicalOrder.sentences
    })
    // console.log(this.newCase.value);

    // $('#newCase').modal('toggle')
    // if ($('#newCase').hasClass('in')) {
    //   $('#newCase').modal('toggle');
    // }

  }

  modifyAnswer(dataItem) {
    const dialog = this._dialog.open(ActivityLogicalSentenceComponent, {
      direction: 'rtl',
      minWidth: '500px',
      data: { courseId: this.currentCourse, isNew: false, oldItem: dataItem, parentId: this.solutionId }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        // this.answers.push(data.data);
        if (this.answers.filter(x => { return x === data.data })) {
          // console.log('yes');
          let currentAnswer = this.answers.filter(x => { return x === data.data });
          currentAnswer = data.data;
        }
        if (this.newCase.get('title').value) {

        }
      }
    })
  }

  addNewPair() {
    const dialog = this._dialog.open(ActivityLogicalSentenceComponent, {
      direction: 'rtl',
      minWidth: '500px',
      data: { courseId: this.currentCourse, isNew: true, oldItem: null, parentId: this.solutionId }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.answers.push(data.data);
        if (this.newCase.get('title').value) {
          // this.onSubmitInternally();
        }
      }
    })
  }








  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  deleteAnswer(dataItem) {
    var id = dataItem.id | dataItem.Id;
    this.answers = this.answers.filter(x => x != dataItem);
    this.cd.markForCheck();
    // console.log(this.answers);
    this._course.deleteSentence(id).subscribe((data) => {
      this._course.getAllActivities(ActivityType.LogicalOrder, this.currentCourse).subscribe((data: any[]) => {
        // this.items = data;
        this.cd.markForCheck();
      })
    });

  }



}
