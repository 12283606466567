import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from '../../../../app/core/services';
import { AlertType } from '../../../../app/core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';



export interface InfoData {
  courseId: number,
  hasVideo: boolean,
  hasLiveMeeting: boolean,
  id: number
  isNew: boolean
  oldItem: any
}



@Component({
  selector: 'sm-courseinfodialog',
  templateUrl: './courseinfodialog.component.html',
  styleUrls: ['./courseinfodialog.component.scss']
})
export class CourseinfodialogComponent implements OnInit {
  progress = 0;
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  editorConfigs: any = {
    'readonly': false,
    'uploader': { 'insertImageAsBase64URI': true },
    'language': 'ar'
  }
  currentCourse = 0;
  get xf() { return this.newCourse.controls; }
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      body: val
    });
    this._editorData = val;
  }


  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    public dialogRef: MatDialogRef<CourseinfodialogComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: InfoData) {
    this.currentCourse = data.courseId;
  }


  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      header: [{ value: '', disabled: this.loading }, Validators.required],
      courseId: this.currentCourse,
      videoId: null,
      link: [{ value: '', disabled: this.loading }],
      body: [{ value: '', disabled: this.loading }, Validators.required],
      order: 0
    });

    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }


  onSubmit() {

    this.submitted = true;

    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;
    this._course.addInfoToCourse(this.newCourse.value).subscribe((data) => {
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.COURSEINFOADDEDSUCCESSFULLY'), type: AlertType.Success }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      this.dialogRef.close({ data: true });
    }, (e) => {
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + ' ' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })

  }



  modify(dataItem) {
    // console.log(dataItem);

    this.newCourse.patchValue({
      id: dataItem.id | dataItem.Id,
      videoId: (dataItem.videoId) ? dataItem.videoId : null,
      courseId: dataItem.courseId,
      header: dataItem.header,
      body: dataItem.body,
      order: dataItem.order
    });
  }


}
