import { Component, OnInit, Inject } from '@angular/core';
import { TranslationService } from './../../../core/services';


@Component({
  selector: 'sm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logo = '';


  constructor(@Inject('API_URL') private apiUrl: string, private _translate: TranslationService,) {
    this.logo = this.apiUrl + '/images/smartlogo.svg'
  }

  changeLanguge(lang) {

    this._translate.setLanguage(lang);
  }

  ngOnInit() {

  }


  logout() {

  }


}
