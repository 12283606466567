import { TranslateModule } from '@ngx-translate/core';
import { CoursesService } from './services/courses.service';
import { AppConfig } from './services/app.config';
import { FilterPipe } from './services/filterandsearch.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth/auth.guard';
import { ConfigService } from './auth/config.service';
import { BaseService } from './auth/base.service';
import { TooltipDirective } from './services/tooltip.directive';
import { TooltipComponent } from './services/tooltip/tooltip.component';
import { ToolxtipDirective } from './services/xtooltip.directive';
import { TooltipcompoComponent } from './services/tooltipcompo/tooltipcompo.component';
import { FilterPipe1 } from './services/filterandsearch1.pipe';
import { FilterCopounPipe } from './services/filterandsearchcopon.pipe';

@NgModule({
    imports: [
        CommonModule, TranslateModule
    ],
    declarations: [FilterPipe, FilterPipe1, FilterCopounPipe, ToolxtipDirective, TooltipComponent, TooltipDirective, TooltipcompoComponent],
    providers: [
        ConfigService, AppConfig,
    ],
    exports: [FilterPipe, FilterPipe1, FilterCopounPipe, TooltipDirective, TooltipComponent]
})
export class CoreModule { }
