export class Certificates {
  Id?: number;
  name: string;
  description: string;
}

export class CERSettingsDTO {
  Id?: number;
  id?: number;
  certificateId: number;

  designId?: number;
  numberOfTrainers: number;
  startingDate: boolean;
  endingDate?: boolean;
  numberOfLogos: number;
}

export class CERDetailsDTO {
  Id?: number;
  certificateId: number;
  name: string;
  type: CerDetails;
  position: DetailsPosition
  order: number;
  body: string;
  fileId?: number;
}

export enum DetailsPosition {
  Left = 1,
  Right = 2,
  Center = 3

}

export enum CerDetails {
  NameOfCourse = 1,
  NameOfTrainer = 2,
  NameOfDirector = 3,
  NameOfStudent = 4,
  Image = 5,
  StartingDate = 6,
  ToDate = 7,
  DateOfPrint = 8,
  Text = 9,
  Logo = 10,
  Signature = 5,
  PositionOfTrainer = 11,
  TrainerSignature = 12,
  ManagementSignature =13,
}
