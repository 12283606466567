import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WikiDTO } from '../../../../../src/app/core/_models';
import { AlertType } from '../../../../../src/app/core/_models/system';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'sm-new-wiki-dailog',
  templateUrl: './new-wiki-dailog.component.html',
  styleUrls: ['./new-wiki-dailog.component.css']
})
export class NewWikiDailogComponent implements OnInit {
  profile: any;
  currentCourse: any;
  submitted = false;
  editorConfigs
  selectedFile: boolean = false;
  selectedFileName
  loading = false;


  newArticle = new FormGroup({
    // Define 'parentx' form control with validation
    wikiId: new FormControl(),
    articleName: new FormControl(),
    id: new FormControl(null),
    date: new FormControl(new Date()),
    isPublished: new FormControl(false),
    userId: new FormControl(),

  });


  // id: null,
  // articleName: [''],

  // wikiId: this.wiki.id | this.wiki.Id,

  // date: new Date(),
  // isPublished: false,
  // userId: parseInt(this.profile.userId)
  get xs() { return this.newArticle.controls; }
  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newArticle.patchValue({
      articleName: val
    });
    this._editorData1 = val;
  }

  constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<NewWikiDailogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      this.newArticle.patchValue({
        userId: parseInt(this.profile.userId)
      })
    }
  }
  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    var url = window.location.href;
    var xurl = url.split('/');
    this.currentCourse = + xurl[5];
    this.newArticle.patchValue({
      wikiId:this.currentCourse
    })
  }
  onSubmitConcept() {



    this.submitted = true;
    if (this.newArticle.invalid) {
      return;
    }
    // console.log(this.newConcept.value);
    // return ;

    this.loading = true;
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });

    // console.log(this.newConcept.);
    // return ;
    console.log(this.newArticle.value);
    
    this._course.addOrModifyArticleToWiki(this.newArticle.value).subscribe((data) => {

      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.WIKISECTIONADDED'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {        
        this.dialogRef.close();
        this.newArticle.reset();
        this.cd.markForCheck();          
      
    })      
      this.submitted = false;

      this.selectedFile = true;
      this.selectedFileName = null;
      this._course.getWikiById(this.currentCourse).subscribe((data: WikiDTO) => {
        this.dialogRef.close(data)
        this.cd.markForCheck();
      })
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })

  }

  close(){
    this.dialogRef.close()
  }



}
