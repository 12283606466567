import { loadScriptFB, SettingsService } from './core/services';
import { UserService } from './core/services';
import { ConnectionService } from './core/services/channel.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, NgZone, AfterViewInit } from '@angular/core';
// import { AuthService } from './core/auth/auth.service';
import * as reducer from './core/state/_reports/reports.reducer';

import { Router } from '@angular/router';
import { TranslationService } from './core/services/translation.service';
// language list
import { locale as enLang } from './core/i18n/en';
import { locale as arLang } from './core/i18n/ar';
import { locale as esLang } from './core/i18n/es';
import { locale as jpLang } from './core/i18n/jp';
import { locale as deLang } from './core/i18n/de';
import { locale as frLang } from './core/i18n/fr';
import { locale as trLang } from './core/i18n/tr';


import * as signalR from '@aspnet/signalr';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from './core/state/localstorage.service';
import * as courseReducer from './core/state/_courses/course.reducer';
import * as systemReducer from './core/state/_system/system.reducer';
import { Observable } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
// import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
// import { ZendeskConfig } from './app.module';
declare var zE: any;


@Component({
  selector: 'body[sm-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  isAuthenticated: boolean;
  profile: any;
  loading = true;
  data;
  loading$: Observable<boolean>;
  myPermissions = [];

  // <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=b66aed9d-71ac-4e1f-b859-b84d1fe2946b"> </script>

  constructor(@Inject('API_URL') private apiUrl: string, private _router: Router,
    // private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private _user: UserService, private _connection: ConnectionService,
    private _store: Store<any>, private _ngZone: NgZone, private roleService: NgxRolesService,
    private translate: TranslateService, private translationService: TranslationService, private permissionsService: NgxPermissionsService, private _settings: SettingsService) {


    // this._NgxZendeskWebwidgetService.initZendesk(new ZendeskConfig());
    const color = localStorage.getItem('color');
    if (color) {
      document.body.classList.add('color-scheme-dark');
    }
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }

    this.loading$ = this._store.pipe(select(systemReducer.loadingStatus));
    this.translationService.loadTranslations(enLang, arLang, esLang, jpLang, deLang, frLang, trLang);
    var body = document.body;


    if (localStorage.getItem('language') == null) {

      localStorage.setItem('language', 'ar');
      this.translationService.setLanguage('ar');
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');

    } else {
      var lang = localStorage.getItem('language')
      this.translationService.setLanguage(lang);
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');

    }
  }
  ngAfterViewInit(): void {


    this.loadScriptFB();
    var permissions: any = JSON.parse(localStorage.getItem('permis'));

    if (permissions) this.permissionsService.loadPermissions(permissions);
    if (!permissions) this.getMyPermissions();
  }



  loadScriptFB() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    var dynamicScripts = [
      "https://static.zdassets.com/ekr/snippet.js?key=b66aed9d-71ac-4e1f-b859-b84d1fe2946b",
    ];

    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.id = 'ze-snippet'
      // node.rel = 'script';
      // node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }



  getMyPermissions() {
    this._settings.getMyPermissions().subscribe((data: any[]) => {
      const roles = data.map(a => a.module);
      localStorage.setItem('permis', JSON.stringify(roles))
      this.permissionsService.loadPermissions(roles);
    })
  }



  ngOnInit() {



    const didSeeMainTour: any = localStorage.getItem('maintour');
    if (!didSeeMainTour || didSeeMainTour == false) {

    }
    // this.introJS.start();
    // this.intercom.boot({
    //   app_id: "znw3q79e",
    //   email: this.profile.email,
    //   user_id: this.profile.userId,
    //   created_at: Date.parse(new Date().toUTCString()) / 1000,
    // });

    // this.loadIntercomScript();
    // this._store.dispatch({ type: '[Reports] Get My Coupons', userId: this.profile.userId });
    // this._store.dispatch({ type: '[Reports] Get My Tickets', userId: this.profile.userId });
    // this._user.test().subscribe((data) => {
    //   // console.log(data);

    // }, (e) => {
    //   // console.log("E  ");
    //   // console.log(e);
    //   if (e.status == 401) {
    //     this._router.navigate(['/login']);
    //   }

    // })
    // this.loading = false;

  }

  ngOnDestroy(): void { }



}
