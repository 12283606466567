import { Component, OnInit } from '@angular/core';
// import { TimelineMax, Back, Power1, SlowMo, TweenMax, Bounce, Circ } from 'gsap'
declare var CustomBounce: any;



@Component({
  selector: 'sm-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // const tl: TimelineMax = new TimelineMax({ repeat: -1, repeatDelay: 1  });
    // // tl.to(document.getElementById('map'), 2, { x: 300, autoAlpha: 1, scale: 1 });
    // // CustomBounce.create("myBounce", { strength: 0.7, squash: 5, squashID: "myBounce-squash" });
    // tl
    //   // .from('#sx',.5,{ autoAlpha: 0 , rotation:-90  ,transformOrigin:"50% 50%"})
    //   .staggerFrom(".rightwing", .8, { autoAlpha: 0, rotation: -90, transformOrigin: "50% 50%", ease: Back.easeOut.config(1.7) }, .05, '-=1.75')
    //   .staggerFrom(".leftwing", .8, { autoAlpha: 0, rotation: 90, transformOrigin: "50% 50%", ease: Back.easeOut.config(1.7) }, .05, '-=1.75')
    //   .from('#map', .5, { y: 100, autoAlpha: 0, ease: Back.easeOut.config(1.7) }, '-=.5')
    //   .from('#crown', 1, { autoAlpha: 0, y: 50, scale: 0.2, transformOrigin: "50% 50%", ease: Back.easeOut.config(1.7), }, '-=.28')
    //   .staggerFrom('#masteryhead path', 0.35, { ease: Back.easeOut, autoAlpha: 0, rotationX: 90, transformOrigin: "50% 50% -35px" }, 0.08, '-=2')
    //   .from('#masterybody', 0.8, { transformOrigin: "50% 50% -35px", ease: Back.easeOut.config(1.7), autoAlpha: 0 }, '-=.9')

  }

}
