import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AffiliateService } from '../../../core/services';
import { Products } from '../../../core/_models';


export interface RequestData {
  request: any;
}


@Component({
  selector: 'sm-affiliate-commission-requests',
  templateUrl: './affiliate-commission-requests.component.html',
  styleUrls: ['./affiliate-commission-requests.component.scss']
})
export class AffiliateCommissionRequestsComponent implements OnInit {


  newCourse: UntypedFormGroup;
  submitted = false;
  profile

  RequestStatus = [
    { id: 1, status: 'Pending' },
    { id: 2, status: 'Processing' },
    { id: 3, status: 'Paid' },
    { id: 4, status: 'Suspended' },
  ]

  constructor(private _affiliate: AffiliateService, private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<AffiliateCommissionRequestsComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: RequestData) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }

  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: this.data.request.id,
      affiliateId: this.data.request.affiliateId,
      total: this.data.request.total,
      requestDate: this.data.request.requestDate,
      updatedAt: this.data.request.updatedAt,
      updated: true,
      status: this.data.request.status,
      updatedBy: this.profile.userId,
      theStatus: [{ value: '' }],
      notes: [{ value: this.data.request.notes }],
    });
  }


  onSubmit() {
    this.submitted = true;
    // console.log(this.newCourse.value);

    if (this.newCourse.invalid) {
      return;
    }

    var category = this.newCourse.get('theStatus').value;
    this.newCourse.patchValue({
      status: this.newCourse.get('theStatus').value
    });
    console.log(this.newCourse.value);
    this._affiliate.UpdatePaymentRequest(this.newCourse.value).subscribe((data) => {
      this.dialogRef.close();
    })
    // this.dialogRef.close();

  }

}
