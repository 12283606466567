import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemService } from './../../core/services/system.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { MenuProfileComponent } from './menu-profile/menu-profile.component';
import { LanguagesComponent } from './languages/languages.component';
import { FileuploaderComponent } from './fileuploader/fileuploader.component';
import { PlayerModule } from './player/player.module';
import { LoaderModule } from './loader/loader.module';
import { LoginComponent } from './login/login.component';
import { TopbarComponent } from './topbar/topbar.component';
import { MobilemenuComponent } from './mobilemenu/mobilemenu.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { BreacrumbComponent } from './breacrumb/breacrumb.component';
import { StoreModule } from '@ngrx/store';
import * as systemReducer from '../../core/state/_system/system.reducer';
import { SchedulemeetingComponent } from './schedulemeeting/schedulemeeting.component';
import { TimezonePickerService } from '../../core/services/timezones.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { BbbmeetingComponent } from '../courses/course/bbbmeeting/bbbmeeting.component';
import { ZoommeetingComponent } from '../courses/course/zoommeeting/zoommeeting.component';
import { TooltipcomponentComponent } from './tooltipcomponent/tooltipcomponent.component';
import { CoreModule } from '../../core/core.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ResetComponent } from './reset/reset.component';
import { ConfirmedComponent } from './confirmed/confirmed.component';
import { ResetformComponent } from './resetform/resetform.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EffectsModule } from '@ngrx/effects';
import { CoursesEffect } from './../../core/state/_courses/course.effect';
import { PiechartComponent } from './piechart/piechart.component';
import { SelectfileComponent } from './selectfile/selectfile.component';
import { VimeoplayerComponent } from './vimeoplayer/vimeoplayer.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TwofactorComponent } from './twofactor/twofactor.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ZoommeetingComponent, BbbmeetingComponent, HeaderComponent, MenuComponent, ResetComponent, ConfirmedComponent, ResetformComponent,
    MenuProfileComponent, LanguagesComponent, FileuploaderComponent, LoginComponent, TopbarComponent, MobilemenuComponent, SidemenuComponent, BreacrumbComponent,
    SchedulemeetingComponent, TooltipcomponentComponent, UnauthorizedComponent, PiechartComponent, SelectfileComponent, VimeoplayerComponent, DatepickerComponent, TwofactorComponent],
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule, FormsModule, NgSelectModule, MatDialogModule, MatButtonModule, 
    CommonModule, TranslateModule, RouterModule, PlayerModule, LoaderModule, CoreModule, NgxPermissionsModule.forChild(),
    EffectsModule.forFeature([CoursesEffect]),NgxDaterangepickerMd,
    StoreModule.forFeature(systemReducer.systemReducerFeatureKey, systemReducer.systemReucer),
  ],
  exports: [ZoommeetingComponent, BbbmeetingComponent, HeaderComponent, MenuComponent, PiechartComponent, LanguagesComponent,
    FileuploaderComponent, TopbarComponent, MobilemenuComponent, SidemenuComponent, BreacrumbComponent, SchedulemeetingComponent
    , ResetComponent, ConfirmedComponent, ResetformComponent, SelectfileComponent, VimeoplayerComponent],

  providers: [SystemService, TimezonePickerService]
})
export class ComponentsModule { }
