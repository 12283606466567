import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Rules } from 'aws-sdk/clients/devicefarm';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sm-rules-dialog',
  templateUrl: './rules-dialog.component.html',
  styleUrls: ['./rules-dialog.component.css']
})
export class RulesDialogComponent implements OnInit {
  newCourse = new FormGroup({
    // Define 'parentx' form control with validation
    condition: new FormControl('', [Validators.required]),
    courseId: new FormControl(0),
    id: new FormControl(null),
    relation: new FormControl(0),
    time: new FormControl(0),
    

  });
  allConditions: any[] = [];
  currentCourse: any;
  allRules: any[] = [];
  submitted = false;

  constructor(private _course: CoursesService,private route: ActivatedRoute, private _translate: TranslateService,private _courses: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<RulesDialogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    this.currentCourse = data
    this.newCourse.patchValue({
      courseId: (this.currentCourse)
    })
    // console.log('dialllll',this.currentCourse);


    this.allConditions = [
      {
        id: 1,
        name: 'AttendedTheExam',
        tName: this._translate.instant('CONDITIONS.AttendedTheExam')
      },
      {
        id: 2,
        name: 'WatchedAllVideos',
        tName: this._translate.instant('CONDITIONS.WatchedAllVideos')
      },
      {
        id: 3,
        name: 'ExamVideosAssignements',
        tName: this._translate.instant('CONDITIONS.ExamVideosAssignements')
      },
      {
        id: 4,
        name: 'ExamVideosAssignementsApprove',
        tName: this._translate.instant('CONDITIONS.ExamVideosAssignementsApprove')
      },
      {
        id: 5,
        name: 'SpcificVideo',
        tName: this._translate.instant('CONDITIONS.SpcificVideo')
      },
      {
        id: 6,
        name: 'AttendLiveMeetings',
        tName: this._translate.instant('CONDITIONS.AttendLiveMeetings')
      },
      {
        id: 7,
        name: 'ParticipateInForums',
        tName: this._translate.instant('CONDITIONS.ParticipateInForums')
      },
      {
        id: 8,
        name: 'ParticipateInCases',
        tName: this._translate.instant('CONDITIONS.ParticipateInCases')
      },
    ]
  }
  ngOnInit(): void {

  }
  get xf() { return this.newCourse.controls; }

  onSubmit() {
    // console.log(this.newCourse.value)

    if (this.newCourse.invalid) {
      return;
    }

    var condition = this.allConditions.find(x => x.id == this.allConditions.find(x => x.name == this.newCourse.get('condition').value).id);
    this.newCourse.patchValue({
      condition: condition.name
    })

    // console.log(this.newCourse.value)


    this._courses.addOrModifyRuleForCourse(this.newCourse.value).subscribe((data) => {
      this.newCourse.reset();
      this.submitted = false;
      this._course.getRulesForCourse((this.currentCourse)).subscribe((data) => {
        this.allRules = data;
        this.newCourse.patchValue({
          courseId: (this.currentCourse)
        })
        this.dialogRef.close( data );
        this.cd.markForCheck();
      })
    })
  }
  onClose(): void {
    // Close dialog and send data back to parent
    this.dialogRef.close();
  }
}
