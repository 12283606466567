import { Injectable, OnInit } from '@angular/core';
declare var $: any;



@Injectable()
export class SystemService implements OnInit {



  // createRadialGradient3(context, c1, c2, c3) {
  //   const chartArea = context.chart.chartArea;
  //   if (!chartArea) {
  //     // This case happens on initial chart load
  //     return null;
  //   }

  //   const chartWidth = chartArea.right - chartArea.left;
  //   const chartHeight = chartArea.bottom - chartArea.top;
  //   if (width !== chartWidth || height !== chartHeight) {
  //     cache.clear();
  //   }
  //   var gradient = cache.get(c1 + c2 + c3);
  //   if (!gradient) {
  //     // Create the gradient because this is either the first render
  //     // or the size of the chart has changed
  //     width = chartWidth;
  //     height = chartHeight;
  //     const centerX = (chartArea.left + chartArea.right) / 2;
  //     const centerY = (chartArea.top + chartArea.bottom) / 2;
  //     const r = Math.min(
  //       (chartArea.right - chartArea.left) / 2,
  //       (chartArea.bottom - chartArea.top) / 2
  //     );
  //     var ctx = context.chart.ctx;
  //     gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
  //     gradient.addColorStop(0, c1);
  //     gradient.addColorStop(0.5, c2);
  //     gradient.addColorStop(1, c3);
  //     cache.set(c1 + c2 + c3, gradient);
  //   }

  //   return gradient;
  // }

  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  loadAPI: Promise<any>;



  loadData() {
    this.loadAPI = new Promise((resolve) => {


      this.loadScript();
      // this.loadScriptHead();
      // this.loadStylesHead();
      resolve(true);
    });
  }



  public ActivateRadioAndCheckBox() {
    if (!$().iCheck) return;
    $(':checkbox:not(.js-switch, .switch-input, .switch-iphone, .onoffswitch-checkbox, .ios-checkbox, .md-checkbox), :radio:not(.md-radio)').each(function () {

      var checkboxClass = $(this).attr('data-checkbox') ? $(this).attr('data-checkbox') : 'icheckbox_minimal-grey';
      var radioClass = $(this).attr('data-radio') ? $(this).attr('data-radio') : 'iradio_minimal-grey';

      if (checkboxClass.indexOf('_line') > -1 || radioClass.indexOf('_line') > -1) {
        $(this).iCheck({
          checkboxClass: checkboxClass,
          radioClass: radioClass,
          insert: '<div class="icheck_line-icon"></div>' + $(this).attr("data-label")
        });
      } else {
        $(this).iCheck({
          checkboxClass: checkboxClass,
          radioClass: radioClass
        });
      }
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [

        "assets/admin/layout2/js/layout.js",
        "assets/global/plugins/tether/js/tether.min.js",
        "assets/global/plugins/bootstrap/js/bootstrap.min.js",
        "assets/global/plugins/appear/jquery.appear.js",
        "assets/global/plugins/jquery-cookies/jquery.cookies.min.js",
        "assets/global/plugins/jquery-block-ui/jquery.blockUI.min.js",
        "assets/global/plugins/bootbox/bootbox.min.js",
        "assets/global/plugins/mcustom-scrollbar/jquery.mCustomScrollbar.concat.min.js",
        "assets/global/plugins/bootstrap-dropdown/bootstrap-hover-dropdown.min.js",
        "assets/global/plugins/charts-sparkline/sparkline.min.js",
        "assets/global/plugins/bootstrap-tags-input/bootstrap-tagsinput.min.js",
        "assets/global/plugins/retina/retina.min.js",
        // "assets/js/select2.full.js",
        // "assets/js/select2.min.js",
        // "assets/global/plugins/icheck/icheck.min.js",
        "assets/global/plugins/backstretch/backstretch.min.js",
        "assets/global/plugins/bootstrap-progressbar/bootstrap-progressbar.min.js",
        "assets/global/js/builder.js",
        "assets/global/js/sidebar_hover.js",
        "assets/global/js/application.js",
        "assets/global/js/plugins.js",

        "assets/global/js/widgets/widget_weather.js",
        "assets/global/plugins/bootstrap-editable/js/bootstrap-editable.min.js",
        "assets/global/plugins/bootstrap-context-menu/bootstrap-contextmenu.min.js",
        "assets/global/plugins/multidatepicker/multidatespicker.min.js",
        "assets/global/js/widgets/todo_list.js",
        "assets/global/plugins/metrojs/metrojs.min.js",
        "assets/global/js/pages/dashboard.js",
        "assets/summernote/summernote.min.js",
        "assets/summernote/lang/summernote-ar-AR.min.js",

      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }
  }



  public loadScriptHead() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "assets/global/plugins/jquery/jquery-3.1.0.min.js",
        "assets/global/plugins/jquery/jquery-migrate-3.0.0.min.js",
        "assets/global/plugins/jquery-ui/jquery-ui.min.js"
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }



  // <link href="../assets/global/css/style.css" rel="stylesheet">
  // <link href="../assets/global/css/rtl.css" rel="stylesheet">
  // <link href="../assets/global/css/theme.css" rel="stylesheet">

  // <link href="../assets/global/css/ui.css" rel="stylesheet">
  // <link href="../assets/admin/layout2/css/layout.css" rel="stylesheet">
  // <!-- BEGIN PAGE STYLE -->
  // <!-- END PAGE STYLE -->
  // <link href="https://cdn.jsdelivr.net/npm/select2@4.0.12/dist/css/select2.min.css" rel="stylesheet" />



}
export function loadScriptFB(lang) {
  var isFound = false;
  var body = document.body;
  var scripts = document.getElementsByTagName("link")
  for (var i = 0; i < scripts.length; ++i) {
    if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
      isFound = true;
    }
  }

  if (!isFound) {
    var dynamicScripts = [];

    if (lang === 'ar') {
      dynamicScripts =
        [
          "assets/newcss/main-ar.css",
          "assets/newcss/style-ar.css",
        ];
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
    } else {
      dynamicScripts = [
        "assets/newcss/main.css",
        "assets/newcss/style-en.css",
      ];
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
    }


    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('link');
      node.href = dynamicScripts[i];
      node.rel = 'stylesheet';
      // node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }
}



export function loadStylesHead() {
  var isFound = false;
  var scripts = document.getElementsByTagName("link")
  // for (var i = 0; i < scripts.length; ++i) {
  //   if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
  //     isFound = true;
  //   }
  // }

  if (!isFound) {
    var dynamicScripts = [
      "assets/bower_components/perfect-scrollbar/css/perfect-scrollbar.min.css"
    ];

    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('link');
      node.href = dynamicScripts[i];
      node.rel = 'stylesheet';
      // node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }
}
