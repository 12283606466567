export class ActiveCampaign {
  id?: number;
  Id?: number;
  accountId: number;
  key: number;
}
export class AWSAPI {

  aws_access_key_id: string;
  aws_secret_access_key: string;
}
export class PaymentGateway {

  id?: number;
  Id?: number;
  stripeId?: number;
  name: string;
  stripe?: Stripe;
}

export class Stripe {
  id?: number;
  Id?: number;
  apiKey: string;
  type: PaymentMethod;
  active: boolean
}

export class BBB {
  id?: number;
  Id?: number;
  url: string;
  secret: string;
}

export class activeCampgainList {
  id?: number;
  Id?: number;
  listName: string;
  senderUrl: string;
  senderReminder: string;
  urlString: string;
  listId: string;
}

export enum PaymentMethod {
  Credit = 1,
  Bank = 2,
  Free = 3,
  Phone = 4,
}


export class SocialMedia {
  facebook: string;
  twitter: string;
  instagram: string;
  linkdin: string;
  youtube: string;
  whatsapp: string;
}


export class ZoomParams {

  topic: string;
  type?: number;
  start_time: string;
  duration?: number;
  schedule_for: string;
  timezone: string;
  password: string;
  agenda: string;
  recurrence?: ZoomRecurrence;
  settings?: ZoomSettings;
}

export class ZoomSettings {

  host_video: boolean;
  participant_video: boolean;
  cn_meeting: boolean;
  in_meeting: boolean;
  join_before_host: boolean;
  mute_upon_entry: boolean;
  watermark: boolean;
  use_pmi: boolean;
  approval_type?: number;
  registration_type?: number;
  audio: string;
  auto_recording: string;
  enforce_login: boolean;
  enforce_login_domains: string;
  alternative_hosts: string;
  registrants_email_notification: boolean;
}


export class ZoomRecurrence {

  type: number;
  repeat_interval?: number;
  weekly_days: string;
  monthly_day?: number;
  monthly_week?: number;
  monthly_week_day?: number;
  end_times: number;
  end_date_time: string;
}



export enum MeetingType {
  Zoom = 1,
  BBB = 2
}


export class Sliders {

  Id?: number;
  websiteId?: number;
  type: SliderType
  name: string;
  link: string;
  image: string;
  header: string;
  description: string;
  description2: string;
  order: number;

}


export enum SliderType {
  Image = 1,
  Video = 2,
  Text = 3,
  Mixed = 4,
  Other = 5
}