import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface QuizzReviewData {
  attempt: any;
  quizz: any;
}
@Component({
  selector: 'sm-quiz-review-dialog',
  templateUrl: './quiz-review-dialog.component.html',
  styleUrls: ['./quiz-review-dialog.component.scss']
})
export class QuizReviewDialogComponent  implements OnInit, OnChanges {

  @Input() attempt: any;
  @Input() quizz: any;


  constructor(
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: QuizzReviewData) { }



  ngOnChanges(changes: SimpleChanges): void {
    // for (const change in changes) {
    //   if (change == 'attempt') {
    //     console.log(this.attempt);

    //   }
    // }
  }

  stripped = '';
  // newstripped = [];
  dialogAttempt : any ;

  ngOnInit(): void {

    // console.log(this.data.quizz);
    // console.log(this.data.attempt);
    
    if (this.data.attempt && this.data.quizz) {
      this.dialogAttempt = this.data.attempt;
      this.quizz = this.data.quizz
      this.attempt = this.dialogAttempt.result ;
    }

    // this.stripped = this.attempt.stripped.split(' ');
    // this.attendanceTable = this.filling.attendanceTable[0].words;

    this.attempt.questions.forEach(question => {
      if (question.questionType == 3) {
        let newstripped = [];
        for (let i = 0; i < question.stripped.split(' ').length; i++) {
          let element = question.stripped.split(' ')[i];
          const word = (element != '(X)') ? element : (question.answers.find(x => x.order == i)).correctWord;
          const wrongWord = (element != '(X)') ? element : (question.answers.find(x => x.correctOrder == i)).correctWord;



          newstripped.push({
            word: word,
            wrongWord: wrongWord,
            original: (element !== '(X)') ? true : false,
            correct: (element == '(X)' && word !== wrongWord || element !== '(X)') ? false : true
          })

        }
        question.newstripped = newstripped;
      }
    });


    // console.log(this.attempt);

  }

}