// China
export const locale = {
  lang: 'ar',
  data: {
    MENU: {
      CREATE_POST: 'اهلا بكم',
      HOME: 'الرئيسية',
      COURSES: 'الدورات',
      CATEGORIES: 'التصنيفات',
      EMAILS: 'ارساليات البريد',
      COUPONS: 'الكوبونات',
      AFFILIATES: 'نظام التسويق',
      B2B: 'نظام الاشتراكات',
      SETTINGS: 'الإعدادات',
      SYSTEM: 'النظام',
      USERS: 'المستخدمين',
      B2BMANAGE: 'إدارة الاشتراكات المفعلة',
      CURRICULUMS: 'الباقات',
      VIDEOBANK: 'بنك الملفات',
      COURSECARD: 'بطاقة الدورة',
      TRAINERS: 'إدارة المدربين',
      REVIEWS: 'اسئلة المتدربين',
      PUBLICVIDEOS: 'الفيديوهات المنشورة',
      CERTIFICATES: 'الشهادات',
      B2BREQUESTS: 'طلبات الاستشارات',
      REPORTS: 'التقارير',
      CONSULTANCY: 'الاستشارات'
    },
    CONSULTANCIES: {
      FINISHMILESTONE: 'تعيين ك تم',
      SCHEDULELINK: 'رابط التقويم',
      MEETINGLINK: 'رابط الجلسة',
      REQUESTID: 'رقم الطلب',
      ALLCONSULTANCIES: 'جميع الاستشارات',
      ORDERS: 'عمليات شراء الاستشارات',
      CALENDAR: 'تقويم المستشار',
      USEREMAIL: 'بريد العميل',
      DATE: 'التاريخ',
      EARLYSESSION: 'هل تريد بدء الجلسة مبكراً',
      SENDREMINDER: 'ارسال تذكير للعميل',
      ENDSESSION: 'انهاء الجلسة',
      DONE: 'انتهت الجلسة',
      DAY: 'ي',
      TIMELEFT: 'ستبدأ الجلسة بعد',
      EMAIL: 'بريد العميل',
      HOUR: 'الساعة',
      HOURS: 'س',
      DAYS: 'ي',
      TIMEZONE: 'المنطقة الزمنية',
      STARTNOW: 'ابدأ الجلسة الآن',
      MINUTES: 'د',
      SECONDS: 'ث',
      CONFIGURE: 'إعداد',
      ACCEPT: 'الموافقة على الطلب',
      DELAY: 'تأجيل',
      TOTALINFO: 'مربع وصف',
      TOTALSESSIONS: 'جلسة',
      INFO: 'وصف الاستشارة',
      SESSIONS: 'جلسة استشارية',

    },
    WIDGETS: {
      QUICKREPORTS: 'تقارير سريعة',
      TOTALUNPUBLISHEDCOURSES: 'دورات غير متاحة',
      TOTALPUBLISHEDCOURSES: 'دورات متاحة',
      TOTALTRAINEES: 'المتدربين',
      TOTALVIDEOSLENGTH: 'مدة الفيديوهات',
      TOTALUNCONFIRMEDUSERS: 'مستخدمين غير موثقين',
      TOTALCONFIRMEDUSERS: 'مستخدمين  موثقين',
      TOTALVIDEOS: ' عدد الفيديوهات ',
      TOTALPAIDORDERS: 'الفواتير المعتمدة',
      TOTALPAYMENTS: 'اجمالي الدفعات',
      THEMOSTPOPULARCOURSE: 'الدورة الأكثر مبيعاً',
      HOURS: 'س'
    },
    PAYMENT: {
      STRIPE: 'Stripe'
    },
    BBB: {
      SITEURL: 'url',
      SECRET: 'secret'
    },
    SETTINGS: {
      SETTINGS: 'Settings',
      CATEGORIES: 'التصنيفات',
      BANNERS: 'بانرات الاكاديمية',
      SLIDER: 'شريط الصفحة الرئيسية',
      AWS: 'الربط مع امازون',
      AWSACCESSKEYID: 'Access Key ID',
      AWSSECRECTACCESSKEY: 'Access Key Secrey',
      BIGBLUEBUTTON: 'اعدادات Big Blue Button',
      ACTIVECAMPAIGN: 'Active Campaign',
      ACCOUNTID: 'Account Id',
      KEY: 'Key',
      STRIPE: 'Stripe',
      SOCIALMEDIA: 'روابط مواقع التواصل',
      APIKEY: 'API Key',
      ACTIVE: 'Active',
      EMAILSETTINGS: 'اعدادات البريد',
      MAILINGLISTS: 'القوائم البريدية',
      SMSSETTINGS: 'الرسائل القصيرة',
      WEBCONFERENCESETTINGS: 'البث المباشر',
      PAYMENTGATEWAYSETTINGS: 'بوابة الدفع',
      ZOOM: 'إعدادات Zoom',
      FACEBOOK: 'رابط الفيس بوك',
      TWITTER: 'رابط تويتر',
      INSTAGRAM: 'رابط الانستجرام',
      YUTUBE: 'رابط قناة اليوتيوب',
      LINKDIN: 'رابط اللينكد ان',
      WHATSAPP: 'رابط الواتس',
      SOCIALS: 'روابط المنصة على مواقع التواصل الاجتماعي',
      NOTIFICATIONEMAILS: 'ايميلات الاشعارات',
      NOTIFICATIONTYPE: 'اختيار القسم',
      EMAIL: 'ادخل البريد',
    },
    ACTIVECAMPAIGN: {
      LISTS: 'القوائم البريدية',
      LISTNAME: 'اسم القائمة',
      URLSTRING: 'عنوان الرابط',
      SENDERURL: 'عنوان المرسل',
      TAG: 'الوسم',
      TAGNAME: 'الوسم',
      SENDERREMINDER: 'شرح عن القائمة'
    },
    CONSULTANCY: {
      CALENDLYLINK: 'رابط calendly',
      CONSULTANCY: 'الاستشارات الشخصية',
      TOTALCONSULTANCIES: 'عدد الاستشارات',
      COMPLETEDSESSIONS: 'الجلسات المنفذة',
      TOTALTIME: 'اجمالي وقت الاستشارات',
      ACTIVE: 'مفعلة',
      INFO: 'معلومات ووصف ',
      ADDINFO: 'إضافة معلومات',
      TYPE: 'نوع الاستشارة',
      CONFIGUERINFO: 'اعداد',
      NUMBEROFSESSIONS: 'عدد الجلسات',
      SUBSCRIPTIONLENGTH: 'مدة الاشتراك',
      NEWCONSULTANCY: 'استشارة جديدة',
      ADDCONSULTANCY: 'إضافة استشارة',
      MODIFYCONSULTANCY: 'تعديل الاستشارة',
      CONSULTANTS: 'المستشارون',
      CONSULTANCIES: 'الاستشارات',
      REQUIRED: 'حقل مطلوب',
      NAME: 'الاسم',
      CONSULTANT: 'المستشار',
      BODY: 'نص الاستشارة',
      COVER: 'الغلاف',
      TIME: 'وقت الاستشارة',
      PRICE: 'سعر الاستشارة',
      IMAGE: 'الصورة الشخصية',
      UPDATECALENDAR: 'تحديث التقويم',
      CALENDAR: 'تقويم الاستشارات',

    },
    CONSULTANT: {
      NEW: 'مستشار جديد',
      NAME: 'الاسم',
      EMAIL: 'البريد الالكتروني',
      AVATAR: 'الصورة الشخصية',
      PHONE: 'رقم الهاتف',
      NOTES: 'ملاحظات',
      MOREINFO: 'معلومات اضافية',
      ADDCONSULTANT: 'إضافة مستشار',
      ADDCONSULTANCY: 'إضافة استشارة',
      MODIFYCONSULTANT: 'تعديل المستشار',
      INFO: 'السيرة الذاتية',
    },
    CERTIFICATES: {
      NEW: 'شهادة جديدة',
      NAME: 'اسم الشهادة',
      DESCRIPTION: 'وصف الشهادة',
      SETTINGS: 'اعدادات الشهادة',
      SETINGS: 'إعدادات الشهادة',
      NUMBEROFLOGOS: 'عدد الشعارات',
      NUMBEROFTRAINERS: 'عدد المدربين',
      ENDINGDATE: 'إظهار نهاية المدة',
      STARTINGDATE: 'إظهار بداية المدة',
      CERTIFICATES: 'إدارة الشهادات',
      CONFIGURE: 'تصميم الشهادة',
      SAVE: 'حفظ',
      ADDFIELD: 'إضافة جديدة',
      TYPE: 'نوع الخانة',
      EXPORTTEST: 'إصدار نسخة تجريبية',
      updatenameofcourse: 'تعديل اسم الدورة',
      updatesignature: 'تعديل توقيع الإدارة',
      updatesecondtrainer: 'تعديل المدرب',
      addsecondtrainer: 'اضافة المدرب ',
      updatesecondtrainersignature: 'تعديل توقيع المدرب '

    },
    FILEBANK: {
      MANAGE: 'إدارة الملفات',
      WIDGETS: 'احصائيات',
      UPLOAD: 'رفع ملفات',
      DRAGORCLICK: 'اضغط او قم بإدراج الملفات لرفعها',
      SELECTFILE: 'اختيار الملف',
      FILEUSAGE: 'استخدامات الملف',
      UPLOADINGDATE: 'تاريخ الإضافة : ',
      FILENAME: 'اسم الملف : ',
      NUMBEROFRESULTS: 'عدد النتائج : ',
      SEARCHRESULTS: 'نتائج  البحث',
    },
    CATEGORIES: {
      NAME: 'اسم التصنيف',
      REQUIRED: 'هذا الحقل مطلوب',
      PARENT: 'التصنيف الرئيسي',
      DESCRIPTION: 'وصف التصنيف',
      KEYWORDS: 'كلمات مفتاحية ',
      NOTES: 'ملاحظات إضافية',
      CATEGORIES: 'التصنيفات',
      NEWCATEGORY: 'تصنيف جديد',
      SELECTPARENTCATEGORY: 'اختر التصنيف الرئيسي',
      ADDCATEGORY: 'اضافة تصنيف',
      UPDATECATEGORY: 'تعديل التصنيف'
    },
    UNITES: {
      IMAGE: 'غلاف الوحدة',
      NAME: 'اسم الوحدة',
      NOTES: 'ملاحظات',
      NEW: 'إدارة الوحدات الدراسية',
      DESCRIPTION: 'وصف الوحدة',
    },
    MATERIALS: {
      NAME: 'اسم المحاضرة',
      VIDEO: 'الفيديو',
      CONTENTS: 'محتويات المحاضرة',
      FILEBANK: 'الاختيار من بنك الملفات',
      NEW: 'محاضرة جديدة',
      REQUIRED: 'حقل مطلوب',
      SUBMITQUESTION: 'إضافة السؤال',
      FALLBACKMINUTE: 'العودة إلى',
    },
    MATERIAL: {
      SUBMITQUESTION: 'اعتمد السؤال',
      FALLBACKMINUTE: 'في حال الإجابة الخاطئة العودة إلى الوقت'
    },
    CONTENTS: {
      AVAILABILITY: 'متاح للجميع',
      TYPE: 'نوع المحتوى'
    },
    CONTENT: {
      AVAILABILITY: 'متاح للجميع',
    },
    QUIZZES: {
      NEW: 'اختبار جديد',
      REVIEWQUIZZ: 'استعراض التقديمات',
      REQUIRED: 'هذا الحقل مطلوب',
      NAME: 'اسم الاختبار',
      PASSWORD: 'كلمة المرور',
      TIMELIMIT: 'الوقت المسموح',
      REPETITION: 'عدد مرات الإعادة',
      IFPASSED: 'في حال التخطي بنجاح',
      IFFAILED: 'في حال عدم التخطي',
      OPTIONAL: 'اختياري',
      BEFORESTART: 'اختبار قبل الدورة',
      UNITE: 'الوحدة الدراسية',
      SHUFFLE: 'عشوائي',
      SORTED: 'ترتيب ',
      QUESTIONMARKREQUIRED: 'درجة السؤال حقل إجباري',
      QUESTIONMARK: 'درجة السؤال',
      NOTES: 'ملاحظات',
      NEWQUESTION: 'سؤال جديد',
      QUESTIONTYPE: 'نوع السؤال',
      QUESTIONNAME: 'نص السؤال',
      QUESTIONNAMEREQUIRED: 'نص السؤال مطلوب',
      FIRSTOFPAIR: 'القسم الأول من الجملة',
      SECONDOFPAIR: 'القسم الثاني من الجملة',
      ADDANSWER: 'اضافة الجواب',
      ANSWER: 'الجواب',
      EXPLANATION: 'التفسير',
      ISCORRECTANSWER: 'الجواب الصحيح',
      MANAGE: 'إدارة الاختبارات',
      FINALQUIZZ: 'الاختبار النهائي',
      ADDQUIZZ: 'إضافة اختبار',
      MODIFYQUIZZ: 'تعديل الاختبار',
      QUESTION: 'السؤال',
      ADDQUESTION: 'إضافة سؤال',
      MANAGEANSWERS: 'إدارة الاجابات',
      MINSCORE: 'الحد الأدنى للنجاح',
      SELECTQUESTIONTYPE: 'اختر نوع السؤال',
      QUESTIONSRANDOMIZE: 'ترتيب الاسئلة',
      NEWANSWER: 'جواب جديد',
      BACKTOALL: 'عودة',
      DELETEQUESTION: 'حذف السؤال',
      MODIFYQUESTION: 'تعديل السؤال',
      QUIZZNAME: 'عنوان الاختبار',
      MANAGEQUESTIONS: 'إدارة الاسئلة',
      DELETEQUIZZ: 'حذف الاختبار',
      MODIFYANSWER: 'تعديل الجواب',
    },
    VIDEOBANK: {
      MANAGE: 'بنك الفيديوهات'
    },
    VIDEO: {
      NEW: 'فيديو جديدة',
      ACTIVE: 'إظهار',
      HEADER: 'العنوان',
      BODY: 'النص المرفق'
    },
    FORM: {
      FILTER: '',
      MODIFY: 'تعديل',
      DELETE: 'حذف',
      SUSPEND: 'ايقاف',
      UNSUSPEND: 'اعادة تفعيل',
      PREVIEW: 'استعراض',
      SEARCHANDFILTER: 'بحث وتصفية',
    },
    STATUS: {
      Opened: 'مفتوحة',
      Pending: 'معلقة',
      InProgress: 'قيد الإنجاز',
      Fixed: 'تمت المعالجة',
      Finished: 'منتهية',
      Feedback: 'بانتظار التقييم',
      ReOpened: 'معاد فتحها',
    },

    PRIORITY: {
      Low: 'منخفضة',
      Normal: 'عادية',
      Important: 'مهمة',
      Critical: 'عاجلة',
    },
    TICKETTYPE: {
      Panel: 'لوحة التحكم',
      Site: 'الموقع',
      Profile: 'ملف المشارك',
      Payment: 'الدفع',
      Content: 'المحتوى',
      User: 'المستخدمين',
    },
    CONDITIONS: {
      AttendedTheExam: 'تقديم الامتحان الاخير',
      WatchedAllVideos: 'متابعة جميع الفيديوهات',
      ExamVideosAssignements: 'الفيديوهات + الواجبات + الاختبارات',
      ExamVideosAssignementsApprove: 'الفيديوهات + الواجبات + الاختبارات + موافقة',
      SpcificVideo: 'فيديوهات محددة',
      AttendLiveMeetings: 'حضور الجلسات المباشرة',
      ParticipateInForums: 'المشاركة في النقاشات',
      ParticipateInCases: 'المشاركة في الحالات الدراسية',
      SpentTime: 'إجمالي وقت مشاهدة',
      CompletedSpecificCourse: 'اتمام دورات محددة',
      CompletedFractionOfUnites: 'اتمام نسبة من الدورات',
      CompletedAllCourses: 'اتمام جميع الدورات',
    },
    SYSTEM: {
      CANCEL: 'إلغاء',
      GOTOWEBSITE: 'الذهاب للموقع',
      DIPLOMANOTEVALUATED: 'تقرير تسليمات الواجبات بدون تصحيح',
      TICKETINGSYSTEM: 'تذاكر النظام',
      NOUSERFOUND: 'لا يوجد مستخدم بهذا البريد',
      USERCOURSES: 'الدورات المشترك بها',
      USERCERTIFICATES: 'الشهادات المصدرة',
      USEDCOUPONS: 'الكوبونات المستخدمة',
      COMPLETEORDERS: 'الطلبات المكتملة',
      ADDCOURSETOUSER: 'إسناد دورات',
      TOTALAMOUNT: 'القيمة الاجمالية',
      SELECTTIMEZONE: 'المنطقة الزمنية',
      PASSWORDREQUIRED: 'كلمة المرور مطلوبة',
      REOPENTICKET: 'إعادة فتح التذكرة',
      REMOVE: 'حذف',
      ARABIC: 'العربية',
      ENGLISH: 'English',
      ISSUEDBY: 'بواسطة',
      ENTERPHONE: 'ادخل رقم الهاتف بدون رمز النداء',
      OPENINGDATE: 'التاريخ',
      ADDMESSAGETOTICKET: 'رد جديد في التذكرة',
      LOGINFORM: 'أهلا بك ',
      CLOSETICKET: 'إغلاق التذكرة',
      SETINPROGRESS: 'قيد العمل',
      RESOLOVED: 'تم الإنجاز',
      DATE: 'التاريخ',
      TICKETS: 'تذاكر النظام',
      PRIORITY: 'مستوى الأهمية',
      NEWTICKET: 'تذكرة جديدة',
      TICKETTYPE: 'نوع التذكرة',
      STATUS: 'الحالة',
      TICKET: 'المشكلة / الحالة',
      ADDTICKET: 'إضافة التذكرة',
      COUPONS: 'الكوبونات',
      CONSULTANCIES: 'الاستشارات',
      ACTIONS: 'اجراءات',
      ADDIMAGE: 'إضافة ملف',
      ACTIVATE: 'نشر الدورة',
      DRAGIMAGE: 'قم بإدراج الملف هنا او اضغط لاختيار',
      AREYOUSURE: 'هل انت متأكد؟',
      YES: 'نعم',
      NAME: 'اسم المتدرب',
      SUCCESS: 'تمت العملية بنجاح',
      NO: 'لا',
      SAVE: 'حفظ',
      ACADEMYWELCOME: 'أهلا بكم في لوحة التحكم',
      USERNAME: 'بريد المستخدم',
      PASSWORD: 'كلمة المرور',
      LOGIN: 'تسجيل الدخول',
      USERNAMEREQUIRED: 'اسم المستخدم مطلوب',
      PASSWORDREUIRED: 'كلمة المرور مطلوبة',
      QUICKWIDGETS: 'تقارير سريعة',
      TOTALPAYMENT: 'اجمالي الدفعات',
      CONFIRMEDUSERS: 'المستخدمين الموثقين',
      AVAILABLECOURSES: 'الدورات المتاحة',
      TOTALCOURSES: 'اجمالي الدورات',
      UNCONFIRMEDUSERS: 'المستخدمين غير الموثقين',
      MOSTCOURSE: 'افضل الدورات',
      TOTALFILES: 'اجمالي الملفات المرفوعة',
      UPLOADFILE: 'رفع ملف',
      FILENAME: 'اسم الملف',
      FILETYPE: 'نوع الملف',
      SELECTFILE: 'اختيار الملف',
      SELECT: 'اختيار',
      FILESIZE: 'حجم الملف',
      SAVEORDER: 'حفظ الترتيب',
      GENERATEREPORT: 'إصدار تقرير',
      SENDEMAIL: 'ارسال بريد',
      ADDCERTIFICATE: 'إضافة شهادة',
      CERTIFICATENAME: 'اسم الشهادة',
      DESCRIPTION: 'توصيف الشهادة',
      MANAGECERTIFICATE: 'إدارة الشهادة',
      UPDAETSETTINGS: 'تحديث بيانات الشهادة',
      SEARCHFORUSER: 'البحث عن مستخدم',
      MESSAGES: 'تعليقات الفيديوهات',
      WELCOME: 'أهلا وسهلا',
      ADMINISTRATOR: 'مدير النظام',
      HEADER: 'لوحة التحكم',
      QUICKLINKS: 'روابط سريعة',
      SEARCHFORCUSTOMER: 'الاستعلام عن مستخدم',
      COMMENTSONVIDEOS: 'التعليقات على الفيديوهات',
      COURSENAME: 'اسم الدورة',
      UNITNAME: 'القسم التدريبي',
      LECTURENAME: 'المحاضرة التدريبية',
      MESSAGE: 'الرسالة / التعليق',
      SUBMIT: 'حفظ',
      CLOSE: 'اغلاق',
      CONFIGURE: 'إدارة الدورة',
      CONFIGUREC: 'تصميم الشهادة',
      DEACTIVATE: 'إلغاء النشر',
      ERROR: 'خطأ',
      SATURDAY: 'السبت',
      SUNDAY: 'الاحد',
      MONDAY: 'الاثنين',
      TUESDAY: 'الثلاثاء',
      WEDNSDAY: 'الأربعاء',
      THURSDAY: 'الخميس',
      FRIDAY: 'الجمعة',
      FROMHOUR: 'ابتداء من',
      TOHOUR: 'انتهاء بـ',
      HOME: 'الصفحة الرئيسية',
      VIDEOBANK: 'بنك الملفات',
      COURSES: 'الدورات',
      CATEGORIES: 'التصنيفات',
      CURRICULUM: 'الباقات',
      NOTFOUND: 'غير موجود',
      FROMDATE: 'من تاريخ',
      TODATE: 'إلى تاريخ',
      GETREPORT: 'استعراض التقرير',
      COMMERCEREPORT: 'تقرير المبيعات',
      DIPLOMTOEXPORT: 'تقرير تصدير شهادات الدبلوم',
      SELECTCOUNTRY: 'اختر البلد',
      NUMBEROFASSIGNMENTS: 'عدد الواجبات المسلمة',
      NEXT: 'التالي',
      LOGOUT: 'تسجيل الخروج',
      CHANGEPASSWORD: 'تغيير كلمة المرور',
      CONTROLPANEL: 'لوحة التحكم',
      PERVIOUS: 'السابق',
      YOUDONTHAVEPERMISSIONS: 'غير مسموح',
      CONTACTYOURADMINISTRATOR: 'لا توجد لديك صلاحية للدخول',
    },
    FORUM: {
      NAME: 'اسم النقاش',
      DESCRIPTION: 'وصف النقاش',
      DISPLAYDESCRIPTION: 'السماح بعرض الوصف',
      CHANGEFORUMSETTINGS: 'تحديث بيانات النقاش',
      ADDTOPIC: 'إضافة موضوع',
      DATE: 'تاريخ الإضافة',
      ATTACHMENT: 'مرفقات',
      TOPIC: 'الموضوع',
      TOPICNAME: 'الموضوع',
    },
    WIKI: {
      NAME: 'اسم الموسوعة',
      DESCRIPTION: 'الوصف',
      PUBLIC: 'موسوعة عامة',
      ADDARTICLE: 'إضافة مقال',
      ARTICLE: 'عنوان المقال',
      ARTICLENAME: 'عنوان المقال',
      UPDATEWIKISETTINGS: 'تحديث بيانات الموسوعة',
      DATE: 'تاريخ الإضافة'
    },
    GRADEBOOK: {
      TOTALLECTURES: 'اجمالي المحاضرات',
      TOTALCONTENTS: 'اجمالي المحتويات',
      NONCONTENT: 'لايوجد ملفات',
      CURRENTPROGRESS: 'مستوى التقدم',
      CURRENTPROGRESSUNITS: 'مستوى تقدم الوحدات',
      COMPLETEDAT: 'تاريخ اتمام الدورة',
      CURRENTPROGRESSASSIGNMENTS: 'الواجبات',
      CURRENTPROGRESSQUIZZES: 'الإختبارات',
    },
    CASESTUDY: {
      CASEHEADER: 'عنوان الحالة الدراسية',
      ISOPENCASE: 'حالة دراسية مفتوحة',
      ANSWER: 'الجواب',
      BACKTOALL: 'العودة إلى الحالات',
      USER: 'بريد المشارك',
      NOTYET: 'غير مصححة',
      NOTES: 'التقييم',
      EVALUATE: 'تقييم الحل',
      EXPLANATION: 'التفسير',
      SELECTCASE: 'استعراض',
      ISCORRECT: 'جواب صحيح',
      ADDANSWER: 'إضافة حل',
      CASEBODY: 'شرح الحالة',
    },
    ANNOUNCEMENTS: {
      BODY: 'نص الإعلان',
      REQUIRED: 'مطلوب',
      HEADER: 'عنوان الإعلان',
    },
    COURSES: {
      GOOGLEID: 'المعرف في متجر جوجل',
      APPLEID: 'المعرف في متجر آبل',
      TRAINEES: 'المتدربين',
      RATINGS: 'تقييم الدورة',
      SHOWINLISTING: 'إظهار في محرك البحث',
      SHOWINLISTINGDOLOMA: 'إظهار في محرك البحث ك دبلوم',
      KEYWORDS: 'الكلمات المفتاحية',
      ISFEATURED: 'دورة مميزة',
      DELETE: 'حذف',
      AVAILABILITYDATES: 'تاريخ الدورة',
      NEWTAGE: 'إضافة وسم جديد',
      ADDNEWSECTION: 'إضافة قسم جديد',
      TAG: 'الوسم الخاص بالتسويق',
      FULLDETAILSREPORT: 'التقرير التفصيلي لمبيعات الدورة',
      NOTSUCCSEEDED: 'لم يتم السداد',
      SUCCSEEDED: 'تم السداد',
      ORDERSTATUS: 'حالة الطلب',
      ACTIVITES: 'الانشطة التدريبية',
      MEETINGS: 'البث المباشر',
      COURSEVALIDITY: 'بحث عن مستخدم',
      ENROLLEDAT: 'من تاريخ',
      VALIDUNTIL: 'إلى تاريخ',
      FIXORDERSTATE: 'إصلاح الطلب',
      ENTERUSEREMAIL: 'ادخل بريد المستخدم',
      ADDGLOSSARY: 'إضافة قائمة مصطلحات',
      ADDCASESTUDY: 'حالة دراسية جديدة',
      ANNOUCEMENTS: 'الإعلانات',
      ADDNEWANNOUNCEMENT: 'إعلان جديد',
      USERGRADEBOOK: 'سجل حضور شخصي',
      CASESTUDIES: 'حالات دراسية',
      FORUM: 'النقاشات',
      GRADEBOOK: 'سجل الدورة',
      CERTIFICATESAMPLE: 'إضافة صورة الشهادة',
      ADDFORUM: 'اضافة نقاش',
      WIKI: 'الموسوعة الحرة',
      ADDWIKI: 'إضافة موسوعة',
      FILELIST: 'الملفات المستخدمة',
      NEWCOURSE: 'دورة جديدة',
      COURSETYPE: 'نوع الدورة',
      COURSEMEDIA: 'الغلاف والفيديو',
      CONTENT: 'محتوى تدريبي',
      NAME: 'اسم الدورة',
      REQUIRED: 'هذا الحقل مطلوب',
      CERTIFICATES: 'الشهادات',
      CERTIFICATE: 'الشهادة',
      ADDCERTIFICATE: 'إضافة شهادة',
      DESCRIPTION: 'وصف الدورة',
      MAILINGLIST: 'القائمة البريدية',
      SQUARECOVER: '1024X1024 غلاف',
      CATEGORY: 'التصنيف',
      ISDIPLOMA: 'تحديد ك دبلوم',
      COURSELINK: 'رابط الكورس',
      VIDEO: 'الفيديو التعريفي',
      SELECTTRAINER: 'اختيار مدرب',
      TRAINERS: 'المدربين',
      TRAINER: 'المدرب',
      ADDCOURSE: 'إضافة دورة تدريبية',
      MODIFY: 'تعديل البيانات',
      CONFIGUERCOURSE: 'إعدادات الدورة',
      NOTES: 'ملاحظات',
      RULES: 'شروط اتمام الدورة',
      IMAGE: 'غلاف الدورة',
      ASSIGNMENTS: 'الواجبات',
      GENERALREPORT: 'التقرير العام',
      FROMDATE: 'من تاريخ',
      TODATE: 'إلى تاريخ',
      COURSES: 'إدارة الدورات',
      SELECTCATEGORY: 'اختر التصنيف',
      INFO: 'وصف الدورة',
      DISCOUNT: 'حسم',
      REPORTS: 'تقارير الدورة',
      YES: 'نعم',
      NO: 'كلا',
      COUPON: 'كوبون',
      INSTRUCTORS: 'المدربين',
      UNITES: 'الأقسام التدريبية',
      QUIZZES: 'الاختبارات',
      PRICELISTS: 'قائمة الاسعار',
      COMPLETETIONRULES: 'شروط اكمال الدورة',
      ADDINFO: 'إضافة وصف',
      ASSIGNEMENTS: 'الواجبات',
      MODIFYINFO: 'تعديل الوصف',
      INSTRUCTOR: 'المدرب',
      ADDTRAINER: 'إضافة مدرب',
      CONTENTS: 'المحتويات',
      ADDUNITE: 'إضافة قسم جديد',
      MODIFYUNITE: 'تعديل القسم',
      ADDLECTURE: 'إضافة محاضرة / درس',
      MODIFYLECTURE: 'تعديل المحاضرة / الدرس',
      ADDCONTENT: 'إضافة محتوى',
      SELECTUNIT: 'اختر القسم',
      ADDRULE: 'اضافة شرط ',
      SELECTCERTIFICATE: 'اختر الشهادة',
      CERTIFICATENAME: 'اسم الشهادة',
      SEARCHFORFILE: 'البحث عن ملف',
      ENTERWORD: 'ادخل اسم الملف او جزء منه',
      CREATENEWCOURSE: 'اضافة دورة جديدة',
      PRICE: 'السعر الحالي',
      TOTALPAYMENTS: 'المبلغ الاجمالي',
      TOTALTRAINEES: 'عدد المتدربين',
      TOTALORDERS: 'عدد الطلبات',
      TOTALGRADUATED: 'عدد الخريجين',
      CONFIGURATIONLIST: 'قائمة الاعدادات',
      TOTALINFO: 'بلوك وصف',
      TOTALASSIGNMENTS: 'الواجبات',
      TOTALUNITS: 'قسم تدريبي',
      TOTALLECTURES: 'محاضرة',
      TOTALVIDEOS: 'دقيقة',
      FIRSTTRAINER: 'المدرب',
      TOTALTRAINERS: 'مدرب',
      FIRSTCERTIFICATE: 'الشهادة',
      TOTALCERTIFICATES: 'شهادة',
      TOTALRULES: 'شرط',
      TOTALPRICES: 'سعر',
      ORIGINALPRICE: 'السعر الأصلي',
      CURRENTPRICE: 'السعر الحالي',
      MEDIA: 'صور الدورة',
      CMEDIA: 'صور الباقة',
      TOTALTESTS: 'الاختبارات',
      QUESTION: 'السؤال',
      QUESTIONTIME: 'وقت السؤال',
      FALLBACK: 'العودة إلى',
      NUMBEROFWORDS: 'عدد الكلمات',
      GLOSSARY: 'قائمة المصطلحات',
      TOTALCOURSES: 'اجمالي الدورات',
    },
    ASSIGNMENTS: {
      NAME: 'اسم الواجب',
      NEW: 'واجب جديد',
      BODY: 'نص الواجب',
      ATTENDANCE: 'سجل التسليمات',
      RULES: 'شرط القبول',
      AVAILABLEFROM: 'متاح من تاريخ',
      AVAILABLETO: 'متاح إلى تاريخ',
      NOTES: 'ملاحظات',
      ADDASSIGNMENT: 'اضافة واجب',
      FILE: 'استعراض الملف',
      DATE: 'تاريخ التقديم',
      STATUS: 'الحالة',
      NOTACCEPTED: 'غير مقبول',
      ACCEPTED: 'مقبول',
      REFUSE: 'رفض',
      ACCEPT: 'قبول',
      TRAINEENAME: 'اسم المتدرب',
      TRAINEEEMAIL: 'بريدالمتدرب',
      UPLOADDATE: 'تاريخ رفع الملف',
      EVALUATE: 'تصحيح الواجبات',
      REPORT: 'تقرير التسليمات',
      EMAIL: 'بريد المتدرب',
      ATTACHFILE: 'إضافة مرفق في الرد',
      SEARCH: 'استعراض الواجبات المقدمة',
      NOTEVALUATEDYET: 'لم يتم التقييم بعد',
      SELECTDATES: 'اختر فترة السماحية'
    },
    RULES: {
      CONDITION: 'الشرط',
      RELATION: 'العلاقة مع الشروط الأخرى',
      TIME: 'الوقت',
      AND: 'و',
      OR: 'أو',
      ADDRULE: 'إضافة شرط',
      FRACTIONOFUNITS: 'نسبة من الدورات',
      SPENDTIME: 'الوقت المطلوب',
    },
    GLOSSARY: {
      NAME: 'اسم مخصص',
      REQUIRED: 'حقل ضروري',
      EDITGLOSSARYSETTINGS: 'تحديث بيانات القائمة',
      DESCRIPTION: 'وصف مخصص لقائمة المصطلحات',
      PUBLIC: 'إضافة متاحة للجميع',
      DISPLAYDESCRIPTION: 'إظهار الوصف',
      APPROVED: 'بحاجة للموافقة',
      ALLOWEDITING: 'السماح بالتعديل',
      DUPLICATES: 'السماح بالتكرار',
      COMMENTS: 'السماح بالتعليقات',
      ADDCONCEPT: 'إضافة مصطلح جديد',
      CONCEPTNAME: 'المصطلح الجديد',
      CONCEPTS: 'المصطلحات',
      DATE: 'تاريخ الإضافة',
      CONCEPT: 'المصطلح',
      KEYWORDS: 'كلمات مفتاحية',
      DEFINITION: 'شرح المصطلح'
    },
    COURSE: {
      PRICELIST: 'قائمة اسعار الدورة',
      INFO: 'وصف الدورة ومعلومات عنها ',
      PUBLISH: 'نشر الدورة',
    },
    INFO: {
      HEADER: "العنوان",
      INSERTTEXTHERE: 'يمكنك كتابة النص هنا',
      BODY: "نص المحتوى"
    },
    PRICE: {
      NAME: 'الاسم',
      PRICE: 'السعر',
      STARTINGFROM: 'يبدأ من تاريخ',
      ENDINGAT: 'ينتهي بتاريخ',
      ISMAINPRICE: 'تعيين كسعر رئيسي',
      DATES: 'فترة صلاحية السعر',
      ADDPRICE: 'إضافة سعر جديد',
    },
    CURRICULUMS: {
      CURRICULUMS: 'الباقات التدريبية',
      EXTERNALID: 'معرف صفحة هبوط خارجية',
      FULLDETAILSREPORT: 'التقرير التفصيلي لمبيعات الباقة',
      MANAGE: 'إدارة الباقات',
      NAME: 'اسم الباقة',
      REQUIRED: 'دورة ضرورية',
      FREQUIRED: 'هذا الحقل ضروري',
      CATEGORY: 'التصنيف',
      PRICE: 'السعر',
      COURSELINK: 'رابط الباقة',
      NOTES: 'ملاحظات',
      IMAGE: 'غلاف الباقة',
      COURSES: 'الدورات المتضمنة',
      SELECTCOURSEORMORE: 'اختر دورة',
      ADDCURRICULUMS: 'إضافة باقة تدريبية',
      PUBLISH: 'تفعيل',
      PRICELIST: 'قائمة الاسعار',
      MANAGECOURSES: 'إدارة الدورات',
      COMPLETETIONRULES: 'شروط اتمام الباقة',
      CERTIFICATES: 'الشهادات',
      PRICES: 'الاسعار',
      ADDCERTIFICATE: 'إضافة شهادة',
      ADDCOURSE: 'إضافة دورة',
      CONFIGURE: 'إدارة الباقة'

    },
    FOOTER: {
      COPYRIGHT: 'Developed With ♥',
      NAME: 'في مختبرات خطوة ذكية',
      ALLRIGHTS: 'جميع الحقوق محفوظة',
      SUPPORT: 'الدعم الفني',
      TERMS: 'شروط الاستخدام',
      PRIVECY: 'سياسة الخصوصية'
    },
    EMAIL: {
      SENDPUBLICEMAIL: 'إرسال بريد عام',
      SUBJECT: 'الموضوع',
      BODY: 'نص الرسالة',
      GROUPS: 'المجموعات المرسل إليها'
    },
    COUPONS: {
      NOTES: 'ملاحظات / سبب الخصم',
      FULLDETAILSREPORT: 'تقرير مبيعات الكوبون',
      BankTransfer: 'تحويل بنكي',
      AVAILABILITYDATES: 'فترة الصلاحية',
      TYPEOFDISCOUNT: 'نوع الخصم',
      FREEPRODUCT: 'منتج مجاني',
      TOTALDISCOUNT: 'مبلغ محدد',
      CourseInOffer: 'دورة ضمن عرض',
      ManagementOrder: 'بطلب من الإدارة',
      Reparation: 'تعويض لعميل',
      Affiliate: 'كوبون مسوق بالعمولة',
      CashPayment: 'دفع نقدي',
      PaypalPayment: 'دفع باي بال',
      COUPONS: 'الكوبونات',
      ATTACHMENT: 'مرفق التحويل',
      NOATTACHEDFILE: 'لا يوجد مرفقات',
      NAME: 'اسم الكوبون',
      ADDPRODUCT: 'إضافة منتج',
      CODE: 'كود الكوبون',
      CODEREQUIRED: 'الكود مطلوب',
      DISCOUNTTYPE: 'نوع الخصم',
      AFFILIATE: 'المسوق',
      LIMITIATION: 'تحديد مرات الاستخدام',
      NUMBEROFORDERS: 'عدد الطلبات',
      NUMBEROFORDERSREQUIRED: 'عدد الطلبات ضروري',
      LIMITNO: 'مرات الاستخدام',
      LIMITNOREQUIRED: 'عدد المرات مطلوب',
      AMOUNT: 'الكمية',
      PERCENT: 'النسبة',
      PERCENTREQUIRED: 'النسبة حقل ضروري',
      SHIPPINGINCLUDED: 'متضمن الشحن',
      ISACTIVE: 'مفعل',
      STARTINGDATE: 'يبدأ من تاريخ',
      ENDINGDATE: 'ينتهي بتاريخ',
      CATEGORIES: 'التصنيفات',
      INCLUSIVECOURSES: 'الدورات المستثناة',
      ADDCOUPON: 'إضافة كوبون',
      ALLPRODUCTS: 'جميع المنتجات',
      PUBLICCOUPON: 'كوبون عام',
      MODIFYCOUPON: 'تعديل الكوبون',
      USAGE: 'الطلبات',
      TOTALUSAGE: 'اجمالي مرات الاستخدام',
      ADDCOURSE: 'إضافة دورة',
      ADDCONSULTANCY: 'إضافة استشارة',
      ADDCURRICULA: 'إضافة باقة',
      UPDATEDATES: 'تحديث فترة صلاحية الكوبون',
      PRODUCTS: 'المنتجات',
      REASON: 'سبب الخصم',
      DATES: 'فترة الصلاحية',
      CONFIGURE: 'إدارة الكوبون'
    },
    AFFILIATES: {
      AFFILIATES: 'نظام التسويق',
      NEWCOMISSION: 'عمولة جديدة',
      PAYMENTREQUESTS: 'طلبات الدفع',
      POLICIES: 'سياسة البرنامج',
      ADDPOLICY: 'إضافة سياسة خصوصية',
      MODIFYPOLICY: 'تعديل السياسة',
      POLICY: 'نص السياسة',
      DETAILS: 'التفاصيل',
      SUBSCRIBE: 'طلب اشتراك',
      AFFILIATERS: 'إدارة المسوقين',
      NAME: 'اسم المسوق',
      EMAIL: 'البريد الالكتروني',
      PHONE: 'رقم الهاتف',
      ADDRESS: 'العنوان',
      BANKINFO: 'معلومات البنك',
      STATUS: 'الحالة',
      ACTIVATE: 'تفعيل الحساب',
      MANAGEACCOUNT: 'إدارة الحساب',
      BANKNAME: 'اسم البنك',
      ACCOUNTNAME: 'اسم الحساب',
      ACCOUNTNUMBER: 'رقم الحساب',
      BANKSWIFT: 'السويفت',
      BANKADDRESS: 'عنوان البنك',
      ACCOUNTIBAN: 'ايبان الحساب',
      ACCOUNTADDRESS: 'عنوان صاحب الحساب',
      MANAGEAFFILIATES: 'إدارة المسوقين',
      MANAGECOMMISSIONS: 'إدارة العمولات',
      ADDAFFILIATE: 'إضافة مسوق جديد',
      ADDCOMMISSION: 'إضافة عمولة جديدة',
      COURSE: 'الدورة التدريبية',
      MOBILE: 'رقم الجوال',
      INFO: 'البيانات الشخصية',
      SOCIALINFO: 'البيانات التسويقية',
      YOUARENOTREGISTERED: 'لست مسجلا في نظام التسويق بالعمولة',
      YOUHAVEREGISTEREDBEFORE: 'لقد قمت بالتسجيل  بنظام التسويق بالعمولة',
      STATUSOFYOURAPPLICATION: 'حالة الطلب',
      REQUIRED: 'حقل ضروري',
      COUNTRY: 'الدولة',
      CITY: 'المدينة',
      POSTALCODE: 'الرمز البريدي',
      POSTOFFICE: 'صندوق البريد',
      AREYOUFAMOUS: 'هل انت مؤثر (تمتلك قاعدة جماهير و متابعين)',
      YES: 'نعم',
      NO: 'لا',
      FAMOUSPLATFORM: ' ماهي المنصة او الوسيلة التي تخاطب من خلالها متابعيك؟',
      DOYOUHAVESOCIALMEDIA: 'هل يوجد لديك متابعين على وسائل التواصل الاجتماعي ',
      FACEBOOK: 'فيسبوك',
      INSTAGRAM: 'انستجرام',
      TWITTER: 'تويتر',
      SNAPCHAT: 'سناب شات',
      YOUTUBE: 'يوتيوب',
      OTHERPLATFORM: 'اخرى',
      AREYOUBLOGGER: 'هل أنت مدون / كاتب',
      BLOGGERSITE: 'رابط المدونة ',
      DOYOUHAVEMAILINGLIST: ' هل يوجد لديك قائمة عملاء بريدية ',
      NUMBEROFSUBSCRIPERS: 'عدد المضافين لديك في القائمة البريدية',
      SUBSCRIBERSINTERESTS: 'ماهي اهم اهتمامات المضافين لديك ',
      DOYOUHAVEWHATSAPPLIST: 'هل يوجد لديك قائمة عملاء من خلال الواتساب',
      NUMBEROFWHTSAPPSUBSCRIBERS: 'عدد المضافين لديك في مجموعات او برودكاست الواتساب',
      EXPLAINYOURPLAN: 'إشرح لنا باختصار ماهي خطتك المختصرة للتسويق لدورات في اكاديميتنا',
      WELCOME: 'أهلا بك',
      MYCOMMISSIONS: 'العمولات المعتمدة',
      MYACCTOUNT: 'بيانات حساب الدفع',
      LINKS: 'روابط المنتجات',
      COPYLINK: 'نسخ الرابط',
      COMMISSION: 'العمولة',
      COMMISSIONS: 'عمولات الدورات',
      NEWCOMMISSION: 'عمولة جديدة',
      TOTALPAIDUNCOMMISSIONS: 'العمولات الغير مدفوعة',
      TOTALPAIDCOMMISSIONS: 'العمولات المدفوعة',
      TOTALCOUPONS: 'عدد الكوبونات',
      ORDERID: 'رقم الطلب',
      DATE: 'تاريخ الطلب',
      COUPONS: 'كوبونات المسوق',
      THECOMMISSIONS: 'عمولات المسوق',
      REQUESTSTATUS: 'حالة الطلب',
      TOTALAMOUNT: 'اجمالي المبلغ',
      REQUESTLATESTUPDATE: 'أخر تحديث',
      MANAGEPAYMENTREQUESTS: 'إدارة طلبات الدفع',
      AFFILIATENAME: 'المسوق',

    },
    ACCOUNT: {
      LOGOUT: 'تسجيل خروج'
    },
    TOPBAR: {
      HI: 'اهلا'
    },
    B2B: {
      REQUESTQUIZZ: 'تقييد الاختبارات',
      B2B: 'B2B',
      categorieslibrary: 'تصنيفات الدورات',
      LIVEDIPLOMAS: 'دبلومات بث مباشرة',
      RECORDEDDIPLOMAS: 'دبلومات مسجلة',
      NUMBEROFBUSINESS: 'عدد المستخدمين',
      SELECTCERTIFICATE: 'اختر الشهادة',
      ADDCERTIFICATE: 'اضافة شهادة',
      COMMUNICATIONTIMELINE: 'سجل التواصل',
      NUMBEROFCOURSES: 'عدد الدورات',
      UPLOADLOGO: 'رفع شعار العميل',
      DOMAINLINK: 'رابط الدخول',
      UPDATESETTINGS: 'تحديث الإعدادات',
      SETTINGS: 'إعدادات الحساب',
      PRIMARYCOLORTHEME: 'Primary Color',
      SECONDARYCOLORTHEME: 'Secondary Color',
      ACCENTCOLORTHEME: 'Accent Color',
      SUBSCRIBE: 'الاشتراك',
      CONFIGURE: 'إعدادات',
      ACTIVEACCOUNTS: 'الاشتراكات النشطة',
      COMPANYNAME: 'اسم الشركة',
      SELECTCONSULTANCY: 'عدد ساعات الاستشارات',
      NUMBEROFDEPARTMENTS: 'عدد الإدارات',
      SELECTUSERFILETOUPLOAD: 'اختر ملف المستخدمين للتحميل',
      SELECTDEPARTMENTFILETOUPLOAD: 'اختر ملف الإدارات للتحميل',
      SELECTPACKAGE: 'اختر باقة',
      PARENT: 'الإدارة الرئيسية',
      DEPARTMENTNAME: 'الإدارة الرئيسية',
      DEPARTMENTEMAIL: 'بريد القسم',
      USERS: 'المستخدمين',
      SUPERVISOREMAIL: 'بريد المشرف',
      ADDUSER: 'إضافة مستخدم',
      USEREMAIL: 'البريد الإلكتروني',
      SELECTUSER: 'اختر البريد الخاص بالمستخدم',
      ADDUSERTODEPARTMENT: 'إضافة مستخدمين إلى القسم',
      MANAGEUSERSINDEPARTMENT: 'ادارة المستخدمين ',
      MANAGECOURSESINDEPARTMENT: 'المنتجات المتاحة',
      ADDLEAD: 'إضافة عميل ',
      COMPANYPHONE: 'رقم هاتف الشركة',
      COMPANYEMAIL: 'بريد الشركة',
      CONTACTPHONE: 'رقم هاتف المسؤول',
      CONTACTEMAIL: 'بريد المسؤول',
      DETAILS: 'تفاصيل',
      CONTACTNAME: 'اسم المسؤول',
      ADDRESS: 'العنوان',
      MANAGESUBSCRIPTIONS: 'إدارة الاشتراكات',
      NUMBEROFENROLLMENTS: 'عدد المستخدمين',
      STARTINGAT: 'يبدأ بتاريخ',
      ENDINGAT: 'ينتهي بتاريخ',
      ISACTIVE: 'مفعل',
      IMPORTUSERS: 'استيراد المستخدمين',
      SUBSCRIPTIONSMANAGEMENT: 'إدارة الاشتراكات',
      MANAGEUSERS: 'ادارة المستخدمين',
      IMPORTDEPARTMENTS: 'إستيراد الاقسام',
      DEPARTMENTS: 'الاقسام والادارات',
      COURSES: 'الدورات المتاحة',
      MANAGECOURSES: 'ادارة الدورات المتاحة للاشتراك',
      SELECTCATEGORY: 'اختر التصنيف',
      NEWSUBSCRIBE: 'اشتراك جديد',
      SELECTCUSTOMER: 'اختر زبون',
      INCLUSIVECOURSES: 'اختر الدورات المستثناة',
      NAME: 'الاسم Name',
      EMAIL: 'البريد الالكتروني Business Email',
      PHONE: 'رقم الهاتف/الموبايل Phone Number',
      JOBTITLE: 'الموقع الوظيفي Job Title',
      COMPANYSIZE: 'حجم الشركة Company Size ',
      COUNTRY: 'الدولة Country',
      CITY: 'المدينة State',
      APPLICATIONS: 'الطلبات',
      LEADS: 'طلب',
      UPDATEINFO: 'تحديث البيانات',
      DELETEAPPLICATION: 'حذف الطلب',
      CONNECTED: 'تم التواصل معهم',
      SIGNEDAPPLICATIONS: 'تم التوقيع معهم',
      APPLICATION: 'طلب',
      ADDTOTIMELINE: 'إضافة تحديث على الحالة',
      ADDUPDATE: 'إضافة تحديث',
      ADDCONTRACT: 'إضافة عقد',
      FROMDATE: 'ابتداء من تاريخ',
      TODATE: 'لغاية تاريخ',
      CONTRACTPERIOD: 'صلاحية الاشتراك',
      NUMBEROFUSERS: 'عدد المستخدمين',
      NUMBEROFCONSULTANCIES: 'عدد الاستشارات',
      NUMBEROFPACKAGES: 'عدد الباقات',
      CONTRACTTOTAL: 'اجمالي قيمة العقد',
      ALLREQUESTS: 'طلبات الاستشارات للشركات',
      SEND: 'موافق',
      DECLINEREASON: 'سبب الرفض',
      DECLINEREQUEST: 'رفض الطلب',
      CANCELED: 'ملغى',
      DONE: 'منتهي',
      APPROVED: 'مقبول',
      DECLINED: 'مرفوض',
      DECLINE: 'رفض',
      ACCEPT: 'قبول',
      SESSIONSLEFT: 'الجلسات المتوفرة',
      SELECTEDDATE: 'التاريخ والوقت',
      SESSIONREASON: 'تفاصيل الجلسة المطلوبة',
      COMPANYNAMEANDDEPARTMENT: 'الشركة / القسم',
      STATUS: 'الحالة',
      REPLY: 'ملاحظات الحالة',
      COMMUNCATIONLOG: 'سجل التواصل',
      DETELEPROFILE: 'حذف الملف',
      NEWSUBSCRIPTIONS: 'طلبات الشركات الجديدة',
      B2BNEWAPPLICATIONS: 'الطلبات الجديدة',
      STARTCONTACT: 'بدء التواصل',
      B2BCONNECTED: 'تم التواصل معهم',
      
    
    },
    TRAINERS: {
      TRAINERS: 'المدربين',
      IMAGE: 'صورة المدرب',
      NAME: 'اسم المدرب',
      AVATAR: 'صورة المدرب',
      EMAIL: 'البريد الالكتروني',
      PHONE: 'رقم الهاتف',
      NOTES: 'ملاحظات',
      TRAINER: 'إدارة المدربين',
      INFO: 'معلومات المدرب',
      ADDTRAINER: 'اضافة مدرب جديد',
      MODIFYTRAINER: 'تعديل مدرب',
      MOREINFO: 'معلومات اضافية',

    },
    TABLE: {
      CELLDELETE: 'حذف',
      CELLMANAGE: 'إدارة',
      NORESULTSFOUND: 'لا توجد نتائج',
      SELECTFROMLIST: 'اختر من القائمة',
      UPDAECELL: 'تعديل'
    },
    USERS: {
      USERS: 'إدارة المستخدمين',
      TRAINERSAPPLICATIONS: 'طلبات المدربين',
      RECORDEDCOURSES: 'هل لديه دورات مسجلة',
      CONSULTANCYABILITY: 'هل لديه القدرة على تقديم الاستشارات',
      TRAINERS: 'المدربين',
      MANAGEUSERS: 'ادارة مستخدمي الادارة',
      CUSTOMERREPORT: 'الاستعلام عن مشترك',
      HASBEENRESET: 'تمت إعادة تعيين كلمة المرور الكلمة الافتراضية هي 123456',
      RESETPASSWORD: 'إعادة تعيين كلمة المرور',
      NO: 'غير مفعل',
      YES: 'مفعل',
      TRAINEESTATUS: 'حالة المتدرب',
      STATUS: 'حالة المشترك',
      PHONENUMBER: 'رقم الهاتف',
      TOTALNUMBEROFCOURSES: 'اجمالي الدورات',
      CREAETNEWUSER: 'اضافة مستخدم جديد',
      NEWUSER: 'مستخدم جديد',
      PHONE: 'رقم الهاتف',
      EMAIL: 'البريد الالكتروني',
      RESENDACTIVATIONMAIL: 'إعادة إرسال بريد التفعيل',
      ACTIVATETRAINEE: 'تفعيل ك متدرب',
      NAME: 'اسم المستخدم',
      ADDUSER: 'إضافة',
    },
    REPORTS: {
      CITY: 'المدينة',
      RATING: 'التقييم',
      REVIEW: 'تعليق على الدورة',
      RATEDATE: 'تاريخ التقييم',
      NAMEOFSESSION: 'المدينة',
      CONTROLS: 'المدينة',
      PROVIDER: 'المدينة',
      PERIODICREPORT: 'المبيعات حسب الأيام',
      PRODUCTSREPORT: 'تقرير المنتجات',
      PRICE: 'السعر',
      PAYMENTFEES: 'رسوم بوابة الدفع',
      COUPONSAMOUNT: 'عدد الكوبونات',
      TOTALDISCOUNT: 'اجمالي الخصم',
      MOSTSELLINGPRODUCTS: 'المنتجات الأكثر مبيعاً',
      CATEGORIESREPORT: 'مبيعات التصنيفات',
      MANAGE: 'ادارة',
      TOTALVIDEOS: ' عدد الفيديوهات ',
      TOTALRESOURCES: ' عدد الملفات التعليمية المساعدة ',
      RATINGS: 'تقييم الدورات',
      TOTALDUE: 'اجمالي المطلوب',
      QUICKWIDGETS: 'تقارير سريعة',
      CATEGORYNAME: 'تقرير مبيعات التصنيفات',
      NAME: 'الاسم',
      COUPONS: 'تقرير الكوبونات',
      TOTALUSEDCOUPONS: 'اجمالي الكوبونات المستخدمة',
      TOTALCOUPONSDISCOUNT: 'اجمالي  قيمة الكوبونات',
      TOTALPUBLISHEDCOURSES: 'اجمالي الدورات المتاحة',
      TOTALCONFIREMEDUSERS: 'اجمالي المسجلين في المنصة',
      STRIPEORDERID: 'مرجع بوابة الدفع',
      MONTHLYSOLDCOURSES: 'مبيعات الشهر الحالي',
      TOTALPAYMENTS: 'إجمالي مبيعات المنصة',
      TOTALVIDEOLENGTH: 'مدة الفيديوهات بالساعة',
      TOTALTRAINEES: 'عدد المتدربين',
      TOTALPAIDORDERS: 'عمليات الشراء',
      TOTALASSIGNMENTS: 'اجمالي الواجبات المسلمة',
      COUPON: 'كوبون',
      ISOLDMEMBER: 'من المنصة القديمة',
      GRANTEDCERTIFICATES: 'تقرير الشهادات الممنوحة',
      CODE: 'كود الشهادة',
      USERNAME: 'اسم الدورة',
      ISSUEDBY: 'المانح',
      COURSENAME: 'اسم الدورة',
      FREECOURSE: 'كورس مجاني',
      ORDERSTATUS: 'حالة الطلب',
      FEES: 'رسوم بوابة الدفع',
      GRANTCERTIFICATE: 'اصدار شهادة',
      PRUCHASEDATE: 'تاريخ الشراء',
      USER: 'بريد المستخدم',
      ORDERID: 'رقم الطلب',
      EMAIL: 'بريد العميل',
      STATUS: 'حالة الطلب',
      DATE: 'تاريخ الشراء',
      PRODUCTNAME: 'اسم المنتج',
      PRODUCTTYPE: 'نوع المنتج',
      TOTALPAID: 'اجمالي المدفوع',
      TOTALS: 'اجمالي المبيعات',
      BESTSELLINGCOURSETHISMONTH: 'الدورة الأكثر مبيعاً',
      TOTALPAYMENT: 'اجمالي المبيعات',
      SELECTDATE: 'اختر تاريخ التقرير',
      BROWSEREPORT: 'استعراض التقرير',
      SELECTDATES: 'يجب اختيار فترة زمنية محددة',
      MONTHLYSALES: 'مبيعات الشهر',
      TODAY: 'اليوم',
      SELLINGREPORTS: 'تقارير المبيعات',
      CUSTOMERS: 'تقارير العملاء',
      REPORTSTYPE: 'نوع التقرير',
      TOTALORDERS: 'اجمالي الطلبات',
      CARTAVARGE: 'متوسط السلة',
      PRODUCTS: 'تقارير المنتجات',
      TOTALNET: 'صافي المبيعات',
      TOTALPAYMENTFEES: 'رسوم بوابة الدفع',
      TOTALCOUPONS: 'كوبونات التخفيض',
      TOTALSALES: 'اجمالي المبيعات',
      SALESSTATISTICS: 'تقرير المبيعات الاجمالي',
      COUPONSREPORT: 'تقرير الكوبونات',
      AFFILIATE: 'المسوق',
      COUPONCODE: 'كود الخصم',
      CUSTOMER: 'العميل',
      CREATIONDATE: 'تاريخ اصدار الكوبون',
      PERCENTAGE: 'نسبة الخصم',
      USAGEDATE: 'تاريخ الاستخدام',
      CERTIFICATES: 'الشهادات',
      ACTIVETRAINEES: 'المتواجدين الآن',
      TOTALCERTIFICATES: 'تخرج حتى الآن',
      TOTALCONSULTANCIES: 'طلبات الاستشارات',
      TOTALB2BAPPLICATIONS: 'طلبات الشركات',
      TOTALAFFILIATES: 'المسوقين بالعمولة',
      TOTALTRAINERSAPPLICATIONS: 'طلبات المدربين',
      CLIENTSSTATISTICS: 'احصائيات العملاء',
      TOTALGRADUATES: 'اجمالي الخريجين',
      MINUTE: 'دقيقة مشاهدة',
      MOSTLEARNER: 'الأكثر تعلماً',
      CERTIFICATE: 'شهادة',
      MOSTACHIEVER: 'الأكثر إنجازاً',
      MOSTBUYERS: 'الأكثر شراء',
      LOYALCLIENTS: 'العملاء الأكثر ولاء',
      ABANDONEDCARTS: 'السلات المتروكة',
      AFFILIATESSTATISTICS: 'احصائيات المسوقين بالعمولة',
      TOTALAFFILIATEAPPLICATIONS: 'اجمالي الطلبات المقدمة',
      TOTALACCEPTEDAPPLICATIONS: 'اجمالي الطلبات الموافق عليها',
      TOTALREFUSED: 'اجمالي الطلبات المرفوضة',
      TOTALACTIVEAFFILIATES: 'المسوقين الفعالين',
      TOTALPROAFFILIATES: 'المسوقين المحترفين',
      AFFILIATEREPORT: 'تقرير التسويق بالعمولة',
      PHONE: 'رقم الهاتف',
      TOTALCOMMISSION: 'اجمالي العمولة المستحقة',
      AFFILIATES: 'التسويق بالعمولة',
      TOTALAMOUNT: 'الاجمالي',
      TOTALREVIEWS: 'عدد المقيمين',
      TOPCOURSE: 'الأعلى تقييماً',
      LEASTCOURSE: 'الأقل تقييماً',
      MOSTCOURSE: 'الأكثر تقييماً',



    },
    COUPON: {
      AFFILIATE: 'اختر مسوق',
      TOTALPAID: 'اجمالي المدفوع',
      STRIPEORDERID: 'رقم العملية في بوابة الدفع',
      PRODUCTNAME: 'اسم المنتج',
      ORDERSTATUS: 'حالة الطلب',
      GRANTEDCERTIFICATES: 'الشهادات الممنوحة',
      ID: '#',
      DATE: 'تاريخ المنح',
      CODE: 'كود الشهادة',
      COURSENAME: 'اسم الدورة',
      ISSUEDBY: 'بواسطة',
      GRANTCERTIFICATE: 'اصدار شهادة'
    },
    ORDERS: {
      PENDING: 'طلب غير مكتمل',
      COMPLETE: 'مدفوع',
      DIDNTCOMPLETE: 'غير مكتمل',
      BANKPROBLEM: 'مشكلة في البنك',
      DONE: 'منتهي',
      NOPAYMENTATTEMPT: 'لم يتم الدفع',

    },
    MEETINGS: {
      MEETINGLINK: 'رابط جلسة الاستشارة',
      NAME: 'اسم الجلسة',
      HOUR: 'تاريخ ووقت الجلسة',
      TIMEZONE: 'بتوقيت',
      NEWMEETING: 'جلسة جديدة',
      NETMEETING: 'جلسة جديدة',
      ZOOMMEETING: 'Zoom باستخدام',
      BBBMEETING: 'BigBlueButton باستخدام ',
      MEETINGTYPE: 'مزود الخدمة',
      ADDNEWMEETING: 'حفظ',
      REQUIRED: 'خانة ضرورية',
      TYPE: 'مزود الخدمة',
      DURATION: 'مدة الجلسة'
    },
    MEETING: {
      NAME: 'اسم الجلسة',
      HOUR: 'تاريخ ووقت الجلسة',
      TIMEZONE: 'بتوقيت',
      NEWMEETING: 'محاضرة جديدة',
      NETMEETING: 'محاضرة جديدة',
      ZOOMMEETING: 'Zoom باستخدام',
      BBBMEETING: 'BigBlueButton باستخدام ',
      MEETINGTYPE: 'مزود الخدمة',
      DURATION: 'مدة الجلسة'
    },
    ZOOM: {
      KEY: 'Key',
      SECRET: 'Secret',
      JWT: 'Jwt Token',
    },
    ACTIVITIES: {
      DECISIONMAKING: 'اتخاذ القرار',
      QUIZZES: 'الاختبارات',
      PARAGRAPH: 'الكلمات المفقودة',
      MATCHINGBETWEEN: 'ربط الجمل',
      LOGICALORDER: 'الترتيب المنطقي',
      CASESTUDIES: 'الحالات الدراسية',
      ADDACTIVITIES: 'نشاط جديد',
      ADDNEW: 'نشاط جديد',
      TITLE: 'العنوان',
      title: 'العنوان',
      BACKTOALL: 'العودة',
      USER: 'المتدرب',
      ANSWER: 'الجواب',
      ISCORRECT: 'التقييم',
      YES: 'مقبول',
      NOTYET: 'لم يتم تقييمه ',
      NO: 'غير مقبول',
      ACCEPT: 'قبول الجواب',
      REFUSE: 'رفض الجواب',
      body: 'النص',
      BODY: 'النص',
      FIRSTSENTENCE: 'القسم الأول من الجملة ',
      SECONDSENTENCE: 'القسم الثاني من الجملة ',
      ADDANSWER: 'اضافة',
      NUMBEROFPAIRS: 'عدد الحلول',
      SENTENCE: 'الجملة',
      SELECTCASE: 'استعراض الحالة',
      INSERTSENTECNETOSEELCT: 'ادخل النص الكامل للسؤال',
      NUMBEROFMISSINGWORDS: 'عدد الكلمات المفقودة',
    },
    PERMISSIONS: {
      PREVIEW: 'اطلاع',
      ADD: 'اضافة',
      MODIFY: 'تعديل',
      DELETE: 'حذف',
      REPORT: 'تقارير',
      SUPER: 'تحديد الكل',
      Courses: 'الدورات',
      CourseReports: 'تقارير الدورات',
      CourseAttendees: 'متدربين الدورات',
      CoursePurchasers: 'شراء الدورات',
      CourseManagement: 'إدارة الدورات',
      Trainers: 'المدربين',
      CourseFiles: 'بنك الملفات',
      Certificates: 'الشهادات',
      Curricula: 'الباقات التدريبية',
      Categories: 'التصنيفات',
      Publish: 'نشر الدورات',
      Consultancies: 'الاستشارات',
      ConsultantsManagement: 'إدارة المستشارين',
      ConsultancyManagement: 'ادارة الاستشارات',
      ConsultancyPurchasers: 'زبائن الاستشارات',
      ConsultancyAttendees: 'حضور الاستشارات',
      ConsultancyReports: 'تقارير الاستشارات',
      Coupons: 'الكوبونات',
      CouponManagement: 'إدارة الكوبونات',
      CouponReports: 'تقارير الكوبونات',
      Affiliates: 'التسويق بالعمولة',
      PaymentManagement: 'معالجة طلبات الدفع',
      SubscriptionsManagement: 'إدارة الاشتراكات',
      PaymentsExport: 'تصدير ملف الدفعات',
      ReportsExport: 'تصدير التقارير',
      AffiliatesSubscriptions: 'المسوقين بالعمولة',
      AffiliatesReports: 'تقارير التسويق بالعمولة',
      Business: 'الشركات',
      ProgramPolicy: 'معلومات البرنامج',
      ManageSubscriptions: 'إدارة الاشتراكات',
      AddSubscription: 'إضافة عميل شركات',
      BusinessCRM: 'التعامل مع الطلبات',
      BusinessReports: 'تقارير الشركات',
      CustomerService: 'خدمة الزبائن',
      CertificatesReports: 'تقرير الشهادات',
      CoursePersonalLogfile: 'تقرير المشترك',
      CourseLogfile: 'تقرير حضور الدورة',
      ManageTags: 'إدارة الوسوم',
      ManageCategories: 'إدارة التصنيفات',
      ExportCourseReport: 'تصدير تقارير الدورات',
      ExportReports: 'تصدير التقارير العامة',
      CoursePaymentReport: 'التقرير المالي للدورات',
      TotalPaymentReports: 'تقرير المبيعات العام',
      Settings: 'الإعدادات',
      TicketSystem: 'نظام التذاكر',
      GeneralSettings: 'الاعدادات الرئيسية',
      Users: 'المستخدمين',
      Terminating: 'تجميد مستخدم',
      AddUser: 'إضافة مستخدم',
      Suspension: 'إيقاف مستخدم',
      UserCourses: 'دورات المشترك',
      Searching: 'البحث عن مشترك',
      Reports: 'التقارير',
      Logfile: 'السجل العام',
      Dashboard: 'الرئيسية',
      TrainersApplicationsExport: 'تصدير طلبات المدربين',
      TrainersApplications: 'الاطلاع على طلبات المدربين',
      SalesExport: 'تصدير تقرير المبيعات',
      Admin: 'مدير اكاديمية',
      USERPMIERSSIONS: 'صلاحيات المستخدم',
      SETSUPERADMIN: 'تعيين مدير اكاديمية',
      UNSETSUPERADMIN: 'إلغاء صلاحيات مدير اكاديمية',
      SAVEPERMISSIONS: 'حفظ الصلاحيات',
      RegisteredUsersExport: 'تصدير تقرير المسجلين'
    },
    TOOLTIPS: {
      LIVEDIPLOMAS: 'دبلومات بث مباشرة',
      RECORDEDDIPLOMAS: 'دبلومات مسجلة',
      EXTERNALID: 'في حال تم بناء صفحة خارجية يمكن ربط الباقة معها',
      AFFILIATECOMMISSIONCOURSE: 'اختر الدورةالمطلوبة',
      HINT: 'تعليمات',
      REQUESTQUIZZ: 'بحاجة لموافقة الادارة لتقديم الاختبارات ',
      GOOGLEID: 'المعرف في متجر جوجل',
      APPLEID: 'المعرف في متجر آبل',
      CALENDLYLINK: 'قم بنسخ الرابط من حسابك في calendly',
      SHOWINLISTING: 'إظهار في محرك البحث',
      SHOWINLISTINGDOLOMA: 'إظهار في محرك البحث ك دبلوم',
      KEYWORDS: 'يمكن ادخال عدد غير محدود من الكلمات المفتاحية  ',
      COUPONSREASON: 'اختر سبب الخصم',
      SELECTDATES: 'اختر فترة زمنية',
      ISFEATURED: 'دورة مميزة',
      CONTENTTYPE: 'بعض  انواع المحتويات التعليمية محددة مثل الفيديو',

      AFFILIATECOMMISSIONFORCOURSE: 'النسبة المئوية للعمولة المقدرة',
      COURSEINFOTITLE: 'العنوان الذي سيظهر في مربع النص',
      COURSEINFOBODY: 'سيتم حفظ التنسيقات الخاصة بالخط والنص',
      CERTIFICATENAME: 'الاسم الخاص بالشهادة ',
      CERTIFICATEDESCRIPTION: 'يمكن وضع وصف مساعد للإدارة لمعرفة سبب استخدام الشهادة',
      CONSULTANCYNAME: 'سيظهر الاسم في الصفحة الرئيسة ويقصد به عنوان الاستشارة',
      CONSULTANCYTYPE: 'طبيعة الاستشارة / جلسات - برنامج الخ',
      CONSULTANCYTIME: 'مدة الجلسة الواحدة من الاستشارة مقدر بالدقائق',
      CONSULTANCYNUMBEROFSESSIONS: 'عدد جلسات الاستشارة ',
      CONSULTANCYSUBSCRIPTIONLENGTH: 'مدة صلاحية الاستشارة مثال شهر واحد',
      CONSULTANCYPRICE: 'سعر الاستشارة',
      CONSULTANCYACTIVATE: 'تفعيل الاستشارة لتظهر في الصفحة الرئيسية',
      CONSULTANCYCOVER: 'صورة تعبيرية عن الاستشارة',
      CONSULTANCYSQUARECOVER: 'صورة مربعة تعبيرية عن الاستشارة',
      TRAINERNAME: 'اسم المدرب',
      TRAINEREMAIL: 'البريد الالكتروني',
      TRAINERPHONE: 'رقم الهاتف الخاص به',
      TRAINERNOTES: 'ملاحظات خاصة بالادارة',
      TRAINERCV: 'السيرة الذاتية الخاصة بالمدرب',
      TRAINERCVPLUS: 'معلومات اضافية',

      CATEGORYNAME: 'الاسم التوضيحي للتصنيف سيظهر في الصفحة الرئيسية',
      PARENTCATEGORY: 'التصنيف الرئيس للتصنيف الحالي',
      CATEGORYDESCRIPTION: 'وصف خاص بالادارة',
      COUPONNAME: 'يفيد الادارة ولا يظهر في الصفحة الرئيسية',
      COUPONCODE: 'الانتباه لحالة الاحرف لتفادي تكرار الكوبونات',
      COUPONACTIVATE: 'تفعيل الكوبون لا يكتمل بدون المنتجات والتاريخ والعكس صحيح',
      COUPONPERCENTAGE: 'النسبة المئوية للحسم على هذا الكوبون',
      COUPONTOTALORDERS: 'عدد الطلبات ',
      COUPONAFFILIATE: 'يجب اختيار اسم المسوق او اختيار كوبون عام',

      QUESTIONTYPE: 'اختر نوع السؤال من القائمة',
      QUESTIONNAME: 'اسم السؤال او عنوان السؤال',
      QUESTIONDEGREE: 'درجة السؤال ',
      FIRSTOFPAIR: 'القسم الأول من جملة السؤال',
      SECONDOFPAIR: 'القسم الثاني من جملة السؤال',
      ANSWER: 'الجواب',
      ANSWEREXPLANATION: 'التعليل سواء صح او خطأ',
      ISCORRECTANSWER: 'تحديد صح او خطأ',
      MEETINGNAME: 'اسم جلسة البث المباشر / المحاضرة',
      MEETINGDATETIME: 'وقت وتاريخ المحاضرة / البث المباشر',
      MEETINGTIMEZONE: 'المنطقة الزمنية الخاصة بالمحاضرة',
      MEETINGDURATION: 'مدة الجلسة مقدرة بالدقائق',
      CASEHEADER: 'عنوان الحالة',
      CASEISOPEN: 'تحديد مفتوحة او اتخاذ قرار',
      CASEANSWER: 'الجواب',
      CASEANSWEREXPLANATION: 'تعليل الجواب سواء صحيح او خاطئ',
      CASEANSWERISCORRECT: 'تحديد كجواب صحيح',
      CASEBODY: 'شرح تفصيلي للحالة',
      FIRSTSENTENCE: 'الجملة',
      FILLSPACETITLE: 'النص الكامل الذي سيتم اقتطاع الكلمات منه',

      FORUMNAME: 'عنوان النقاش',
      FORUMDESCRIPTION: 'شرح النقاش',
      FORUMDISPLAYDESCRIPTION: 'اظهار شرح النقاش في الصفحة الرئيسية',
      GLOSSARYNAME: 'عنوان قائمة المصطلحات',
      GLOSSARYDISPLAYDESCRIPTION: 'اظهار شرح قائمة المصطلحات في الصفحة الرئيسية',
      GLOSSARYPUBLIC: 'إظهار لجميع المستخدمين بغض النظر عن اشتراكهم',

      GLOSSARYAPPROVED: 'الموافقة على المصطلحات قبل اضافتها',
      GLOSSARYALLOWEDITING: 'السماح بتعديل المصطلحات والتعريفات لغير الادارة',
      GLOSSARYDUPLICATES: '',
      GLOSSARYCOMMENTS: '',
      CONCEPTNAME: '',
      CONCEPTKEYWORDS: '',
      CONCEPTDEFINITION: '',
      LOGICALORDERTITLE: '',
      LOGICALORDERSENTENCE: '',

      CONTENTAVAILABILITY: 'اتاحة المحتوى للذين لم يشتروا الدورة التدريبية بعد',
      CONTENTBODY: '',
      LECTURENAME: '',
      ASSIGNMENTNAME: '',
      ASSIGNMENTBODY: '',
      ASSIGNMENTACCEPTANCERULE: '',
      ASSIGNMENTAVAILABLEFROM: '',
      ASSIGNMENTAVAILABLETO: '',
      ASSIGNMENTFOREMAIL: '',
      ASSIGNMENTREPLYCOMMENT: '',
      ASSGINMENTREPLYFILE: '',
      COURSEUNITNAME: '',
      MATCHINGACTIVITYTITLE: '',
      MATCHINGFIRSTSENTENCE: '',
      MATCHINGSECONDSENTENCE: '',
      COURSERULECONDITION: '',
      COURSERULERELATION: '',
      COURSEWIKINAME: '',
      COURSEWIKIDESCRIPTION: '',
      COURSEWIKIPUBLIC: '',
      COURSEWIKIARTICLENAME: '',
      COURSENAME: 'اسم الدورة التدريبية',
      COURSETYPE: 'نوع الدورة يمكن اختيار',
      CCOURSDESCRIPTION: 'وصف الدورة التدريبية يظهر في صفحة الدورة',
      COURSECATEGORY: 'تصنيف الدورة',
      COURSETAG: 'هذا التاغ خاص بالرابط مع اكتف كامبين ',
      COURSEISDIPLOMA: 'سيظهر ضمن الباقات التدريبية وليس ضمن الدورات',
      COURSELINK: 'نص فريد يدل على الدورة يظهر في آخر الرابط ',
      COURSEPRICENAME: 'اسم خاص بالسعر',
      COURSEPRICE: 'السعر ',
      COURSEPRICEISMAIN: 'يجب اختيار سعر رئيسي واحد فقط',
      COURSEPRICEFROMDATE: 'تاريخ بداية صلاحية السعر / اكبر او يساوي',
      COURSEPRICETODATE: 'تاريخ انتهاء صلاحية السعر / اصغر او يساوي',
      QUIZZNAME: 'اسم الاختبار',
      QUIZZPASSWORD: 'كلمة مرور خاصة بالاختبار',
      QUIZZTIMELIMIT: 'الوقت الزمني ',
      QUIZZREPETITION: 'عدد مرات السماح بغض النظر تم الاجتياز او لا',
      QUIZZUNITE: 'تخصيص الاختبار ليكون اختبار معرفي خاص بوحدة دراسية مخصصة',
      QUIZZIFFAILED: 'هذه الشروط تظهر في حال عدم الاجتياز',
      QUIZZIFPASSED: 'هذه الشروط تظهر في حال الاجتياز',
      QUIZZMINSCORE: 'الحد الادنى للاجتياز',
      QUESTIONSRANDOMIZE: 'إعادة ترتيب الاسئلة والاجوبة بشكل عشوائي او تلقائي',
      QUIZZWHENTOQUIZZ: 'متى يظهر الاختبار للمتدرب',
      QUIZZOPTIONAL: 'في حال تحديد اختياري لا يحتسب ضمن شروط التخرج',
      CURRICULUMADDCOURSE: 'اضاف دورات على الباقة التدريبية',
      CURRICULANAME: 'اسم الباقة التدريبية الذي سيظهرفي الصفحة الرئيسية',
      CURRICULALINK: 'الجزء الخاص بالباقة في الرابط العام',
      CURRICULACATEGORY: 'التصنيف الخاص بالباقة التدريبية',
      NOTES: 'ملاحظات',
      USERNAME: 'اسم المستخدم الكامل',
      USEREMAIL: 'البريد الالكتروني للمستخدم',

      BANNERIMAGE: 'صورة البانر',
      BANNERTYPE: 'نوع البانر',
      BANNERHEADER: 'عنوان البانر',
      BANNERVIDEOLINK: 'رابط فيديو للبانر',
      PACKAGENAME: 'اسم الباقة',
      EXTERNALLINK: 'رابط خارجي',
      BANNERBODY: 'محتوى البانر',
    },
    TRAINER: {
      FIRSNAME: 'الاسم',
      LASTNAME: 'اللقب',
      EMAIL: 'البريد الالكتروني',
      NATIONALITY: 'الجنسية',
      COUNTRY: 'الدولة التي تقيم فيها',
      CITY: 'المدينة التي تقيم فيها',
      MOBILE: 'رقم الموبايل',
      BIRTHDATE: 'تاريخ ميلادك',
      EDUCATIONALLEVEL: 'آخر درجة تعليمية حصلت عليها',
      MAJOR: 'التخصص العام',
      SPECIALIZED: 'التخصص الدقيق',
      PROGRAMS: 'مجالات التدريب الرئيسية التي تحترف التدريب فيها',
      EXPERIENCE: 'سنوات الخبرة كمدرب محترف',
      SCOPEDEXPERIENCE: 'معظم خبرتك في التدريب كانت في تدريب القطاع',
      LANGUAGES: 'اختر اللغة التي تحترف التدريب فيها بطلاقة',
      ADDITIONALSERVICES: 'ماهي الخدمات الإضافية التي تقدمها إضافة لخدمة التدريب داخل القاعة ولديك خبرة لا تقل عن سنتين فيها',
      PERSONALPROGRAM: 'اسم البرنامج الذي ترغب تدريبه مع اكاديميتنا',
      REQUIRED: 'حقل مطلوب',
      TITLE: 'اللقب',
      FULLNAME: 'الاسم الكامل',
      PHONE: 'رقم الهاتف',
      CONSULTANCY: 'هل ترغب في تقديم خدمة الاستشارات عن بعد',
      YES: 'نعم',
      NO: 'كلا',
      DOYOUTUBE: 'هل لديك قناة يوتيوب',
      DOINSTAGRAM: 'هل لديك صفحة انستغرام',
      DOFACEBOOK: 'هل لديك صفحة فيس بوك',
      RECORDEDCOURSES: 'هل قدمت دورات الكترونية مسجلة سابقا؟  (Recorded Courses)',
      YOUTUBECHANNEL: 'ادخل رابط القناة',
      INSTAGRAMPROFILE: 'ادخل رابط الحساب',
      FACEBOOKPAGE: 'ادخل رابط الصفحة',
      YOURAPPLICATIONHASBEENSENT: 'تم ارسال الطلب  سيتم التواصل معكم في أقرب وقت ممكن  شكراً لاختياركم اكاديميتنا ',
    },
    TITLES: {
      TITLE1: 'السيد/ة',
      TITLE2: 'أخصائي/ة',
      TITLE3: 'استاذ/ة',
      TITLE4: 'مهندس/ة',
      TITLE5: 'دكتور/ة',
      TITLE6: 'استاذ/ة دكتور/ة',
      TITLE7: '',
    },
    STUDIES: {
      STUDY1: 'ثانوية',
      STUDY2: 'بكالوريوس',
      STUDY3: 'ماجستير',
      STUDY4: 'دكتوراه',
      STUDY5: 'بروفسور',
      STUDY6: '',
      STUDY7: '',
      STUDY8: '',
      STUDY9: '',
      STUDY10: '',
    },
    EXPFIELDS: {
      STUDY1: 'القطاع الحكومي',
      STUDY2: 'شركات كبيرة',
      STUDY3: 'شركات صغيرة ومتوسطة',
      STUDY4: 'افراد وجمهور عام',
      STUDY5: 'منظمات غير ربحية',
      STUDY6: '',
      STUDY7: '',
      STUDY8: '',
      STUDY9: '',
      STUDY10: '',
    },
    LANGUAGES: {
      STUDY1: 'اللغة العربية',
      STUDY2: 'اللغة الانجليزية',
      STUDY3: 'لغات أخرى',
      STUDY4: 'افراد وجمهور عام',
      STUDY5: 'منظمات غير ربحية',
      STUDY6: '',
      STUDY7: '',
      STUDY8: '',
      STUDY9: '',
      STUDY10: '',
    },
    MAJORS: {
      STUDY1: 'الإدارة والقيادة',
      STUDY2: 'التسويق والمبيعات',
      STUDY3: 'الإدارة المالية',
      STUDY4: 'المحاسبة',
      STUDY5: 'إدارة الموارد البشرية',
      STUDY6: 'الجودة والتمييز المؤسسي',
      STUDY7: 'الأمن والسلامة',
      STUDY8: 'التصوير الاحترافي',
      STUDY9: 'الميديا وانتاج الفيديو',
      STUDY10: 'التصميم الفوتوجرافي',
      STUDY11: 'تطوير الويب والتطبيقات',
      STUDY12: 'التقنية والمعلوماتية',
      STUDY13: 'الإعلام والعلاقات العامة',
      STUDY14: 'القانون والعقود',
      STUDY15: 'الهندسة والطاقة',
      STUDY16: 'التعليم والتدريب',
      STUDY17: 'التنمية الذاتية',
      STUDY18: 'نمط الحياة',
      STUDY19: 'الصحة والرياضة',
      STUDY20: 'يمكنك كتابة اي نشاط آخر',
    },
    PROFILE: {
      WELCOMEYA: 'أهلاً بك',
      ATTACHMENTS: 'مرفقات',
      HOMEPAGE: 'الصفحة الرئيسية',
      MYACCOUNT: 'ملفي الشخصي',
      MYCOURSES: 'دوراتي ',
      SUBMITREPLY: 'الرد على التعليق',
      CERTIFICATES: 'شهاداتي',
      MYCOURSE: 'دوراتي ',
      NOTIFICATIONS: 'اشعاراتي',
      NOTES: 'ملاحظاتي',
      DISCUSSION: 'التعليقات',
      NODISCUSSION: 'لا يوجد تعليقات حول هذه المحاضرة',
      SUBMITCOMMENT: 'أضف تعليق',
      REPLY: 'الرد على التعليق',
      QUIZZNAME: 'اسم الامتحان',
      STARTTHEQUIZZ: 'ابدأ الامتحان',
      NEXTQUESTION: 'السؤال التالي',
      SUBMITYOURANSWERS: 'اتمام الامتحان',
      NAME: 'الاسم',
      DATEOFBIRTH: 'تاريخ الميلاد',
      GENDER: 'الجنس',
      UPDATEPASSWORD: 'تحديث كلمة المرور',
      PHONENUMBER: 'رقم الهاتف',
      UPDATEINFO: 'تحديث البيانات',
      DRAGYOURANSWERHERE: 'قم بإدراج الجواب الصحيح هنا',
      SUBMITYOURANSWERSMESSAGE: 'لقد اتممت الاجابة على الاسئلة اضغط على اتمام الامتحان لاعتماد الاجوبة',
      CURRENTPASSWORD: 'كلمة المرور الحالية',
      NEWPASSWORD: 'كلمة المرور الجديدة',
      REPEATPASSWORD: 'إعادة إدخال كلمة المرور الجديدة',
      NONOTIFICATIONS: 'لايوجد إشعارات جديدة'
    },
    TABS: {
      TRAINEEINFORMATION: 'المعلومات العامة',
      ISACTIVATED: 'حالة الحساب',
      ACTIVATETRAINEE: 'تفعيل الحساب',
      ENROLLEDAT: 'تاريخ الإنضمام',
      NAH: 'لا يوجد',
      YES: 'نعم',
      NO: 'لا',
      NEWVALIDUNTIL: 'الصلاحية الجديدة',
      EXTENDCOURSE: 'تمديد',
      EXTEND: 'تمديد',
      TOTALPAYMENTS: 'اجمالي المشتريات',
      TOTALORDERS: 'اجمالي عدد الطلبات',
      ADDEDAT: 'تاريخ  البداية  ',
      ADDEDBY: 'اضيفت بواسطة  ',
      VALIDUNTIL: 'صالح لغاية  ',
      TOTALPAID: 'القيمة المدفوعة  ',
      ISFINISHED: 'اتم الدورة  ',
      COUPON: 'كود الخصم  ',
      TOTALDISCOUNT: 'نسبة الخصم  ',
      CURRENTPROGRESS: 'مستوى التقدم  ',
      CERTIFICATECODE: 'كود الشهادة  ',
      TRAINEECOURSES: 'الدورات',
    },
    BANNERS: {
      ADDNEWBANNER: 'إضافة بانر جديد'
    },
    DIALOGS: {
      FILENOTFOUND: 'الملف غير موجود في بنك الملفات',
      USERCARD: 'بطاقة مستخدم',
      TRAINERADDEDSUCCESSFULLY: 'تم اضافة المدرب بنجاح',
      BANNERHASBEENADDEDSUCCSSFULLY: 'تم حفظ البانر بنجاح',
      NEWPAIR: 'إضافة جمل جديدة',
      NEWANSWER: 'إضافة جواب جديد',
      NEWSENTENCE: 'إضافة جملة جديدة',
      UNITHASBEENADDED: 'تمت العملية بنجاح',
      COURSESTATUSECHANGED: 'تم تغيير حالة نشر الدورة بنجاح',
      NOCOURSEFOUND: 'ليس لديه هذا الكورس',
      SEARCHFORFILE: 'البحث عن ملف',
      CONSULTANCYADDEDSUCCESSFULLY: 'تم إضافة الاستشارة بنجاح',
      CONSULTANCYMODIFIEDSUCCESSFULLY: 'تم تعديل الاستشارة بنجاح',
      ONLYONEMAINPRICE: 'يمكن اضافة سعر اصلي واحد فقط',
      PRICEDATESNEEDED: 'يجب تحديد تواريخ السعر',
      CODEISUSED: 'هذا الكود مستخدم',
      SELECTEDFILE: 'الملف المحدد',
      CURRICULAADDED: 'تم إضافة الباقة بنجاح',
      COURSESUPDATEDSUCCESSFULLY: 'تم تحديث بيانات الدورة بنجاح',
      THISLECTUREALREADYHASVIDEOTITLE: 'هذه المحاضرة تحتوي على فيديو',
      THISLECTUREALREADYHASLVIEMEETINGTITLE: 'هذه المحاضرة تحتوي على رابط بث مباشر',
      THISLECTUREALREADYHASVIDEO: 'يمكن لمحاضرة ان تحتوي على فيديو واحد فقط ! ',
      THISLECTUREALREADYHASLIVEMEETING: 'يمكن لمحاضرة ان تحتوي على رابط بث مباشر واحد فقط ! ',
      QUESTIONADDED: 'تمت الاضافة بنجاح',
      QUESTIONADDEDBODY: 'تمت إضافة سؤال للفيديو ',
      SORTINGELEMENTSSUCCESSED: 'تمت إعادة ترتيب العناصر بنجاح',
      ELEMENTDELTED: 'تم حذف العنصر بنجاح',
      AREYOUSURE: 'تأكيد عملية الحذف',
      DELETEPERMENTALY: 'عملية الحذف نهائية لا يمكن العودة عنها ',
      CLOSE: 'إغلاق',
      CONFIRM: 'تأكيد الحذف',
      CANCEL: 'إلغاء الامر',
      SAVE: 'حفظ',
      REACTIVATED: 'تم اعادة تفعيل المستخدم بنجاح',
      DEACTIVATED: 'تم إيقاف المستخدم بنجاح',
      DONE: 'نجاح العملية',
      MODIFY: 'تعديل',
      CONFIGURE: 'إعداد',
      DELETE: 'حذف',
      NEWLECTURE: 'محاضرة جديدة',
      DEPARTMENTSIMPORTEDSUCCESSFULLY: 'تم استيراد الأقسام بنجاح',
      ERROR: 'خطأ',
      ERRORHAPPENED: 'حصل خطأ اثناء تنفيذ العملية',
      ORDERSUCCSSED: 'تمت إعادة الترتيب بنجاح',
      PASSWORDCHANGED: 'تم تغيير كلمة المرور بنجاح',
      CONSULTANTADDEDSUCCESSFULLY: 'تم إضافة مستشار بنجاح',
      SESSIONSTARTED: 'بدأت الجلسة',
      ANNOUNCEMENTADDEDSUCCESSFULLY: 'تم بث الإعلان بنجاح',
      MEETINGADDEDSUCCESSFULLY: 'تمت إضافة الجلسة بنجاح',
      CASESTUDYADDEDSUCCESSFULLY: 'تمت إضافة الحالة الدراسية بنجاح',
      ACTIVITYADDEDSUCCESSFULLY: 'تمت إضافة النشاط بنجاح',
      COURSEINFOADDEDSUCCESSFULLY: 'تمت إضافة وصف للدورة بنجاح',
      GLOSSARYADDEDSUCCESSFULLY: 'تم بناء قائمةالمصطلحات بنجاح',
      CONCEPTADDEDSUCCESSFULLY: 'تم إضافة مصطلح جديد بنجاح',
      CATEGORYADDEDSUCCESSFULLY: 'تمت إضافة تصنيف جديد بنجاح',
      COUPONDATESUPDATEDSUCCESSFULLY: 'تم تعديل تاريخ الكوبون بنجاح',
      WIKIADDED: 'تمت إضافة الموسوعة الحرة بنجاح',
      WIKISECTIONADDED: 'تمت إضافة قسم إلى الموسوعة بنجاح',
      MEETINGACCEPTED: 'تم قبول الجلسة',
      COURSEADDEDSUCCESSFULLY: 'تمت إضافة الدورة التدريبية بنجاح',
      GETRECORD: 'استعراض سجل الحضور',
      INITLOG: 'إصلاح سجل الحضور',
    },
    DASHBOARD: {
      ADDEDBY: 'بواسطة',
      OUTOF: 'من اصل',
      QUESTIONNO: 'السؤال رقم',
      YOURANSWER: 'اجابتك',
      CORRECTANSWER: 'الجواب الصحيح',
      CORRECTANSWERFROM: 'اجابة صحيحة من اصل',
      TEXT: 'نص',
      RETURNTOPROFILE: 'العودة إلى حسابي',
      LINKS: 'رابط خارجي',
      MYNOTES: 'الملاحظات',
      ACCEPTED: 'قبول الحل',
      REFUSED: 'رفض الحل',
      TRAINEENAME: 'اسم المتدرب',
      REPLY: 'تقييم المدرب',
      ADDCOMMENT: 'إضافة تعليق',
      EXPORTCERTIFICATE: 'تنزيل الشهادة',
      AVAILABLE: 'متاح',
      ASSIGNMENTAPPLICATIONS: 'تقديم',
      NOTAVAILABLE: 'غير متاح',
      PASSEDTHEQUIZ: 'قمتم باجتياز الاختبار',
      FAILEDTHEQUIZZ: 'لم يتم اجتياز الاختبار',
      QUIZRESULT: 'نتيجة الإختبار',
      HELLO: 'اهلاً',
      EMAIL: 'البريد الإلكتروني',
      GOING: 'جارية',
      UPDATEINFORMATION: 'البيانات الشخصية',
      EXPIRED: 'منتهية',
      PROGRESS: 'التقدم',
      LEARNINGPLANS: 'الخطة التعليمية',
      NUMBEROFHOURS: 'عدد الساعات',
      TOTALLEARNINGTIME: 'اجمالي الوقت المستهدف',
      NOTIFYME: 'قم بتذكيري',
      NEWLEARNINGPLAN: 'خطة تدريبية جديدة',
      DATES: 'الفترة الزمنية',
      SAVENOTE: 'حفظ',
      MYNOTEBOOK: 'الملاحظات',
      ACTIVITY: 'نشاط تدريبي',
      ASSIGNMENTS: 'الواجبات',
      NOFILEBACK: 'بانتظار التقييم',
      YOUCANTATTENDASSIGNMENT: 'المشاركة في الواجب غير متاحة',
      WAITINGREPLY: 'بانتظار تقييم المدرب',
      NUMBEROFARTICLES: 'عدد المقالات',
      NUMBEROFASSIGNMENTS: 'عدد الواجبات ',
      NUMBEROFTOPICS: 'عدد المواضيع',
      NUMBEROFCASES: 'عدد الحالات الدراسية',
      FINALQUIZZ: 'الاختبار النهائي',
      MINUTE: 'دقيقة',
      QUIZATTEMPT: 'محاولة',
      NUMBEROFCONCEPTS: 'عدد المصطلحات : ',
      GLOSSARY: 'قائمة المصطلحات',
      NEWCONCEPT: 'إضافة مصطلح جديد',
      DATEOFATTEMPT: 'تاريخ المحاولة : ',
      TOTALDEGREE: 'الدرجةالمستحقة : ',
      PERVIOUSATTEMPTSRESULTS: 'سجل المحاولات السابقة',
      VIDEOQUESTION: 'املئ الفراغات بالكلمات المناسبة',
      ADDCOMMENTREPLY: 'اضف رداً على التعليق',
      COMMENTREPLY: 'الرد على التعليق',
      REQUIRED: 'لا يمكن أن يكون النص فارغاً',
      YOUPASSEDTHEQUIZZ: 'ممتاز لقد قمت باجتياز الاختبار',
      QUIZSCORE: 'الدرجة المستحقة',
      YOUDIDNOTPASSEDTHEQUIZZ: 'لم تقم باجتياز الاختبار',
      COMPLETETHETEST: 'انهاء المحاولة',
      CASESTUDY: 'حالة دراسية',
      QUIZZATTEMPTINFO: 'معلومات المحاولة الأخيرة',
      NUMBEROFQUESTIONSANSWERED: 'عدد الاسئلة التي تم الإجابة عليها :',
      NUMBEROFQUESTIONSNOTANSWERED: 'عدد الاسئلة التي لم يتم الإجابة عليها :',
      ATTEMPTDATE: 'معلومات المحاولة الأخيرة',
      VIEWRESULT: 'نتيجة الأختبار',
      QUESTIONNUMBER: 'السؤال رقم :',
      NEXTQUESTION: 'السؤال التالي',
      DOWNLOADPDF: 'تنزيل الملف',
      MEETINGURL: 'رابط الدخول إلى جلسة البث المباشر',
      QUIZINFO: 'معلومات عامة عن الاختبار',
      NUMBEROFATTEPMTS: 'عدد المحاولات المسموحة : ',
      TIMEALLOWED: 'الوقت المسموح للمحاولة : ',
      QUIZNOTE: '* إن محاولة العودة إلى الخلف تعني الغاء محاولة الاختبار ويتم احتساب ما تم الاجابة عليه',
      PERVIOUSATTEMPTS: 'محاولة التقديم للاختبار من قبل : ',
      HOUR: 'الساعة',
      DAY: 'اليوم',
      UNDEFINIED: 'غير محدد',
      ATTEMPTQUIZ: 'قم بمحاولة الإختبار',
      HOMEPAGE: 'الصفحة الرئيسية',
      VIDEOQUESTIONS: 'اختر المدرب او قم بطرح سؤال عام',
      MYCONSULTANCIES: 'استشاراتي',
      AFFILIATEPROGRAM: 'برنامج التسويق بالعمولة',
      AVILABLEDATES: 'اختر اليوم',
      AVILABLEHOURS: 'اختر الوقت',
      LECTURENAME: 'المحاضرة',
      TIMEZONE: 'المنطقة الزمنية',
      ATTIME: 'الوقت في الفيديو',
      REQUESTMEETING: 'طلب حجز جلسة',
      MYSESSIONS: 'جلسات الاستشارة',
      NOTE: 'الملاحظة',
      MYBOOKMARKSTITLE: 'ملاحظاتي من الدورات',
      PUBLICQUESTION: 'سؤال عام',
      // HOMEPAGE: 'تقارير سريعة',
      COMMISSION: 'العمولة المستحقة',
      ADDBOOKMARK: 'اضف ملاحظة',
      NOCOMMENTS: 'لا يوجد ردود بعد',
      REQUESTDATE: 'تاريخ الطلب',
      TOTALAMOUNT: 'اجمالي المبلغ',
      COURSEWIKI: 'الموسوعة الحرة',
      REQUESTSTATUS: 'حالة الطلب',
      TOTALPAIDCOMMISSIONS: 'اجمالي العمولات المقبوضة',
      TOTALUNPAIDCOMMISSIONS: 'اجمالي العمولات الغير مقبوضة',
      SELECTPERIOD: 'اختر فترة زمنية',
      TOTALCOMMISSIONS: 'اجمالي العمولات المستحقة',
      ADDARTICLE: 'إضافة مقال',
      CURRENTLEARNINGPLAN: 'خطة الدراسة الحالية',
      FROMDATE: 'من تاريخ',
      TODATE: 'إلى تاريخ',
      LEARNPROGRESS: 'مستوى التقدم',
      SELECTTWODATES: 'اختر فترة زمنية ',
      CHANGEPASSWORD: 'تغيير كلمة المرور',
      MYFINANCE: 'طلباتي',
      ORDERID: 'رقم الطلب',
      PRODUCTNAME: 'اسم المنتج',
      PURCHASEDDATE: 'تاريخ الشراء',
      PRICE: 'سعر الشراء',
      EDITINFO: 'تحديث البيانات',
      ANNOUNCEMENTS: 'إشعارات الدورات',
      NONOTIFICATIONS: 'لا يوجد إشعارات حالية',
      MYLEARNINGPLAN: 'خطة التعلم',
      MENU: 'القائمة الرئيسية',
      ACADEMY: 'Emastery Academy',
      PROFILEVERSION: 'V.1.052113',
      HEADER: 'العنوان',
      SENDANSWER: 'إرسال الجواب',
      ANSWER: 'الجواب',
      LOGOUT: 'تسجيل الخروج',
      CASESTUDIES: 'حالات دراسية',
      DOWNLOADCERTIFICATE: 'تنزيل الشهادة',
      SETREMINDER: 'تذكير يومي',
      SELECTPLANTYPE: 'هدف خطة التعلم',
      TIMEGOAL: 'اجمالي ساعات',
      COURSEGOAL: 'اتمام دورات',
      TARGETTIME: 'اتمام اجمالي ساعات تعلم',
      SETTIMEPERHOUR: 'عدد الساعات',
      SUBMITPLAN: 'حفظ خطة التعلم',
      BODY: 'نص المقال',
      ADDEDAT: 'تاريخ الإضافة',
      ADDREPLY: 'اضافة مشاركة في النقاش',
      MYCOURSES: 'دوراتي',
      MYCERTIFICATES: 'شهاداتي',
      TOPICSLIST: 'قائمة المواضيع',
      GOTOTOPICS: 'العودة لقائمة المواضيع',
      TOTALTOPICS: 'اجمالي المشاركات',
      ADDCONCEPT: 'إضافة المصطلح',
      MYQUIZZES: 'اختباراتي',
      COURSENAME: ' الدورة',
      QUIZZNAME: ' الاختبار',
      STATUS: 'الحالة',
      MYASSIGNMENTS: 'واجباتي',
      MYBOOKMARKS: 'دفتر الملاحظات',
      COMPLETE: 'مكتمل',
      COMPLETED: 'نسبة الاكتمال',
      DATE: 'تاريخ الاضافة',
      FILE: 'الملف',
      FILEBACK: 'ملف المدرب',
      COMMENT: 'تقييم المدرب',
      DONE: 'اتممت الدرس بنجاح',
      ATTACHFILE: 'قم بادراج الملف هنا',
      TOTAL: 'الاجمالي',
      NOTPASSED: 'غير المقبولة',
      PASSED: 'المقبولة',
      PENDING: 'بانتظار التقييم',
      CONTINUE: 'متابعة',
      CONTENTS: 'المحتويات',
      GOBACK: 'العودة للملف الشخصي',
      PERVIOUSLESSON: 'الدرس السابق',
      NEXTLESSON: 'الدرس التالي',
      GENERALINFO: 'معلومات عامة عن الاختبار',
      TOTALATTEMPTS: 'عدد المحاولات المسموحة',
      TIMELIMIT: 'الوقت المسموح للمحاولة',
      NUMBEROFTRIES: 'عدد المحاولات التي قمتم بها ',
      ONCE: ' مرة ',
      MINUTES: ' دقيقة ',
      TIME: ' مرة ',
      YOUDIDNTTRY: 'لم تقم بمحاولة تقديم الاختبار من قبل',
      DONTCLICK: 'إن محاولة العودة إلى الخلف تعني الغاء محاولة الاختبار ويتم احتساب ما تم الاجابة عليه ',
      TRYATTEMPQUIZZ: 'قم بمحاولة اجتياز الاختبار الآن',
      TIMELEFT: 'الوقت المتبقي',
      ATTEMPTINFO: 'معلومات المحاولة الأخيرة',
      TOTALANSWERED: 'عدد الاسئلة التي تم الإجابة عليها',
      TOTALUNANASWERED: 'عدد الاسئلة التي لم يتم الإجابة عليها',
      ATTENDEDAT: 'تاريخ التقديم',
      SUBMITYOURQUIZZ: 'نتيجة الإختبار',
      QUIZZESSECTION: 'الاختبارات',
      ATTEMPTRESULT: 'نتيجة الاختبار',
      YOURSCORE: 'الدرجة المستحقة',
      RESULT: 'النتيجة النهائية',
      DIDPASS: 'ممتاز لقد قمتم باجتياز الاختبار بنجاح',
      DIDNTPASS: 'لم يتم اجتياز الاختبار بنجاح',
      CONTINUEQUIZZ: 'متابعة المحاولة الأخيرة',
      TIMEISUP: 'انتهى الوقت المخصص للمحاولة',
      SENDFILE: 'ارسال الملف',
      ASSIGNMENTSECTION: 'الواجبات',
      DASHBOARD: 'ملف المتدرب',
      LEARNINGPLAN: 'الخطة التدريبية',
      NEWLEARNINGPLANE: 'خطة تدريبية جديدة',
      MYCURRENTLEARNINGPLAN: 'الخطة التدريبية الحالية',
      PERSONALINFO: 'المعلومات الشخصية',
      GOHOME: 'متابعة',
      CONSULTANCYDATE: 'تاريخ الشراء',
      CONSULTANCYNAME: 'اسم الاستشارة',
      CONSULTANTNAME: 'اسم المدرب',
      NUMBEROFSESSIONS: 'عدد الجلسات',
      REQUESTSESSION: 'اطلب جلسة استشارة',
    },
  }
};
