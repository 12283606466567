

import { ConditionRelation, Conditions, Course } from ".";


export class Curriculum {


  Id?: number;
  id?: number;
  languageId?: number;
  categoryId?: number;
  name: string;
  image: string;
  video: string;
  active: boolean;
  linkName: string;
  categoryName: string;
  currentPrice: number;
  description: string;
  squareImage: string;
  price: number;
  notes: string;
  addedBy: number;
  addedDate?: Date;
  updateAt?: Date;
  courses?: Course[];
  rules?: CURRules[];
  prices?: CurriculaPrices[];
}


export class CurriculaPrices {

  id?: number;
  Id?: number;
  curriculaId?: number;
  price: number;
  name: string;
  startingFrom?: Date;
  endingAt?: Date;
  active: boolean;
  isMainPrice: boolean;
}

export class CURCourses {
  Id?: number;
  id?: number;
  curriculaId?: number;
  courseId?: number;
  required?: boolean;
}

// public class CURCertificationsDTO {
//     public int? Id { get; set; }
//     public int ? curriculaId { get; set; }
//     public string certificate { get; set; }

//     // public virtual MAS_COU_Curriculum curricula { get; set; }
//     // public virtual ICollection<MAS_CUR_CER_Assigned> certificates { get; set; }

// }



export class CURRules {
  Id?: number;
  id?: number;
  curriculaId?: number;
  relation: ConditionRelation;
  condition: Conditions;
  fraction?: number;
  unitId?: number;
  lessonId?: number;
  time?: number;
}


// public class CURCERAssignedDTO {
//     public int? Id { get; set; }
//     public int ? userId { get; set; }
//     public int ? certificateId { get; set; }
//     public string degree { get; set; }
//     public string notes { get; set; }
//     public int ? addedBy { get; set; }
//     public DateTime ? addedDate {
//     get; set;
// }
//     public DateTime ? updateAt { get; set; }

// }
