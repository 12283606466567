import { B2BService } from './../../../../core/services/b2b.service';
import { Constants } from './../../../../core/services/constants';
import { MeetingType } from './../../../../core/_models/settings';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { TimezonePickerService } from '../../../../core/services/timezones.service';
import { NotfiyService, CoursesService } from '../../../../core/services';
import * as moment from 'moment';
import { AlertType } from '../../../../core/_models/system';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../../dialogs/alertconfirmmessage/alertconfirmmessage.component';
import { MeetinData } from '../bbbmeeting/bbbmeeting.component';



@Component({
  selector: 'sm-zoommeeting',
  templateUrl: './zoommeeting.component.html',
  styleUrls: ['./zoommeeting.component.scss'],
  providers: [
    Constants
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoommeetingComponent implements OnInit {


  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  locale = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'rtl', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'موافق', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'فترة مخصصة',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: 0 // first day is monday
  }
  today = moment();
  submitted = false;
  newCourse: UntypedFormGroup;
  button: string = '';
  profile: any;
  courseId;
  @Input() requestId;
  @Input() item;
  @Output() result = new EventEmitter<boolean>();
  loading
  fHour
  allTimezones: any[] = [];
  selectedTimeZone
  datetime;
  public format = 'MM/dd/yyyy HH:mm';

  constructor(private cd: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, public ngNoty: NotfiyService, private _translate: TranslateService, private _store: Store<any>, private _constants: Constants,
    public dialogRef: MatDialogRef<ZoommeetingComponent>, @Inject(MAT_DIALOG_DATA) public data: MeetinData, private _dialog: MatDialog, @Inject('API_URL') private originUrl: string, private _b2b: B2BService, private _router: Router, private dragulaService: DragulaService, private _timezones: TimezonePickerService,
    private route: ActivatedRoute, private _course: CoursesService) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.button = this._translate.instant('MEETINGS.ADDNEWMEETING');
    this.allTimezones = _timezones.getZones();
    this.courseId = data.courseId;
  }

  get xf() { return this.newCourse.controls; }


  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    this.newCourse = this.formBuilder.group({
      id: null,
      meetingId: null,
      meetId: null,
      requestId: null,
      topic: [{ value: '', disabled: this.loading }, Validators.required],
      start_time: new Date(),
      duration: [{ value: '', disabled: this.loading }, Validators.required],
      password: this._constants.randomStringGenerator(8),
      timezone: [{ value: '', disabled: this.loading }, Validators.required],
      type: 2
    });
  }



  onSubmit() {
    // console.log(this.datetime);
    // return;
    var datex = moment(this.datetime.startDate);
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this.newCourse.patchValue({
      start_time: datex.utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss')
    })


    var meeting = {
      type: MeetingType.Zoom,
      courseId: this.courseId,
      addedBy: this.profile.userId,
      addedAt: moment().utc().toDate(),
      zoomMeeting: this.newCourse.value
    };
    if (this.requestId) {
      this.newCourse.patchValue({
        requestId: this.requestId
      })
      this._b2b.acceptMeeting(this.newCourse.value).subscribe((data) => {
        var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.MEETINGACCEPTED'), type: AlertType.Success }
        const dialog = this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
        dialog.afterClosed().subscribe(() => {
          this.dialogRef.close({ data: true })
        })

      }, error => {
        // console.log(error);

        var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + error.error, type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })

      })
    } else {
      this._course.addMeeting(meeting).subscribe((data) => {
        var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.DONE'), type: AlertType.Success }
        const dialog = this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
        dialog.afterClosed().subscribe(() => {
          this.dialogRef.close({ data: true })
        })
      }, error => {
        // console.log(error);

        var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })

      })
    }

  }

}
