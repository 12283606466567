import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, UserService } from '../../../../../src/app/core/services';
import { NewWikiDailogComponent } from '../new-wiki-dailog/new-wiki-dailog.component';
import { FormGroup, FormControl } from '@angular/forms';
import { WikiDTO } from '../../../../../src/app/core/_models';
import { AlertType } from '../../../../../src/app/core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

@Component({
  selector: 'sm-update-wiki-dailog',
  templateUrl: './update-wiki-dailog.component.html',
  styleUrls: ['./update-wiki-dailog.component.css']
})
export class UpdateWikiDailogComponent implements OnInit {
  profile:any
  submitted = false;
  currentCourse: any;
  loading = false;
  editorConfigs
  newCourse = new FormGroup({
    // Define 'parentx' form control with validation
    name: new FormControl(),
    description: new FormControl(),
    id: new FormControl(null),
    date: new FormControl(new Date()),
    courseId: new FormControl(false),
    isPublic: new FormControl(true),
    userId: new FormControl(),

  });
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      description: val
    });
    this._editorData = val;
  }
  // id: this.wiki.id,
  // name: this.wiki.name,
  // description: this.wiki.description,
  // courseId: this.wiki.courseId,
  // isPublic: this.wiki.isPublic,
  // date: new Date(this.wiki.date),

  // userId: parseInt(this.profile.userId)

  
  get xf() { return this.newCourse.controls; }

  constructor( private _translate: TranslateService,private _course: CoursesService,private _store: Store<any>, private cd: ChangeDetectorRef, private _user: UserService, public dialogRef: MatDialogRef<UpdateWikiDailogComponent> ,  private _dialog: MatDialog , @Inject(MAT_DIALOG_DATA) public data: any){

    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      this.newCourse.patchValue({
        userId: parseInt(this.profile.userId)
      })
    }
  }
  ngOnInit(): void {
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    var url = window.location.href;
    var xurl = url.split('/');
    this.currentCourse = + xurl[5];
    this.newCourse.patchValue({
      id:this.currentCourse,
      courseId :this.currentCourse
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    console.log(this.newCourse.value);
    
    this._course.addOrModiyWiki(this.newCourse.value).subscribe((data: WikiDTO) => {
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.WIKIADDED'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {        
        this.dialogRef.close();
        this.newCourse.reset();
        this.cd.markForCheck();          
      
    })   
      this.submitted = false;




    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }

  close(){
    this.dialogRef.close()
  }
}
