import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { SettingsService, CoursesService, SystemService } from '../../../core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'sm-newtagdialog',
  templateUrl: './newtagdialog.component.html',
  styleUrls: ['./newtagdialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewtagdialogComponent implements OnInit {

  public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  button = 'حفظ';
  allLists: any[];
  searchText: string = '';

  constructor(private _settings: SettingsService, private _router: Router, private _courses: CoursesService, private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<NewtagdialogComponent>, private _system: SystemService, private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }
  }



  ngOnInit() {
    this.newCourse = this.formBuilder.group({
      id: null,
      Id: null,
      tag: [{ value: '', disabled: this.loading }, Validators.required],
    });
  }

  get xf() { return this.newCourse.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    this._settings.addActiveCampaginTag(this.newCourse.value).subscribe((data) => {

      this.newCourse.reset();
      this.submitted = false;
      this.dialogRef.close({ data: data });
      // this.button = 'إضافة الدورة'
    });
  }


  modify(dataItem) {
    this.newCourse.patchValue({
      id: dataItem.id,
      Id: dataItem.Id,
      listName: dataItem.listName,
      senderUrl: dataItem.senderUrl,
      senderReminder: dataItem.senderReminder,
      urlString: dataItem.urlString,
    })
  }


}
