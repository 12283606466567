import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService, NotfiyService, AffiliateService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';


export interface AnswerDialogData {

  quizzId: number,
  selectedQuestion: any,
  isNew: boolean,
  oldAnswer: any
}
@Component({
  selector: 'sm-quizzquestionanswer',
  templateUrl: './quizzquestionanswer.component.html',
  styleUrls: ['./quizzquestionanswer.component.scss']
})
export class QuizzquestionanswerComponent implements OnInit {

  newPairOfAnswers: UntypedFormGroup;

  newSolution: UntypedFormGroup;
  newAnswer: UntypedFormGroup;
  progress = 0;
  selectedQuestion
  editorConfigs = {
    useSearch: false,
    language: "ar",
    enter: "br",
    direction: "rtl",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };
  get xfx() { return this.newAnswer.controls; }
  get xfxPairs() { return this.newPairOfAnswers.controls; }
  submitted = false;

  profile
  lang = 'ar'
  questionType
  loading


  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newPairOfAnswers.patchValue({
      second: val
    });
    this._editorData1 = val;
  }
  
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newPairOfAnswers.patchValue({
      first: val
    });
    this._editorData = val;
  }
  _editorData2 = '';
  get editorData2() {
    return this._editorData2;
  }

  set editorData2(val) {
    this.newAnswer.patchValue({
      answer: val
    });
    this._editorData2 = val;
  }
  
  _editorData3 = '';
  get editorData3() {
    return this._editorData3;
  }

  set editorData3(val) {
    this.newAnswer.patchValue({
      explanation: val
    });
    this._editorData3 = val;
  }


  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private _course: CoursesService, private _router: Router, public ngNoty: NotfiyService,
    @Inject(MAT_DIALOG_DATA) public data: AnswerDialogData, private dialogRef: MatDialogRef<QuizzquestionanswerComponent>,
    private _dialog: MatDialog, private _courses: CoursesService, private cd: ChangeDetectorRef, private _translate: TranslateService, private _store: Store<any>, private _report: ReportsService
    , private _affiliate: AffiliateService, private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
    this.selectedQuestion = data.selectedQuestion;

  }

  ngOnInit(): void {
    this.newPairOfAnswers = this.formBuilder.group({
      id: null,
      questionId: this.selectedQuestion.id,
      first: [{ value: '', disabled: this.loading }, Validators.required],
      second: [{ value: '', disabled: this.loading }, Validators.required],
    });

    this.newAnswer = this.formBuilder.group({
      id: null,
      questionId: this.selectedQuestion.id,
      answer: [{ value: '', disabled: this.loading }, Validators.required],
      explanation: [{ value: '', disabled: this.loading }],
      isCorrectAnswer: [{ value: false, disabled: this.loading }, Validators.required],
    });

    if (!this.data.isNew) {
      this.modify(this.data.oldAnswer);
    }


  }




  submittedA
  onSubmitAnswer() {
    // console.log('WE CLICKED ON SUBMIT');

    this.submittedA = true;
    if (!this.selectedQuestion) {
      // this.submittedA = false;
      return;
    }
    if (this.selectedQuestion.questionType == 6) {
      if (this.newPairOfAnswers.invalid) {
        setTimeout(() => {
          this.submittedA = false;
          return;
        }, 1000);
      }
    } else {
      if (this.newAnswer.invalid) {

        setTimeout(() => {
          this.submittedA = false;
          return;
        }, 1000);
      }
    }

    // console.log(this.newAnswer.value);
    // console.log(this.newPairOfAnswers.value);

    if (this.selectedQuestion.questionType == 6) {

      if (this.newPairOfAnswers.valid) {
        this._course.addOrModifyPairOfAnswers(this.newPairOfAnswers.value).subscribe((data) => {
        }, () => { }, () => {
          this.dialogRef.close({ result: true });
        })
      }
    }
    else {

      if (this.newAnswer.valid) {
        this._course.AddAnswer(this.newAnswer.value).subscribe((data) => {
        }, () => { }, () => {
          this.dialogRef.close({ result: true });
        })
      }
    }

  }



  modify(dataItem) {
    if (this.selectedQuestion.questionType == 6) {
      this.newPairOfAnswers.patchValue({
        id: dataItem.id,
        questionId: dataItem.questionId,
        first: dataItem.first,
        second: dataItem.second
      })
    } else if (this.selectedQuestion.questionType == 3) {


    } else {
      this.newAnswer.patchValue({
        id: dataItem.id,
        questionId: dataItem.questionId,
        explanation: dataItem.explanation,
        isCorrectAnswer: dataItem.isCorrectAnswer,
        answer: dataItem.answer
      })
    }
  }


}
