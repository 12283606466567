import { Component, OnInit, Inject } from '@angular/core';

import { SystemService } from './../../../core/services/system.service';

@Component({
  selector: 'sm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(@Inject('API_URL') private apiUrl: string,
    private _system: SystemService) {
    // let connection = new signalR.HubConnectionBuilder()
    //   .withUrl(apiUrl + "/mastery", { accessTokenFactory: () => this.oidcSecurityService.getToken() })
    //   .configureLogging(signalR.LogLevel.Information)
    //   .build();
    // connection.start();
    // connection.on("Categories", data => {
    //   console.log(data);

    // });
    _system.loadData()
  }

  ngOnInit() {
  }

}
