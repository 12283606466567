import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DragulaService } from 'ng2-dragula';
import { B2BService, NotfiyService, SystemService } from '../../../core/services';
declare var Noty: any;
declare var $: any;
import Bounce from 'bounce.js';
import * as moment from 'moment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { B2BProfile, B2BStatus, B2BCommunicationLogDTO, B2BTimelineDTO } from '../../../core/_models';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface b2bCommunicationData {
  isNew: boolean,
  oldItem: any,
  logFile: any
}


@Component({
  selector: 'sm-b2bcommunicationdialog',
  templateUrl: './b2bcommunicationdialog.component.html',
  styleUrls: ['./b2bcommunicationdialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class B2bcommunicationdialogComponent implements OnInit {



  newCommunication: UntypedFormGroup;
  // get xfx() { return this.newContract.controls; }
  profile

  submitted = false;
  lang
  loading = false;
  hasError = false;
  errorMsg = '';
  logFile

  editorConfigs

  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private cd: ChangeDetectorRef,
    private _translate: TranslateService, private ngNoty: NotfiyService, private _store: Store<any>, private _service: B2BService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private dragulaService: DragulaService
    , public dialogRef: MatDialogRef<B2bcommunicationdialogComponent>, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: b2bCommunicationData
  ) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
  }

  ngOnInit(): void {
    this.newCommunication = this.formBuilder.group({
      id: null,
      message: [{ value: '', disabled: this.loading }, Validators.required],
      userId: this.profile.userId,
      date: moment.utc(new Date()).toDate(),
      logId: null,
    });

    this.logFile = this.data.logFile;
    this.cd.markForCheck();
    if (!this.data.isNew) {
      this.newCommunication.patchValue({
        logId: this.data.logFile.id
      })
    }

  }


  onSubmitC() {

    if (this.newCommunication.invalid) {
      return;
    }
    if (this.data.logFile) {
      var logId = this.data.logFile.id;
      this.newCommunication.patchValue({
        logId: logId
      })
    }

    // console.log(this.logFile);

    // console.log(this.newCommunication.value);
    this.submitted = true;
    this.loading = true;
    this._service.addMessageToTimeline(this.newCommunication.value).subscribe((data) => {
      this.dialogRef.close();
    })
  }


}
