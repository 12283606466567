export enum AlertType {
  Information = 1,
  Success = 2,
  Warning = 3,
  Error = 4,
  Primary = 5,
}



export class routesMap {
  root: string;
  level1: string;
  level2: string;
  level3: string;
  level4: string;
  level5: string;
}




export class TicketsDTO {
  Id?: number;
  id?: number;
  name: string;
  userId: number;
  openingDate: Date;
  type: TicketType
  status: TicketStatus
  priority: TicketPriority
  ticket: string;
  messages: TICMessagesDTO[];
}

export class TICMessagesDTO {
  Id?: number;
  id?: number;
  name: string;
  userId: number;
  ticketId?: number;
  message: string;
  attachment: string;
  date: Date;
}



export enum TicketType {
  Panel = 1,
  Site = 2,
  Profile = 3,
  Payment = 4,
  Content = 5,
  User = 6
}
export enum TicketStatus {
  Opened = 1,
  Pending = 2,
  InProgress = 3,
  Fixed = 4,
  Feedback = 5,
  Finished = 6,
  ReOpened = 7
}
export enum TicketPriority {
  Low = 1,
  Normal = 2,
  Important = 3,
  Critical = 4
}


export class User {
  id: number;
  email: string;
  groupId: string;
  userId: string;
  password: string;
  loginName: string;
  // fullName: string;
  avatar: string;
  name: string;
  jwtToken?: string;
  refreshToken?: string;
}

export enum PermissionsLevel {
  Preview = 1,
  Add = 2,
  Modify = 3,
  Delete = 4,
  Report = 5,
  Super = 6,
  Restricted = 7
}

export enum MainRoles {
  Courses = 'Courses',
  CourseReports = 'CourseReports',
  CourseAttendees = 'CourseAttendees',
  CoursePurchasers = 'CoursePurchasers',
  CourseManagement = 'CourseManagement',
  Trainers = 'Trainers',
  CourseFiles = 'CourseFiles',
  Certificates = 'Certificates',
  Curricula = 'Curricula',
  Categories = 'Categories',
  Publish = 'Publish',
  Consultancies = 'Consultancies',
  ConsultantsManagement = 'ConsultantsManagement',
  ConsultancyManagement = 'ConsultancyManagement',
  ConsultancyPurchasers = 'ConsultancyPurchasers',
  ConsultancyAttendees = 'ConsultancyAttendees',
  ConsultancyReports = 'ConsultancyReports',
  Coupons = 'Coupons',
  CouponManagement = 'CouponManagement',
  CouponReports = 'CouponReports',
  Affiliates = 'Affiliates',
  PaymentManagement = 'PaymentManagement',
  SubscriptionsManagement = 'SubscriptionsManagement',
  PaymentsExport = 'PaymentsExport',
  ReportsExport = 'ReportsExport',
  AffiliatesSubscriptions = 'AffiliatesSubscriptions',
  AffiliatesReports = 'AffiliatesReports',
  Business = 'Business',
  ProgramPolicy = 'ProgramPolicy',
  ManageSubscriptions = 'ManageSubscriptions',
  AddSubscription = 'AddSubscription',
  BusinessCRM = 'BusinessCRM',
  BusinessReports = 'BusinessReports',
  CustomerService = 'CustomerService',
  CertificatesReports = 'CertificatesReports',
  CoursePersonalLogfile = 'CoursePersonalLogfile',
  CourseLogfile = 'CourseLogfile',
  ManageTags = 'ManageTags',
  ManageCategories = 'ManageCategories',
  ExportCourseReport = 'ExportCourseReport',
  ExportReports = 'ExportReports',
  CoursePaymentReport = 'CoursePaymentReport',
  TotalPaymentReports = 'TotalPaymentReports',
  Settings = 'Settings',
  TicketSystem = 'TicketSystem',
  GeneralSettings = 'GeneralSettings',
  Users = 'Users',
  Terminating = 'Terminating',
  AddUser = 'AddUser',
  Suspension = 'Suspension',
  UserCourses = 'UserCourses',
  Searching = 'Searching',
  Reports = 'Reports',
  Logfile = 'Logfile',
  Dashboard = 'Dashboard',
  TrainersApplicationsExport = 'TrainersApplicationsExport',
  TrainersApplications = 'TrainersApplications',
  SalesExport = 'SalesExport',
  RegisteredUsersExport = 'RegisteredUsersExport',
}
