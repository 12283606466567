import { VideoDTO } from './../_models/video';
import { Curriculum, CURCourses, CURRules } from './../_models/curriculums';
import { Assignments } from './../_models/assignments';
import { Rules } from './../_models/rules';
import { Quizz } from './../_models/quizzes';
import { Materials, questionWithAnswers, FileBankDTO } from './../_models/materials';
import { Unites } from './../_models/unites';
import { Course, courseCard } from './../_models/courses';
import { AppConfig } from './app.config';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError, EMPTY } from 'rxjs';
import { COUCategories } from '../_models';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { observe } from '@progress/kendo-angular-grid/dist/es2015/utils';

@Injectable()
export class CoursesService {




  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
    // console.log(oidcSecurityService);
  }




  // New Stuff !

  extendCourseForTrainee(forum) {
    return this.http.post(this.apiUrl + '/api/courses/extendcourse/', forum).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyNewForum(forum) {
    return this.http.post(this.apiUrl + '/api/courses/forum/', forum).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyCasestudy(forum) {
    return this.http.post(this.apiUrl + '/api/courses/casestudy/', forum).pipe(catchError(error => this.handleError(error)));
  }
  evaluateCasestudyAttendance(forum) {
    return this.http.post(this.apiUrl + '/api/courses/evaluatecase/', forum).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifySolutionToCasestudy(forum) {
    return this.http.post(this.apiUrl + '/api/courses/csolution/', forum).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyAnswerToSolutionToCasestudy(forum) {
    return this.http.post(this.apiUrl + '/api/courses/canswer/', forum).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyTopic(topic) {
    return this.http.post(this.apiUrl + '/api/courses/topic/', topic).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyGlossary(glossary) {
    return this.http.post(this.apiUrl + '/api/courses/glossary/', glossary).pipe(catchError(error => this.handleError(error)));;
  }
  addOrModifyReplyOnTopicOfForum(topicreply) {
    return this.http.post(this.apiUrl + '/api/courses/topicreply/', topicreply).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyGlossaryConcept(concept) {
    return this.http.post(this.apiUrl + '/api/courses/concept/', concept).pipe(catchError(error => this.handleError(error)));
  }
  addOrModiyWiki(wiki) {
    return this.http.post(this.apiUrl + '/api/courses/wiki/', wiki).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyArticleToWiki(wikiarticle) {
    return this.http.post(this.apiUrl + '/api/courses/wikiarticle/', wikiarticle).pipe(catchError(error => this.handleError(error)));
  }



  addFillinQuestionToQuizz(question) {
    return this.http.post(this.apiUrl + '/api/courses/fillquestion/', question).pipe(catchError(error => this.handleError(error)));
  }


  addFillinQuestionToActivityQuizz(question) {
    return this.http.post(this.apiUrl + '/api/courses/fillacquestion/', question).pipe(catchError(error => this.handleError(error)));
  }

  getForumById(forumbyid) {
    return this.http.get(this.apiUrl + '/api/courses/forumbyid/' + forumbyid).pipe(catchError(error => this.handleError(error)));
  }


  deleteForumTopic(forumbyid) {
    return this.http.delete(this.apiUrl + '/api/courses/topic/' + forumbyid).pipe(catchError(error => this.handleError(error)));
  }


  fixOrderById(forumbyid) {
    return this.http.get(this.apiUrl + '/api/courses/fixorder/' + forumbyid).pipe(catchError(error => this.handleError(error)));
  }

  getForumsByCourse(forumbycourse) {
    return this.http.get(this.apiUrl + '/api/courses/forumbycourse/' + forumbycourse).pipe(catchError(error => this.handleError(error)));
  }



  getWikiByCourseId(forumbycourse) {
    return this.http.get(this.apiUrl + '/api/courses/wikibycourse/' + forumbycourse).pipe(catchError(error => this.handleError(error)));
  }


  checkIfCodeUsed(code) {
    return this.http.get(this.apiUrl + '/api/courses/check_coupon_code/' + code).pipe(catchError(error => this.handleError(error)));
  }


  getWikiById(forumbycourse) {
    return this.http.get(this.apiUrl + '/api/courses/wiki/' + forumbycourse).pipe(catchError(error => this.handleError(error)));
  }
  getTopicsOfForum(forumId) {
    return this.http.get(this.apiUrl + '/api/courses/topics/' + forumId).pipe(catchError(error => this.handleError(error)));
  }
  getAllRepliesOnTopic(topicId) {
    return this.http.get(this.apiUrl + '/api/courses/topicreplies/' + topicId).pipe(catchError(error => this.handleError(error)));
  }
  getGlossaryById(glossaryId) {
    return this.http.get(this.apiUrl + '/api/courses/glossary/' + glossaryId).pipe(catchError(error => this.handleError(error)));
  }
  getGlossaryByCourseId(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/glossarybycourseid/' + courseId).pipe(catchError(error => this.handleError(error)));
  }
  getAllConceptsOfGlossary(glossaryId) {
    return this.http.get(this.apiUrl + '/api/courses/concepts/' + glossaryId).pipe(catchError(error => this.handleError(error)));
  }
  getArticlesOfWiki(wikiId) {
    return this.http.get(this.apiUrl + '/api/courses/wikiarticles/' + wikiId).pipe(catchError(error => this.handleError(error)));
  }
  getCasestudiesForCourse(wikiId) {
    return this.http.get(this.apiUrl + '/api/courses/casestudies/' + wikiId).pipe(catchError(error => this.handleError(error)));
  }
  getCasestudyAttendance(wikiId) {
    return this.http.get(this.apiUrl + '/api/courses/caseattendance/' + wikiId).pipe(catchError(error => this.handleError(error)));
  }
  getCasestudyById(caseId) {
    return this.http.get(this.apiUrl + '/api/courses/casestudy/' + caseId).pipe(catchError(error => this.handleError(error)));
  }


  deleteArticleDetails(detailId) {
    return this.http.delete(this.apiUrl + '/api/courses/articledetails/' + detailId).pipe(catchError(error => this.handleError(error)));
  }
  deleteGlossaryConcept(conceptId) {
    return this.http.delete(this.apiUrl + '/api/courses/concept/' + conceptId).pipe(catchError(error => this.handleError(error)));
  }
  deleteReplyOnForumTopic(replyId) {
    return this.http.delete(this.apiUrl + '/api/courses/topicreply/' + replyId).pipe(catchError(error => this.handleError(error)));
  }
  deleteForum(forumId) {
    return this.http.delete(this.apiUrl + '/api/courses/forum/' + forumId).pipe(catchError(error => this.handleError(error)));
  }
  deleteGlossary(glossaryId) {
    return this.http.delete(this.apiUrl + '/api/courses/glossary/' + glossaryId).pipe(catchError(error => this.handleError(error)));
  }
  deleteWiki(wikiId) {
    return this.http.delete(this.apiUrl + '/api/courses/wiki/' + wikiId).pipe(catchError(error => this.handleError(error)));
  }
  deleteArticleFromWiki(articleId) {
    return this.http.delete(this.apiUrl + '/api/courses/articlewiki/' + articleId).pipe(catchError(error => this.handleError(error)));
  }
  deleteCasestudySolutionAnswer(answerId) {
    return this.http.delete(this.apiUrl + '/api/courses/canswer/' + answerId).pipe(catchError(error => this.handleError(error)));
  }
  deleteCasestudySolution(articleId) {
    return this.http.delete(this.apiUrl + '/api/courses/csolution/' + articleId).pipe(catchError(error => this.handleError(error)));
  }
  deleteCasestudy(caseId) {
    return this.http.delete(this.apiUrl + '/api/courses/casestudy/' + caseId).pipe(catchError(error => this.handleError(error)));
  }










  //Start Of Originals


  getMaterialQuestions(videoId) {
    return this.http.get(this.apiUrl + '/api/courses/vidques/' + videoId).pipe(catchError(error => this.handleError(error)));
  }

  deleteQuestionOfVideo(courseId) {
    return this.http.delete(this.apiUrl + '/api/courses/vidques/' + courseId).pipe(catchError(error => this.handleError(error)));
  }
















  // getAllCategories() {
  //   return this.allCategories;
  // }

  // updateAllCategories(id?: number) {
  //   // if (id) {
  //   //   var currentCategories = this.allCategories.getValue();
  //   //   if (currentCategories == []) {
  //   //     this.AllCategories().subscribe((data) => {
  //   //       if (data.length != 0) {
  //   //         this.allCategories.next(data);
  //   //         this.updateAllCategories(id);
  //   //       }

  //   //     });
  //   //   }
  //   //   else {
  //   //     var currentCateogry = currentCategories.find(x => x.id == id);
  //   //     if (!currentCateogry) {
  //   //       this.getCategoryById(id).subscribe((data: any) => {
  //   //         currentCategories.push(data);
  //   //         this.allCategories.next(currentCategories);
  //   //       })
  //   //     } else {
  //   //       currentCategories = currentCategories.filter((x => x.id != id));
  //   //       this.getCategoryById(id).subscribe((data: any) => {
  //   //         if (data) {
  //   //           currentCategories.push(data);
  //   //         }

  //   //         this.allCategories.next(currentCategories);
  //   //       })
  //   //     }
  //   //   }
  //   // } else {

  //   //   var currentCategories = this.allCategories.getValue();
  //   //   // console.log("NUMBER " + currentCategories);
  //   //   if (currentCategories.length == 0) {
  //   //     // console.log("XY");

  //   //     this.AllCategories().subscribe((data) => {
  //   //       if (data.length != 0) {
  //   //         this.allCategories.next(data);
  //   //         this.updateAllCategories(id);
  //   //       }
  //   //     });
  //   //   }
  //   // }
  //   this.AllCategories().subscribe((data) => {
  //     this.allCategories.next(data);
  //   })
  // }







  private allQuizzes = new BehaviorSubject<Quizz[]>([]);


  getAllQuizzes() {
    return this.allQuizzes;
  }

  updateAllQuizzes(courseId: number, id?: number) {

    if (!courseId)
      return;

    if (id) {
      var currentCategories: any[] = this.allQuizzes.getValue();
      if (currentCategories.length == 0) {
        this.getQuizzHeaderById(courseId).subscribe((data: any[]) => {
          if (data.length != 0) {
            this.allQuizzes.next(data);
            this.updateAllQuizzes(courseId, id);
          }

        });
      }
      else {
        var currentCateogry = currentCategories.find(x => x.id == id);
        if (!currentCateogry) {
          this.getQuizzHeaderById(id).subscribe((data: any) => {
            // console.log(data);

            currentCategories.push(data);
            this.allQuizzes.next(currentCategories);
          })
        } else {
          currentCategories = currentCategories.filter((x => x.id != id));
          this.getQuizzHeaderById(id).subscribe((data: any) => {
            if (data) {
              currentCategories.push(data);
            }

            this.allQuizzes.next(currentCategories);
          })
        }
      }
    } else {

      this.allQuizzes = new BehaviorSubject<Quizz[]>([]);
      var currentCategories: any[] = this.allQuizzes.getValue();
      // console.log("NUMBER " + currentCategories);
      if (currentCategories.length == 0) {
        // console.log("XY");

        this.getQuizzHeaderForCourse(courseId).subscribe((data: any[]) => {
          if (data.length != 0) {
            this.allQuizzes.next(data);
            // this.updateAllMaterials(unitId, id);
          }
        });
      }
    }

  }




  getQuizzHeaderById(id) {
    return this.http.get(this.apiUrl + '/api/courses/quizzheader/' + id).pipe(catchError(error => this.handleError(error)));
  }



  // getCourseCard(id) {
  //   return this.http.get(this.apiUrl + '/api/courses/coursecard/' + id).pipe(catchError(error => this.handleError(error)));
  // }


  GetQuestionById(id) {
    return this.http.get(this.apiUrl + '/api/courses/question/' + id).pipe(catchError(error => this.handleError(error)));
  }
  getQuestionForActivityQuizz(id) {
    return this.http.get(this.apiUrl + '/api/courses/activity_question/' + id).pipe(catchError(error => this.handleError(error)));
  }


  getQuizzHeaderForCourse(id) {
    return this.http.get(this.apiUrl + '/api/courses/quiheaforcou/' + id).pipe(catchError(error => this.handleError(error)));
  }


  clearBannerForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/clearbanner/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  clearVideoForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/clearvideo/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  clearSquareForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/clearsquare/' + courseId).pipe(catchError(error => this.handleError(error)));
  }




  getCourseRatings(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/course-rating/' + courseId).pipe(catchError(error => this.handleError(error)));
  }


  clearCourseCertificateSample(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/clearcoursesample/' + courseId).pipe(catchError(error => this.handleError(error)));
  }
  clearCurriculaCertificateSample(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/clearcoursesamplec/' + courseId).pipe(catchError(error => this.handleError(error)));
  }


  clearBannerForCurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/courses/clearbannerp/' + curriculaId).pipe(catchError(error => this.handleError(error)));
  }

  clearVideoForCurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/courses/clearvideop/' + curriculaId).pipe(catchError(error => this.handleError(error)));
  }

  clearSquareForCurricula(curriculaId) {
    return this.http.get(this.apiUrl + '/api/courses/clearsquarep/' + curriculaId).pipe(catchError(error => this.handleError(error)));
  }






  addOrModifyCategory(file: File, data: COUCategories) {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));

    return this.http.post(this.apiUrl + '/api/courses/category/', input, { headers: this.jwt2().headers, reportProgress: true, observe: 'events' });
  }


  addOrModifyMaterial(file: File, data: Materials) {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));

    return this.http.post(this.apiUrl + '/api/courses/material/', input, this.jwt2());
  }


  addOrModifyCurricula(data: Curriculum) {
    let input = new FormData();
    // if (file && file.length != 0) {
    //   input.append("file", file[0]);
    //   input.append("file1", file[1]);
    // }
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));

    return this.http.post(this.apiUrl + '/api/courses/curricula/', input, { headers: this.jwt2().headers, reportProgress: true, observe: 'events' });
  }


  deleteCurricula(curriculumId) {
    return this.http.delete(this.apiUrl + '/api/courses/curricula/' + curriculumId).pipe(catchError(error => this.handleError(error)));
  }

  getCurriculaById(curriculumId): Observable<Curriculum> {
    return this.http.get<Curriculum>(this.apiUrl + '/api/courses/curricula/' + curriculumId).pipe(catchError(error => this.handleError(error)));
  }

  getCurriculas(categoryId?: number): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(this.apiUrl + '/api/courses/curriculas/' + categoryId).pipe(catchError(error => this.handleError(error)));
  }


  addCourseToCurriculas(courses) {
    return this.http.post(this.apiUrl + '/api/courses/coursetocurricula/', courses).pipe(catchError(error => this.handleError(error)));
  }

  getCoursesForCurricula(curriculumId): Observable<Course[]> {
    return this.http.get<Course[]>(this.apiUrl + '/api/courses/coursesforcurricula/' + curriculumId).pipe(catchError(error => this.handleError(error)));
  }


  getCurriculaRuleById(ruleId) {
    return this.http.get(this.apiUrl + '/api/courses/curricularule/' + ruleId).pipe(catchError(error => this.handleError(error)));
  }


  getBankOfVideos(): Observable<Materials[]> {
    return this.http.get<Materials[]>(this.apiUrl + '/api/courses/bank').pipe(catchError(error => this.handleError(error)));
  }

  deleteRuleForCurricula(ruleId) {
    return this.http.delete(this.apiUrl + '/api/courses/curricularule/' + ruleId).pipe(catchError(error => this.handleError(error)));
  }

  getRulesForCurricula(curriculumId) {
    return this.http.get(this.apiUrl + '/api/courses/curricularules/' + curriculumId).pipe(catchError(error => this.handleError(error)));
  }

  addRuleForCurricula(attr: CURRules) {
    return this.http.post(this.apiUrl + '/api/courses/ruleforcurricula', attr).pipe(catchError(error => this.handleError(error)));
  }

  deleteCourseFromCurricula(courseId) {
    return this.http.delete(this.apiUrl + '/api/courses/curriculacourse/' + courseId).pipe(catchError(error => this.handleError(error)));
  }


  addOrModifyUnite(file: File, data: Unites) {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));

    return this.http.post(this.apiUrl + '/api/courses/unite/', input, this.jwt2());
  }

  addOrModifyVideo(file: File[], data: any) {
    let input = new FormData();
    file.forEach(element => {
      input.append("file", element);
    });
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));

    return this.http.post(this.apiUrl + '/api/courses/video/', input, { headers: this.jwt2().headers, reportProgress: true, observe: 'events' });
  }


  getVideoById(videoId): Observable<FileBankDTO> {
    return this.http.get<FileBankDTO>(this.apiUrl + '/api/courses/video/' + videoId).pipe(catchError(error => this.handleError(error)));
  }
  deleteVideoFromBank(videoId) {
    return this.http.delete(this.apiUrl + '/api/courses/video/' + videoId).pipe(catchError(error => this.handleError(error)));
  }
  getAllVideos(): Observable<FileBankDTO[]> {
    return this.http.get<FileBankDTO[]>(this.apiUrl + '/api/courses/videos').pipe(catchError(error => this.handleError(error)));
  }



  addOrModifyCourse(file: File[], data: Course) {
    let input = new FormData();
    if (file && file.length != 0) {
      input.append("file", file[0]);
      input.append("file1", file[1]);
      input.append("file2", file[2]);
    }
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));
    console.log(input);

    return this.http.post(this.apiUrl + '/api/courses/course/', input, this.jwt2());
  }


  // addOrModifyCategory(data: Category) {
  //     return this.http.post(this.config.apiUrl + '/api/courses/category', data).pipe(catchError(this.handleError));
  // }


  AddQuizz(attr): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/courses/addquizz', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }



  assignCertificateToCourse(attr): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/courses/assigcercou', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }
  assignCertificateToCurricula(attr): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/courses/assigcercur', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }



  AddQuizzHeaderOnly(attr): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/courses/addquizzheader', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }

  ModifyQuestion(attr) {
    return this.http.post<any>(this.apiUrl + '/api/courses/modifyquestion', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }


  ModifyAnswer(attr) {
    return this.http.post<any>(this.apiUrl + '/api/courses/modifyanswer', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }

  GetQuestionsForAQuizz(quizzId) {
    return this.http.get(this.apiUrl + '/api/courses/questions/' + quizzId).pipe(catchError(error => this.handleError(error)));
  }


  getQuestionsForActivityQuizz(quizzId) {
    return this.http.get(this.apiUrl + '/api/courses/activity_questions/' + quizzId).pipe(catchError(error => this.handleError(error)));
  }
  AddQuestion(attr) {
    return this.http.post<any>(this.apiUrl + '/api/courses/addquestion', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }

  AddQuestionToActivity(attr) {
    return this.http.post<any>(this.apiUrl + '/api/courses/activity_question', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }

  AddAnswer(attr) {
    return this.http.post<any>(this.apiUrl + '/api/courses/addanswer', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }
  AddAnswerForActivityQuizz(attr) {
    return this.http.post<any>(this.apiUrl + '/api/courses/activity_addanswer', attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }


  GetQuizzForUnit(attr) {
    return this.http.get(this.apiUrl + '/api/courses/quizzofunit/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }



  ChangeStatusOfCourse(attr) {
    return this.http.get(this.apiUrl + '/api/courses/status/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }


  GetQuizzForCourse(attr) {
    return this.http.get(this.apiUrl + '/api/courses/quizzofcourse/' + attr, { headers: this.jwt().headers })
  }

  GetQuizzById(attr) {
    return this.http.get(this.apiUrl + '/api/courses/quizzbyid/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }

  DeleteQuizz(attr) {
    return this.http.delete(this.apiUrl + '/api/courses/quizz/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }
  DeleteQuestion(attr) {
    return this.http.delete(this.apiUrl + '/api/courses/question/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }

  deleteQuestionFromActivityQuizz(attr) {
    return this.http.delete(this.apiUrl + '/api/courses/activity_question/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }
  DeleteAnswer(attr) {
    return this.http.delete(this.apiUrl + '/api/courses/answer/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }


  deleteAnswerForQuestionInActivityQuizz(attr) {
    return this.http.delete(this.apiUrl + '/api/courses/activity_questionanswer/' + attr, { headers: this.jwt().headers, reportProgress: true, observe: 'events' })
  }


  initCourseRecordForTrainee(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/init_course_log', attrs).pipe(catchError(error => this.handleError(error)));
  }


  totalTraineesFinishedCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/total_finished/' + courseId).pipe(catchError(error => this.handleError(error)));
  }
  getCourseAttendanceRecordForUser(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/course_log_trainee', attrs).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyPairOfAnswers(paris) {
    return this.http.post(this.apiUrl + '/api/courses/pairsofanswers', paris).pipe(catchError(error => this.handleError(error)));
  }

  getPairOfAnswers(questionid) {
    return this.http.get(this.apiUrl + '/api/courses/pairsofanswers/' + questionid).pipe(catchError(error => this.handleError(error)));
  }
  getListOfActivitiesCourse(questionid) {
    return this.http.get(this.apiUrl + '/api/courses/course_activities/' + questionid).pipe(catchError(error => this.handleError(error)));
  }


  addOrModifyPairOfAnswersForActivity(paris) {
    return this.http.post(this.apiUrl + '/api/courses/activity_pairsofanswers', paris).pipe(catchError(error => this.handleError(error)));
  }

  getPairOfAnswersForActivity(questionid) {
    return this.http.get(this.apiUrl + '/api/courses/activity_pairsofanswers/' + questionid).pipe(catchError(error => this.handleError(error)));
  }
  deletePairOfAnswersForActivity(questionid) {
    return this.http.delete(this.apiUrl + '/api/courses/activity_pairsofanswers/' + questionid).pipe(catchError(error => this.handleError(error)));
  }


  addInfoToCourse(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/info', attrs).pipe(catchError(error => this.handleError(error)));
  }

  getCourseInfo(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/info/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  deleteCourseInfo(infoId) {
    return this.http.delete(this.apiUrl + '/api/courses/info/' + infoId).pipe(catchError(error => this.handleError(error)));
  }


  addInfoToCurricula(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/cinfo', attrs).pipe(catchError(error => this.handleError(error)));
  }

  getCurriculaInfo(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/cinfo/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  deleteCurriculaInfo(infoId) {
    return this.http.delete(this.apiUrl + '/api/courses/cinfo/' + infoId).pipe(catchError(error => this.handleError(error)));
  }

  resortCurriculaInfo(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/sortcinfos', attrs).pipe(catchError(error => this.handleError(error)));
  }


  deletePairOfAnswers(pair) {
    return this.http.delete(this.apiUrl + '/api/courses/pairsofanswers/' + pair)
  }

  addOrModifyRuleForCourse(rule): Observable<Rules> {
    return this.http.post<Rules>(this.apiUrl + '/api/courses/rule', rule).pipe(catchError(error => this.handleError(error)));
  }


  addOrModifyAssignementForCourse(assignment: Assignments): Observable<Assignments> {
    return this.http.post<Assignments>(this.apiUrl + '/api/courses/assignment', assignment).pipe(catchError(error => this.handleError(error)));
  }

  getAssignmentById(assignmentId): Observable<Assignments> {
    return this.http.get<Assignments>(this.apiUrl + '/api/courses/assignment/' + assignmentId).pipe(catchError(error => this.handleError(error)));
  }

  getAllAssignmentsForCourse(courseId): Observable<Assignments[]> {
    return this.http.get<Assignments[]>(this.apiUrl + '/api/courses/assignments/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  deleteAssignement(assignmentId) {
    return this.http.delete(this.apiUrl + '/api/courses/assignment/' + assignmentId).pipe(catchError(error => this.handleError(error)));
  }

  getRulesForCourse(courseId): Observable<Rules[]> {
    return this.http.get<Rules[]>(this.apiUrl + '/api/courses/rules/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  getRuleById(ruleId): Observable<Rules> {
    return this.http.get<Rules>(this.apiUrl + '/api/courses/rule/' + ruleId).pipe(catchError(error => this.handleError(error)));
  }

  deleteRule(ruleId): Observable<number> {
    return this.http.delete<number>(this.apiUrl + '/api/courses/rule/' + ruleId).pipe(catchError(error => this.handleError(error)));
  }

  addOrModifyQuestionOnVideo(attrs: questionWithAnswers): Observable<boolean> {
    return this.http.post<boolean>(this.apiUrl + '/api/courses/videoquestion', attrs).pipe(catchError(error => this.handleError(error)));
  }

  AllCategories(): Observable<COUCategories[]> {
    return this.http.get<COUCategories[]>(this.apiUrl + '/api/courses/categories/').pipe(catchError(this.handleError));
  }


  AllCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(this.apiUrl + '/api/courses/courses/').pipe(catchError(this.handleError));
  }

  AllUnites(courseId): Observable<Unites[]> {
    return this.http.get<Unites[]>(this.apiUrl + '/api/courses/unites/' + courseId).pipe(catchError(this.handleError));
  }


  AllMaterials(courseId): Observable<Materials[]> {
    return this.http.get<Materials[]>(this.apiUrl + '/api/courses/materials/' + courseId).pipe(catchError(this.handleError));
  }


  getCourseById(id): Observable<Course> {
    return this.http.get<Course>(this.apiUrl + '/api/courses/course/' + id).pipe(catchError(error => this.handleError(error)));
  }

  getCategoryById(id): Observable<COUCategories> {
    return this.http.get<COUCategories>(this.apiUrl + '/api/courses/category/' + id).pipe(catchError(error => this.handleError(error)));
  }

  getUniteById(id): Observable<Unites> {
    return this.http.get<Unites>(this.apiUrl + '/api/courses/unite/' + id).pipe(catchError(error => this.handleError(error)));
  }



  getMaterialById(id): Observable<Materials> {
    return this.http.get<Materials>(this.apiUrl + '/api/courses/material/' + id).pipe(catchError(error => this.handleError(error)));
  }
  getContentById(id): Observable<Materials> {
    return this.http.get<Materials>(this.apiUrl + '/api/courses/content/' + id).pipe(catchError(error => this.handleError(error)));
  }

  getAllCategoriesForParent(id: any) {
    return this.http.get(this.apiUrl + '/api/courses/categoriesbyparent/' + id).pipe(catchError(this.handleError));
  }

  getCategoryWithCourses(id: any) {
    return this.http.get(this.apiUrl + '/api/courses/category/' + id).pipe(catchError(this.handleError));
  }


  deleteCategory(id: any) {
    return this.http.delete(this.apiUrl + '/api/courses/category/' + id).pipe(catchError(error => this.handleError(error)));
  }

  deleteCourse(id: any) {
    return this.http.delete(this.apiUrl + '/api/courses/course/' + id).pipe(catchError(error => this.handleError(error)));
  }


  deleteUnite(id: any) {
    return this.http.delete(this.apiUrl + '/api/courses/unite/' + id).pipe(catchError(error => this.handleError(error)));
  }


  deleteMaterial(id: any) {
    return this.http.delete(this.apiUrl + '/api/courses/material/' + id).pipe(catchError(error => this.handleError(error)));
  }

  addPriceForCourse(attr) {
    return this.http.post(this.apiUrl + '/api/courses/price', attr).pipe(catchError(error => this.handleError(error)));
  }

  getCoursePriceList(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/prices/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  deletePriceForCourse(priceId) {
    return this.http.delete(this.apiUrl + '/api/courses/prices/' + priceId).pipe(catchError(error => this.handleError(error)));
  }

  getTheLatestPriceForTheCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/coursePrice/' + courseId).pipe(catchError(error => this.handleError(error)));
  }



  addPriceForCurricula(attr) {
    return this.http.post(this.apiUrl + '/api/courses/cprice', attr).pipe(catchError(error => this.handleError(error)));
  }

  getCurriculaPriceList(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/cprices/' + courseId).pipe(catchError(error => this.handleError(error)));
  }

  deletePriceForCurricula(priceId) {
    return this.http.delete(this.apiUrl + '/api/courses/cprices/' + priceId).pipe(catchError(error => this.handleError(error)));
  }

  getTheLatestPriceForTheCurricula(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/curriculaPrice/' + courseId).pipe(catchError(error => this.handleError(error)));
  }




  addOrModifyCoupon(file: File[], data: Course) {
    let input = new FormData();
    if (file && file.length != 0) {
      input.append("file", file[0]);
    }
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));
    // console.log(input);

    return this.http.post(this.apiUrl + '/api/courses/coupon/', input, this.jwt2()).pipe(catchError(error => this.handleError(error)));
  }

  // addOrModifyCoupon(attr) {
  //   return this.http.post(this.apiUrl + '/api/courses/coupon', attr).pipe(catchError(error => this.handleError(error)));
  // }

  updateCouponDates(attr) {
    return this.http.post(this.apiUrl + '/api/courses/coupondates', attr).pipe(catchError(error => this.handleError(error)));
  }
  getCouponById(couponId) {
    return this.http.get(this.apiUrl + '/api/courses/coupon/' + couponId).pipe(catchError(error => this.handleError(error)));
  }

  getCouponProducts(couponId) {
    return this.http.get(this.apiUrl + '/api/courses/coudetails/' + couponId).pipe(catchError(error => this.handleError(error)));
  }


  addProductToCoupon(detail) {
    return this.http.post(this.apiUrl + '/api/courses/copdetail/', detail).pipe(catchError(error => this.handleError(error)));
  }
  addNewAnnouncementToCourse(detail) {
    return this.http.post(this.apiUrl + '/api/courses/announcement/', detail).pipe(catchError(error => this.handleError(error)));
  }
  getAnnouncementsForCourse(detail) {
    return this.http.post(this.apiUrl + '/api/courses/getannouncements/', detail).pipe(catchError(error => this.handleError(error)));
  }
  seeAnnouncements(detail) {
    return this.http.post(this.apiUrl + '/api/courses/seeannouncement/', detail).pipe(catchError(error => this.handleError(error)));
  }


  removeProductFromCoupon(detail) {
    return this.http.delete(this.apiUrl + '/api/courses/copdetai/' + detail).pipe(catchError(error => this.handleError(error)));
  }


  getCouponsForCourse(courseId) {
    return this.http.get(this.apiUrl + '/api/courses/coupons/' + courseId).pipe(catchError(error => this.handleError(error)));
  }


  getAllCoupons() {
    return this.http.get(this.apiUrl + '/api/courses/allcoupons').pipe(catchError(error => this.handleError(error)));
  }

  deteleCoupon(couponId) {
    return this.http.delete(this.apiUrl + '/api/courses/coupon/' + couponId)
  }


  getCoursesForCategry(cateogryId): Observable<Course[]> {
    return this.http.get<Course[]>(this.apiUrl + '/api/courses/coursesbycateogryid/' + cateogryId).pipe(catchError(error => this.handleError(error)));
  }


  setCourseStatus(status) {
    return this.http.post(this.apiUrl + '/api/courses/status', status).pipe(catchError(error => this.handleError(error)));
  }



  setPacakgeStatus(status) {
    return this.http.post(this.apiUrl + '/api/courses/package_status', status).pipe(catchError(error => this.handleError(error)));
  }

  getCourseCard(courseId: string): Observable<courseCard> {
    return this.http.get<courseCard>(this.apiUrl + '/v1/card/' + courseId, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }
  getCourseCardById(courseId: number): Observable<courseCard> {
    return this.http.get<courseCard>(this.apiUrl + '/v1/cardbyid/' + courseId, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }


  addOrModifyPublicVideo(video: VideoDTO) {
    return this.http.post(this.apiUrl + '/api/courses/publicvideo', video).pipe(catchError(error => this.handleError(error)));
  }
  getPublicVideo(videoId) {
    return this.http.get(this.apiUrl + '/api/courses/publicvideo/' + videoId).pipe(catchError(error => this.handleError(error)));
  }
  deletePublicVideo(videoId) {
    return this.http.delete(this.apiUrl + '/api/courses/publicvideo/' + videoId).pipe(catchError(error => this.handleError(error)));
  }

  getAllPublicVideos() {
    return this.http.get(this.apiUrl + '/api/courses/publicvideos').pipe(catchError(error => this.handleError(error)));
  }



  addTrainerToCourse(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/trainertocourse', attrs).pipe(catchError(error => this.handleError(error)));
  }
  deleteTrainerFromCourse(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/trainertocourse/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getCourseTrainers(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/coursetrainers/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getTrainerCourses(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/trainercourses/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  addContentToMaterial(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/materialcontent', attrs).pipe(catchError(error => this.handleError(error)));
  }


  addMeetingContentToMaterial(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/contentmeeting', attrs).pipe(catchError(error => this.handleError(error)));
  }


  updatevideolength(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/updatevideolength', attrs).pipe(catchError(error => this.handleError(error)));
  }

  deleteContentFromMaterial(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/materialcontent/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getAllMaterialContents(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/contents/' + attrs).pipe(catchError(error => this.handleError(error)));
  }
  addAttachmentFileToMaterial(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/materialfile', attrs).pipe(catchError(error => this.handleError(error)));
  }

  deleteAttachmentFileFromMaterial(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/materialfile/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getAllMaterialAttachments(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/materialfiles/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  resortMaterialContets(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/sortcontents', attrs).pipe(catchError(error => this.handleError(error)));
  }


  resortCategories(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/sortcategories', attrs).pipe(catchError(error => this.handleError(error)));
  }


  resortLectures(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/sortlectures', attrs).pipe(catchError(error => this.handleError(error)));
  }


  resortCourseInfo(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/sortinfos', attrs).pipe(catchError(error => this.handleError(error)));
  }



  resortBanners(attrs) {
    return this.http.post(this.apiUrl + '/v2/sortbanners', attrs).pipe(catchError(error => this.handleError(error)));
  }
  resortUnites(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/sortunites', attrs).pipe(catchError(error => this.handleError(error)));
  }


  addOrModifyCertifcate(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/certificate', attrs).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyCertifcateSettings(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/certificatesettings', attrs).pipe(catchError(error => this.handleError(error)));
  }
  addOrModifyCertifcateDetails(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/certificatedetails', attrs).pipe(catchError(error => this.handleError(error)));
  }
  resortCertificateDetails(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/certificatedetailsorder', attrs).pipe(catchError(error => this.handleError(error)));
  }




  deleteCertificate(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/certificate/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getCertificate(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/certificate/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getAllCertificates(attrs?: any) {
    return this.http.get(this.apiUrl + '/api/courses/certificates/' + attrs).pipe(catchError(error => this.handleError(error)));
  }
  getCertificatesForCurricula(attrs?: any) {
    return this.http.get(this.apiUrl + '/api/courses/curriculacertificates/' + attrs).pipe(catchError(error => this.handleError(error)));
  }


  grantCertificateManually(attrs?: any) {
    return this.http.post(this.apiUrl + '/api/courses/grantcertificate/', attrs, this.pdfJWT());
  }
  grantCertificateManuallyJSON(attrs?: any) {
    return this.http.post(this.apiUrl + '/api/courses/grantcertificate/', attrs, this.jwt());
  }
  grantCertificateManuallyb(attrs?: any) {
    return this.http.post(this.apiUrl + '/api/courses/grantcertificateb/', attrs, this.pdfJWT());
  }
  getCertificatesForCourse(attrs?: any) {
    return this.http.get(this.apiUrl + '/api/courses/coursecertificates/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  deleteCertificateDetails(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/certificatedetails/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getAllDetailsOfCertificate(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/certificatedetails/' + attrs).pipe(catchError(error => this.handleError(error)));
  }



  getAssignmentsForEvaluation(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/assignmentstoevaluate', attrs).pipe(catchError(error => this.handleError(error)));
  }


  getQuizzAttendanceCardForTrainee(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/traineequizzinfo', attrs).pipe(catchError(error => this.handleError(error)));
  }




  addMeeting(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/meeting', attrs).pipe(catchError(error => this.handleError(error)));
  }


  addActivty(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/activity', attrs).pipe(catchError(error => this.handleError(error)));
  }


  updateFillSpaceActivity(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/fill_activity', attrs).pipe(catchError(error => this.handleError(error)));
  }

  updateAcitivtyQuizzName(attrs) {
    return this.http.post(this.apiUrl + '/api/courses/quizz_activity', attrs).pipe(catchError(error => this.handleError(error)));
  }

  getActivityById(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/activity/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  deleteActivity(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/activity/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  deletePair(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/activity_pair/' + attrs).pipe(catchError(error => this.handleError(error)));
  }
  deleteSentence(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/activity_sentence/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  deleteAnswer(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/activity_answer/' + attrs).pipe(catchError(error => this.handleError(error)));
  }


  getAllActivities(attrs, id) {
    return this.http.get(this.apiUrl + '/api/courses/activities/' + attrs + '/' + id).pipe(catchError(error => this.handleError(error)));
  }




  deleteMeeting(attrs) {
    return this.http.delete(this.apiUrl + '/api/courses/meeting/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getMeetingById(attrs) {
    return this.http.get(this.apiUrl + '/api/courses/meeting/' + attrs).pipe(catchError(error => this.handleError(error)));
  }

  getAllCoursesMeetings(attrs?: any) {
    return this.http.get(this.apiUrl + '/api/courses/meetings/' + attrs).pipe(catchError(error => this.handleError(error)));
  }




  updateAssignment(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(userId));
    return this.http.post<any>(this.apiUrl + '/api/courses/markassignment', input, this.jwt2()).pipe(catchError(error => this.handleError(error)));
  }



  certificateimage(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(userId));
    return this.http.post<any>(this.apiUrl + '/api/courses/certificateimage', input, this.jwt2()).pipe(catchError(error => this.handleError(error)));
  }


  markAllAssignmentsForUserAtOnce(attrs): Observable<any> {

    return this.http.post<any>(this.apiUrl + '/api/courses/markallassignments', attrs).pipe(catchError(error => this.handleError(error)));
  }




  exportpdf(id) {

    // const options = { headers: this.jwt(), responseType: 'blob' };
    return this.http.get(this.apiUrl + '/api/courses/exportpdf/' + id, this.pdfJWT());

  }
  exportpdfNew(id) {

    // const options = { headers: this.jwt(), responseType: 'blob' };
    // return this.http.get(this.apiUrl + '/api/courses/exportpdfnew/' + id, this.jwt());
    return this.http.get(this.apiUrl + '/api/courses/exportpdfnew/' + id, this.pdfJWT());

  }




  getAllSliders() {
    return this.http.get(this.apiUrl + '/v2/sliders').pipe(catchError(error => this.handleError(error)));
  }


  deleteSlider(sliderId) {
    return this.http.delete(this.apiUrl + '/v2/slider/' + sliderId).pipe(catchError(error => this.handleError(error)));
  }


  addSliderOrUpdate(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(userId));
    return this.http.post<any>(this.apiUrl + '/v2/slider', input, this.jwt2()).pipe(catchError(error => this.handleError(error)));
  }




  getAllBanners() {
    return this.http.get(this.apiUrl + '/v2/banners').pipe(catchError(error => this.handleError(error)));
  }






  deleteBanner(bannerId) {
    return this.http.delete(this.apiUrl + '/v2/banner/' + bannerId).pipe(catchError(error => this.handleError(error)));
  }




  replyToReview(comment) {
    return this.http.post(this.apiUrl + '/v2/reply-review', comment).pipe(catchError(error => this.handleError(error)));
  }


  getAllReviews(pageSize, pageNumber) {
    return this.http.get(`${this.apiUrl}/v2/reviews?pageSize=${pageSize}&pageNumber=${pageNumber}`).pipe(catchError(error => this.handleError(error)));
  }






  addBanner(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(userId));
    return this.http.post<any>(this.apiUrl + '/v2/banner', input, this.jwt2()).pipe(catchError(error => this.handleError(error)));
  }







  getSignedAuth(videoId: string) {

    return this.http.get<any>(this.apiUrl + '/api/bunny/bunny-signed?videoId=' + videoId, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }



  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.error(error.error.error)
    }
    // return an observable with a user-facing error message
    return throwError(error.error.error);
  };



  // protected handleError(error: any) {

  //   var applicationError = error.headers.get('Application-Error');

  //   // either application-error in header or model error in body
  //   if (applicationError) {
  //     return throwError(applicationError);
  //   }

  //   var modelStateErrors: string = '';

  //   // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
  //   for (var key in error.error) {
  //     if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
  //   }

  //   modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
  //   return throwError(modelStateErrors || 'Server error');
  // }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private pdfJWT() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      responseType: 'blob' as 'blob',
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


}
