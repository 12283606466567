import { COUCategories } from './../../_models';
import { createReducer, on, Action, createSelector, createFeatureSelector, ActionReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as categoriesActions from './courses.actions';
import { appState } from '../app.state';


export interface categoriesState extends EntityState<COUCategories> {
  selectedCategoryId?: number;
  isLoading: boolean;
}

export function selectItemId(a: COUCategories): string {
  //In this case this would be optional since primary key is id
  return a.id.toString();
}


export function sortByName(a: COUCategories, b: COUCategories): number {
  return a.name.localeCompare(b.name);
}

export const categoriessAdapter: EntityAdapter<COUCategories> = createEntityAdapter<COUCategories>();

// export const defaultItems: cartState = {
//   ids: [],
//   entities: {},
//   selectedItemId: null,
//   total: 0,
// }

const initialState = categoriessAdapter.getInitialState({
  selectId: selectItemId,
  sortComparer: sortByName,
  isLoading: false,
  selectedCategoryId: null,
});


const _reducer = createReducer(
  initialState,
  on(categoriesActions.updateCategory, (state, { category }) => {
    return categoriessAdapter.updateOne(category, state);
  }),
  on(categoriesActions.LoadCategoriesSuccess, (state, { categories }) => {
    return categoriessAdapter.setAll(categories, state)
  }),
  on(categoriesActions.addCategory, (state, { category }) => {
    return categoriessAdapter.setOne(category, state)
  }),
  on(categoriesActions.removeCategory, (state, { id }) => {
    return categoriessAdapter.removeOne(id, state);
  }),
)





export const categoriesReducerFeatureKey = 'categoriess';
export const categoriessFeature = createFeatureSelector<categoriesState>(categoriesReducerFeatureKey);


export const getSeletedItemId = (state: categoriesState) => state.selectedCategoryId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = categoriessAdapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectEntities;

// select the total user count
export const selectUserTotal = selectTotal;


export const getCOUCategoriess = createSelector(
  categoriessFeature, selectAll
)


export const loadingStatus = createSelector(
  categoriessFeature,
  (state: categoriesState) => state.isLoading
)



export const totalItems = createSelector(
  categoriessFeature,
  (state: categoriesState) => {
    const allItems = Object.values(state.entities);
    return allItems.length
  })


export function persistStateReducer(_reducer: ActionReducer<categoriesState>) {
  const localStorageKey = '__categories';
  return (state: categoriesState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}


export function categoriesReucer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<categoriesState>) {
  return (state: categoriesState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}


