import { ActivatedRoute } from '@angular/router';
import { UserService } from './../core/services/users.service';
import { CoursesService } from './../core/services/courses.service';
import { SystemService } from '../core/services/system.service';
import { Component, OnInit, ViewEncapsulation, Inject, AfterViewInit } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import * as _reducer from './../core/state/_system/system.reducer';
import { environment } from './../../environments/environment';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as introJs from 'intro.js/intro.js';
// import { TourService } from 'ngx-ui-tour-tui-dropdown';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, AfterViewInit {


  loading$: Observable<boolean>;
  introJS = introJs();
  constructor(
    // private readonly tourService: TourService,
    private _user: UserService, 
     private _store: Store<any>) { }

  ngAfterViewInit(): void {
    this.loadData();
    this.loading$ = this._store.pipe(select(_reducer.loadingStatus));
  }

  ngOnInit(): void {
    this._user.test().subscribe((data) => {});

    this.introJS.start();
    setTimeout(() => {
      this.loadIntercomScript();
    }, 10000);
  }


  loadAPI: Promise<any>;



  loadData() {
    this.loadAPI = new Promise((resolve) => {

      this.loadStripe();
      // this.loadScript();
      // this.loadScriptHead();
      // this.loadStylesHead();
      // resolve(true);
    });
  }


  public loadStripe() {
    var dynamicScripts = [
      "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js",
      "https://cdn.jsdelivr.net/npm/select2@4.0.12/dist/js/select2.min.js",
      "../assets/dragula.min.js",
      "../assets/bower_components/popper.js/dist/umd/popper.min.js",
      "../assets/bower_components/moment/moment.js",
      "../assets/bower_components/chart.js/dist/Chart.min.js",
      "../assets/bower_components/jquery-bar-rating/dist/jquery.barrating.min.js",
      "../assets/bower_components/ckeditor/ckeditor.js",
      "../assets/bower_components/bootstrap-validator/dist/validator.min.js",
      "../assets/bower_components/bootstrap-daterangepicker/daterangepicker.js",
      "../assets/bower_components/ion.rangeSlider/js/ion.rangeSlider.min.js",
      "../assets/bower_components/dropzone/dist/dropzone.js",
      "../assets/bower_components/editable-table/mindmup-editabletable.js",
      "../assets/bower_components/fullcalendar/dist/fullcalendar.min.js",
      "../assets/bower_components/perfect-scrollbar/js/perfect-scrollbar.jquery.min.js",
      "../assets/bower_components/tether/dist/js/tether.min.js",
      "../assets/bower_components/slick-carousel/slick/slick.min.js",
      "../assets/bower_components/bootstrap/js/dist/util.js",
      "../assets/bower_components/bootstrap/js/dist/alert.js",
      "../assets/bower_components/bootstrap/js/dist/button.js",

      "../assets/bower_components/bootstrap/js/dist/carousel.js",
      "../assets/bower_components/bootstrap/js/dist/collapse.js",
      "../assets/bower_components/bootstrap/js/dist/dropdown.js",
      "../assets/bower_components/bootstrap/js/dist/modal.js",
      "../assets/bower_components/bootstrap/js/dist/tab.js",
      "../assets/bower_components/bootstrap/js/dist/tooltip.js",
      "../assets/bower_components/bootstrap/js/dist/popover.js",
      "../assets/js/demo_customizer.js",

      "../assets/js/main.js",

    ];



    // loadIntercomScript() {
    //   let node = document.createElement('script'); // creates the script tag
    //   // node.text = "snaptr('track', 'ADD_CART');"; // sets the source (insert url in between quotes)
    //   node.text =  // sets the source (insert url in between quotes)
    //   node.type = 'text/javascript'; // set the script type
    //   node.async = true; // makes script run asynchronously
    //   node.charset = 'utf-8';
    //   // append to head of document
    //   document.getElementsByTagName('body')[0].appendChild(node);
    // }

    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      // node.type = 'text/javascript';
      node.async = true;
      // node.charset = 'utf-8';
      document.getElementsByTagName('body')[0].appendChild(node);
    }


  }

  loadIntercomScript() {
    let node = document.createElement('script'); // creates the script tag
    // node.text = "snaptr('track', 'ADD_CART');"; // sets the source (insert url in between quotes)
    node.text = `window.intercomSettings = {app_id: "znw3q79e"};(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/znw3q79e';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`; // sets the source (insert url in between quotes)
    // node.type = 'text/javascript'; // set the script type
    // node.async = true; // makes script run asynchronously
    // node.charset = 'utf-8';
    // append to head of document
    document.head.appendChild(node);
  }


}
