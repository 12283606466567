import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityDecisionAnswersComponent } from '../activity-decision-answers/activity-decision-answers.component';
import { ActivityLogicalSentenceComponent } from '../activity-logical-sentence/activity-logical-sentence.component';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';


@Component({
  selector: 'sm-activity-decision',
  templateUrl: './activity-decision.component.html',
  styleUrls: ['./activity-decision.component.scss']
})
export class ActivityDecisionComponent implements OnInit, AfterViewInit {



  //New Table
  dataSourceTrainees: any = [];
  @ViewChild(MatPaginator) paginatorTrainees: MatPaginator;
  @ViewChild(MatSort) sortTrainees: MatSort;
  resultsTraineesLength = 0;
  //End New Table

  newCase: UntypedFormGroup;
  profile: any;
  filterText = ''
  answers = [];
  submitted = false;
  newActivity: UntypedFormGroup;
  newSolution: UntypedFormGroup;

  get xf() { return this.newCase.controls; }
  get xs() { return this.newSolution.controls; }

  currentCourse
  lang = 'ar'
  activityId
  loading = false;
  isOpenCase: boolean;
  solutionId
  editorConfigs = {
    useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCase.patchValue({
      caseBody: val
    });
    this._editorData = val;
  }
  
  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityDecisionComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.currentCourse = this.data.courseId;

  }

  ngOnInit(): void {
    this.newActivity = this.formBuilder.group({
      id: this.activityId,
      Id: this.activityId,
      courseId: this.currentCourse,
      type: ActivityType.CaseStudies,
      caseStudy: [''],
      title: [{ value: '', disabled: this.loading }, Validators.required],
      isMarked: false,
      mark: 0,
    });
    this.newCase = this.formBuilder.group({
      id: null,
      addedAt: moment(),
      addedBy: parseInt(this.profile.userId),
      fromDate: moment(),
      toDate: moment(),
      openCase: false,
      caseHeader: [{ value: '', disabled: this.loading }],
      caseBody: [{ value: '', disabled: this.loading }],
      description: [{ value: '', disabled: this.loading }],
      courseId: this.currentCourse,
      solution: ['']
    });

    this.newSolution = this.formBuilder.group({
      id: null,
      caseStudyId: null,
      answers: ['']
    });


    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }

  loadAnswers() {
    this.dataSourceTrainees = new MatTableDataSource(this.answers)
    this.resultsTraineesLength = (this.answers) ? this.answers.length : 0;
    this.dataSourceTrainees.paginator = this.paginatorTrainees;
    this.dataSourceTrainees.sort = this.sortTrainees;

  }



  applyFilterTrainees(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTrainees.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceTrainees.paginator) {
      this.dataSourceTrainees.paginator.firstPage();
    }
  }




  ngAfterViewInit(): void {
    this.dataSourceTrainees.paginator = this.paginatorTrainees;
    this.dataSourceTrainees.sort = this.sortTrainees;
  }


  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    this.newSolution.patchValue({
      answers: this.answers
    });
    this.newCase.patchValue({
      solution: this.newSolution.value
    })

    this.newActivity.patchValue({
      caseStudy: this.newCase.value,
      title: this.newCase.get('caseHeader').value,
      id: this.activityId,
      Id: this.activityId
    })


    // console.log(this.newActivity.value);
    // return;

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);

    this._course.addActivty(this.newActivity.value).subscribe((data) => {

      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe((data) => {
        this.dialogRef.close({ data: true });
      })
    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + ' ' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }




  deleteAnswer(dataItem) {
    var id = dataItem.id | dataItem.Id;
    this.answers = this.answers.filter(x => x != dataItem);
    this.cd.markForCheck();
    if (id != 0)
      this._course.deleteAnswer(id).subscribe((data) => { });

      this.loadAnswers();

  }

  modifyAnswer(dataItem) {
    const dialog = this._dialog.open(ActivityDecisionAnswersComponent, {
      direction: 'rtl',
      minWidth: '500px',
      data: { courseId: this.currentCourse, isNew: false, oldItem: dataItem, parentId: this.solutionId }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        // console.log(data);

        // this.answers.push(data.data);
        if (this.answers.filter(x => { return x === data.data })) {
          // console.log('yes');
          let currentAnswer = this.answers.filter(x => { return x === dataItem });
          currentAnswer = data.data;
          this.answers = this.answers.filter(x => { return x != dataItem });
          this.answers.push(currentAnswer);
          // console.log(this.answers);

          this.loadAnswers();

        }
        // if (this.newCase.get('title').value) {

        // }
      }
    })
  }


  modify(dataItem) {
    this.activityId = dataItem.Id | dataItem.id;
    // console.log(dataItem);


    if (dataItem.openCase) {
      this.isOpenCase = true;
    } else {
      this.isOpenCase = false;
    }

    this.answers = dataItem.caseStudy.solution.answers;
    this.loadAnswers();
    this.solutionId = dataItem.caseStudy.solution.Id | dataItem.caseStudy.solution.id;

    this.newCase = this.formBuilder.group({
      id: dataItem.caseStudy.id | dataItem.caseStudy.Id,
      addedAt: dataItem.caseStudy.addedAt,
      fromDate: moment(dataItem.caseStudy.fromDate),
      toDate: moment(dataItem.caseStudy.toDate),
      openCase: dataItem.caseStudy.openCase,
      caseHeader: dataItem.caseStudy.caseHeader,
      caseBody: dataItem.caseStudy.caseBody,
      description: dataItem.caseStudy.description,
      courseId: dataItem.courseId,
      addedBy: parseInt(dataItem.caseStudy.addedBy),
      solution: dataItem.caseStudy.solution
    });

    if (!dataItem.openCase) {
      this.newSolution = this.formBuilder.group({
        id: dataItem.caseStudy.solution.Id | dataItem.caseStudy.solution.id,
        caseStudyId: dataItem.id | dataItem.Id,
        answers: dataItem.answers
      })
    }
  }


  addNewPair() {
    const dialog = this._dialog.open(ActivityDecisionAnswersComponent, {
      direction: 'rtl',
      minWidth: '500px',
      data: { courseId: this.currentCourse, isNew: true, oldItem: null, parentId: this.solutionId }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        // console.log(data);

        this.answers.push(data.data);
        this.loadAnswers();
        // if (this.newCase.get('title').value) {
        //   // this.onSubmitInternally();
        // }
      }
    })
  }


}
