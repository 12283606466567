export enum MaterialTypes {
    Video = 1,
    Image = 2,
    PDF = 3,
    Article = 4,
    Quizz = 5,
    Link = 6,
    LiveStreaming = 7,
    DecisionMaking = 8,
    CaseStudies = 9,
    MatchBetween = 10,
    FillSpace = 11,
    LogicalOrder = 12
}