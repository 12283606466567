import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';


export interface matchingActivityData {
  isNew: boolean;
  oldItem: any;
  courseId: number;
  parentId?: number;

}

@Component({
  selector: 'sm-activity-matching',
  templateUrl: './activity-matching.component.html',
  styleUrls: ['./activity-matching.component.scss']
})
export class ActivityMatchingComponent implements OnInit, AfterViewInit {


  newCase: UntypedFormGroup;
  newSolution: UntypedFormGroup;

  loading = false;
  lang = 'ar';

  get xf() { return this.newCase.controls; }
  get xs() { return this.newSolution.controls; }


  profile;
  currentCourse = 0;
  solutionId
  submitted
  answers = [];

  items = [];
  filterText = ''
  editorConfigs = {
    useSearch: false,
    language: "ar",
    enter: "br",
    direction: "rtl",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };


  //New Table
  dataSourceTrainees: any = [];
  @ViewChild(MatPaginator) paginatorTrainees: MatPaginator;
  @ViewChild(MatSort) sortTrainees: MatSort;
  resultsTraineesLength = 0;
  //End New Table



  applyFilterTrainees(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTrainees.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceTrainees.paginator) {
      this.dataSourceTrainees.paginator.firstPage();
    }
  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    this.dataSourceTrainees.paginator = this.paginatorTrainees;
    this.dataSourceTrainees.sort = this.sortTrainees;
  }




  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ActivityMatchingComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.currentCourse = this.data.courseId;
  }

  ngOnInit(): void {
    this.newCase = this.formBuilder.group({
      id: null,
      courseId: this.currentCourse,
      type: ActivityType.MatchBetween,
      matching: [''],
      title: [{ value: '', disabled: this.loading }, Validators.required],
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: null,
      addedAt: moment(),
      activityId: null,
      title: [{ value: '', disabled: this.loading }, Validators.required],
      addedBy: parseInt(this.profile.userId),
      pairs: ['']
    });

    if (!this.data.isNew) {
      this.modify(this.data.oldItem);

    }
  }


  loadAnswers() {
    this.dataSourceTrainees = new MatTableDataSource(this.answers)
    this.resultsTraineesLength = (this.answers) ? this.answers.length : 0;
    this.dataSourceTrainees.paginator = this.paginatorTrainees;
    this.dataSourceTrainees.sort = this.sortTrainees;

  }


  onSubmit() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    this.newSolution.patchValue({
      title: this.newCase.get('title').value
    })
    this.newSolution.patchValue({
      pairs: this.answers
    });
    this.newCase.patchValue({
      matching: this.newSolution.value
    })

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    this._course.addActivty(this.newCase.value).subscribe((data) => {
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });

      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })

      this.dialogRef.close({ data: true });

    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }


  onSubmitInternally() {
    this.submitted = true;
    if (this.newCase.invalid) {
      return;
    }
    this.loading = true;
    this.newSolution.patchValue({
      title: this.newCase.get('title').value
    })
    this.newSolution.patchValue({
      pairs: this.answers
    });
    this.newCase.patchValue({
      matching: this.newSolution.value
    })

    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    // console.log(this.newCase.value);
    this._course.addActivty(this.newCase.value).subscribe((data) => {
      this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });

      this.cd.markForCheck();
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ACTIVITYADDEDSUCCESSFULLY'), type: AlertType.Success }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })

      // this.dialogRef.close({ data: true });

    }, (e) => {
      this._store.dispatch({ type: '[Profile] Stop Loading' });
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    })
  }




  modify(dataItem) {

    // console.log(dataItem);


    this.answers = dataItem.matching.pairs;
    this.loadAnswers();
    this.solutionId = dataItem.matching.Id | dataItem.matching.id;

    this.newCase = this.formBuilder.group({
      id: dataItem.id | dataItem.Id,
      courseId: this.currentCourse,
      type: ActivityType.MatchBetween,
      matching: dataItem.matching,
      title: dataItem.matching.title,
      isMarked: false
    });

    this.newSolution = this.formBuilder.group({
      id: dataItem.matching.id | dataItem.matching.Id,
      addedAt: dataItem.matching.addedAt,
      activityId: dataItem.matching.activityId,
      title: dataItem.matching.title,
      addedBy: dataItem.matching.addedBy,
      pairs: dataItem.matching.pairs
    })
    // console.log(this.newCase.value);

    // $('#newCase').modal('toggle')
    // if ($('#newCase').hasClass('in')) {
    //   $('#newCase').modal('toggle');
    // }

  }




  deleteAnswer(dataItem) {
    var id = dataItem.id | dataItem.Id;
    this.answers = this.answers.filter(x => x != dataItem);
    this.loadAnswers();
    this.cd.markForCheck();
    // console.log(this.answers);
    this._course.deletePair(id).subscribe((data) => {
      this._course.getAllActivities(ActivityType.MatchBetween, this.currentCourse).subscribe((data: any[]) => {
        this.items = data;
        this.cd.markForCheck();
      })
    });

  }



  addNewPair() {
    const dialog = this._dialog.open(ActivityMatchingSentenceComponent, {
      direction: 'rtl',
   
      data: { courseId: this.currentCourse, isNew: true, oldItem: null, parentId: this.solutionId }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.answers.push(data.data);
        if (this.newCase.get('id').value) {
          // this.onSubmitInternally();
        }
        this.loadAnswers();
      }
    })
  }



  modifyAnswer(dataItem) {
    const dialog = this._dialog.open(ActivityMatchingSentenceComponent, {
      direction: 'rtl',
      data: { courseId: this.currentCourse, isNew: false, oldItem: dataItem, parentId: this.solutionId }
    })
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        // this.answers.push(data.data);
        if (this.answers.filter(x => { return x === data.data })) {
          // console.log('yes');
          let currentAnswer = this.answers.filter(x => { return x === data.data });
          currentAnswer = data.data;
        }
        if (this.newCase.get('title').value) {
          this.onSubmitInternally();
        }
        this.loadAnswers();
      }
    })
  }



}
