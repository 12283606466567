import { createReducer, on, createFeatureSelector, createSelector, ActionReducer, Action } from '@ngrx/store';
import * as reportActions from './reports.actions';



export interface reportState {
  isLoading: boolean;
  totalCourses: number;
  totalPublishedCourses: number;
  coupons: any[];
  tickets: any[];
  totalUnpublishedCourses: number;
  totalVideos: number;
  totalFiles: number;
  totalcoupondiscount: number;
  totalVideosLength: number;
  totalConfirmedUsers: number;
  totalUnConfirmedUsers: number;
  totalCoupons: number;
  totalTrainees: number;
  totalPayments: number;
  totalPaidOrders: number;
  totalFreeEnrollments: number;
  mostpopularcourse: string;
}




const initialState = {
  isLoading: false,
  totalCourses: undefined,
  totalPublishedCourses: undefined,
  totalUnpublishedCourses: undefined,
  totalVideos: undefined,
  totalFiles: undefined,
  totalVideosLength: undefined,
  totalConfirmedUsers: undefined,
  totalcoupondiscount: undefined,
  totalCoupons: undefined,
  totalUnConfirmedUsers: undefined,
  coupons: [],
  tickets: [],
  totalTrainees: undefined,
  totalPayments: undefined,
  totalPaidOrders: undefined,
  mostpopularcourse: undefined,
  totalFreeEnrollments: undefined
};



const _reducer = createReducer(
  initialState,
  on(reportActions.getTotalConfirmedUsersSuccess, (state, { total }) => {
    return { ...state, totalConfirmedUsers: total }
  }),
  on(reportActions.getTotalCoursesSuccess, (state, { total }) => {
    return { ...state, totalCourses: total }
  }),
  on(reportActions.getTotalFreeEnrollmentSuccess, (state, { total }) => {
    return { ...state, totalFreeEnrollments: total }
  }),
  on(reportActions.getTotalNoOfVideosSuccess, (state, { total }) => {
    return { ...state, totalVideos: total }
  }),
  on(reportActions.getTotalNoOfFilesSuccess, (state, { total }) => {
    return { ...state, totalFiles: total }
  }),
  on(reportActions.getTotalPaidOrdersSuccess, (state, { total }) => {
    return { ...state, totalPaidOrders: total }
  }),
  on(reportActions.getTotalAmountOfCouponDiscountSuccess, (state, { total }) => {
    return { ...state, totalcoupondiscount: total }
  }),
  on(reportActions.getTotalPaymentsSuccess, (state, { total }) => {
    return { ...state, totalPayments: total }
  }),
  on(reportActions.getTotalPublishedCoursesSuccess, (state, { total }) => {
    return { ...state, totalPublishedCourses: total }
  }),
  on(reportActions.getTotalUsedCouponsSuccess, (state, { total }) => {
    return { ...state, totalCoupons: total }
  }),
  on(reportActions.getTotalTraineesSuccess, (state, { total }) => {
    return { ...state, totalTrainees: total }
  }),
  on(reportActions.getTotalUnconfirmedUsersSuccess, (state, { total }) => {
    return { ...state, totalUnConfirmedUsers: total }
  }),
  on(reportActions.getTotalUnpublishedCoursesSuccess, (state, { total }) => {
    return { ...state, totalUnpublishedCourses: total }
  }),
  on(reportActions.getTotalVideosLengthSuccess, (state, { total }) => {
    return { ...state, totalVideosLength: total }
  }),
  on(reportActions.getTheMostPopularCourseSuccess, (state, { course }) => {
    return { ...state, mostpopularcourse: course }
  }),
  on(reportActions.getMyCouponsSuccess, (state, { coupons }) => {
    return { ...state, coupons: coupons }
  }),
  on(reportActions.getMyTicketsSuccess, (state, { tickets }) => {
    return { ...state, tickets: tickets }
  }),
)



export const reportReducerFeatureKey = 'repots';
export const reportFeature = createFeatureSelector<reportState>(reportReducerFeatureKey);



export const totalTrainees = createSelector(
  reportFeature,
  (state: reportState) => state.totalTrainees
)

export const totalCourses = createSelector(
  reportFeature,
  (state: reportState) => state.totalCourses
)



export const totalCoupons = createSelector(
  reportFeature,
  (state: reportState) => state.totalCoupons
)


export const totalCouponDiscounts = createSelector(
  reportFeature,
  (state: reportState) => state.totalcoupondiscount
)


export const mostPopularCourse = createSelector(
  reportFeature,
  (state: reportState) => state.mostpopularcourse
)


export const totalPublishedCourses = createSelector(
  reportFeature,
  (state: reportState) => state.totalPublishedCourses
)

export const totalUnpublishedCourses = createSelector(
  reportFeature,
  (state: reportState) => state.totalUnpublishedCourses
)


export const totalVideosAmount = createSelector(
  reportFeature,
  (state: reportState) => state.totalVideos
)


export const totalFilesAmount = createSelector(
  reportFeature,
  (state: reportState) => state.totalFiles
)



export const totalVideoLength = createSelector(
  reportFeature,
  (state: reportState) => state.totalVideosLength
)


export const totalPaidOrders = createSelector(
  reportFeature,
  (state: reportState) => state.totalPaidOrders
)


export const totalPayments = createSelector(
  reportFeature,
  (state: reportState) => state.totalPayments
)


export const totalFreeEnrollments = createSelector(
  reportFeature,
  (state: reportState) => state.totalFreeEnrollments
)


export const totalConfirmedUsers = createSelector(
  reportFeature,
  (state: reportState) => state.totalConfirmedUsers
)


export const totalUnconfirmedUsers = createSelector(
  reportFeature,
  (state: reportState) => state.totalUnConfirmedUsers
)



export const myCoupons = createSelector(
  reportFeature,
  (state: reportState) => state.coupons
)



export const myTickets = createSelector(
  reportFeature,
  (state: reportState) => state.tickets
)





export function persistStateReducer(_reducer: ActionReducer<reportState>) {
  const localStorageKey = '__reports';
  return (state: reportState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}


export function reportReducer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<reportState>) {
  return (state: reportState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}



