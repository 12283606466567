import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../core/services';
import { AlertType } from '../../../core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
declare var $: any;

export interface CourseDialogData {
  isNew: boolean
  oldCourse: any
  tags: any[]
}




@Component({
  selector: 'sm-newtrainerdialog',
  templateUrl: './newtrainerdialog.component.html',
  styleUrls: ['./newtrainerdialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewtrainerdialogComponent implements OnInit {

  get xf() { return this.newCourse.controls; }
  newCourse: UntypedFormGroup;
  loading = false;
  profile;
  submitted = false;
  button: string = '';
  isNew = true;
  oldCourse: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: CourseDialogData,
    private dialogRef: MatDialogRef<NewtrainerdialogComponent>, private _dialog: MatDialog,
    private _translate: TranslateService, private _user: UserService, private cd: ChangeDetectorRef, private formBuilder: UntypedFormBuilder) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }

  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      Id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      profileImage: [{ value: '', disabled: this.loading }],
      phone: [{ value: '', disabled: this.loading }],
      email: [{ value: '', disabled: this.loading }],
      info: [{ value: '', disabled: this.loading }],
      moreInfo: [{ value: '', disabled: this.loading }],
      groupId: 2,
      notes: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
      addedDate: new Date()
    });
    // console.log(this.data);

    this.isNew = this.data.isNew;
    if (!this.isNew) {
      this.oldCourse = this.data.oldCourse;
      this.modify(this.oldCourse);
      this.button = this._translate.instant('TRAINERS.MODIFYTRAINER');
    }

  }

  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;
    // this.newCourse.patchValue({
    //   info: $('#summernote').summernote('code')
    // })
    // this.newCourse.patchValue({
    //   moreInfo: $('#summernote1').summernote('code')
    // })
    // console.log(this.newCourse.value);

    this._user.addOrModifyTrainer(this.fileToUpload, this.newCourse.value).subscribe((data) => {
      this.newCourse.reset();
      this.submitted = false;
      this.button = this._translate.instant('TRAINERS.ADDTRAINER')

      this.dialogRef.close({ status: true });

    }, (e) => {
      var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED') + '' + e.error, type: AlertType.Error }
      this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
    });
  }

  editorConfigs: any = {
    'uploader': { 'insertImageAsBase64URI': true },
    'language': 'ar'
  }

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      info: val
    });
    this._editorData = val;
  }

  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newCourse.patchValue({
      moreInfo: val
    });
    this._editorData1 = val;
  }

  modify(dataItem) {
    // console.log(dataItem);


    this.button = this._translate.instant('TRAINERS.MODIFYTRAINER')
    // var blob = null
    // var xhr = new XMLHttpRequest()
    // xhr.open("GET", "path/to/file")
    // xhr.responseType = "blob"

    // xhr.onload = function () {
    //   blob = xhr.response

    // }
    // xhr.send()
    // $('#summernote').summernote('code', dataItem.info);
    // $('#summernote1').summernote('code', dataItem.moreInfo);
    $('#profile').css('background-image', 'url(' + dataItem.profileImage + ')').addClass('hasImage');
    this.newCourse.patchValue({
      Id: (dataItem.Id) ? dataItem.Id : dataItem.id | dataItem.Id,
      name: dataItem.name,
      profileImage: dataItem.profileImage,
      phone: dataItem.phone,
      email: dataItem.email,
      info: dataItem.info,
      moreInfo: dataItem.moreInfo,
      notes: dataItem.notes,
      addedBy: parseInt(this.profile.userId),
    });
  }

  fileToUpload: File


  upload() {
    $('#mediaFile').click();
  }


  addFile(e) {
    // console.log('lele');

    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(reader.result);
        $('#profile').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }


}
