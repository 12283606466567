import { Trainer } from './../_models/trainers';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';



@Injectable()
export class UserService {
  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string,
  ) {
    // console.log(oidcSecurityService);

  }

  private _trainers = new BehaviorSubject<Trainer[]>([]);


  getTrainers() {
    return this._trainers;
  }


  updateTrainers(id?: number) {
    if (id) {
      var currentTrainers = this._trainers.getValue();
      if (currentTrainers.length == 0) {
        this.getAllTrainers().subscribe((data) => {
          if (data.length != 0) {
            this._trainers.next(data);
            this.updateTrainers(id);
          }

        });
      }
      else {
        var currentCateogry = currentTrainers.find(x => x.Id == id || x.id == id);
        if (!currentCateogry) {
          this.getTrainerById(id).subscribe((data: Trainer) => {
            currentTrainers.push(data);
            this._trainers.next(currentTrainers);
          })
        } else {
          currentTrainers = currentTrainers.filter(x => x.id != id);
          // console.log(currentTrainers);

          this.getTrainerById(id).subscribe((data: Trainer) => {
            if (data) {
              currentTrainers.push(data);
            }

            this._trainers.next(currentTrainers);
          })
        }
      }
    } else {

      var currentTrainers = this._trainers.getValue();
      // console.log("NUMBER " + currentTrainers);
      if (currentTrainers.length == 0) {
        // console.log("XY");

        this.getAllTrainers().subscribe((data) => {
          if (data.length != 0) {
            this._trainers.next(data);
            this.updateTrainers(id);
          }
        });
      }
    }

  }



  addOrModifyTrainer(file: File, data: Trainer) {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(data));
    // console.log(input.get('data'));

    return this.http.post(this.apiUrl + '/api/users/trainer/', input, { headers: this.jwt2().headers, reportProgress: true, observe: 'events' });
  }




  getAllTrainers(): Observable<Trainer[]> {
    return this.http.get<Trainer[]>(this.apiUrl + '/api/users/trainers', this.jwt());
  }
  getAllRequestName(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/cname_requests', this.jwt());
  }
  changeName(form) {
    return this.http.post(this.apiUrl + '/v1/cname_request', form, this.jwt());
  }
  getAllPanelUsers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/panelusers', this.jwt());
  }

  getAllModules(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/settings/allmodules', this.jwt());
  }

  changePassword(form) {
    return this.http.post(this.apiUrl + '/v1/changepassword', form, this.jwt());
  }


  getUserPermissions(userId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/settings/userpermissions/' + userId, this.jwt());
  }


  setUserSuperadminForPanel(userId) {
    return this.http.get(this.apiUrl + '/api/users/setsuperadmin/' + userId, this.jwt());
  }


  addUserToPanel(attrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/users/usertopanel', attrs, this.jwt());
  }


  updatePermissions(attrs) {
    return this.http.post(this.apiUrl + '/api/users/updatepermissions', attrs, this.jwt());
  }

  changepasswordx(model): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/account/changepassword', model, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }
  reactivate(email) {
    return this.http.get(this.apiUrl + '/api/users/reactivate/' + email, this.jwt());
  }



  getTrainerById(trainerId): Observable<Trainer> {
    return this.http.get<Trainer>(this.apiUrl + '/api/users/trainer/' + trainerId, this.jwt());
  }

  deleteTrainer(trainerId) {
    return this.http.delete(this.apiUrl + '/api/users/trainer/' + trainerId, this.jwt());
  }


  deleteUser(userId) {
    return this.http.delete(this.apiUrl + '/api/users/user/' + userId, this.jwt());
  }


  suspenduser(userId) {
    return this.http.get(this.apiUrl + '/api/users/suspenduser/' + userId, this.jwt());
  }

  unSuspenduser(userId) {
    return this.http.get(this.apiUrl + '/api/users/unsuspenduser/' + userId, this.jwt());
  }


  test() {
    return this.http.get(this.apiUrl + '/api/users/test', this.jwt());
  }


  getAllUsersFroModis() {
    return this.http.get(this.apiUrl + '/api/users/usrformod', this.jwt());
  }


  activateUserByManagement(userid) {
    return this.http.get(this.apiUrl + '/api/users/activatex/' + userid, this.jwt());
  }
  activateTraineeByManagement(userid) {
    return this.http.get(this.apiUrl + '/api/users/activatet/' + userid, this.jwt());
  }

  resendActivationMail(email) {
    return this.http.get(this.apiUrl + '/account/reactivate/' + email, this.jwt());
  }


  checkUserStatus(email) {
    return this.http.get(this.apiUrl + '/api/users/checkstatus/' + email, this.jwt());
  }
  getCustomerGeneralReport(email) {
    return this.http.get(this.apiUrl + '/api/users/custoemrreports/' + email, this.jwt());
  }





  addCourseToUser(email) {
    return this.http.post(this.apiUrl + '/api/users/coursetouser', email, this.jwt());
  }

  removeCourseFromUser(email) {
    return this.http.post(this.apiUrl + '/api/users/rcoursetouser', email, this.jwt());
  }



  getAllGroups() {
    return this.http.get(this.apiUrl + '/api/users/groups/', this.jwt());
  }



  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));
    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));
    let token = profile.token;
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // console.log(token);

    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }

}
