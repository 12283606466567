import { createAction, props } from '@ngrx/store';





export const getTotalCourses = createAction('[Reports] Get Total Courses');
export const getTotalUnpublishedCourses = createAction('[Reports] Get Total Unpublished Courses');
export const getTotalPublishedCourses = createAction('[Reports] Get Total published Courses');
export const getTotalNoOfVideos = createAction('[Reports] Get Total No Of Videos');
export const getTotalVideosLength = createAction('[Reports] Get Total Videos Length');
export const getTotalFilesInLMS = createAction('[Reports] Get Total Files');
export const getTotalUnconfirmedUsers = createAction('[Reports] Get Total Unconfirmed Users');
export const getTotalConfirmedUsers = createAction('[Reports] Get Total Confirmed Users');
export const getTotalTrainees = createAction('[Reports] Get Total Trainees');
export const getTotalPayments = createAction('[Reports] Get Total Payments');
export const getTotalPaidOrders = createAction('[Reports] Get Total Paid Orders');
export const getTotalFreeEnrollment = createAction('[Reports] Get Total Free Course Enrollments');
export const getTheMostPopularCourse = createAction('[Reports] Get The Most Popular Course');
export const getTotalUsedCoupons = createAction('[Reports] Get Total Used Coupons');

export const getTotalAmountOfCouponDiscount = createAction('[Reports] Get Total Amount Of Coupon Discount');
export const getTotalAmountOfCouponDiscountSuccess = createAction('[Reports] Get Total Amount Of Coupon Discount Success', props<{ total: number }>());


export const getTotalUsedCouponsSuccess = createAction('[Reports] Get Total Used Coupons Success', props<{ total: number }>());
export const getTheMostPopularCourseSuccess = createAction('[Reports] Get The Most Popular Success', props<{ course: string }>());
export const getTotalCoursesSuccess = createAction('[Reports] Get Total Courses Success', props<{ total: number }>());
export const getTotalUnpublishedCoursesSuccess = createAction('[Reports] Get Total Unpublished Courses Success', props<{ total: number }>());
export const getTotalPublishedCoursesSuccess = createAction('[Reports] Get Total published Courses Success', props<{ total: number }>());
export const getTotalNoOfVideosSuccess = createAction('[Reports] Get Total No Of Videos Success', props<{ total: number }>());
export const getTotalNoOfFilesSuccess = createAction('[Reports] Get Total Files Success', props<{ total: number }>());
export const getTotalVideosLengthSuccess = createAction('[Reports] Get Total Videos Length Success', props<{ total: number }>());
export const getTotalUnconfirmedUsersSuccess = createAction('[Reports] Get Total Unconfirmed Users Success', props<{ total: number }>());
export const getTotalConfirmedUsersSuccess = createAction('[Reports] Get Total Confirmed Users Success', props<{ total: number }>());
export const getTotalTraineesSuccess = createAction('[Reports] Get Total Trainees Success', props<{ total: number }>());
export const getTotalPaymentsSuccess = createAction('[Reports] Get Total Payments Success', props<{ total: number }>());
export const getTotalPaidOrdersSuccess = createAction('[Reports] Get Total Paid Orders Success', props<{ total: number }>());
export const getTotalFreeEnrollmentSuccess = createAction('[Reports] Get Total Free Course Enrollments Success', props<{ total: number }>());


export const getMyCoupons = createAction('[Reports] Get My Coupons', props<{ userId: number }>());
export const getMyCouponsSuccess = createAction('[Reports] Get My Coupons Success', props<{ coupons: any[] }>());


export const getMyTickets = createAction('[Reports] Get My Tickets', props<{ userId: number }>());
export const getMyTicketsSuccess = createAction('[Reports] Get My Tickets Success', props<{ tickets: any[] }>());
