import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as _reducer from '../../../core/state/_courses/course.reducer'
import { CoursesService } from '../../../../../src/app/core/services';

@Component({
  selector: 'sm-add-affliate',
  templateUrl: './add-affliate.component.html',
  styleUrls: ['./add-affliate.component.css']
})
export class AddAffliateComponent implements OnInit {
linkValue:number=1
adPlace:{value:number,title:string}[]=[
  {value:1,title:"الكل"},
  {value:2,title:"الصفحه الرئيسية"},
  {value:3,title:"المتجر"},
  {value:4,title:"المنتجات"},
  {value:5,title:"سلة المشتريات"},
  {value:6,title:"إتمام الشراء"},

]
courses: any[]=[]

  ads:FormGroup = new FormGroup({
    id: new FormControl(this.data?this.data.id:null) ,
    title: new FormControl(this.data?this.data.title:''),
    body: new FormControl(this.data?this.data.body:''),
    fontColor: new FormControl(this.data?this.data.fontColor:''),
    BGColor: new FormControl(this.data?this.data.BGColor:''),
    date: new FormControl(this.data? new Date(this.data.date).toISOString().split('T')[0]:new Date().toISOString().split('T')[0]),
    isActive:new FormControl(this.data?this.data.isActive:false),
    link:new FormControl(this.data?this.data.link:1),
    place: new FormControl(this.data?this.data.place:1),
    courseId:new FormControl(this.data?this.data.courseId:null) ,
    linkUrl:new FormControl(this.data?this.data.linkUrl:null) ,
  })
  
constructor( public dialogRef: MatDialogRef<AddAffliateComponent> , @Inject(MAT_DIALOG_DATA) public data: any ,private _coursesService:CoursesService){
console.log(this.data);

    // ✅ Detect changes when user selects a different radio button
  
    this.ads.get('link')?.valueChanges.subscribe(value => {
      console.log("User selected:", value);
      this.linkValue=value
    });
}




ngOnInit(): void {
this.getAllCourses()
  
}

getAllCourses(){
  this._coursesService.AllCourses().subscribe((res)=>{
    this.courses = res

  })
}
closeModal(){
  
  this.dialogRef.close()
}

onSubmit(infoData:FormGroup){
this._coursesService.addLink(infoData.value).subscribe((res)=>{
  console.log(res);
  this.closeModal()
})
}

deleteAdd(){
  this._coursesService.deletLink(this.data.id).subscribe((res)=>{
    console.log(res);
    this.closeModal()
  })
  
}
}
