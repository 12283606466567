import { ActivatedRoute } from '@angular/router';



import { Injectable, Inject, EventEmitter } from '@angular/core';
import * as signalR from '@aspnet/signalr';

import { CoursesService, UserService } from '.';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../auth/authentication.service';

export enum ConnectionState {
  Connecting = 1,
  Connected = 2,
  Reconnecting = 3,
  Disconnected = 4
}
@Injectable()
export class ConnectionService {

  constructor(@Inject('API_URL') private apiUrl: string, private _store: Store<any>, private authenticationService: AuthenticationService,
    private _course: CoursesService, private _route: ActivatedRoute, private _user: UserService,) {

    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();

  }

  private createConnection() {
    const user = this.authenticationService.userValue;
    // const isLoggedIn = user && user.jwtToken;
    if (typeof window !== 'undefined') {
      this._hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(this.apiUrl + "/mastery", { accessTokenFactory: () => user.jwtToken })
        .configureLogging(signalR.LogLevel.Information)
        .build();
    }
  }

  private startConnection(): void {
    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        this.connectionEstablished.emit(true);
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        setTimeout(function () { this.startConnection(); }, 5000);
      });
  }


  private _hubConnection: signalR.HubConnection;
  connectionEstablished = new EventEmitter<Boolean>();
  private connectionIsEstablished = false;




  Invoke(channel, ...data) {
    this._hubConnection.invoke(channel, ...data);
  }

  private registerOnServerEvents(): void {

    this._hubConnection.on("Categories", data => {
      // this._course.updateAllCategories(data);
    });
    this._hubConnection.on("Trainers", data => {
      // console.log(data);

      this._user.updateTrainers(data);
    });
    this._hubConnection.on("Unites", data => {
      // console.log(data);
      var xdata = data.split('-');
      // this._store.dispatch({ type: '[Courses] Load All Courses' });
    });
    this._hubConnection.on("Courses", data => {
      // console.log(data);
      this._store.dispatch({ type: '[Courses] Load All Courses' });
    });
    this._hubConnection.on("Curriculums", data => {
      var url = window.location.href;
      var urls = url.split('/');
      // console.log();

      // if (!isNaN(+urls[urls.length - 2]))
      // this._course.updateCurrentCurricula(+urls[urls.length - 2]);

      // this._course.updateAllCurriculas(data);
    });
    this._hubConnection.on("PermissionsUpdated", data => {

      const currentUser = JSON.parse(localStorage.getItem('mprofile'));
      if (data == currentUser.userId) {
        console.log("WE UPDATED INFO");
        console.log('' + data);
        this.authenticationService.getMyPermissions();
      }
    })
    this._hubConnection.on("CoursesInCurriculas", data => {
      var url = window.location.href;
      var urls = url.split('/');
      // console.log(urls[urls.length - 2]);

      // this._course.updateCurrentCurricula(+urls[urls.length - 2]);
    });


    // var firstId = + this._route.parent.snapshot.paramMap.get('id');
    this._hubConnection.on("Materials", data => {

    });
  }


}
