import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sm-user-course-progress',
  templateUrl: './user-course-progress.component.html',
  styleUrls: ['./user-course-progress.component.css']
})
export class UserCourseProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
