import { B2BProfile, B2BDeaprtments, B2BTimelineDTO } from './../_models/b2b';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { Affiliate } from '../_models';

@Injectable()
export class B2BService {

  private b2bCurrentProfile = new BehaviorSubject<B2BProfile>(null);

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }


  getCurrentB2B() {
    return this.b2bCurrentProfile;
  }

  updateCurrentB2BProfile(b2bId) {
    var attr = {
      id: b2bId
    }
    this.getB2BSubscription(attr).subscribe(data => this.b2bCurrentProfile.next(data));
  }


  AddOrModifyB2BSubscription(attr): Observable<B2BProfile> {
    return this.http.post<B2BProfile>(this.apiUrl + '/api/b2b/subscribe', attr, this.jwt());
  }
  getB2BSubscription(attr): Observable<B2BProfile> {
    return this.http.post<B2BProfile>(this.apiUrl + '/api/b2b/subscription', attr, this.jwt());
  }

  addMessageToTimeline(attr) {
    return this.http.post(this.apiUrl + '/api/b2b/message', attr, this.jwt());
  }
  getAllPendingB2BSubscriptions(): Observable<B2BProfile[]> {
    return this.http.get<B2BProfile[]>(this.apiUrl + '/api/b2b/allpending', this.jwt());
  }
  getAllConnectedB2BSubscriptions(): Observable<B2BProfile[]> {
    return this.http.get<B2BProfile[]>(this.apiUrl + '/api/b2b/allconnected', this.jwt());
  }
  getAllSignedContractsB2BSubscriptions(): Observable<B2BProfile[]> {
    return this.http.get<B2BProfile[]>(this.apiUrl + '/api/b2b/allsigned', this.jwt());
  }
  getAllActiveB2BSubscriptions(): Observable<B2BProfile[]> {
    return this.http.get<B2BProfile[]>(this.apiUrl + '/api/b2b/allactive', this.jwt());
  }
  getAllSuspenededB2BSubscriptions(): Observable<B2BProfile[]> {
    return this.http.get<B2BProfile[]>(this.apiUrl + '/api/b2b/allsuspended', this.jwt());
  }

  getCommunicationTimeline(customerId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/b2b/timeline/' + customerId, this.jwt());
  }

  deleteB2BSubscription(attr): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/api/b2b/deletesubscription/' + attr, this.jwt());
  }


  importUsersForB2BProfile(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/importusers', attrs, this.jwt());
  }

  importDepartmentsForB2BProfile(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/importdepartments', attrs, this.jwt());
  }


  getAllDepartmentsForB2B(b2bId): Observable<B2BDeaprtments[]> {
    return this.http.get<B2BDeaprtments[]>(this.apiUrl + '/api/b2b/departments/' + b2bId, this.jwt());
  }


  assignCourses(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/assigncourses', attrs, this.jwt());
  }



  updateDepartmentConsultancies(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/departmentconsultancies', attrs, this.jwt());
  }


  getDepartmentConsultancies(attrs) {
    return this.http.get(this.apiUrl + '/api/b2b/departmentconsultancies/' + attrs, this.jwt());
  }

  availableCoursesForDepartment(departmentId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/b2b/coursesfordepartment/' + departmentId, this.jwt());
  }


  deleteCourseFromDepartment(courseId) {
    return this.http.post(this.apiUrl + '/api/b2b/coursefromdepartment', courseId, this.jwt());
  }


  getAllUsersForB2B(b2bId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/b2b/users/' + b2bId, this.jwt());
  }


  usersInDepartment(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/usersindepartment', attrs, this.jwt());
  }
  usersForAddingInDepartment(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/allcompanyusers', attrs, this.jwt());
  }

  assignUserToDepartment(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/assigntodepartment', attrs, this.jwt());
  }



  assigneCertificateToCompany(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/certificate', attrs, this.jwt());
  }
  getAllCertificateForCompany(b2bId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/b2b/certificates/' + b2bId, this.jwt());
  }
  deleteCertificateFromCompany(courseId) {
    return this.http.delete(this.apiUrl + '/api/b2b/certificate/' + courseId, this.jwt());
  }

  updateCompanySettings(file: File[], data: any) {
    let input = new FormData();
    if (file && file.length != 0) {
      input.append("file", file[0]);
    }
    input.append("jsonData", JSON.stringify(data));


    return this.http.post(this.apiUrl + '/api/b2b/companysettings', input, this.jwt2());
  }


  getCompanySettingsFile(b2bId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/b2b/companysettings/' + b2bId, this.jwt());
  }

  getAllConsultancyRequests(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/b2b/consultancyrequests' , this.jwt());
  }

  addContractToB2BCustomer(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/contract', attrs, this.jwt());
  }


  acceptMeeting(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/acceptconsultancy', attrs, this.jwt());
  }


  declineconsultancy(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/declineconsultancy', attrs, this.jwt());
  }





  getNumberOfDepartmentsForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberofdepartments/' + b2bId, this.jwt());
  }

  getNumberOfUsersForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberofusers/' + b2bId, this.jwt());
  }

  getNumberOfCertificatesForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberofcertificates/' + b2bId, this.jwt());
  }

  getNumberOfTemplatesForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberoftemplates/' + b2bId, this.jwt());
  }

  getNumberOfCoursesForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberofcourses/' + b2bId, this.jwt());
  }

  getNumberOfPackagesForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberofpackages/' + b2bId, this.jwt());
  }

  getNumberOfConsultanciesForB2B(b2bId): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/api/b2b/numberofconsultancies/' + b2bId, this.jwt());
  }



  addPolicy(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/policy', attrs, this.jwt());
  }


  resortPolicies(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/sortpolicies', attrs, this.jwt());
  }

  deletePolicy(attrs) {
    return this.http.delete(this.apiUrl + '/api/b2b/policy/' + attrs, this.jwt());
  }

  getAllPolicies() {
    return this.http.get(this.apiUrl + '/api/b2b/policies', this.jwt());
  }



  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


}
