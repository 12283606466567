
import { TranslateService } from '@ngx-translate/core';
import { NotfiyService, SettingsService } from './../../../core/services';
import { ReportsService } from './../../../core/services/reports.service';
import { FileBankDTO, FileTypes } from './../../../core/_models/materials';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { RTL } from '@progress/kendo-angular-l10n';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { COUCategories } from './../../../core/_models';
import { SelectableSettings, SelectableMode, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { CoursesService, SystemService } from './../../../core/services';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { environment } from './../../../../environments/environment';
import * as _filesReducer from '../../../core/state/_courses/files.reducer'
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
declare var Noty: any;
import Bounce from 'bounce.js';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerComponent } from '../../components/player/player/player.component';
declare var $: any;
import { AlertType } from './../../../core/_models/system';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';
import { DomSanitizer } from '@angular/platform-browser';



export interface FileData {
  type: FileTypes;
}


@Component({
  selector: 'sm-selecfiledialog',
  templateUrl: './selecfiledialog.component.html',
  styleUrls: ['./selecfiledialog.component.scss'],
  providers: [{ provide: RTL, useValue: true }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelecfiledialogComponent implements OnInit, AfterViewInit {

  @Input() toSelect = true;
  public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;
  newSearch: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  allCategories$: Observable<FileBankDTO[]>;
  public selectableSettings: SelectableSettings;
  public mode: SelectableMode = 'single';
  public state: State = {};
  button: string = 'رفع ملف';
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    // this.gridData = process(this.allCategories, this.state);
  }
  lang = 'ar'
  p = 1;
  searchText = '';
  public gridData: GridDataResult;
  fileType: FileTypes;


  constructor(public dialogRef: MatDialogRef<SelecfiledialogComponent>, private _cookie: CookieService, private _router: Router, private _courses: CoursesService, private authenticationService: AuthenticationService, private _report: ReportsService, private _settings: SettingsService,
    private _dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: FileData, private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef, private _store: Store<any>, private _translate: TranslateService, private ngNoty: NotfiyService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }
    this.fileType = data.type;

    const user = this.authenticationService.userValue;
    const isLoggedIn = user && user.jwtToken;
    this.config.headers = {
      "Authorization": "Bearer " + user.jwtToken
    }
    this.config.chunkSize = 600000000;
  }
  ngAfterViewInit(): void {

    //$(".menu-w").removeClass("menu-layout-full");
    //  $(".menu-w").addClass("menu-layout-mini");
  }


  ngOnInit() {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    var urlName = this._translate.instant('SYSTEM.VIDEOBANK');
    this._store.dispatch({ type: '[SYSTEM] Navigate To New Destination Level1', url: 'videobank', name: urlName })
    // this._store.dispatch({ type: '[SYSTEM] Navigate To New Destination Level2', url: '/courses/course/' + (data.id | data.Id), name: data.name })


    // this._system.loadData();
    this._store.dispatch({ type: '[Files] Load All Files' });
    this.allCategories$ = this._store.pipe(select(_filesReducer.getFileBankDTO));
    this.cd.markForCheck();
    this.newCourse = this.formBuilder.group({
      files: ''
    });
    this.loading = true;

    this.loading = false;
    // this._courses.getAllVideos().subscribe((data: FileBankDTO[]) => {
    //   this.allCategories = data;

    // });
    // // this.cd.detectChanges();

    this.newSearch = this.formBuilder.group({
      keyword: ''
    })
  }

  get xf() { return this.newCourse.controls; }
  get xsearch() { return this.newSearch.controls; }


  progress: number = 0;
  @Output() public selectedVideo = new EventEmitter<FileBankDTO>();
  @Output() public didselectVideo = new EventEmitter<FileBankDTO>();
  @Input() selected = false;

  selectVideo(event) {
    this.selectedVideo.emit(event);
    this.selected = true;
    var infos = document.getElementById('infos');
    this.dialogRef.close(event);
  }

  onSubmit() {

    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;
    if (this.fileToUpload == null) return;



    for (let i = 0; i < this.files.length; i++) {
      const element = this.filesToUpload[i];
      var infos = document.getElementById('infos-' + i);
      element.length = infos.textContent
    }
    this.newCourse.patchValue({
      files: this.files
    })
    // console.log(this.newCourse.value);
    // console.log(this.fileToUpload);

    this._courses.addOrModifyVideo(this.fileToUpload, this.newCourse.value)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            this.cd.markForCheck();
            // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // console.log('User successfully created!', event.body);


            this.uploader.removeUpload(0);
            this.newCourse.reset();
            this.files = [];
            this.fileToUpload = [];
            this.submitted = false;
            this.button = 'رفع ملف'
            this.progress = 0;
            this.loading = false;
            this.newCourse.patchValue({
              uploadDate: new Date(),
              uploadedBy: parseInt(this.profile.userId)
            })
            this.cd.markForCheck();
          // setTimeout(() => {

          // }, 1500);

        }
      })

  }


  submitted2 = true;

  onSearch() {

    this.submitted2 = true;
    if (this.newSearch.invalid) {
      return;
    }
    this.loading = true;
    this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    this.notfound = false;
    var attrs = {
      keyword: this.newSearch.get('keyword').value,
      type: this.fileType
    }
    if (this.fileType != 7) {
      this._report.searchForFile(attrs).subscribe((data: any[]) => {
        this.file = [];
        this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
        if (data.length != 0) {
          // console.log(data);
          this.file = data;
          this.cd.markForCheck();
        } else if (data.length == 0) {
          this.notfound = true;
          this.cd.markForCheck();
          setTimeout(() => {
            this.notfound = false;
            this.cd.markForCheck();
          }, 3000);

        }
        this.loading = false;
        this.submitted2 = false;
      })
    } else {
      this._report.searchForFileOldWay(this.newSearch.get('keyword').value).subscribe((data: any[]) => {
        this.file = [];
        this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
        if (data.length != 0) {
          // console.log(data);
          this.file = data;
          this.cd.markForCheck();
        } else if (data.length == 0) {
          this.notfound = true;
          this.cd.markForCheck();
          setTimeout(() => {
            this.notfound = false;
            this.cd.markForCheck();
          }, 3000);

        }
        this.loading = false;
        this.submitted2 = false;
      })
    }


  }

  file: any[];
  notfound = false;
  selectedFile: any;
  link: any;

  @Input() toSelectFile = false;
  usageList: string[] = []
  selectFile(f) {
    this.selectedFile = null;
    var link = f.name;

    this.selectedFile = null;
    var link = f.name;
    this._settings.getSignedURL(link).subscribe((data: string) => {

      this.selectedFile = f;
      this.link = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      this.cd.markForCheck();
    });


    this._report.getFileUsageByFileid(f.id | f.Id).subscribe((data: any[]) => {
      this.usageList = data;
      this.cd.markForCheck();
    })

  }

  // deleteContact({ dataItem }) {

  // }



  config: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    url: environment.API + '/api/courses/video/',
    maxFilesize: null,
    chunking: true,
    chunkSize: 20000000,
    timeout: 3000000
  };;



  totalTime(e) {
    // console.log(e);
    var fileId = (this.selectedFile.Id) ? this.selectedFile.Id : this.selectedFile.id;
    this._courses.updatevideolength({ fileId: fileId, length: e }).subscribe((data) => {
      // console.log('Time Updated');

    })
  }


  fileToUpload: File[] = [];
  @ViewChild(FileuploaderComponent, { static: false }) uploader: FileuploaderComponent;
  infos = [];
  filesToUpload = [];

  getFile(event) {
    this.infos = event;
    var files = [];
    for (let i = 0; i < event.length; i++) {
      const element = event[i];

      this.loading = true;
      // console.log(element);
      var type = this.applyFileType(element.type);
      this.fileToUpload.push(element);
      this.getFileDuration(element, i);
      this.filesToUpload.push({
        id: null,
        fileName: element.name,
        name: '',
        fileSize: element.size,
        type: type,
        uploadDate: new Date(),
        uploadedBy: parseInt(this.profile.userId)
      })
      this.loading = false;
    }
    // this.newCourse.patchValue({
    //   files: files
    // })
  }



  getFileDuration(file, i) {
    var video = document.createElement('video');
    video.preload = 'metadata';
    // var duration = 0;
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      // console.log(i);
      // console.log(duration);

      var infos = document.getElementById('infos-' + i);
      infos.textContent = "";
      infos.textContent += duration;
    }
    video.src = URL.createObjectURL(file);
  }

  allContentType = [
    { id: 1, name: "Video" },
    { id: 2, name: "Image" },
    { id: 3, name: "PDF" },
    { id: 4, name: "Text" },
    { id: 5, name: "Audio" },
    { id: 6, name: "Zip" },
    { id: 7, name: "Other" },
    { id: 8, name: "Unfomratted" }
  ]



  applyFileType(type: any): FileTypes {
    // console.log(type);

    var fileType: FileTypes = FileTypes.Unfomratted;
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return FileTypes.Text;
        break;
      case "application/pdf":
        return FileTypes.PDF;
        break;
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
      case "image/bmp":
      case "image/gif":
      case "image/TIFF":
        return FileTypes.Image;
        break;
      case "video/mp4":
      case "video/avi":
      case "video/mpeg":
      case "video/mkv":
      case "video/3gp":
      case "video/OGG":
      case "video/quicktime":
      case "video/WEBM":
      case "video/FLV":
      case "video/MPEG-TS":
      case "video/mov":
        return FileTypes.Video;
        break;
      default:
        return FileTypes.Unfomratted;
        break;
    }
    this.newCourse.patchValue({
      type: fileType
    })
  }

  // $('.image-upload-wrap').bind('dragover', function() {
  //   $('.image-upload-wrap').addClass('image-dropping');
  // });
  // $('.image-upload-wrap').bind('dragleave', function() {
  //   $('.image-upload-wrap').removeClass('image-dropping');
  // });


  files: File[] = [];

  onSelect(event) {
    // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }



  onUploadError(e) {
    // console.log(e);

  }
  onUploadSuccess(e) {
    console.log(e);

  }


  addedFiles(e) {
    console.log(e);

  }



  deleteContact(f) {

    let self = this;
    const dialogRef = this._dialog.open(AlertconfirmmessageComponent, {
      height: '200px',
      width: '400px'
    })
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var id = (f.Id) ? f.Id : f.id;
        self._courses.deleteVideoFromBank(id).subscribe((data: any[]) => {
          self.selectedFile = null;
          self.file = [];
          self.newSearch.reset();
          self.cd.markForCheck();
          var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.ELEMENTDELTED'), type: AlertType.Success }
          this._dialog.open(AlertMessageComponent, {
            data: alertData,
            height: '200px',
            width: '400px'
          })
        })
      } else {
      }
    })

  }



}
