export class Trainer {
  Id?: number;
  id?: number;
  userId?: number;
  groupId: number;
  name: string;
  email: string;
  phone: string;
  info: string;
  moreInfo: string;
  profileImage: string;
  notes: string;
  addedBy: string;
  addedDate: string;
  updateAt: string;
}
