import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RTL } from '@progress/kendo-angular-l10n';
import { DragulaService } from 'ng2-dragula';
import { B2BService, NotfiyService, SystemService } from '../../../core/services';
declare var Noty: any;
declare var $: any;
import Bounce from 'bounce.js';
import * as moment from 'moment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { B2BProfile, B2BStatus, B2BCommunicationLogDTO, B2BTimelineDTO } from '../../../core/_models';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';

export interface b2bProfileData {
  isNew: boolean,
  oldItem: any
}

@Component({
  selector: 'sm-b2bprofiledialog',
  templateUrl: './b2bprofiledialog.component.html',
  styleUrls: ['./b2bprofiledialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class B2bprofiledialogComponent implements OnInit {



  newCourse: UntypedFormGroup;
  submitted = false;
  loading = false;
  hasError = false;
  errorMsg = '';
  get xf() { return this.newCourse.controls; }
  companySize = [
    {
      id: 1,
      name: '1-9'
    },
    {
      id: 2,
      name: '10-50'
    },
    {
      id: 3,
      name: '51-100'
    },
    {
      id: 4,
      name: '101-400'
    },
    {
      id: 5,
      name: '400+'
    }
  ]
  phonex
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(undefined, [Validators.required])
  });

  lang
  profile;



  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private cd: ChangeDetectorRef,
    private _translate: TranslateService, private ngNoty: NotfiyService, private _store: Store<any>, private _service: B2BService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private dragulaService: DragulaService
    , public dialogRef: MatDialogRef<B2bprofiledialogComponent>, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: b2bProfileData
  ) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
  }

  ngOnInit(): void {

    this.newCourse = this.formBuilder.group({
      id: null,
      groupId: 4,
      companyName: [{ value: '', disabled: this.loading }, Validators.required],
      companyEmail: [{ value: '', disabled: this.loading }, Validators.required],
      contactName: [{ value: '', disabled: this.loading }, Validators.required],
      contactPhone: [{ value: '', disabled: this.loading }, Validators.required],
      userId: this.profile.userId,
      appliedOn: moment.utc(new Date()),
      type: [''],
      jobTitle: [{ value: '', disabled: this.loading }, Validators.required],
      companySize: [{ value: '', disabled: this.loading }],
      country: [{ value: '', disabled: this.loading }, Validators.required],
      city: [{ value: '', disabled: this.loading }, Validators.required],
      status: B2BStatus.Pending,
    });

    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }

  modify(item) {

    this.phonex = item.contactPhone;
    this.newCourse = this.formBuilder.group({
      id: item.id | item.Id,
      groupId: item.groupId,
      userId: item.userId,
      appliedOn: moment.utc(item.appliedOn).toDate(),
      status: item.status,
      companyName: [{ value: item.companyName, disabled: this.loading }, Validators.required],
      companyEmail: [{ value: item.companyEmail, disabled: this.loading }, Validators.required],
      contactName: [{ value: item.contactName, disabled: this.loading }, Validators.required],
      type: (item.companySize) ? this.companySize.find(x => x.id == item.companySize).name : null,
      contactPhone: [{ value: item.contactPhone, disabled: this.loading }],
      jobTitle: [{ value: item.jobTitle, disabled: this.loading }, Validators.required],
      companySize: [{ value: item.companySize, disabled: this.loading }],
      country: [{ value: item.country, disabled: this.loading }, Validators.required],
      city: [{ value: item.city, disabled: this.loading }, Validators.required],

    });
  }




  onSubmit() {

    if (this.phonex)
      this.newCourse.patchValue({
        contactPhone: this.phonex.e164Number
      })

    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }


    var size = this.newCourse.get('type').value;
    this.newCourse.patchValue({
      companySize: this.companySize.find(x => x.name == size).id
    })

    this.loading = true;
    // console.log(this.newCourse.value);

    this._service.AddOrModifyB2BSubscription(this.newCourse.value).subscribe((data) => {
      this.dialogRef.close();
    })
  }



}
