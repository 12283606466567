import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter1'
})
export class FilterPipe1 implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    //  console.log(items,searchText);

    return items.filter(it => {

      if (typeof (it.email) === 'undefined')
        it.email = '';






      return it.email.includes(searchText)
        ;
    });
  }
}
