import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AlertType } from '../../../core/_models/system';
import { CoursesService } from '../../../core/services';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { UnitDialog } from '../courseunitdialog/courseunitdialog.component';

@Component({
  selector: 'sm-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss']
})
export class AssignmentDialogComponent implements OnInit {

  newCourse: UntypedFormGroup;
  public profile: any = {};
  submitted = false;
  get xf() { return this.newCourse.controls; }
  allConditions: any[] = [
    {
      id: 1,
      name: 'WhenInstructorAccept'
    },
    {
      id: 2,
      name: 'WhenSubmitAnAnswer'
    }
  ];
  editorConfigs = {
    useSearch: false,
    language: "ar",
    enter: "br",
    direction: "rtl",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 250,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      body: val
    });
    this._editorData = val;
  }


  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  currentCourse;
  loading = false;
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  locale = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'rtl', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'موافق', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'فترة مخصصة',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: 0 // first day is monday
  }

  onSubmit() {
    // console.log(this.newCourse.get('selected').value);

    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }

    this.loading = true;
    this.newCourse.patchValue({
      availableFrom: this.newCourse.get('selected').value.startDate.format('LLL'),
      availableTo: this.newCourse.get('selected').value.endDate.format('LLL')
    })

    this._course.addOrModifyAssignementForCourse(this.newCourse.value).subscribe((data) => {
      var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.COURSEADDEDSUCCESSFULLY'), type: AlertType.Success }
      const dialog = this._dialog.open(AlertMessageComponent, {
        data: alertData,
        height: '200px',
        width: '400px'
      })
      dialog.afterClosed().subscribe(() => {
        this.dialogRef.close({ data: true });
      })


      this.newCourse.patchValue({
        addedBy: parseInt(this.profile.userId),
        courseId: this.currentCourse.Id | this.currentCourse.id,
      })
      this.submitted = false;
    })
  }

  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    private _store: Store<any>, public dialogRef: MatDialogRef<AssignmentDialogComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UnitDialog) {
    this.currentCourse = data.courseId;
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
  }


  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      rule: [{ value: '', disabled: this.loading }, Validators.required],
      name: [{ value: '', disabled: this.loading }, Validators.required],
      availableFrom: [{ value: '', disabled: this.loading }],
      availableTo: [{ value: '', disabled: this.loading }],
      notes: [{ value: '', disabled: this.loading }],
      selected: [''],
      body: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
      courseId: this.currentCourse.Id | this.currentCourse.id,
    });

    if (!this.data.isNew) {
      this.modify(this.data.oldItem)
    }
  }


  modify(dataItem) {
    const dates = { startDate: moment(dataItem.availableFrom), endDate: moment(dataItem.availableTo) };


    this.newCourse.patchValue({
      id: dataItem.id | dataItem.id | dataItem.Id,
      rule: this.allConditions[dataItem.rule - 1].name,
      name: dataItem.name,
      body: dataItem.body,
      availableFrom: moment(dataItem.availableFrom),
      availableTo: moment(dataItem.availableTo),
      notes: dataItem.notes,
      selected: dates,
      addedBy: dataItem.addedBy,
      courseId: this.currentCourse.Id | this.currentCourse.id,
    })
  }

}
