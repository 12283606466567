import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CoursesService, NotfiyService, AffiliateService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';
declare var $: any;
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';


export function checkIfCodeUsed(isUsed): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !isUsed ? { checkIfCodeUsed: { value: control.value } } : null;
  };
}

export interface CouponDialogData {
  isNew: boolean
  oldCoupon: any
  tags: any[]
}


@Component({
  selector: 'sm-newcoupondialog',
  templateUrl: './newcoupondialog.component.html',
  styleUrls: ['./newcoupondialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewcoupondialogComponent implements OnInit {


  DiscountCategory = [
    { name: 'Discount', id: 1, },
    { name: 'FreeProduct', id: 2, },
    { name: 'FreeShipping', id: 3, }]
  DiscountScope = [
    { name: 'OnOrder', id: 1, },
    { name: 'OnCheapestProduct', id: 2, },
    { name: 'OnSpecificProduct', id: 3, }]
  DiscountType = [
    { name: 'Percentage', id: 1, },
    { name: 'FixedAmount', id: 2, },]

  allReasons: any[] = [
    {
      id: 1,
      name: "BankTransfer"
    },
    {
      id: 2,
      name: "CourseInOffer"
    },
    {
      id: 3,
      name: "ManagementOrder"
    },
    {
      id: 4,
      name: "Reparation"
    },
    {
      id: 5,
      name: "Affiliate"
    },
    {
      id: 6,
      name: "CashPayment"
    },
    {
      id: 7,
      name: "PaypalPayment"
    },
  ];

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  locale = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'rtl', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'موافق', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'فترة مخصصة',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: 0 // first day is monday
  }



  public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  lang = 'ar'
  allAffiliates = [];
  oldCoupon: any;
  editorConfigs = {
    useSearch: false,
    language: "ar",
    enter: "br",
    direction: "rtl",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 300,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    toolbarInlineForSelection: false,
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      notes: val
    });
    this._editorData = val;
  }
  // allCourses = [];
  freeProduct = false;
  appliedCourse = false;
  discount = false;
  percent = false;
  fixedAmount = false;
  minDate: Dayjs = dayjs();

  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private _course: CoursesService, private _router: Router, public ngNoty: NotfiyService,
    @Inject(MAT_DIALOG_DATA) public data: CouponDialogData, private dialogRef: MatDialogRef<NewcoupondialogComponent>,
    private _dialog: MatDialog, private _courses: CoursesService, private cd: ChangeDetectorRef, private _translate: TranslateService, private _store: Store<any>, private _report: ReportsService
    , private _affiliate: AffiliateService, private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
  }
  get xf() { return this.newCourse.controls; }


  ngOnInit(): void {
    var todate = moment(new Date(), 'DD.MM.YYYY H:mm:ss');
    this._affiliate.getAllActiveAffiliates().subscribe((data) => {
      this.allAffiliates = data;
      this.allAffiliates.push({
        id: this._translate.instant('COUPONS.PUBLICCOUPON'),
        name: this._translate.instant('COUPONS.PUBLICCOUPON'),
      });
      this.cd.markForCheck();
    })
    // this._courses.AllCourses().subscribe((data) => {
    //   this.allCourses = data;
    //   this.cd.markForCheck();
    // })
    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      code: [{ value: '', disabled: !this.data.isNew }, [checkIfCodeUsed(false)]],
      // code: [{ value: '', disabled: !this.data.isNew }, [Validators.required, checkIfCodeUsed(false)]],
      numberOfOrders: [{ value: '', disabled: this.loading }, Validators.required],
      reason: [{ value: '', disabled: this.loading }, Validators.required],
      affiliatex: [{ value: '', disabled: this.loading }],
      category: [{ value: '', disabled: this.loading }],
      totalDiscount: [{ value: '', disabled: this.loading }],
      freeproductId: [{ value: '', disabled: this.loading }],
      affiliateId: 0,
      limitiationOfUsage: [{ value: '', disabled: this.loading }],
      thecategory: [{ value: '', disabled: this.loading }],
      limitNo: [{ value: '', disabled: this.loading }],
      amount: [{ value: '', disabled: this.loading }],
      notes: [{ value: '', disabled: this.loading }],
      thecourses: [{ value: null }],
      percent: [{ value: '', disabled: this.loading }, Validators.required],
      type: [{ value: '', disabled: this.loading }],
      startingDate: [{ value: '', disabled: this.loading },],
      endingDate: [{ value: '', disabled: this.loading },],
      selected: [{ value: '', disabled: this.loading }],
      active: [{ value: false, disabled: this.loading },],
      shipping: [{ value: false, disabled: this.loading },],
      // courseId: (this.currentCourse.id|this.currentCourse.Id),
      addedAt: new Date(),
      addedBy: parseInt(this.profile.userId)
    });
    this.onChanges();

    if (!this.data.isNew) {
      this.oldCoupon = this.data.oldCoupon;
      this.modify(this.oldCoupon);
    }
  }


  onChanges(): void {
    if (this.data.isNew) {
      this.newCourse.get('code').valueChanges.subscribe((val) => {
        if (val.length > 3) {
          this._course.checkIfCodeUsed(val).subscribe((data) => {
            if (data) {
              this.newCourse.get('code').setErrors({ email: true });
            } else {
              this.newCourse.get('code').setErrors(null);
            }
          })
        }
      })
    }

    this.newCourse.get('category').valueChanges.subscribe((val) => {

      if (val) {
        if (val === 'FreeProduct') {
          this.freeProduct = true;
          this.appliedCourse = false;
          this.discount = false;
          this.cd.markForCheck();
        }
        if (val === 'Discount') {
          this.freeProduct = false;
          this.appliedCourse = false;
          this.discount = true;
        }
      }
    })
    this.newCourse.get('type').valueChanges.subscribe((val) => {

      if (val) {
        if (val === 'Percentage') {
          this.percent = true;
          this.fixedAmount = false;
          this.cd.markForCheck();
        }
        if (val === 'FixedAmount') {
          this.percent = false;
          this.fixedAmount = true;
          this.cd.markForCheck();
        }
      }
    })

    this.newCourse.get('affiliatex').valueChanges.subscribe(val => {
      if (val != '' && val != null && val != this._translate.instant('COUPONS.PUBLICCOUPON')) {
        this.newCourse.patchValue({
          affiliateId: this.allAffiliates.find(x => x.name.includes(val)).Id | this.allAffiliates.find(x => x.name.includes(val)).id
        })
      }
      else {
        this.newCourse.patchValue({
          affiliateId: 0
        })
      }
    });

    this.newCourse.get('selected').valueChanges.subscribe((val) => {
      // console.log(val);
      if (val) {
        this.newCourse.patchValue({
          startingDate: this.newCourse.get('selected').value.startDate.format('LLL'),
          endingDate: this.newCourse.get('selected').value.endDate.format('LLL')
        })
      }
    })
  }

  upload() {
    $('#mediaFile').click();
  }

  fileToUpload


  addFile(e) {
    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(reader.result);
        $('#attachment').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }


  onSubmit() {
    // console.log(this.newCourse.controls);
    // console.log(this.newCourse);


    this.submitted = true;
    // console.log(this.newCourse.value);

    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    var subAttr: any[] = [];
    // console.log(this.theWholeCourses);
    var val = this.newCourse.get('affiliatex').value;
    if (val != '' && val != null && val != this._translate.instant('COUPONS.PUBLICCOUPON')) {
      this.newCourse.patchValue({
        affiliateId: this.allAffiliates.find(x => x.name.includes(val)).Id | this.allAffiliates.find(x => x.name.includes(val)).id
      })
    }
    else {
      this.newCourse.patchValue({
        affiliateId: 0
      })
    }

    if (!this.data.isNew) {
      this.newCourse.get('code').enable();
      this.newCourse.patchValue({
        code: this.code
      });
    }
    var files = [this.fileToUpload];
    // console.log(this.newCourse.value);
    this._course.addOrModifyCoupon(files, this.newCourse.value).subscribe((data: any) => {

      this.dialogRef.close({ id: data.id });

      var id = data.Id | data.id;

    })

  }


  getPercentage(e) {
    // console.log(e);
    if (e.target.value > 100) {
      e.target.value = 100
    }
  }

  code = ''

  modify(dataItem) {
    const dates = { startDate: moment(dataItem.startingDate), endDate: moment(dataItem.endingDate) };
    this.code = dataItem.code;
    this.newCourse.patchValue({
      id: dataItem.id | dataItem.Id,
      name: dataItem.name,
      code: dataItem.code,
      // code: [{ value: dataItem.code, disabled: this.loading }, [Validators.required, checkIfCodeUsed(false)]],
      numberOfOrders: dataItem.numberOfOrders,
      category: dataItem.category,
      percent: dataItem.percent,
      totalDiscount: dataItem.totalDiscount,
      freeproductId: dataItem.freeproductId,
      type: dataItem.type,
      selected: dates,
      reason: dataItem.reason,

      startingDate: new Date(dataItem.startingDate),
      endingDate: new Date(dataItem.endingDate),
      active: dataItem.active,
      shipping: dataItem.shipping,
      addedAt: dataItem.addedAt,
      addedBy: dataItem.addedBy
    })
    this.newCourse.get('code').setErrors(null);
  }





}
