import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityLogicalSentenceComponent } from '../activity-logical-sentence/activity-logical-sentence.component';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';


@Component({
  selector: 'sm-activity-decision-answers',
  templateUrl: './activity-decision-answers.component.html',
  styleUrls: ['./activity-decision-answers.component.scss']
})
export class ActivityDecisionAnswersComponent implements OnInit {

  profile: any;
  editorConfigs = {
    useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };
  currentCourse
  newAnswer: UntypedFormGroup;
  get xd() { return this.newAnswer.controls; }
  solutionId
  submitted = false;
  loading = false;
  currentAnswer

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newAnswer.patchValue({
      answer: val
    });
    this._editorData = val;
  }
  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newAnswer.patchValue({
      explanation: val
    });
    this._editorData1 = val;
  }



  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityDecisionAnswersComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.currentCourse = this.data.courseId;
    this.solutionId = data.parentId;

  }

  ngOnInit(): void {
    this.newAnswer = this.formBuilder.group({
      id: null,
      solutionId: this.solutionId,
      answer: [{ value: '', disabled: this.loading }, Validators.required],
      explanation: [{ value: '', disabled: this.loading }],
      isCorrect: [{ value: false, disabled: this.loading }],
    })
    if (!this.data.isNew) {
      this.currentAnswer = this.data.oldItem;
      this.modifyAnswer(this.data.oldItem);
    }
  }



  onSubmitAnswerS() {
    if (this.newAnswer.invalid) return;
    // this.answers.push(this.newAnswer.value);
    this.dialogRef.close({ data: this.newAnswer.value });
    // console.log(this.answers);
  }


  modifyAnswer(dataItem) {
    // console.log(dataItem);


    this.newAnswer.patchValue({
      id: dataItem.id | dataItem.Id,
      solutionId: dataItem.solutionId,
      answer: dataItem.answer,
      explanation: dataItem.explanation,
      isCorrect: dataItem.isCorrect
    });
  }


}
