import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PlayerService } from './player.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerComponent } from './player/player.component';
import { ContextmenuComponent } from './contextmenu/contextmenu.component';
import { QuestionComponent } from './question/question.component';


@NgModule({
  declarations: [PlayerComponent, ContextmenuComponent, QuestionComponent],
  imports: [
    CommonModule, HttpClientModule, FormsModule
  ],
  exports: [PlayerComponent, ContextmenuComponent, QuestionComponent],
  // entryComponents: [PlayerComponent, ContextmenuComponent, QuestionComponent],
  providers: [PlayerService]
})
export class PlayerModule { }
