import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AffiliateService } from '../../../core/services';
import { Products } from '../../../core/_models';

export interface ComissionsData {
  allCourses: any[]
  isNew: boolean
  oldItem: any
}

@Component({
  selector: 'sm-commissiondialog',
  templateUrl: './commissiondialog.component.html',
  styleUrls: ['./commissiondialog.component.scss']
})
export class CommissiondialogComponent implements OnInit {


  newCourse: UntypedFormGroup;
  submitted = false;
  allCourses = [];

  constructor(private _affiliate: AffiliateService, private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CommissiondialogComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ComissionsData) { }

  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      courseId: 0,
      parentx: [{ value: '' }],
      productType: Products.Course,
      commission: [{ value: '' }],
    });
    this.allCourses = this.data.allCourses;
    if (!this.data.isNew) {
      this.modify(this.data.oldItem);
    }
  }


  onSubmit() {
    this.submitted = true;
    // console.log(this.newCourse.value);

    if (this.newCourse.invalid) {
      return;
    }

    var category = this.newCourse.get('parentx').value;
    if (category != '' && category != null && category != 'null') {
      this.newCourse.patchValue({
        courseId: this.allCourses.find(x => x.name == category).id | this.allCourses.find(x => x.name == category).Id
      });
    } else {
      return;
    }
    // console.log(this.newCourse.value);

    this._affiliate.addCommissionToProduct(this.newCourse.value).subscribe((data) => {

      this.dialogRef.close();
    })

  }

  modify(dataItem) {
    this.newCourse.patchValue({
      id: dataItem.id | dataItem.id | dataItem.Id,
      courseId: dataItem.courseId,
      productType: Products.Course,
      commission: dataItem.commission,
      parentx: (dataItem.course != null) ? this.allCourses.find(x => x.id == dataItem.courseId || x.Id == dataItem.courseId).name : null,
    });
  }


}
