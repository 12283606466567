import { Consultants, Consultancy } from './../_models/consultancy';
import { Curriculum, CURCourses, CURRules } from '../_models/curriculums';
import { Assignments } from '../_models/assignments';
import { Rules } from '../_models/rules';
import { Quizz } from '../_models/quizzes';
import { Materials, questionWithAnswers, FileBankDTO } from '../_models/materials';
import { Unites } from '../_models/unites';
import { Course } from '../_models/courses';
import { AppConfig } from './app.config';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError, EMPTY } from 'rxjs';
import { COUCategories } from '../_models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { observe } from '@progress/kendo-angular-grid/dist/es2015/utils';

@Injectable()
export class ConsultancyService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string,) {
  }


  getConsultanciesForConsultant(consutlantId) {
    return this.http.get(this.apiUrl + '/api/consultancy/consforcnas/' + consutlantId, this.jwt());
  }

  getConsultancies() {
    return this.http.get<any[]>(this.apiUrl + '/api/consultancy/consultancies', this.jwt());
  }

  getConsultancyById(consultancyId) {
    return this.http.get(this.apiUrl + '/api/consultancy/consultancy/' + consultancyId, this.jwt());
  }


  getConsultants() {
    return this.http.get(this.apiUrl + '/api/consultancy/consultants', this.jwt());
  }


  acceptRequest(requestId) {
    return this.http.get(this.apiUrl + '/api/consultancy/acceptrequest/' + requestId, this.jwt());
  }


  completeMileStone(requestId) {
    return this.http.get(this.apiUrl + '/api/consultancy/finish_milestone/' + requestId, this.jwt());
  }


  startbbbSession(requestId) {
    return this.http.get(this.apiUrl + '/api/consultancy/startbbbsessiob/' + requestId, this.jwt());
  }

  getbbbSessionByMeetingID(meetingID) {
    return this.http.get(this.apiUrl + '/api/consultancy/bbbsession/' + meetingID, this.jwt());
  }

  joinBBBSessionAsModerator(meetingID) {
    return this.http.get(this.apiUrl + '/api/consultancy/joinbbbsession/' + meetingID, this.jwt());
  }


  sendReminderToAttend(meetingID) {
    return this.http.get(this.apiUrl + '/api/consultancy/sendreminder/' + meetingID, this.jwt());
  }


  endBBBSessionForConsultancy(meetingID) {
    return this.http.get(this.apiUrl + '/api/consultancy/endsession/' + meetingID, this.jwt());
  }

  getMaterialQuestions(consultantId) {
    return this.http.get(this.apiUrl + '/api/consultancy/consultant/' + consultantId, this.jwt());
  }


  getSessionsRequestsForConsultancy(consultantId) {
    return this.http.get(this.apiUrl + '/api/consultancy/sessionrequests/' + consultantId, this.jwt());
  }


  getAllMilesStonesForConsultancy(consultantId) {
    return this.http.get(this.apiUrl + '/api/consultancy/milestones/' + consultantId, this.jwt());
  }


  addInfoToCourse(attrs) {
    return this.http.post(this.apiUrl + '/api/consultancy/info', attrs, this.jwt());
  }

  getCourseInfo(courseId) {
    return this.http.get(this.apiUrl + '/api/consultancy/info/' + courseId, this.jwt());
  }
  getNumberOfCompletedSessionsByConsultant(courseId) {
    return this.http.get(this.apiUrl + '/api/consultancy/completed_sessions/' + courseId, this.jwt());
  }
  getTotalPaymentsForConsultant(courseId) {
    return this.http.get(this.apiUrl + '/api/consultancy/total_payments/' + courseId, this.jwt());
  }
  getTotalConsultancyTimeForConsultant(courseId) {
    return this.http.get(this.apiUrl + '/api/consultancy/total_time/' + courseId, this.jwt());
  }
  getAmountConsultanciesForConsultant(courseId) {
    return this.http.get(this.apiUrl + '/api/consultancy/amount_consultancies/' + courseId, this.jwt());
  }

  deleteCourseInfo(infoId) {
    return this.http.delete(this.apiUrl + '/api/consultancy/info/' + infoId, this.jwt());
  }


  resortCourseInfo(attrs) {
    return this.http.post(this.apiUrl + '/api/consultancy/sortinfos', attrs, this.jwt());
  }


  deleteConsultant(consultantId) {
    return this.http.delete(this.apiUrl + '/api/consultancy/consultant/' + consultantId, this.jwt());
  }

  deleteConsultancy(consultancyId) {
    return this.http.delete(this.apiUrl + '/api/consultancy/consultancy/' + consultancyId, this.jwt());
  }



  addOrModifyConsultant(file: File, data: Consultants) {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(data));
    // return this.http.post(this.apiUrl + '/api/consultancy/consultant/', input, { headers: this.jwt2().headers, reportProgress: true, observe: 'events' });
    return this.http.post(this.apiUrl + '/api/consultancy/consultant/', input, this.jwt2());
  }

  addOrModifyConsultancy(file: File[], data: Consultancy) {
    let input = new FormData();
    if (file && file.length != 0) {
      input.append("file", file[0]);
      input.append("file1", file[1]);
    }
    input.append("jsonData", JSON.stringify(data));
    // return this.http.post(this.apiUrl + '/api/consultancy/consultancy/', input, { headers: this.jwt2().headers, reportProgress: true, observe: 'events' });
    return this.http.post(this.apiUrl + '/api/consultancy/consultancy/', input, this.jwt2());
  }




  updateConsultantCalendar(attrs) {
    return this.http.post(this.apiUrl + '/api/consultancy/updatecalendar', attrs, this.jwt());
  }

  consultantCalendar(consultantId) {
    return this.http.get(this.apiUrl + '/api/consultancy/calendar/' + consultantId, this.jwt());
  }



  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


}
