import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BbbmeetingComponent, MeetinData } from '../../courses/course/bbbmeeting/bbbmeeting.component';
import { ZoommeetingComponent } from '../../courses/course/zoommeeting/zoommeeting.component';

@Component({
  selector: 'sm-managelivestream',
  templateUrl: './managelivestream.component.html',
  styleUrls: ['./managelivestream.component.scss']
})
export class ManagelivestreamComponent implements OnInit {


  courseId
  constructor(@Inject(MAT_DIALOG_DATA) public data: MeetinData, private _dialog: MatDialog, public dialogRef: MatDialogRef<ManagelivestreamComponent>,) { }

  ngOnInit(): void {
    this.courseId = this.data.courseId;
  }



  setMeeting(type: string) {
    switch (type) {
      case 'bbb':
        const dialogRef = this._dialog.open(BbbmeetingComponent, {
          height: '58%',
          width: '65%',
          direction: 'rtl',
          data: { courseId: this.courseId, isNew: true  }
        })
        dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            this.dialogRef.close({ data: true })
          }
        })
        break;
      case 'zoom':
        const dialogRef1 = this._dialog.open(ZoommeetingComponent, {
          height: '58%',
          width: '65%',
          direction: 'rtl',
          data: { courseId: this.courseId , isNew: true }
        })
        dialogRef1.afterClosed().subscribe((data) => {
          if (data) {
            this.dialogRef.close({ data: true })
          }
        })
        break;

      default:
        break;
    }
  }

}
