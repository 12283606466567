export class B2BProfile {
  Id?: number;
  id?: number;
  groupId?: number;
  companyName: string;
  companyPhone: string;
  companyEmail: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  startingAt?: Date;
  endingAt?: Date;
  totalNoOfEnrollments: number;
  activated: boolean;
  userId: number;
  activatedOn: Date;
  appliedOn: Date;

  jobTitle: string;
  companySize: any;
  country: string;
  city: string;
  status: B2BStatus
  contracts?: B2BContractDTO[];
}

export class B2BDeaprtments {
  Id?: number;
  id?: number;
  companyId?: number;
  parentId?: number;
  maximumOfTrainees?: number;
  supervisorId?: number;
  name: string;
  image: string;
  email: string;
  description: string;
}


export class B2BUsers {

  b2bId: number;
  users: any[];
}






export class B2BInDepartmentsDTO {
  Id?: number;
  id?: number;
  departmentId: number;
  userId: number;

}

export class B2BAvailableCourseDTO {
  Id?: number;
  id?: number;
  departmentId: number;
  userId: number;
  companyId: number;
  courseId: number;
}

export class B2BAvailableConsultanciesDTO {
  Id?: number;
  id?: number;
  companyId: number;
  departmentId: number;
  consultantId: number;
  totalTime: number;
  totalSessions: number;

}

export class B2BEnrollInCourseDTO {
  Id?: number;
  id?: number;
  traineeId: number;
  courseId: number;

}

export class B2BCommunicationLogDTO {
  Id?: number;
  id?: number;
  customerId: number;
  type: LogType;
  userId: number;
  date: Date


}

export class B2BTimelineDTO {
  Id?: number;
  id?: number;
  logId: number;
  userId: number;
  originalId: number;
  date: Date
  message: string;
  attachedFile: string;

}

export class B2BContractDTO {
  Id?: number;
  id?: number;
  companyId: number;
  date: Date
  fromDate: Date
  numberOfBusiness: number
  toDate: Date
  numberOfUsers: number;
  numberOfCourses: number;
  numberOfConsultancies: number;
  numberOfPackages: number;
  totalPayment: number;
  userId: number;
}

export class B2BMeetingRoomsDTO {
  Id?: number;
  id?: number;
  name: string;
  userId: number;
  creatorId: number;
  consultantId: number;
  companyId: number;
  departmentId: number;
}
export class B2BMeetingsDTO {
  Id?: number;
  id?: number;
  roomId: number;
  date: Date

  isPublic: boolean
  isRecoreded: boolean
  nameOfMeeting: string;
  meetingID: string;

  meetingLongId: string;
  password: string;
  maxParticipants: number;

  attendeePW: string;
  timetableId: number;
  moderatorPW: string;
  welcome: string;
  logoutURL: string;
  record: boolean;
  duration: number;
  moderatorOnlyMessage: string;
  autoStartRecording: boolean;
  allowStartStopRecording: boolean;
  bannerText: string;
  bannerColor: string;
  meetingName: string;

}

export class B2BMEEFiles {
  Id?: number;
  id?: number;
  fileId: number;
  meetingId: number;

  name: string;


}

export class B2BMEEAttendanceDTO {
  Id?: number;
  id?: number;
  meetingId: number;

  userId: number;
  username: string;

  didAttend: boolean
  date: Date
  leaveDate: Date

}


export enum B2BStatus {
  Pending = 1,
  Connected = 2,
  Defining = 3,
  Signing = 4,
  Active = 5,
  Suspended = 6,
  Terminated = 7,
  Ended = 8
}
export enum LogType {
  Contract = 1,
  Support = 2,
}


export class B2BTemplatesDTO {
  Id?: number;
  id?: number;
  logo: string;
  color: string
  companyId: number;
  image: string;
  link: string;
}
