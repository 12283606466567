import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as _actions from './system.actions';


export interface systemState {
  baseUrl: string;
  level1: string;
  level1Name: string;
  level2: string;
  level2Name: string;
  level3: string;
  level3Name: string;
  level4: string;
  level4Name: string;
  level5: string;
  level5Name: string;
  level6: string;
  level6Name: string;
  level7: string;
  level7Name: string;
  loading: boolean;
  consultantId: any;
}


export const initialState: systemState = {
  baseUrl: environment.SITE,
  level1: null,
  level1Name: null,
  level2: null,
  level2Name: null,
  level3: null,
  level3Name: null,
  level4: null,
  level4Name: null,
  level5: null,
  level5Name: null,
  level6: null,
  level6Name: null,
  level7: null,
  level7Name: null,
  consultantId: null,
  loading: false
}


const _reducer = createReducer(initialState,

  on(_actions.GetBaseURL, (state, { url }) => ({ ...state, baseUrl: url })),
  on(_actions.SetNewURILevel1, (state, { url, name }) => ({ ...state, level1: url, level1Name: name, level2: null, level2Name: null })),
  on(_actions.SetNewURILevel2, (state, { url, name }) => ({ ...state, level2: url, level2Name: name })),
  on(_actions.SetNewURILevel3, (state, { url, name }) => ({ ...state, level3: url, level3Name: name })),
  on(_actions.SetNewURILevel4, (state, { url, name }) => ({ ...state, level4: url, level4Name: name })),
  on(_actions.SetNewURILevel5, (state, { url, name }) => ({ ...state, level5: url, level5Name: name })),
  on(_actions.SetNewURILevel6, (state, { url, name }) => ({ ...state, level6: url, level6Name: name })),
  on(_actions.SetNewURILevel7, (state, { url, name }) => ({ ...state, level7: url, level7Name: name })),


  //Reset Links
  on(_actions.ResetLevel1URL, (state) => ({ ...state, level1: null, level1Name: null })),
  on(_actions.ResetLevel2URL, (state) => ({ ...state, level2: null, level2Name: null })),
  on(_actions.ResetLevel3URL, (state) => ({ ...state, level3: null, level3Name: null })),
  on(_actions.ResetLevel4URL, (state) => ({ ...state, level4: null, level4Name: null })),
  on(_actions.ResetLevel5URL, (state) => ({ ...state, level5: null, level5Name: null })),
  on(_actions.ResetLevel6URL, (state) => ({ ...state, level6: null, level6Name: null })),
  on(_actions.ResetLevel7URL, (state) => ({ ...state, level7: null, level7Name: null })),
  on(_actions.SetConsultantId, (state, { id }) => ({ ...state, consultantId: id })),
  on(_actions.InLoading, (state) => ({ ...state, loading: true })),
  on(_actions.OutLoading, (state) => ({ ...state, loading: false })),
);



export const systemReducerFeatureKey = 'system';
export const systemFeature = createFeatureSelector<systemState>(systemReducerFeatureKey);





export const loadingStatus = createSelector(
  systemFeature,
  (state: systemState) => state.loading
)


export const consultantId = createSelector(
  systemFeature,
  (state: systemState) => state.consultantId
)


export const baseURL = createSelector(
  systemFeature,
  (state: systemState) => state.baseUrl
)
export const level1URL = createSelector(
  systemFeature,
  (state: systemState) => state.level1
)
export const level1URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level1Name
)
export const level2URL = createSelector(
  systemFeature,
  (state: systemState) => state.level2
)
export const level2URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level2Name
)
export const level3URL = createSelector(
  systemFeature,
  (state: systemState) => state.level3
)
export const level3URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level3Name
)
export const level4URL = createSelector(
  systemFeature,
  (state: systemState) => state.level4
)
export const level4URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level4Name
)
export const level5URL = createSelector(
  systemFeature,
  (state: systemState) => state.level5
)
export const level5URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level5Name
)
export const level6URL = createSelector(
  systemFeature,
  (state: systemState) => state.level6
)
export const level6URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level6Name
)
export const level7URL = createSelector(
  systemFeature,
  (state: systemState) => state.level7
)
export const level7URLName = createSelector(
  systemFeature,
  (state: systemState) => state.level7Name
)



export function persistStateReducer(_reducer: ActionReducer<systemState>) {
  const localStorageKey = '__system';
  return (state: systemState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}


export function systemReucer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<systemState>) {
  return (state: systemState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}
