import { QuestionTypes } from ".";

export class ForumsDTO {
  id?: number;
  Id?: number;
  courseId: number;

  name: string;
  description: string;
  displayDescription?: boolean;
  userId: number;
  fromDate?: Date;
  toDate?: Date;
  topics?: TopicDTO[];
}

export class TopicDTO {
  id?: number;
  Id?: number;
  forumId: number;
  userId: number;
  topic: string;
  attachment: string;
  date: Date;

}

export class TopicReplyDTO {
  id?: number;
  Id?: number;
  topicId: number;
  userId: number;
  reply: string;
  attachment: string;
  date: Date;
}

export class GlossaryDTO {
  id?: number;
  Id?: number;
  isPublic: boolean;
  courseId: number;
  name: string;
  description: string;
  displayDescription: boolean;
  approved: boolean;
  allowEditing: boolean;
  duplicates: boolean;
  comments: boolean;
  linking: boolean;
  display?: GlossaryDisplay
  concepts?: GlossaryConceptsDTO[];
}

export class GlossaryConceptsDTO {
  id?: number;
  Id?: number;
  glossaryId: number;
  userId: number;
  concept: string;
  definition: string;
  keywords: string;
  attachment: string;
  fileId?: number;
  date: string;
  approved: boolean;

}

export class WikiDTO {
  id?: number;
  Id?: number;
  courseId: number;
  userId: number;
  name: string;
  description: string;

  date: Date;
  articles?: WikiArticlesDTO[];
  isPublic: boolean;
}

export class WikiArticlesDTO {
  id?: number;
  Id?: number;
  wikiId: number;
  articleName: string;
  userId: number;
  date: Date;
  isPublished: boolean;
  details?: ArticleDetailsDTO[];
}

export class ArticleDetailsDTO {
  id?: number;
  Id?: number;
  articleId: number;
  userId: number;
  header: string;
  body: string;
  date: Date;
  isPublished: boolean;

}




export enum GlossaryDisplay {
  Simple = 1,
  Continuous = 2,
  FullWithAuthor = 3,
  FullWithoutAuthor = 4,
  Encyclopedia = 5,
  EntryList = 6,
  FAQ = 7
}










export class CaseStudiesDTO {
  id?: number;
  Id?: number;
  courseId: number;
  userId: number;
  addedAt: Date;
  fromDate: Date;
  toDate: Date;
  openCase: boolean;
  caseHeader: string;
  caseBody: string;
  solution?: CASSolutionsDTO;
}

export class CASSolutionsDTO {
  id?: number;
  Id?: number;
  caseId: number;
  answers?: CASSOLAnswersDTO[];
}

export class CASSOLAnswersDTO {
  id?: number;
  Id?: number;
  solutionId: number;
  answer: string;
  explanation: string;
  isCorrect: boolean;

}

export class CASAttendanceDTO {
  id?: number;
  Id?: number;
  userId: number;
  caseId?: number;
  solutionId?: number;
  answerId?: number;
  isCorrect: boolean;
  answer: string;
  date: Date;

}



export enum ActivityType {
  DecisionMaking = 1,
  CaseStudies = 2,
  TrueFalse = 3,
  MatchBetween = 4,
  FillSpace = 5,
  Quizz = 6,
  LogicalOrder = 7
}

export class ActivitiesDTO {
  Id?: number
  id?: number
  courseId?: number
  type: ActivityType
  isMarked: boolean
  mark?: number

  decision?: ACTDecisionMakingDTO
  caseStudy?: ACTCaseStudiesDTO
  trueFalse?: ACTTrueFalseDTO
  matching?: ACTMatchBetweenDTO
  filling?: ACTFillSpacesDTO
  quizz?: ACTQuizzDTO
  logicalOrder?: ACTLogicalOrderDTO

}

export class ACTDecisionMakingDTO {
  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  body: string;
  attendanceTable?: ACTDECAttendanceTableDTO[]
}

// Attendance Table 
export class ACTDECAttendanceTableDTO {
  Id?: number
  id?: number
  decisionId?: number
  decision?: ACTDecisionMakingDTO
  userId?: number
  attendanceAt: Date
  opinion: string;
  trainerId?: number
  repliedAt: Date
  reply: string;
  score?: number;
  comments?: ACTDECATTCommentsDTO[]
}
// Comments On Decisions 
export class ACTDECATTCommentsDTO {
  Id?: number
  id?: number
  attendanceId?: number
  attendance?: ACTDECAttendanceTableDTO
  userId?: number
  commentedAt: Date
  comment: string;
}

export class ACTCaseStudiesDTO {

  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  body: string;
  openCase: boolean
  caseHeader: string;
  caseBody: string;
  solution?: ACTCATCASSolutionsDTO
  attendanceTable?: ACTCATCASAttendanceDTO[]
}

export class ACTCATCASSolutionsDTO {
  Id?: number
  id?: number
  caseStudyId?: number
  caseStudy?: ACTCaseStudiesDTO
  answers?: ACTCASSOLAnswersDTO[]
}

export class ACTCASSOLAnswersDTO {
  Id?: number
  id?: number
  solutionId?: number
  answer: string;
  explanation: string;
  isCorrect: boolean
  solution?: ACTCATCASSolutionsDTO
}

export class ACTCATCASAttendanceDTO {
  Id?: number
  id?: number
  userId?: number
  email: string;
  caseId?: number
  solutionId?: number
  answerId?: number
  isCorrect: boolean
  reply: string;
  answer: string;
  date: Date
}

export class ACTTrueFalseDTO {
  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  answers?: ACTTRUAnswersDTO[]
  attendanceTable?: ACTTRUAttendanceDTO[]
}

//Answers 
export class ACTTRUAnswersDTO {
  Id?: number
  id?: number
  questionId?: number
  question?: ACTTrueFalseDTO
  answer: string;
  isCorrect: boolean
}
// Attendance Table 
export class ACTTRUAttendanceDTO {
  Id?: number
  id?: number
  userId?: number
  email: string;
  caseId?: number
  answerId?: number
  isCorrect: boolean
  reply: string;
  answer: string;
  date: Date

}

export class ACTMatchBetweenDTO {
  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  pairs?: ACTMATPairsDTO[]
  attendanceTable?: ACTMATAttendanceDTO[]

}

export class ACTMATPairsDTO {
  Id?: number
  id?: number
  questionId?: number
  first: string;
  second: string;
  question?: ACTMatchBetweenDTO
}

export class ACTMATAttendanceDTO {
  Id?: number
  id?: number
  userId?: number
  email: string;
  questionId?: number
  pairId?: number
  selectedId?: number
  isCorrect: boolean
  reply: string;
  answer: string;
  date: Date

}

export class ACTFillSpacesDTO {
  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  body: string;
  stripped: string;
  words?: ACTFILWordsDTO[]
  attendanceTable?: ACTFILAttendanceDTO[]

}

//Words 
export class ACTFILWordsDTO {
  Id?: number
  id?: number
  questionId?: number
  question?: ACTFillSpacesDTO
  order?: number
  word: string;
}
//Attendance Table 
export class ACTFILAttendanceDTO {
  Id?: number
  id?: number
  userId?: number
  email: string;
  questionId?: number
  isCorrect: boolean
  reply: string;
  answer: string;
  date: Date

}

export class ACTLogicalOrderDTO {
  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  sentences?: [ACTLOOSentencesDTO]
  attendanceTable?: ACTLOOAttendanceDTO[]
}

//Sentences 
export class ACTLOOSentencesDTO {
  Id?: number
  id?: number
  questionId?: number
  question?: ACTLogicalOrderDTO
  order?: number
  sentence: string;

}
//Attendance Table 
export class ACTLOOAttendanceDTO {
  Id?: number
  id?: number
  userId?: number
  email: string;
  questionId?: number

  isCorrect: boolean
  reply: string;
  answer: string;
  date: Date

  orders?: ACTLOOATTOrdersDTO[]
}

export class ACTLOOATTOrdersDTO {
  Id?: number
  id?: number
  attendanceId?: number
  attendance?: ACTLOOAttendanceDTO
  sentenceId?: number
  order?: number

}

export class ACTQuizzDTO {
  Id?: number
  id?: number
  activityId?: number
  activity?: ActivitiesDTO
  addedBy?: number
  addedAt: Date
  title: string;
  name: string;
  notes: string;
  questions?: ACTQUIQuestionsDTO[]
  attendanceTable?: ACTQUIAttendanceDTO[]
}

export class ACTQUIQuestionsDTO {
  Id?: number
  id?: number

  questionType?: QuestionTypes
  question: string;
  score?: number;
  optional: boolean
  quizzId?: number
  answer?: ACTQUEAnswersDTO[]
  pairs?: ACTQUEANSPairsDTO[]
}

export class ACTQUEAnswersDTO {
  Id?: number
  id?: number
  questionId?: number

  answer: string;
  explanation: string;
  isCorrectAnswer: boolean
  question?: ACTQUIQuestionsDTO
}

export class ACTQUEANSPairsDTO {
  Id?: number
  id?: number
  questionId?: number
  first: string;
  second: string;

  question?: ACTQUIQuestionsDTO
}
export class ACTQUIAttendanceDTO {
  Id?: number
  id?: number

  userId?: number
  attendedAt: Date
  finished: boolean
  totalDegree?: number;
  quizzId?: number

  attendanceTable?: ACTQUIATTDetailsDTO[]

}

export class ACTQUIATTDetailsDTO {
  Id?: number
  id?: number
  attendanceId?: number
  questionId?: number
  answerId?: number

  pairId?: number
  selectedId?: number
  degree?: number

  attendance?: ACTQUIAttendanceDTO
  question?: ACTQUIQuestionsDTO
  answer?: ACTQUEAnswersDTO
  pair?: ACTQUEANSPairsDTO
  selected?: ACTQUEANSPairsDTO

}