
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotfiyService, SystemService, UserService } from '../../../core/services';
import Bounce from 'bounce.js';
import { Observable, Subscription } from 'rxjs';
import { AlertType } from './../../../core/_models/system';
import { MatDialog } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';


@Component({
  selector: 'sm-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangepasswordComponent implements OnInit, OnDestroy {

  lang = 'ar'
  profile: any;
  sub$ = new Subscription();
  userId = 0;
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  button = '';

  get xf() { return this.newCourse.controls; }


  constructor(private _translate: TranslateService, private ngNoty: NotfiyService,
    private _dialog: MatDialog,    private cd: ChangeDetectorRef, private _service: UserService,
    private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      this.userId = this.profile.userId;
    }
  }


  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }


  ngOnInit(): void {

    this.newCourse = this.formBuilder.group({
      id: null,
      currentpassword: [{ value: '', disabled: this.loading }, Validators.required],
      repeatpassword: [{ value: '', disabled: this.loading }, Validators.required],
      newpassword: [{ value: '', disabled: this.loading }, Validators.required],
      userId: this.userId,
    });
  }


  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }

    this.loading = true;
    if (this.newCourse.get('newpassword').value != this.newCourse.get('repeatpassword').value) return;




    this.newCourse.patchValue({
      userId: this.profile.userId
    })
    // console.log(this.newCourse.value);

    this._service.changePassword(this.newCourse.value).subscribe((data) => {
      // console.log(data);
      if (data != 0) {
        var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.PASSWORDCHANGED'), type: AlertType.Success }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
      } else {
        var alertData = { title: this._translate.instant('DIALOGS.ERROR'), body: this._translate.instant('DIALOGS.ERRORHAPPENED'), type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
      }

      this.submitted = false;
      this.newCourse.reset();
      // this.button = this._translate.instant('ACCOUNT.CHANGEPASSWORD')
    })
  }



}
