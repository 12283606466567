import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
declare var $: any;


@Component({
  selector: 'sm-uploader',
  templateUrl: './fileuploader.component.html',
  styleUrls: ['./fileuploader.component.css']
})
export class FileuploaderComponent implements OnInit {


  @Input() id = 0;
  constructor() { }

  ngOnInit() {
  }

  @Output() public fileToUpload = new EventEmitter<File>();

  clickOnAdd() {
    $('.file-upload-input-' + this.id).trigger('click');
  }

  @Input() public accept: string = '*/*';
  loading = false;


  readURL(input): File {
    this.loading = true;
    // console.log(input);

    if (input.item && input.item(0)) {
      this.fileToUpload.emit(input);
      var reader = new FileReader();

      let self = this;
      reader.onload = function (e: any) {

        $('.image-upload-wrap-' + self.id).hide();

        $('.file-upload-image-' + self.id).attr('src', e.target.result);
        $('.file-upload-content-' + self.id).show();

        $('.image-title-' + self.id).html(input.item(0).name);
      };

      reader.readAsDataURL(input.item(0));
      this.loading = false;

    } else {
      this.removeUpload(0);
    }

    return input.item(0);
  }


  public removeUpload(id) {

    var uploaders = document.querySelectorAll('.file-upload-content');
    uploaders.forEach((element: Element) => {
      $('.file-upload-content-' + id).hide();
      $('.image-upload-wrap-' + id).show();
    });



  }


}
