export class Coupons {

  id?: number;
  Id?: number;
  name: string;
  code: string;
  numberOfOrders: number;
  limitiationOfUsage: Limitiation;
  limitNo: number;
  shipping: boolean;
  amount: number;
  percent: number;
  type: DiscountType;
  startingDate: Date;
  endingDate: Date;
  active: boolean;
  addedAt: Date;
  addedBy: number;
  totalUsage?: number;
  totalPercent?: number;
  affiliateName: string;

}

export enum Products {
  Course = 1,
  CoursePackage = 2,
  Consultant = 3,
  Coaching = 4,
  Books = 5,
  Package = 6

}
export enum DiscountType {
  ToOrderTotal = 1,
  ToProduct = 2,
  ToCategory = 3,
  ToOrderSubTotal = 4,
  ToBooks = 5,
  ToCoaching = 6,
  ToConsultant = 7,
  ToAll = 8
}

export enum Limitiation {
  Unlimited = 1,
  PerN = 2,
  PerNPerU = 3
}


export class CouponDetails {
  id?: number;
  Id?: number;
  couponId?: number;
  categoryId?: number;
  courseId?: number;
  consultantId?: number;
  coachingId?: number;
  packageId?: number;
}


export class FullCoupon {
  coupon: Coupons;
  details: CouponDetails[];
}
