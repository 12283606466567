import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from '../core/auth/authentication.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {

  constructor(private location: Location,private authenticationService: AuthenticationService, private router: Router) {

    // this.login();
  }
  lang = 'ar'
  ngOnInit() {
    const user = this.authenticationService.userValue;
    if(!user) this.router.navigate(['/login']);

    setTimeout(() => {
      this.router.navigate(['/'])
    }, 2000);
  }

  // login() {
  //   this.oidcSecurityService.authorize();
  // }

  // goback() {
  //   this.location.back();
  // }
}
