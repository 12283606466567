import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sm-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
