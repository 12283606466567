import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { ActivityMatchingSentenceComponent } from '../activity-matching-sentence/activity-matching-sentence.component';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

@Component({
  selector: 'sm-activity-logical-sentence',
  templateUrl: './activity-logical-sentence.component.html',
  styleUrls: ['./activity-logical-sentence.component.scss']
})
export class ActivityLogicalSentenceComponent implements OnInit {


  newAnswer: UntypedFormGroup;
  get xd() { return this.newAnswer.controls; }
  profile
  currentCourse
  solutionId
  loading = false;
  submitted = false;
  currentAnswer



  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ActivityLogicalSentenceComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }

    this.solutionId = data.parentId;
    this.currentCourse = this.data.courseId;
  }

  ngOnInit(): void {

    this.newAnswer = this.formBuilder.group({
      id: null,
      questionId: this.solutionId,
      order: 0,
      sentence: [{ value: '', disabled: this.loading }, Validators.required],
    })
    if (!this.data.isNew) {
      this.currentAnswer = this.data.oldItem;
      this.modifyAnswer(this.data.oldItem);
    }

  }

  onSubmitAnswerS() {

    if (this.newAnswer.invalid) return;
    if (this.newAnswer.get('id').value != null) {
      // var currentAnswer = this.answers.find(x => x.id == this.newAnswer.get('id').value);

      this.currentAnswer.sentence = this.newAnswer.get('sentence').value;

      this.dialogRef.close({ data: this.newAnswer.value });
      return;

    }

    this.newAnswer.patchValue({
      questionId: this.solutionId,
    })
    // console.log(this.newAnswer.value);
    // this.answers.push(this.newAnswer.value);
    this.dialogRef.close({ data: this.newAnswer.value });



  }



  modifyAnswer(dataItem) {
    // console.log(dataItem);


    this.newAnswer.patchValue({
      id: dataItem.id | dataItem.Id,
      questionId: dataItem.questionId,
      sentence: dataItem.sentence,
      second: dataItem.second,

    });
  }






}
