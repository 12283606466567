import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CoursesService, NotfiyService, SystemService } from '../../../core/services';
import { ActivityType } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { matchingActivityData } from '../activity-matching/activity-matching.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';

@Component({
  selector: 'sm-activity-matching-sentence',
  templateUrl: './activity-matching-sentence.component.html',
  styleUrls: ['./activity-matching-sentence.component.scss']
})
export class ActivityMatchingSentenceComponent implements OnInit {

  newAnswer: UntypedFormGroup;
  get xd() { return this.newAnswer.controls; }
  submitted
  profile
  currentCourse;
  editorConfigs = {
    useSearch: false,
    language: "ar",
    enter: "br",
    direction: "rtl",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 200,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };
  solutionId
  loading = false;
  // answers = [];
  currentAnswer
  submittedQ = false;

  _editorData1 = '';
  get editorData1() {
    return this._editorData1;
  }

  set editorData1(val) {
    this.newAnswer.patchValue({
      second: val
    });
    this._editorData1 = val;
  }
  
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newAnswer.patchValue({
      first: val
    });
    this._editorData = val;
  }


  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ActivityMatchingSentenceComponent>, @Inject(MAT_DIALOG_DATA) public data: matchingActivityData, private _dialog: MatDialog,
    private _course: CoursesService, private _router: Router, private _courses: CoursesService,
    private cd: ChangeDetectorRef, private ngNoty: NotfiyService, private _translate: TranslateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder, private _store: Store<any>,) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.currentCourse = this.data.courseId;
    this.solutionId = data.parentId;
   
  }


  ngOnInit(): void {
    this.newAnswer = this.formBuilder.group({
      id: null,
      questionId: this.solutionId,
      first: [{ value: '', disabled: this.loading }, Validators.required],
      second: [{ value: '', disabled: this.loading }, Validators.required],
    })
    if (!this.data.isNew) {
      this.currentAnswer = this.data.oldItem;
      this.modifyAnswer(this.data.oldItem);
    }
  }


  onSubmitAnswerS() {

    if (this.newAnswer.invalid) return;
    if (this.newAnswer.get('id').value != null) {
      // var currentAnswer = this.answers.find(x => x.id == this.newAnswer.get('id').value);

      this.currentAnswer.first = this.newAnswer.get('first').value;
      this.currentAnswer.second = this.newAnswer.get('second').value;
      // this.newAnswer.reset();
      // this.newAnswer.patchValue({
      //   questionId: this.solutionId,
      // })
      this.dialogRef.close({ data: this.newAnswer.value });
      return;

    }

    this.newAnswer.patchValue({
      questionId: this.solutionId,
    })
    // console.log(this.newAnswer.value);
    // this.answers.push(this.newAnswer.value);
    this.dialogRef.close({ data: this.newAnswer.value });

  }


  modifyAnswer(dataItem) {
    // console.log(dataItem);


    this.newAnswer.patchValue({
      id: dataItem.id | dataItem.Id,
      questionId: dataItem.questionId,
      first: dataItem.first,
      second: dataItem.second,

    });
  }


}
