import { Course } from './../../_models';
import { createReducer, on, Action, createSelector, createFeatureSelector, ActionReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as courseActions from './courses.actions';
import { appState } from '../app.state';
import { getMergedRoute } from '../_router/router-state.selectors';


export interface courseState extends EntityState<Course> {
  selectedCourseId?: number;
  isLoading: boolean;
  selectedCourse: Course;
}

export function selectItemId(a: Course): string {
  //In this case this would be optional since primary key is id
  return a.id.toString();
}


export function sortByName(a: Course, b: Course): number {
  return a.name.localeCompare(b.name);
}

export const coursesAdapter: EntityAdapter<Course> = createEntityAdapter<Course>();

// export const defaultItems: cartState = {
//   ids: [],
//   entities: {},
//   selectedItemId: null,
//   total: 0,
// }

const initialState = coursesAdapter.getInitialState({
  selectId: selectItemId,
  sortComparer: sortByName,
  isLoading: false,
  selectedCourseId: null,
  selectedCourse: undefined,
});




const _reducer = createReducer(
  initialState,
  on(courseActions.updateCourse, (state, { course }) => {
    return coursesAdapter.updateOne(course, state);
  }),
  on(courseActions.LoadCorusesSuccess, (state, { courses }) => {
    return coursesAdapter.setAll(courses, state)
  }),
  on(courseActions.GetSelectedCourse, (state, { course }) => {
    return { ...state, selectedCourse: course }
  }),
  on(courseActions.clearSelectedCourse, (state) => {
    return { ...state, selectedCourse: undefined }
  }),
  on(courseActions.addCourseSuccess, (state, { course }) => {
    return coursesAdapter.setOne(course, state)
  }),
  on(courseActions.removeCourseSuccess, (state, { id }) => {
    return coursesAdapter.removeOne(id, state);
  }),
  on(courseActions.updateCourse, (state, { course }) => {

    return coursesAdapter.updateOne(course, state);
  }),
  // on(courseActions.removeCourse, (state, { id }) => {
  //   return coursesAdapter.removeOne(id, state);
  // }),
)





export const courseReducerFeatureKey = 'courses';
export const coursesFeature = createFeatureSelector<courseState>(courseReducerFeatureKey);


export const getSeletedItemId = (state: courseState) => state.selectedCourseId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = coursesAdapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectEntities;

// select the total user count
export const selectUserTotal = selectTotal;


export const getCourses = createSelector(
  coursesFeature, selectAll
)


export const loadingStatus = createSelector(
  coursesFeature,
  (state: courseState) => state.isLoading
)

export const getSelectedCourse = createSelector(
  coursesFeature,
  (state: courseState) => state.selectedCourse
)

// export const getSelectedCourse = createSelector(getCourses, getMergedRoute, (courses, mergedRoute) => mergedRoute.params.id);


export const totalItems = createSelector(
  coursesFeature,
  (state: courseState) => {
    const allItems = Object.values(state.entities);
    return allItems.length
  })


export function persistStateReducer(_reducer: ActionReducer<courseState>) {
  const localStorageKey = '__courses';
  return (state: courseState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}


export function courseReucer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<courseState>) {
  return (state: courseState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}


