import { TicketPriority, TicketType } from "./system";

export class AnnouncementsDTO {
  Id?: number;
  id?: number;
  courseId?: number;
  userId: number;
  groupId: number;
  header: string;
  body: string;
  date: Date;
  type: TicketType
  priority: TicketPriority
}

export class ANNAssignedDTO {
  Id?: number;
  id?: number;
  announcmentId: number;
  userId: number;
  deliveredAt?: Date;
  seenAt?: Date;
}




export class RatingDTO {
  Id?: number;
  id?: number;
  courseId?: number;
  userId?: number;
  rating: Rating
  review: string;
  addedDate?: Date;
  updateAt?: Date;
}
export class SurveyDTO {
  Id?: number;
  id?: number;
  name: string;
  message: string;
  courseId?: number;
  afterCompletion: boolean;
  type: SurveyType
}

export class SURQuestionsDTO {
  Id?: number;
  id?: number;
  surveyId?: number;
  questionType: QuestionTypes
  question: string;
  score: number;
  optional: boolean;
}

export class SURQUEAnswersDTO {
  Id?: number;
  id?: number;
  questionId?: number;
  answer: string;
  isCorrectAnswer: boolean;
}
export class SURAttendanceDTO {
  Id?: number;
  id?: number;
  surveyId?: number;
  userId?: number;
  attendedAt: Date;
  finished: boolean;
  totalDegree: number;
}

export class SURATTDetailsDTO {
  Id?: number;
  id?: number;
  attendanceId?: number;
  qustionId?: number;
  answerId?: number;
  degree: number;
}


export enum SurveyType {
  Customers = 1,
  Trainers = 2,
  Trainees = 3,
  B2B = 4,
  Affiliate = 5,
  Visitors = 6,
  All = 7
}


enum QuestionTypes {
  SingleChoice = 1,
  MultipleChoices = 2,
  Text = 3,
  UploadFile = 4,
  TrueFalse = 5,
  MatchBetween = 6

}
enum Rating {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5
}
