import { Router } from '@angular/router';
import { ActiveCampaign, AWSAPI, PaymentGateway, Stripe, activeCampgainList } from './../_models/settings';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SettingsService {

  private activeCampaign = new BehaviorSubject<ActiveCampaign>(null);
  private aws = new BehaviorSubject<AWSAPI>(null);


  AWSAPI() {
    if (this.aws.value != null) return this.aws;

    this.getAWSAPI().subscribe((data) => { this.aws.next(data); return this.aws });

    return this.aws;
  }

  // getActiveCampaign() {
  //   if (this.activeCampaign.value != null) return this.activeCampaign;
  //   this.getActiveCampaginSettings().subscribe((data) => {
  //     this.activeCampaign.next(data);
  //     return this.activeCampaign;
  //   })
  // }


  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string, private _router: Router) {
  }



  checkAWS() {
    return this.http.get(this.apiUrl + '/api/settings/checkaws', this.jwt());
  }

  initPayment(): Observable<PaymentGateway> {
    return this.http.get<PaymentGateway>(this.apiUrl + '/api/settings/payment/', this.jwt());
  }

  updateStripe(stripe): Observable<Stripe> {
    return this.http.post<Stripe>(this.apiUrl + '/api/settings/stripe', stripe, this.jwt());
  }

  getSignedURL(fileName: string) {
    return this.http.get(this.apiUrl + '/api/settings/signurl/' + fileName, this.jwt());
  }


  getVideoFromVimeo(fileName: string) {
    return this.http.get(this.apiUrl + '/api/settings/getvimeo/' + fileName, this.jwt());
  }

  getSignCookie(fileName: string) {
    return this.http.get(this.apiUrl + '/api/settings/signcookie/' + fileName, this.jwt());
  }



  getMyPermissions() {
    return this.http.get(this.apiUrl + '/api/settings/mypermissions');
  }



  getLiveStreamProviders() {
    return this.http.get(this.apiUrl + '/api/settings/meetings_providers');
  }



  getAllCountries() {
    return this.http.get(this.apiUrl + '/api/settings/countries/', this.jwt());
  }





  verifyEmail(email): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/verifyemail/' + email, this.jwt());
  }
  sendEmail(email): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/send/' + email, this.jwt());
  }

  getAllActiveCampaginLists() {
    return this.http.get(this.apiUrl + '/api/settings/allists', this.jwt())
  }
  getActiveCampaignList(id) {
    return this.http.get(this.apiUrl + '/api/settings/list/' + id, this.jwt())
  }

  addActiveCampaingList(list: activeCampgainList) {
    return this.http.post(this.apiUrl + '/api/settings/activelist', list, this.jwt());
  }



  getAllVimeoVidoes(attrs) {
    return this.http.post(this.apiUrl + '/api/settings/getviemos', attrs, this.jwt());
  }


  getAllActiveCampaginTags() {
    return this.http.get(this.apiUrl + '/api/settings/alltags', this.jwt())
  }
  deleteActiveCampaginTag(id) {
    return this.http.delete(this.apiUrl + '/api/settings/tag/' + id, this.jwt())
  }

  addActiveCampaginTag(list: any) {
    return this.http.post(this.apiUrl + '/api/settings/activetag', list, this.jwt());
  }

  testStripe(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/testpay', this.jwt());
  }


  updateSocialMedia(atttrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/settings/social', atttrs, this.jwt());
  }




  getSocialmedia(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/social', this.jwt());
  }

  getBigBlueButtonSettings(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/bbb', this.jwt());
  }

  updateBigBlueButtonSettings(atttrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/settings/bbb', atttrs, this.jwt());
  }


  getZoomIntegration(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/zoom', this.jwt());
  }

  updateZoomIntegration(atttrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/settings/zoom', atttrs, this.jwt());
  }


  scheduleZoomMeeting(atttrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/settings/zoommeeting', atttrs, this.jwt());
  }



  deleteListId(listid): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/list/' + listid, this.jwt());
  }



  updateAWSAPI(attrs): Observable<AWSAPI> {
    return this.http.post<AWSAPI>(this.apiUrl + '/api/settings/aws', attrs, this.jwt());
  }

  private getAWSAPI(): Observable<AWSAPI> {
    return this.http.get<AWSAPI>(this.apiUrl + '/api/settings/aws', this.jwt());
  }

  updateActiveCampaign(attrs): Observable<ActiveCampaign> {
    return this.http.post<ActiveCampaign>(this.apiUrl + '/api/settings/activecampaign', attrs, this.jwt());
  }

  getActiveCampaginSettings(): Observable<ActiveCampaign> {
    return this.http.get<ActiveCampaign>(this.apiUrl + '/api/settings/activecampaign/', this.jwt());
  }












  addEmailToBeNotified(attrs) {
    return this.http.post(this.apiUrl + '/api/settings/emailnotified', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
  removeEmailToBeNotified(attrs) {
    return this.http.delete(this.apiUrl + '/api/settings/emailnotified/' + attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
  getAllEmailsToBeNotified() {
    return this.http.get(this.apiUrl + '/api/settings/emailnotified', this.jwt()).pipe(catchError(error => this.handleError(error)));
  }




  updateTicketStatus(attrs) {
    return this.http.post(this.apiUrl + '/api/settings/updateticket', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getAllTickets() {
    return this.http.get(this.apiUrl + '/api/settings/tickets', this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getTicketById(id) {
    return this.http.get(this.apiUrl + '/api/settings/ticket/' + id, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  addNewTicket(attrs) {
    return this.http.post(this.apiUrl + '/api/settings/ticket', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  tmessage(message) {
    return this.http.post(this.apiUrl + '/api/settings/tmessage', message, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }








  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));
    if (profile == null) {
      this._router.navigate(['/login']);
      return
    }
    let token = profile.token;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


}
