import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sm-managemaiquizz',
  templateUrl: './managemaiquizz.component.html',
  styleUrls: ['./managemaiquizz.component.scss']
})
export class ManagemaiquizzComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
