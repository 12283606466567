import { AlertMessageComponent } from './../alert-message/alert-message.component';
import { SelecfiledialogComponent } from './../selecfiledialog/selecfiledialog.component';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CoursesService } from '../../../core/services';
import { ActivityType, FileTypes, MeetingType } from '../../../core/_models';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AlertType } from '../../../core/_models/system';


export interface DialogData {
  courseId: number,
  hasVideo: boolean,
  hasLiveMeeting: boolean,
  id: number
  isNew: boolean
  oldItem: any
}


@Component({
  selector: 'sm-lecturecontentdialog',
  templateUrl: './lecturecontentdialog.component.html',
  styleUrls: ['./lecturecontentdialog.component.css'],
})
export class LecturecontentdialogComponent implements OnInit {


  newCourse: UntypedFormGroup;
  submitted = false;
  toScheduleMeeting = false;
  meetingsList;
  toAddVideo = false;
  toAddQuizz = false;
  toAddArticle = false;
  toAddFile = false;
  toAddActivity = false;
  selectedFile = false;
  selectedFileName = '';
  allContents: any[] = [];
  allContentType = [
    { id: 1, name: "Video" },
    { id: 14, name: "Audio" },
    { id: 2, name: "Image" },
    { id: 4, name: "Article" },
    { id: 3, name: "PDF" },
    { id: 5, name: "Quizz" },
    { id: 6, name: "Link" },
    { id: 7, name: "LiveStreaming" },
    { id: 8, name: "CaseStudies" },
    { id: 9, name: "DecisionMaking" },
    { id: 10, name: "MatchBetween" },
    { id: 11, name: "FillSpace" },
    { id: 12, name: "LogicalOrder" },
    { id: 13, name: "Attachment" },
  ]

  activitiesList;
  selectedActivity

  get activity() {

    return this.selectedActivity;
  }
  set activity(activity) {
    this.selectedActivity = activity;

  }


  selectedMeeting

  get meeting() {

    return this.selectedMeeting;
  }
  set meeting(meeting) {
    // console.log(meeting);

    this.selectedMeeting = meeting;

  }
  isPublic = false;
  setPublic() {
    this.isPublic = !this.isPublic;
    // this.newCourse.patchValue({
    //   isFree: this.isPublic
    // })
  }
  loading = false;
  modelIsLoading
  editorConfigs: any = {
    'uploader': { 'insertImageAsBase64URI': true },
    'language': 'ar'
  }
  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      body: val
    });
    this._editorData = val;
  }


  id: number;
  button: string = '';
  contentTypeName = '';

  get contentType() {
    return this.contentTypeName;
  }
  set contentType(any: string) {
    this.contentTypeName = any;
    // console.log(any);

    if (any === 'LiveStreaming') {


      if (this.hasLiveMeeting) {
        var alertData = { title: this._translate.instant('DIALOGS.THISLECTUREALREADYHASLVIEMEETINGTITLE'), body: this._translate.instant('DIALOGS.THISLECTUREALREADYHASLIVEMEETING'), type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          id: 'alert',
          height: '200px',
          width: '400px'
        })
      } else {
        this.toAddVideo = false;
        this._course.getAllCoursesMeetings(this.currentCourse).subscribe((data: any[]) => {
          // console.log(data);

          this.meetingsList = data;
          this.meetingsList.forEach(element => {
            if (element.type == MeetingType.Zoom) {
              element.zoomMeeting.start_time = moment(element.zoomMeeting.start_time).tz(element.zoomMeeting.timezone)
            }
          });
          // console.log(this.allContents);
          this.cd.markForCheck();
        })
      }


      this.cd.markForCheck();
    }
    if (any === 'Video') {
      // this.toAddVideo = true;

      if (this.hasVideo) {
        var alertData = { title: this._translate.instant('DIALOGS.THISLECTUREALREADYHASVIDEOTITLE'), body: this._translate.instant('DIALOGS.THISLECTUREALREADYHASVIDEO'), type: AlertType.Error }
        this._dialog.open(AlertMessageComponent, {
          data: alertData,
          id: 'alert',
          height: '200px',
          width: '400px'
        })
      } else {
        const dialogRef = this._dialog.open(SelecfiledialogComponent, {
          id: 'selectfile',
          height: '80%',
          width: '80%',
          data: { type: FileTypes.Video }
        })
        dialogRef.afterClosed().subscribe((data) => {
          // console.log(data);
          this.selectedVideo(data);
        })
      }
    }
    if (any === 'Link') {
      // this.toAddVideo = true;
    }
    if (any === 'PDF') {
      const dialogRef = this._dialog.open(SelecfiledialogComponent, {
        id: 'selectfile',
        height: '80%',
        width: '80%',
        data: { type: FileTypes.PDF }
      })
      dialogRef.afterClosed().subscribe((data) => {
        // console.log(data);
        this.selectedVideo(data);
      })
    }
    if (any === 'Audio') {
      const dialogRef = this._dialog.open(SelecfiledialogComponent, {
        id: 'selectfile',
        height: '80%',
        width: '80%',
        data: { type: FileTypes.Audio }
      })
      dialogRef.afterClosed().subscribe((data) => {
        // console.log(data);
        this.selectedVideo(data);
      })
    }
    if (any === 'Attachment') {
      const dialogRef = this._dialog.open(SelecfiledialogComponent, {
        id: 'selectfile',
        height: '80%',
        width: '80%',
        data: { type: FileTypes.Other }
      })
      dialogRef.afterClosed().subscribe((data) => {
        // console.log(data);
        this.selectedVideo(data);
      })
    }
    if (any === 'Image') {
      // this.toAddVideo = true;
      const dialogRef = this._dialog.open(SelecfiledialogComponent, {
        id: 'selectfile',
        height: '80%',
        width: '80%',
        data: { type: FileTypes.Image }
      })
      dialogRef.afterClosed().subscribe((data) => {
        // console.log(data);
        this.selectedVideo(data);
      })
    }
    if (any === 'Article') {
      this.toAddVideo = false;
    }
    if (any === 'CaseStudies') {
      this.toAddVideo = false;
      this.modelIsLoading = true;
      this.activitiesList = null;
      this.cd.markForCheck;
      this._course.getAllActivities(ActivityType.DecisionMaking, this.currentCourse).subscribe((data: any[]) => {
        this.activitiesList = data;
        this.activitiesList.forEach(element => {
          element.title = element.decision.title
        });

        this.modelIsLoading = false;
        this.cd.markForCheck;
      })
    }
    if (any === 'LogicalOrder') {
      this.toAddVideo = false;
      this.modelIsLoading = true;
      this.activitiesList = null;
      this.cd.markForCheck;
      this._course.getAllActivities(ActivityType.LogicalOrder, this.currentCourse).subscribe((data: any[]) => {
        this.activitiesList = data;
        this.activitiesList.forEach(element => {
          element.title = element.logicalOrder.title
        });

        this.modelIsLoading = false;
        this.cd.markForCheck;
      })
    }
    if (any === 'Quizz') {
      this.toAddVideo = false;
      this.modelIsLoading = true;
      this.activitiesList = null;
      this.cd.markForCheck;
      this._course.getAllActivities(ActivityType.Quizz, this.currentCourse).subscribe((data: any[]) => {
        this.activitiesList = data;

        this.activitiesList.forEach(element => {
          element.title = element.quizz.name
        });

        this.modelIsLoading = false;
        this.cd.markForCheck;
      })
    }
    if (any === 'DecisionMaking') {
      this.toAddVideo = false;
      this.modelIsLoading = true;
      this.activitiesList = null;
      this.cd.markForCheck;
      this._course.getAllActivities(ActivityType.CaseStudies, this.currentCourse).subscribe((data: any[]) => {
        this.activitiesList = data;
        this.activitiesList.forEach(element => {
          element.title = element.caseStudy.caseHeader
        });

        this.modelIsLoading = false;
        this.cd.markForCheck;
      })
    }
    if (any === 'MatchBetween') {
      this.toAddVideo = false;
      this.modelIsLoading = true;
      this.activitiesList = null;
      this.cd.markForCheck;
      this._course.getAllActivities(ActivityType.MatchBetween, this.currentCourse).subscribe((data: any[]) => {
        this.activitiesList = data;
        this.activitiesList.forEach(element => {
          element.title = element.matching.title
        });

        this.modelIsLoading = false;
        this.cd.markForCheck;
      })
    }
    if (any === 'FillSpace') {
      this.toAddVideo = false;
      this.modelIsLoading = true;
      this.activitiesList = null;
      this.cd.markForCheck;
      this._course.getAllActivities(ActivityType.FillSpace, this.currentCourse).subscribe((data: any[]) => {
        this.activitiesList = data;
        this.activitiesList.forEach(element => {
          element.title = element.filling.title
        });

        this.modelIsLoading = false;
        this.cd.markForCheck;
      })
    }

    return
  }

  currentCourse = 0;
  hasVideo = false;
  hasLiveMeeting = false;
  isNew = true;
  oldItem: any;


  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    public dialogRef: MatDialogRef<LecturecontentdialogComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.currentCourse = data.courseId;
    this.id = data.id;
    this.hasVideo = data.hasVideo;
    this.hasLiveMeeting = data.hasLiveMeeting;
    this.isNew = data.isNew;
    this.oldItem = data.oldItem;

    this.cd.markForCheck();
  }


  modifyContent(e) {
    this.contentType = this.allContentType.find(x => x.id == this.allContentType[e.type - 1].id).name;
    this.newCourse.patchValue({
      id: e.Id | e.id,
      body: e.body,
      type: this.allContentType.find(x => x.id == this.allContentType[e.type - 1].id).name,
      parentId: null,
      fileId: e.fileId,
      activityId: e.activityId,
      order: e.order,
      isFree: e.isFree,
      materialId: e.materialId
    })
  }
  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      body: [''],
      type: [''],
      parentId: null,
      courseId: this.currentCourse,
      fileId: null,
      activityId: null,
      meetingId: null,
      isFree: false,
      materialId: this.id,
    });
    if (!this.isNew) {
      this.modifyContent(this.oldItem);
    }
    this.onChanges();
    // this.loadData();
  }

  onChanges(): void {


  }




  get xf() { return this.newCourse.controls; }

  selectedVideo(event) {


    this.selectedFile = true;
    this.selectedFileName = event.fileName;
    var id = (event.Id) ? event.Id : event.id;
    this.newCourse.patchValue({
      fileId: id,
    })
  }


  selectFile() {
    this.selectedFile = true;
  }



  onSubmit() {
    this.submitted = true;
    // console.log(this.newCourse.value);

    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;

    switch (this.contentTypeName) {
      case 'LiveStreaming':
        // console.log(this.selectedMeeting);

        var activity = this.meetingsList.find(x => x.zoomMeeting.topic == this.selectedMeeting && x.zoomMeeting.meetingId);
        // console.log(activity);

        if (activity) {
          this.newCourse.patchValue({
            meetingId: activity.Id | activity.id
          })
        }
        break;
      case 'CaseStudies':
        var activity = this.activitiesList.find(x => x.title == this.selectedActivity);
        if (activity) {
          this.newCourse.patchValue({
            activityId: activity.Id | activity.id
          })
        } else {
          return;
        }
        break;
      case 'FillSpace':
        var activity = this.activitiesList.find(x => x.title == this.selectedActivity);
        if (activity) {
          this.newCourse.patchValue({
            activityId: activity.Id | activity.id
          })
        } else {
          return;
        }
        break;
      case 'MatchBetween':
        var activity = this.activitiesList.find(x => x.title == this.selectedActivity);
        if (activity) {
          this.newCourse.patchValue({
            activityId: activity.Id | activity.id
          })
        } else {
          return;
        }
        break;
      case 'LogicalOrder':
        var activity = this.activitiesList.find(x => x.title == this.selectedActivity);
        if (activity) {
          this.newCourse.patchValue({
            activityId: activity.Id | activity.id
          })
        } else {
          return;
        }
        break;
      case 'DecisionMaking':
        var activity = this.activitiesList.find(x => x.title == this.selectedActivity);
        if (activity) {
          this.newCourse.patchValue({
            activityId: activity.Id | activity.id
          })
        } else {
          return;
        }
        break;
      case 'Quizz':
        var activity = this.activitiesList.find(x => x.title == this.selectedActivity);
        if (activity) {
          this.newCourse.patchValue({
            activityId: activity.Id | activity.id
          })
        } else {
          return;
        }
        break;

      default:
        break;
    }
    // return;
    this.newCourse.patchValue({
      type: this.contentTypeName
    })
    // console.log(this.id);
    // return ;

    this._course.addContentToMaterial(this.newCourse.value).subscribe((data) => {
      this.submitted = false;
      this.newCourse.reset();
      this.contentTypeName = null;
      this.activitiesList = null;
      this.isPublic = false;
      this.activity = null;
      this.newCourse.patchValue({
        fileId: null,
        isFree: false,
        materialId: this.id,
      });
      // $('#summernote').summernote('code', '');

      this.dialogRef.close(true);
    })
  }

}
