import { mergeMap, catchError, map, tap, exhaustMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoursesService, UserService } from '../../services';
import { EMPTY, of } from 'rxjs';
import * as courseActions from './courses.actions';
import { Action } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';






@Injectable()
export class CoursesEffect {
  constructor(
    private _user: UserService,
    private actions$: Actions,
    private permissionsService: NgxPermissionsService,
    private _service: CoursesService,
  ) {
  }



  getSelectedCourse$ = createEffect(() => this.actions$.pipe(
    ofType('[Courses] Select Course'),
    exhaustMap((action: any) => this._service.getCourseById(action.courseId).pipe(
      map(course => ({ type: '[Courses] Load Current Course', course: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );



  // getUserPermissions$ = createEffect(() => this.actions$.pipe(
  //   ofType('[SYSTEM] Get My Permissions'),
  //   exhaustMap((action: any) => this._user.getUserPermissions(action.userId).pipe(
  //     map(permissions => ({ type: '[SYSTEM] Get My Permissions Success', permissions: permissions }),
  //       catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
  //       )
  //     ))
  //   ))
  // );


  getSelectedUnit$ = createEffect(() => this.actions$.pipe(
    ofType('[Unites] Select And Update Unites'),
    exhaustMap((action: any) => this._service.getUniteById(action.unitId).pipe(
      map(course => ({ type: '[Unites] Load Current Unites', unit: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );


  getSelectedMaterial$ = createEffect(() => this.actions$.pipe(
    ofType('[Lectures] Select And Update Lectures'),
    exhaustMap((action: any) => this._service.getMaterialById(action.lectureId).pipe(
      map(course => ({ type: '[Lectures] Load Current Lectures', lecture: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );



  getSelectedCurricula$ = createEffect(() => this.actions$.pipe(
    ofType('[Curricula] Select And Update Curricula'),
    exhaustMap((action: any) => this._service.getCurriculaById(action.curriculaId).pipe(
      map(course => ({ type: '[Curricula] Load Current Curricula', curricula: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );


  loadAllUnitesForCourse$ = createEffect(() => this.actions$.pipe(

    ofType('[Unites] Load All Unites'),
    exhaustMap((action: any) => this._service.AllUnites(action.courseId).pipe(
      map(course => ({ type: '[Unites] Load All Unites Success', unites: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));


  removeCourse$ = createEffect(() => this.actions$.pipe(

    ofType('[Courses] Remove Single Course'),
    exhaustMap((action: any) => this._service.deleteCourse(action.id).pipe(
      map(course => ({ type: '[Courses] Remove Single Course Success', id: action.id }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));



  removeCourseFromCuurricula$ = createEffect(() => this.actions$.pipe(

    ofType('[Courses] Remove Single Course'),
    exhaustMap((action: any) => this._service.deleteCourse(action.id).pipe(
      map(course => ({ type: '[Courses] Remove Single Course Success', id: action.id }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));



  removeUnit$ = createEffect(() => this.actions$.pipe(

    ofType('[Unites] Remove Single Unites'),
    exhaustMap((action: any) => this._service.deleteUnite(action.id).pipe(
      map(course => ({ type: '[Unites] Remove Single Unites Success', unites: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));


  removeLecture$ = createEffect(() => this.actions$.pipe(

    ofType('[Lectures] Remove Single Lectures'),
    exhaustMap((action: any) => this._service.deleteMaterial(action.id).pipe(
      map(course => ({ type: '[Lectures] Remove Single Lectures Success', unites: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));



  loadAllLecturesForUnit$ = createEffect(() => this.actions$.pipe(

    ofType('[Lectures] Load All Lectures'),
    exhaustMap((action: any) => this._service.AllMaterials(action.unitId).pipe(
      map(course => ({ type: '[Lectures] Load All Lectures Success', lectures: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));



  loadAllFiles$ = createEffect(() => this.actions$.pipe(

    ofType('[Files] Load All Files'),
    exhaustMap((action: any) => this._service.getAllVideos().pipe(
      map(course => ({ type: '[Files] Load All Files Success', files: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )));





  loadAllCourses$ = createEffect(() => this.actions$.pipe(

    ofType('[Courses] Load All Courses'),
    mergeMap(() => this._service.AllCourses()
      .pipe(
        map(courses => ({ type: '[Courses] Load All Courses Success', courses: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));




  loadAllCategories$ = createEffect(() => this.actions$.pipe(

    ofType('[Categories] Load All Categories'),
    mergeMap(() => this._service.AllCategories()
      .pipe(
        map(courses => ({ type: '[Categories] Load All Categories Success', categories: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));



  loadAllTrainers$ = createEffect(() => this.actions$.pipe(

    ofType('[Trainers] Load All Trainers'),
    mergeMap(() => this._user.getAllTrainers()
      .pipe(
        map(courses => ({ type: '[Trainers] Load All Trainers Success', trainers: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));


  loadAllCurricula$ = createEffect(() => this.actions$.pipe(

    ofType('[Curricula] Load All Curricula'),
    mergeMap(() => this._service.getCurriculas()
      .pipe(
        map(courses => ({ type: '[Curricula] Load All Curricula Success', curricula: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));



  addCourse$ = createEffect(() => this.actions$.pipe(
    ofType('[Courses] Add New Course'),
    exhaustMap((action: any) => this._service.addOrModifyCourse(action.courseData.files, action.courseData.course).pipe(
      map(course => ({ type: '[Courses] Add New Course Successed', course: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );



  addCurricula$ = createEffect(() => this.actions$.pipe(
    ofType('[Curricula] Add New Curricula'),
    exhaustMap((action: any) => this._service.addOrModifyCurricula(action.curricula).pipe(
      map(course => ({ type: '[Curricula] Load All Curricula' }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );


  addUnit$ = createEffect(() => this.actions$.pipe(
    ofType('[Unites] Add New Unites'),
    exhaustMap((action: any) => this._service.addOrModifyUnite(action.unitData.files, action.unitData.course).pipe(
      map(course => ({ type: '[Unites] Add New Unites Successed', unit: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );



  addMaterial$ = createEffect(() => this.actions$.pipe(
    ofType('[Lectures] Add New Lectures'),
    exhaustMap((action: any) => this._service.addOrModifyMaterial(action.lectureData.files, action.lectureData.course).pipe(
      map(lectures => ({ type: '[Lectures] Add New Lectures Successed', lectures: lectures }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );


  apiError$ = createEffect(
    () => this.actions$.pipe(
      ofType(courseActions.LoadCorusesFail),
      tap(({ error }) => {
      }
      )
    ), { dispatch: false }
  )


  // redirectToCourse$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(courseActions.LoadCorusesSuccess),
  //     tap(({ course }) => {
  //       console.log({ error })
  //     }
  //     )
  //   ), { dispatch: false }
  // )



  // loadPermissionsIntoNGX$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType('[SYSTEM] Get My Permissions Success'),
  //     tap((permissions: any) => {
  //       const roles = permissions.permissions.map(a => a.module);
  //       this.permissionsService.loadPermissions(roles);
  //       console.log('Permissions Updated ! ');

  //     }
  //     )
  //   ), { dispatch: false }
  // )


}
