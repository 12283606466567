// China
export const locale = {
  lang: 'tr',
  data: {
    MENU: {
      CREATE_POST: 'Hoş Geldiniz',
      HOME: 'Ana Sayfa',
      COURSES: 'Kurslar',
      CATEGORIES: 'Sınıflandırmalar',
      COUPONS: 'Kuponlar',
      AFFILIATES: 'Pazarlama Sistemi',
      B2B: 'b2b müşteri sitemi ',
      SETTINGS: 'Ayarlar',
      USERS: 'Kullanıcılar ',
      CURRICULUMS: 'Müfredat',
      VIDEOBANK: 'Dosyalar Bankası',
      COURSECARD: 'Kursun Kimliği',
      TRAINERS: 'Hocalar',
      PUBLICVIDEOS: 'Yayınlanan videolar',
      CERTIFICATES: 'Deplomalar',
      CONSULTANCY: 'Danşma'
    },
    WIDGETS: {
      QUICKREPORTS: 'Hızlı Raporlar',
      TOTALUNPUBLISHEDCOURSES: 'Açılmayan kurslar',
      TOTALPUBLISHEDCOURSES: 'Açılan kurslar',
      TOTALTRAINEES: 'Öğrenciler',
      TOTALVIDEOSLENGTH: 'Videolar süresi',
      TOTALUNCONFIRMEDUSERS: 'Kayıdsız Kullancılar',
      TOTALCONFIRMEDUSERS: 'Kayıtlı kullancılar',
      TOTALVIDEOS: ' Videolar',
      TOTALPAIDORDERS: 'Onaylanmış Faturalar',
      TOTALPAYMENTS: 'Toplam Tutar',
      THEMOSTPOPULARCOURSE: 'En Çok Satılan Kurslar',
      HOURS: 'S'
    },
    SETTINGS: {
      SETTINGS: 'Ayarlar',
      AWS: 'Amazon',
      AWSACCESSKEYID: 'Access Key ID',
      AWSSECRECTACCESSKEY: 'Access Key Secrey',
      ACTIVECAMPAIGN: 'Active Campaign',
      ACCOUNTID: 'Account Id',
      KEY: 'Key',
      STRIPE: 'Stripe',
      APIKEY: 'API Key',
      ACTIVE: 'Active',
    },
    ACTIVECAMPAIGN: {
      LISTS: 'Posta Listeleri',
      LISTNAME: 'Liste Adı',
      URLSTRING: 'Bağlantı Adresi',
      SENDERURL: 'عنوان المرسل',
      SENDERREMINDER: 'شرح عن القائمة'
    },
    CONSULTANCY: {
      CONSULTANCY: 'Kişisel danışmanlık',
      ACTIVE: 'Aktiv',
      NEWCONSULTANCY: ' Yeni Danışma',
      REQUIRED: 'Zorunlu',
      NAME: 'Adı',
      CONSULTANT: 'Danışman',
      BODY: 'Danışma Metini',
      TIME: 'Danışma zamanı',
      PRICE: 'Danışma Fiyatı',
      IMAGE: 'Kişisel Fotoğraf',
    },
    CONSULTANT: {
      NEW: 'Yeni Danışman',
      NAME: 'Adı',
      EMAIL: 'Email',
      PHONE: 'Telefon',
      NOTES: 'Notlar',
      MOREINFO: 'Ek Bilgiler',
      INFO: 'Özgeçmiş',
    },
    CERTIFICATES: {
      NEW: 'Yeni Deploma',
      NAME: 'Deploma Adı',
      DESCRIPTION: 'Deploma Tanımı',
      SETINGS: 'Deploma Ayarları',
      NUMBEROFLOGOS: 'Logo Sayısı',
      NUMBEROFTRAINERS: 'Hoca Sayısı ',
      ENDINGDATE: 'Bitiş Tarihi Göster',
      STARTINGDATE: 'Başlangıç Tarihi Göster'
    },
    FILEBANK: {
      MANAGE: 'Dosya Yönetimi',

    },
    CATEGORIES: {
      NAME: 'Sınıflandırma Adı',
      REQUIRED: 'Zorunlu',
      PARENT: 'Ana Sınıflandırma',
      DESCRIPTION: 'Sınıflandırma Tanıtımı',
      KEYWORDS: 'Anahtar Kelimeleri',
      NOTES: 'Notlar',
    },
    UNITES: {
      IMAGE: 'Ünite Kapağı',
      NAME: 'Ünite Adı',
      NOTES: 'Notlar',
      NEW: 'Ünitelerin yönetimi',
      DESCRIPTION: 'Ünitenin Tanıtımı',
    },
    MATERIALS: {
      NAME: 'Ders Adı',
      VIDEO: 'Video',
      CONTENTS: 'Ders içeriği',
      FILEBANK: 'Dosya Bankasından Seç',
      NEW: 'Yeni Ders',
      SUBMITQUESTION: 'Soru Ekle',
      FALLBACKMINUTE: 'Geri',
    },
    MATERIAL: {
      SUBMITQUESTION: 'Soruyu Onaylama',
      FALLBACKMINUTE: 'Yanlış Cevap Durumunda Zamana Geri Dönün'
    },
    CONTENTS: {
      AVAILABILITY: 'Herkese Açık',
      TYPE: 'Tip'
    },
    QUIZZES: {
      NEW: 'Yeni Sınav',
      REQUIRED: 'Zorunlu',
      NAME: 'Sınav Adı',
      PASSWORD: 'Şifre',
      TIMELIMIT: 'Süre',
      REPETITION: 'Tekrarlama Sayısı',
      IFPASSED: 'Geçme Durumunda',
      IFFAILED: 'Kalma Durumunda',
      UNITE: 'Ünite',
      SHUFFLE: 'Rasgele',
      SORTED: 'Düzenleme ',
      NOTES: 'Notlar',
      NEWQUESTION: 'Yeni Soru',
      QUESTIONTYPE: 'Soru TİPİ',
      QUESTIONNAME: 'SORU METİNİ',
      QUESTIONNAMEREQUIRED: 'ZORUNLU',
      FIRSTOFPAIR: 'Cümlenin Birinci Bölümü',
      SECONDOFPAIR: 'Cümlenin İkinci Bölümü',
      ANSWER: 'Cevap',
      EXPLANATION: 'Açıklama',
      ISCORRECTANSWER: 'Doğru Cevap',
    },
    VIDEOBANK: {
      MANAGE: 'Video Bankası'
    },
    VIDEO: {
      NEW: 'Yeni Video',
      ACTIVE: 'Aktif',
      HEADER: 'Başlık',
      BODY: 'Ekli Metin'
    },
    FORM: {
      FILTER: '',
      SEARCHANDFILTER: 'Tablodaki Herhangi Bir Alan Arayabilirsiniz (Sayı.Tarih.Metin)',
    },
    SYSTEM: {
      ADDIMAGE: 'Dosya Ekle',
      DRAGIMAGE: 'Dosya Seç Veya Bırak',
      AREYOUSURE: 'Emin Misiniz',
      YES: 'Evet',
      NO: 'Hayır'
    },
    COURSES: {
      NAME: 'Kurs Adı',
      REQUIRED: 'Zorunlu',
      DESCRIPTION: 'Kurs Tanıtımı',
      MAILINGLIST: 'Mail Lisesi',
      SQUARECOVER: '1024X1024 Kapak',
      CATEGORY: 'Sınıflandırma',
      ISDIPLOMA: 'Diploma Olarak İşaretleyiniz',
      COURSELINK: 'Kurs Linki',
      VIDEO: 'Tanıtım Videosu',
      SELECTTRAINER: 'Hoca Seç',
      TRAINERS: 'Hocalar',
      TRAINER: 'Hoca',
      KEYWORDS: 'Anahtar Kelimeleri',
      NOTES: 'Notlar',
      RULES: 'Kursun Tamamlama Şartları',
      IMAGE: 'Kurs Kapağı',
      ASSIGNMENTS: 'Ödevler',
      GENERALREPORT: 'Genel Raporu',
      FROMDATE: 'Tarihten',
      TODATE: 'Tarihe',
      DISCOUNT: 'İndirim',
      YES: 'Evet',
      NO: 'Hayır',
      COUPON: 'Kupon',
    },
    ASSIGNMENTS: {
      NAME: 'Ödev Metini',
      RULES: 'Kabul Şartı',
      AVAILABLEFROM: 'Tarihten Açık',
      AVAILABLETO: 'Tarihe Açık',
      NOTES: 'Notlar',
    },
    RULES: {
      CONDITION: 'Şart',
      RELATION: 'Diğer Koşullarla İlişki',
      TIME: 'Zamöan',
      AND: 'Ve',
      OR: 'Veya'
    },
    COURSE: {
      PRICELIST: 'Kursun Fiyatlar Listesi',
      INFO: 'kurs BİLGİLERİ ',
      PUBLISH: 'Kursu Yayınla',
    },
    INFO: {
      HEADER: "Başlık",
      BODY: "İçeriğin Metini"
    },
    PRICE: {
      NAME: 'Adı',
      PRICE: 'Fiyat',
      STARTINGFROM: 'Başlangıç Tarihi',
      ENDINGAT: 'Bitiş Tarihi',
      ISMAINPRICE: 'Ana Fiyat Olarak Ayarla'
    },
    CURRICULUMS: {
      CURRICULUMS: 'Eğitim Paketleri',
      MANAGE: 'Paketlerin Yönetimi',
      NAME: 'Paket Adı',
      REQUIRED: 'Gerekli Kurs',
      FREQUIRED: 'Zorunlu',
      CATEGORY: 'التصنيف',
      PRICE: 'Fiyat',
      COURSELINK: 'Paket linki',
      NOTES: 'Notları',
      IMAGE: 'Paket Kapağı',
      COURSES: 'Kurslar',
      SELECTCOURSEORMORE: 'Kurs Seç',
    },
    COUPONS: {
      COUPONS: 'Kuponlar',
      NAME: 'Kupon Adı',
      CODE: 'Kupon Kodu',
      CODEREQUIRED: 'Zorunlu',
      DISCOUNTTYPE: 'Kupon Tipi',
      LIMITIATION: 'Kullanım Sürelerini Tanımla',
      NUMBEROFORDERS: 'İstek Sayısı',
      NUMBEROFORDERSREQUIRED: 'Zorunlu',
      LIMITNO: 'Kullanım Süreleri',
      LIMITNOREQUIRED: 'Zorunlu',
      AMOUNT: 'Miktar',
      PERCENT: 'Oran',
      PERCENTREQUIRED: 'Zorunlu',
      SHIPPINGINCLUDED: 'Ücretsiz Kargo',
      ISACTIVE: 'Aktif',
      STARTINGDATE: 'Başlangıç Tarihi',
      ENDINGDATE: 'Bitiş Tarihi',
      CATEGORIES: 'Sınıflandırmalar',
      INCLUSIVECOURSES: 'Hariç Tutulan Kurslar',
    },
    AFFILIATES: {
      AFFILIATES: 'Pazarlama Sistemi',
      SUBSCRIBE: 'Abone Ol',
      NAME: 'Pazarlamacının Adı',
      EMAIL: 'Email',
      PHONE: 'Telefon Numarası',
      ADDRESS: 'Adres',
      BANKINFO: 'Banka Bilgileri',
      BANKNAME: 'Bsnka Adı',
      ACCOUNTNAME: 'HESAP Adı',
      ACCOUNTNUMBER: 'Hesaap Numarası',
      BANKSWIFT: 'السويفت',
      BANKADDRESS: 'Banka Adresi',
      ACCOUNTIBAN: 'İban',
      ACCOUNTADDRESS: 'Hesap Sahibinin Adresi',
      COURSE: 'Eğitim Kursu',
      COMMISSION: 'Komisiyon',
    },
    ACCOUNT: {
      LOGOUT: 'Çıkış'
    },
    TOPBAR: {
      HI: 'Merhaba'
    },
    B2B: {
      B2B: 'B2B',
      SUBSCRIBE: 'Abone',
      COMPANYNAME: 'Şirketin Adı',
      COMPANYPHONE: 'Şirketin Telefon Numarası',
      COMPANYEMAIL: 'Şirketin Emaili',
      CONTACTPHONE: 'Sorumlunun Telefon Numarası',
      CONTACTEMAIL: 'Sorumlunun Emaili',
      CONTACTNAME: 'Sorumlunun Adı',
      ADDRESS: 'Adres',
      MANAGESUBSCRIPTIONS: 'Abonelik yöntemi',
      NUMBEROFENROLLMENTS: 'Kullancı Sayısı',
      STARTINGAT: 'Başlangıç Tarihi',
      ENDINGAT: 'Bitiş Tarihi',
      ISACTIVE: 'Aktif',
      IMPORTUSERS: 'Kullancıları İçe Aktar',
      MANAGEUSERS: 'Kullancı YÖnetimi',
      IMPORTDEPARTMENTS: 'Bölümleri İçe Aktar',
      DEPARTMENTS: 'Bölümler Ve Yöntemler',
      COURSES: 'Açık Kurslar',
      MANAGECOURSES: 'Açık Kursların Yönetimi',
      SELECTCATEGORY: 'Sınıflandırma Seç',
      INCLUSIVECOURSES: 'Hariç Tutulan Kursları Seçiniz',
    },
    TRAINERS: {
      TRAINERS: 'Hocalar',
      IMAGE: 'Hoca fotoğrafı',
      NAME: 'Hoca Adı',
      EMAIL: 'Email',
      PHONE: 'Telefon numarası',
      NOTES: 'Notlar',
      TRAINER: 'Hocalar Yönetimi',
    }
  }
};

