export class Assignments {

  Id?: number;
  id?: number;
  name: string;
  courseId?: number;
  rule: AssignmentAcceptanceRules;
  file: string;
  body: string;
  availableFrom?: Date;
  availableTo?: Date;
  notes: string;
  addedBy: number;
  addedDate?: Date;
  updateAt?: Date;
}


export enum AssignmentAcceptanceRules {
  WhenInstructorAccept = 1,
  WhenSubmitAnAnswer = 2
}

