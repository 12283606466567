import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotfiyService, CoursesService } from '../../../core/services';
import { COUCategories } from '../../../core/_models';
import { AlertType } from '../../../core/_models/system';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { CourseDialogData, NewcoursedialogComponent } from '../newcoursedialog/newcoursedialog.component';
declare var $: any;


export interface CategoriesDialogData {
  isNew: boolean
  oldCourse: any
  allCategories: any[]
}




@Component({
  selector: 'sm-categories',
  templateUrl: './newcategories.component.html',
  styleUrls: ['./newcategories.component.scss']
})
export class NewCategoriesComponent implements OnInit {


  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  get xf() { return this.newCourse.controls; }
  isNew = true;
  oldCourse;
  profile;
  allCategories = [];
  fileToUpload
  editorConfigs
  progress = 0;

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newCourse.patchValue({
      description: val
    });
    this._editorData = val;
  }


  constructor(private ngNoty: NotfiyService, private _translate: TranslateService, private _courses: CoursesService,
    @Inject(MAT_DIALOG_DATA) public data: CategoriesDialogData, private dialogRef: MatDialogRef<NewcoursedialogComponent>,
    private _dialog: MatDialog, private formBuilder: UntypedFormBuilder) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('mprofile'));
      // this.userId = this.profile.ident;
    }

  }

  ngOnInit(): void {
    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      parentx: [{ value: '', disabled: this.loading }],
      parentId: null,
      image: [{ value: '', disabled: this.loading }],
      promo: [{ value: '', disabled: this.loading }],
      keywords: [{ value: '', disabled: this.loading }],
      description: [{ value: '', disabled: this.loading }],
      meta: [{ value: '', disabled: this.loading }],
      notes: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
    });
    this.loading = true;
    this.isNew = this.data.isNew;
    this.allCategories = this.data.allCategories;
    if (!this.isNew) {
      this.oldCourse = this.data.oldCourse;
      this.modify(this.oldCourse);

    }

  }


  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    console.log(this.newCourse.value);
    this.loading = true;
    var category = this.newCourse.get('parentx').value;
    if (category != '' && category != null && category != 'null') {
      this.newCourse.patchValue({
        parentId: this.allCategories.find(x => x.name == category).id
      });
    } else {
      this.newCourse.patchValue({
        parentId: null
      });
    }


    this._courses.addOrModifyCategory(this.fileToUpload, this.newCourse.value)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // console.log('User successfully created!', event.body);
            // this.uploader.removeUpload(0);

            var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.DONE'), type: AlertType.Success }
            this._dialog.open(AlertMessageComponent, {
              data: alertData,
              height: '200px',
              width: '400px'
            })
            this.dialogRef.close({ data: true });

          // setTimeout(() => {

          // }, 1500);

        }
      })
    // .subscribe((data) => {
    //
    // });
  }


  modify(dataItem) {

      console.log(dataItem);
      
    $('#profile').css('background-image', 'url(' + dataItem.image + ')').addClass('hasImage');

    this.newCourse.patchValue({
      id: dataItem.id | dataItem.Id,
      name: dataItem.name,
      parentId: (dataItem.parent != null) ? dataItem.parentId : null,
      image: dataItem.image,
      promo: dataItem.promo,
      keywords: dataItem.keywords,
      description: dataItem.description,
      meta: dataItem.meta,
      notes: dataItem.notes,
      addedBy: parseInt(this.profile.userId),
      parentx: (dataItem.parent != null) ? this.allCategories.find(x => x.id == dataItem.parentId).name : null,
    });
  }


  upload() {
    $('#mediaFile').click();
  }


  addFile(e) {
    // console.log('lele');

    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        console.log(reader.result);
        $('#profile').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }


}
