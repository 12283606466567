import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from '../../../core/services';

declare var $: any;

export interface CertificateDetailData {
  certificateId: number;
  type: any;
}




@Component({
  selector: 'sm-certificatesignature',
  templateUrl: './certificatesignature.component.html',
  styleUrls: ['./certificatesignature.component.scss']
})
export class CertificatesignatureComponent {


  certificateId = 0;
  profile;
  newCourseC: UntypedFormGroup;


  constructor(private _course: CoursesService, private formBuilder: UntypedFormBuilder, private _translate: TranslateService,
    private _store: Store<any>, public dialogRef: MatDialogRef<CertificatesignatureComponent>,
    private cd: ChangeDetectorRef, private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CertificateDetailData) {
    // this.currentCourse = data.courseId;
    this.certificateId = data.certificateId;
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }

    this.newCourseC = this.formBuilder.group({
      certificateId: data.certificateId,
      type: data.type
    });
  }



  fileToUpload

  upload() {
    document.getElementById('certificateImage').click();
  }



  addFile(e) {


    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(reader.result);
        $('.newprofile').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }


  onSubmitC() {
    // console.log(this.fileToUpload);
    this._course.certificateimage(this.fileToUpload, this.newCourseC.value).subscribe((data) => {
      // console.log(data);
      this.dialogRef.close({ data: true });
    })
  }

}
