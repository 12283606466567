import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { id } from '@swimlane/ngx-charts';
import { CoursesService, NotfiyService, AffiliateService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';




export interface QuestionDialogData {
  isNew: boolean
  oldQuestion: any
  quizzId: any
}



@Component({
  selector: 'sm-activityquizquestion',
  templateUrl: './activityquizquestion.component.html',
  styleUrls: ['./activityquizquestion.component.scss']
})
export class ActivityquizquestionComponent implements OnInit {


  statusOfExams = [{
    id: 1,
    name: "SingleChoice"
  },
  {
    id: 2,
    name: "MultipleChoices"
  },
  {
    id: 3,
    name: "Text"
  },
  // {
  //   id: 4,
  //   name: "UploadFile"
  // },
  {
    id: 5,
    name: "TrueFalse"
  },
  {
    id: 6,
    name: "MatchBetween"
  }]

  editorConfigs = {
    useSearch: false,
    language: "ar",
    direction: "rtl",
    enter: "br",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    inline: false,
    "allowResizeY": false,
    "height": 250,
    disablePlugins:
      "video,media,image,image-processor,image-properties,file,table,table-keyboard-navigation,select-cells,resize-cells,preview,print",
    showPlaceholder: false,
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,cut,copy,paste,link,undo,redo",
    "toolbar": true,
  };

  _editorData = '';
  get editorData() {
    return this._editorData;
  }

  set editorData(val) {
    this.newQuestionForm.patchValue({
      question: val
    });
    this._editorData = val;
  }



  newQuestionForm
  get xfxx() { return this.newQuestionForm.controls; }
  submittedQ
  profile
  lang
  loading
  progress

  constructor(@Inject('API_URL') private apiUrl: string, private route: ActivatedRoute, private _course: CoursesService, private _router: Router, public ngNoty: NotfiyService,
    @Inject(MAT_DIALOG_DATA) public data: QuestionDialogData, private dialogRef: MatDialogRef<ActivityquizquestionComponent>,
    private _dialog: MatDialog, private _courses: CoursesService, private cd: ChangeDetectorRef, private _translate: TranslateService, private _store: Store<any>, private _report: ReportsService
    , private _affiliate: AffiliateService, private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    this.lang = localStorage.getItem('language');
  }

  ngOnInit(): void {
    this.newQuestionForm = this.formBuilder.group({
      id: null,
      question: [{ value: '', disabled: this.loading }, Validators.required],
      questionType: { value: '', disabled: this.loading },
      score: [{ value: '', disabled: this.loading }, Validators.required],
      optional: false,
      quizzId: this.data.quizzId,
    });
    if (!this.data.isNew) {
      this.modify(this.data.oldQuestion);
    }

  }

  modify(e) {
    this.newQuestionForm.patchValue({
      id: e.id,
      question: e.question,
      questionType: this.statusOfExams.find(x => x.id == e.questionType).name,
      score: e.score,
      optional: e.optional,
      quizzId: e.quizzId
    });
  }

  onSubmitQuestion() {
    this.submittedQ = true;
    // console.log(this.newQuestionForm.value);
    // console.log(this.selectedCase);

    if (this.newQuestionForm.invalid) {
      return;
    }
    var id = this.data.quizzId
    
    var attrs = {
      question: this.newQuestionForm.value,
      ansawers: []
    }
    // console.log(attrs);
    // return;
    this._course.AddQuestionToActivity(attrs).subscribe((data) => {
      this.dialogRef.close();

      // this._course.getQuestionsForActivityQuizz(id).subscribe((data: any[]) => {
      //   this.allQuestions = data;
      //   this.cd.markForCheck();
      // })

      // this.newQuestionForm.reset();
      // this.submittedQ = false;
    })

    this.loading = true;

  }


}
