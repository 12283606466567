import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderComponent } from './loader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule, NgxSpinnerModule,
  ],
  exports: [LoaderComponent]
})
export class LoaderModule { }
