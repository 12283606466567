import { ReportsService } from './../../../core/services/reports.service';
import { TranslateService } from '@ngx-translate/core';
import { Curriculum } from './../../../core/_models/curriculums';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { RTL } from '@progress/kendo-angular-l10n';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { COUCategories, Course } from './../../../core/_models';
import { SelectableSettings, SelectableMode, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { CoursesService } from './../../../core/services/courses.service';
import { SystemService } from './../../../core/services/system.service';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { environment } from './../../../../environments/environment';
import * as _categoriesreducer from '../../../core/state/_courses/categories.reducer'
import { Subscription, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as _reducer from '../../../core/state/_courses/course.reducer'
import * as _curriculaReducer from '../../../core/state/_courses/curricula.reducer'

import { NotfiyService } from '../../../core/services';
import * as moment from 'moment';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';


export interface CurriculaDialogData {
  isNew: boolean;
  oldItem: any;
}

@Component({
  selector: 'sm-newcurriculadialog',
  templateUrl: './newcurriculadialog.component.html',
  styleUrls: ['./newcurriculadialog.component.css'],
  providers: [{ provide: RTL, useValue: true }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewcurriculadialogComponent implements OnInit, OnDestroy, AfterViewInit {



  public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  allCategories: COUCategories[] = [];

  button: string = '';

  categoriesSubscription = new Subscription();
  isNew = true;
  oldItem;
  selectedCategories = [];


  constructor(private _router: Router, private _courses: CoursesService, private cd: ChangeDetectorRef,
    private _dialogRef: MatDialogRef<NewcurriculadialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CurriculaDialogData, private _dialog: MatDialog, private _store: Store<any>, private _translate: TranslateService, private ngNoty: NotfiyService, private _report: ReportsService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }

    this.button = this._translate.instant('CURRICULUMS.ADDCURRICULUMS')
  }


  ngOnDestroy(): void {
    this.categoriesSubscription.unsubscribe();
    // this.subCurri$.unsubscribe();
    // this.trainersSubscription.unsubscribe();
  }


  ngAfterViewInit(): void {

    //$(".menu-w").removeClass("menu-layout-full");
    //  $(".menu-w").addClass("menu-layout-mini");
  }


  categories$: Observable<COUCategories[]>
  ngOnInit() {
    this._store.dispatch({ type: '[SYSTEM] Get My Permissions', userId: this.profile.userId });
    var urlName = this._translate.instant('SYSTEM.CURRICULUM');
    this._store.dispatch({ type: '[SYSTEM] Navigate To New Destination Level1', url: '/curriculums', name: urlName })
    this._store.dispatch({ type: '[Curricula] Load All Curricula' });
    this.newCourse = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      linkName: [{ value: '', disabled: this.loading }, Validators.required],
      externalId: [{ value: '', disabled: this.loading }],
      parentx: [{ value: '', disabled: this.loading }],
      categoryId: null,
      active: false,
      showInListing: false,
      showInCoursesListing: false,
      categories: this.selectedCategories,
      image: [{ value: '', disabled: this.loading }],
      video: [{ value: '', disabled: this.loading }],
      price: [{ value: 0, disabled: this.loading }],
      notes: [{ value: '', disabled: this.loading }],
      addedBy: parseInt(this.profile.userId),
    });

    this.categories$ = this._store.pipe(select(_categoriesreducer.getCOUCategoriess));
    this.categoriesSubscription = this.categories$.subscribe((data) => {
      // console.log(data);

      this.allCategories = data;
      this.cd.markForCheck();
    })

    this.isNew = this.data.isNew;
    if (!this.isNew) {
      // console.log(this.data.oldItem);
      this.modify(this.data.oldItem)
    }
    this.onChanges();
  }

  get xf() { return this.newCourse.controls; }



  onSubmit() {
    this.submitted = true;
    if (this.newCourse.invalid) {
      return;
    }
    this.loading = true;
    var categories = this.newCourse.get('parentx').value;
    if (categories != '' && categories != null && categories != 'null') {
      categories.forEach(cateogry => {
        var category = this.allCategories.find(x => x.name == cateogry);
        this.selectedCategories.push({
          packageId: this.newCourse.get('id').value,
          categoryId: category.id
        })
        this.newCourse.patchValue({
          categories: this.selectedCategories
        })
      });
    } else {
      this.newCourse.patchValue({
        categoryId: null
      });
    }
    this.newCourse.patchValue({
      linkName: this.linkName
    })
    if (this.isUpdateing) {

      this._store.dispatch({ type: '[Curricula] Update Single Curricula', curricula: { id: this.newCourse.get('id').value, changes: this.newCourse.value } });
      this.cd.markForCheck();
    }
    var files = [this.fileToUpload, this.fileToUpload3];

    this._store.dispatch({ type: '[Curricula] Add New Curricula', curricula: this.newCourse.value })
    var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.CURRICULAADDED'), type: AlertType.Success }
    this._dialog.open(AlertMessageComponent, {
      data: alertData,
      height: '200px',
      width: '400px'
    })
    this.newCourse.reset();
    this.submitted = false;
    this._dialogRef.close();
    this.button = this._translate.instant('CURRICULUMS.ADDCURRICULUMS')
  }

  fileToUpload: File = null;
  fileToUpload2: File = null;
  fileToUpload3: File = null;
  @ViewChild(FileuploaderComponent, { static: false }) uploader: FileuploaderComponent;



  getFile($event) {
    this.fileToUpload = $event[0];
  }


  getSquare($event) {
    this.fileToUpload3 = $event[0];
  }





  linkName = '';
  onChanges(): void {
    this.newCourse.get('linkName').valueChanges.subscribe((val: string) => {
      if (val && val !== '') {
        val = val.replace(/ /g, "_");
        this.linkName = val;
      }

    });

  }

  modify(dataItem) {
    // console.log(dataItem);
    
    // console.log(this.allCategories);
    this.linkName = dataItem.linkName;
    let parents = [];
    if (dataItem.categories.length != 0) {

      dataItem.categories.forEach(category => {
        // console.log(category);

        const item = this.allCategories.find(x => x.id == category.categoryId);
        // console.log(item);
        // this.selectedCategories.push(category);
        parents.push(item.name);
      });
    }

    this.newCourse.patchValue({
      id: dataItem.id | dataItem.Id,
      name: dataItem.name,
      parentx: (parents.length != 0) ? parents : null,
      categoryId: dataItem.categoryId,
      image: dataItem.image,
      externalId: dataItem.externalId,
      showInListing: dataItem.showInListing,
      showInCoursesListing: dataItem.showInCoursesListing,
      categories: this.selectedCategories,
      active: dataItem.active,
      linkName: dataItem.linkName,
      video: dataItem.video,
      price: 0,
      notes: dataItem.notes,
      addedBy: dataItem.addedBy,
    })
    this.isUpdateing = true;
    this.onChanges();
  }


  isUpdateing = false;



}
