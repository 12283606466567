import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class PlayerService {


  public api = "https://api.sproutvideo.com/v1/";
  constructor(private http: HttpClient) { }



  getVideo(id) {
    return this.http.get(this.api + 'videos/' + id, this.jwt());
  }

  private jwt() {

    let headers = new HttpHeaders({
      'SproutVideo-Api-Key': '134210dbb1bfe802e1d10c18b8c3c082',
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }




}
