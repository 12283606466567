export enum Result {
  Message = 1,
  Questions = 2,
  QuestionsAndGivenAnswers = 3,
  QuestionsAndGivenAnswersAndScore = 4,
  QuestionsAndGivenAnswersAndScoreAndCorrectAnswers = 5,
}
export enum QuestionTypes {
  SingleChoice = 1,
  MultipleChoices = 2,
  Text = 3,
  UploadFile = 4,
  TrueFalse = 5,
  MatchBetween = 6
}
export class Quizz {
  id?: number;
  Id?: number;
  materialId?: number;
  unitId?: number;
  name: string;
  notes: string;
  timeLimit?: number;
  password: string;
  shuffle: boolean;
  repetition: number;
  beforeStart: boolean;
  optional: boolean;
  ifPassed: Result;
  ifFailed: Result;
  history: number;
  minScore: number;
  addedBy: number;
  addedDate?: Date;
  updateAt?: Date;
}

export class Question {
  id?: number;
  Id?: number;
  questionType: QuestionTypes;
  question: string;
  score: number;
  optional: boolean;
  quizzId: number;
}
export class Answer {
  id?: number;
  Id?: number;
  questionId?: number;
  answer: string;
  explanation: string;
  isCorrectAnswer: boolean;
}
export class PairOfAnswers {

  id?: number;
  Id?: number;
  questionId: number;
  first: string;
  second: string;

}
