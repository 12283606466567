import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Directive, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';


@Directive({
  selector: '.tooltip-container'
})
export class TooltipContainerDirective {
}

@Component({
  selector: 'sm-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('smTooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(30, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TooltipComponent implements OnInit {

  top: string;
  @ViewChild(TooltipContainerDirective, { read: ElementRef }) private tooltipContainer;

  constructor(@Inject('tooltipConfig') private config) {
  }

  ngOnInit() {
    const { top } = this.config.host.getBoundingClientRect();
    // const { height } = this.tooltipContainer.nativeElement.getBoundingClientRect();
    // this.top = `${top - height}px`;
  }

}
