import { ConsultancyService } from './../../../core/services/consultancy.service';
import { Consultancy, Consultants } from './../../../core/_models/consultancy';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { COUCategories, Trainer } from './../../../core/_models';

import { Router } from '@angular/router';
import { FileuploaderComponent } from '../../components/fileuploader/fileuploader.component';
import { environment } from './../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
declare var Noty: any;
import Bounce from 'bounce.js';
import { Store, select } from '@ngrx/store';
import { SettingsService, NotfiyService, CoursesService } from '../../../core/services';
import { ReportsService } from '../../../core/services/reports.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AlertType } from './../../../core/_models/system';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertMessageComponent } from './../../dialogs/alert-message/alert-message.component';
import { AlertconfirmmessageComponent } from './../../dialogs/alertconfirmmessage/alertconfirmmessage.component';
import { Observable, Subscription } from 'rxjs';
import * as _categoriesreducer from '../../../core/state/_courses/categories.reducer'

interface ConsultancyDialog {
  isNew: boolean;
  oldItem: any;
  consultantId: number;
  consultants: any[];
}

@Component({
  selector: 'sm-newconsultancydialog',
  templateUrl: './newconsultancydialog.component.html',
  styleUrls: ['./newconsultancydialog.component.scss'],
  
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewconsultancydialogComponent implements OnInit, OnDestroy {


  newCourseC: UntypedFormGroup;
  buttonC
  lang
  profile
  loading
  isPublic
  allConsultants = [];
  submitted
  consultantId = 0;
  categoriesSubscription = new Subscription()
  categories$: Observable<COUCategories[]>


  constructor(private _CoursesService:CoursesService,private _service: ConsultancyService, private cd: ChangeDetectorRef, private _router: Router,
    private _dialogRef: MatDialogRef<NewconsultancydialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConsultancyDialog, private _dialog: MatDialog, private _translate: TranslateService, private _report: ReportsService, private _store: Store<any>, private _settings: SettingsService,
    private ngNoty: NotfiyService,
    private formBuilder: UntypedFormBuilder,) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }

    this.buttonC = this._translate.instant('CONSULTANT.ADDCONSULTANCY');
    this.lang = localStorage.getItem('language')
    this.consultantId = data.consultantId;
  }

  allCategories = [];


  ngOnDestroy(): void {
    this.categoriesSubscription.unsubscribe();
  }

  ngOnInit(): void {


    this._store.dispatch({ type: '[Categories] Load All Categories' });


    this.getAllCat()

    this.allConsultants = this.data.consultants;
    // this._store.dispatch({ type: '[SYSTEM] We Are On The Load' });
    this.newCourseC = this.formBuilder.group({
      id: null,
      name: [{ value: '', disabled: this.loading }, Validators.required],
      // body: [{ value: '', disabled: this.loading }, Validators.required],
      parentx: [{ value: '', disabled: this.loading }],
      image: [{ value: '', disabled: this.loading }],
      squareImage: [{ value: '', disabled: this.loading }],
      price: [{ value: '', disabled: this.loading }],
      numberOfSessions: [{ value: '', disabled: this.loading }],
      subscriptionLimit: [{ value: '', disabled: this.loading }],
      typeOfSession: [{ value: '', disabled: this.loading }],
      calendlyLink: [{ value: '', disabled: this.loading }, Validators.required],
      categories: this.selectedCategories,
      notes: [{ value: '', disabled: this.loading }],
      time: [{ value: '', disabled: this.loading }],
      consultantId: this.consultantId,
      active: false,
    });

  }

  get xfc() { return this.newCourseC.controls; }

getAllCat(){
  this.categories$ = this._CoursesService.AllCategories()
  this.categoriesSubscription = this.categories$.subscribe((data) => {
    this.allCategories = data;
    if (!this.data.isNew) {
      this.modifyC(this.data.oldItem);
    }
    
    // this.cd.markForCheck();
  })
}

  modifyC(dataItem) {
    let parents = [];

    if (dataItem.categories && dataItem.categories.length != 0) {

      dataItem.categories.forEach(category => {
        // console.log(category);

        const item = this.allCategories.find(x => x.id == category.categoryId);
        if (item)
          parents.push(item.name);
      });
    }

    $('.newprofile').css('background-image', 'url(' + dataItem.image + ')').addClass('hasImage');
    $('.newprofile2').css('background-image', 'url(' + dataItem.squareImage + ')').addClass('hasImage');
    this.buttonC = this._translate.instant('CONSULTANCY.MODIFYCONSULTANCY');
    this.isPublic = dataItem.active;

    


    this.newCourseC.patchValue({
      id: dataItem.id | dataItem.Id,
      name: dataItem.name,
      consultantId: dataItem.consultantId,
      image: dataItem.image,
      squareImage: dataItem.squareImage,
      price: dataItem.price,
      calendlyLink: dataItem.calendlyLink,
      typeOfSession: dataItem.typeOfSession,
      subscriptionLimit: dataItem.subscriptionLimit,
      numberOfSessions: dataItem.numberOfSessions,
      time: dataItem.time,
      parentx: (parents.length != 0) ? parents : null,
      active: dataItem.active,
    
      // parentx: this.allConsultants.find(x => x.id == dataItem.consultantId || x.Id == dataItem.consultantId).name
    });
    
  }


  setPublic() {
    this.isPublic = !this.isPublic;
    this.newCourseC.patchValue({
      active: this.isPublic
    })
  }


  getSquare($event) {
    this.fileToUpload3 = $event[0];
  }

  fileToUpload3: File = null;
  fileToUpload: File = null;

  @ViewChild(FileuploaderComponent, { static: false }) uploader: FileuploaderComponent;
  selectedCategories = [];


  onSubmitC() {
    this.submitted = true;
    if (this.newCourseC.invalid) {
      return;
    }
    this.loading = true;

    var categories = this.newCourseC.get('parentx').value;
    if (categories != '' && categories != null && categories != 'null') {

      // console.log(categories);
      categories.forEach(cateogry => {
        var category = this.allCategories.find(x => x.name == cateogry);
        this.selectedCategories.push({
          conultancyId: this.newCourseC.get('id').value,
          categoryId: category.id
        })
        this.newCourseC.patchValue({
          categories: this.selectedCategories
        })
      });

    } else {
      this.newCourseC.patchValue({
        categoryId: null
      });
    }

    var files = [this.fileToUpload, this.fileToUpload3];
    // console.log(files);

    console.log(this.newCourseC.value);
    // return;
    this._service.addOrModifyConsultancy(files, this.newCourseC.value)
      .subscribe(() => {
        if (this.data.isNew) {
          var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.CONSULTANCYADDEDSUCCESSFULLY'), type: AlertType.Success }
          const dialogRef = this._dialog.open(AlertMessageComponent, {
            data: alertData,
            height: '200px',
            width: '400px'
          })
          dialogRef.afterClosed().subscribe((data) => {
            this._dialogRef.close(this.data.oldItem);
          })
        } else {
          var alertData = { title: this._translate.instant('DIALOGS.DONE'), body: this._translate.instant('DIALOGS.CONSULTANCYMODIFIEDSUCCESSFULLY'), type: AlertType.Success }
          const dialogRef = this._dialog.open(AlertMessageComponent, {
            data: alertData,
            height: '200px',
            width: '400px'
          })
          dialogRef.afterClosed().subscribe((data) => {
            this._dialogRef.close(this.data.oldItem);
          })
        }
      })
  }



  getFile($event) {
    this.fileToUpload = $event[0];
  }


  upload() {
    document.getElementById('mediaFile1').click();
  }

  upload2() {
    $('#mediaFile2').click();
  }

  addFile(e) {


    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(reader.result);
        $('.newprofile').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }
  addFile2(e) {
    var input = e.target;
    if (input.files && input.files[0]) {
      var file = input.files[0];
      this.fileToUpload3 = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(reader.result);
        $('.newprofile2').css('background-image', 'url(' + reader.result + ')').addClass('hasImage');
      }
    }
  }

}
