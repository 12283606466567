export class Unites {
  Id?: number;
  id?: number;
  name: string;
  image: string;
  video: string;
  notes: string;
  addedBy?: number;
  trainerId: number;
  addedDate?: Date;
  updateDate?: Date;

}
