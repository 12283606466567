import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _reducer from './../../../core/state/_system/system.reducer';

@Component({
  selector: 'sm-breacrumb',
  templateUrl: './breacrumb.component.html',
  styleUrls: ['./breacrumb.component.scss']
})
export class BreacrumbComponent implements OnInit {

  level1$;
  level1Name$;
  level2$;
  level2Name$;
  level3$;
  level3Name$;
  level4$;
  level4Name$;
  level5$;
  level5Name$;
  level6$;
  level6Name$;
  level7$;
  level7Name$;
  baseURL$;

  constructor(private _store: Store<any>) { }

  ngOnInit(): void {
    this.level1$ = this._store.pipe(select(_reducer.level1URL));
    this.level1Name$ = this._store.pipe(select(_reducer.level1URLName));
    this.level2$ = this._store.pipe(select(_reducer.level2URL));
    this.level2Name$ = this._store.pipe(select(_reducer.level2URLName));
    this.level3$ = this._store.pipe(select(_reducer.level3URL));
    this.level3Name$ = this._store.pipe(select(_reducer.level3URLName));
    this.level4$ = this._store.pipe(select(_reducer.level4URL));
    this.level4Name$ = this._store.pipe(select(_reducer.level4URLName));
    this.level5$ = this._store.pipe(select(_reducer.level5URL));
    this.level5Name$ = this._store.pipe(select(_reducer.level5URLName));
    this.level6$ = this._store.pipe(select(_reducer.level6URL));
    this.level6Name$ = this._store.pipe(select(_reducer.level6URLName));
    this.level7$ = this._store.pipe(select(_reducer.level7URL));
    this.level7Name$ = this._store.pipe(select(_reducer.level7URLName));
  }



  resetBefore(num) {
    switch (num) {
      case 1:
        this._store.dispatch({ type: '[SYSTEM] Reset Level1 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level2 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level3 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level4 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level5 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level6 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level7 URL' })
        break;

      case 2:
        this._store.dispatch({ type: '[SYSTEM] Reset Level3 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level4 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level5 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level6 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level7 URL' })
        break;
      case 3:

        this._store.dispatch({ type: '[SYSTEM] Reset Level4 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level5 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level6 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level7 URL' })
        break;
      case 4:

        this._store.dispatch({ type: '[SYSTEM] Reset Level5 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level6 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level7 URL' })
        break;
      case 5:

        this._store.dispatch({ type: '[SYSTEM] Reset Level6 URL' })
        this._store.dispatch({ type: '[SYSTEM] Reset Level7 URL' })
        break;
      case 6:
        this._store.dispatch({ type: '[SYSTEM] Reset Level7 URL' })
        break;
      case 7:
        break;

      default:
        break;
    }
  }

}
