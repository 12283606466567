import { Injectable } from '@angular/core';
import { NotyOptions, NotyModel } from './notify.model';

declare let Noty: any;



@Injectable()
export class NotfiyService {
    create(options: NotyOptions): NotyModel {
        return new Noty(options);
    }

    closeAll(name?: string) {
        Noty.closeAll(name);
    }

    setMaxVisible(count: number, name?: string) {
        Noty.setMaxVisible(count, name);
    }
}