import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filtercopoun'
})
export class FilterCopounPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    //  console.log(items,searchText);

    return items.filter(it => {


      if (typeof (it.code) === 'undefined')
        it.code = '';



      return it.code.includes(searchText)
    });
  }
}
