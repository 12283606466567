import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType } from '../../../core/_models/system';



export interface AlertData {
  title: string;
  body: string;
  type: AlertType;
}



@Component({
  selector: 'sm-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AlertMessageComponent implements OnInit {


  alertType: AlertType;
  alertTitle: string;
  alertBody;
  alertTypeClass = ''

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertData) {
    this.alertBody = data.body;
    this.alertTitle = data.title;
    this.alertType = data.type;
    switch (this.alertType) {
      case AlertType.Error:
        this.alertTypeClass = 'dangers'
        break;
      case AlertType.Information:
        this.alertTypeClass = 'infos'
        break;
      case AlertType.Warning:
        this.alertTypeClass = 'warnings'
        break;
      case AlertType.Success:
        this.alertTypeClass = 'successs'
        break;
      case AlertType.Primary:
        this.alertTypeClass = 'primarys'
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
  }

}
