import { BehaviorSubject } from 'rxjs';
import { PlayerService } from './../player.service';
import { Component, OnInit, Input, ViewChild, ViewEncapsulation, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, ElementRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
declare var $: any;
import { OnDestroy } from '@angular/core';
import { questionWithAnswers } from './../../../../core/_models';
import videojs from 'video.js';
// require('videojs-contrib-quality-levels');
// require('videojs-selector-quality-hls');
// require('videojs-max-quality-selector');
// require('videojs-seek-buttons');

@Component({
  selector: 'sm-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerComponent implements OnInit, AfterViewInit, OnChanges {


  @Input() questions: questionWithAnswers[] = [];
  @Output() currentTime = new EventEmitter<number>();
  @Output() totalTime = new EventEmitter<number>();
  @Input() isAdmined: boolean = false;
  @Input() link: string;
  @Input() id: number = 1;

  // @Input() bookmarks: VIDBookmarksDTO[];



  top = 0;
  left = 0;
  public video: any = null;
  // public mediaSource = new MediaSource();
  private didAnswerTheQuestion = false;
  playback = new BehaviorSubject<string>('1.0x');
  get didAnswer() {
    return this.didAnswerTheQuestion;
  }
  set didAnswer(event) {
    this.didAnswerTheQuestion = event;
    if (event) {
      this.canContinue.next(true);
      this.toBeQuestioned.next(false);
      this.play();
    } else {

      if (this.question) {
        if (this.question.fallBack) {
          this.canContinue.next(true);
          this.question.isAnswered = false;
          this.video.currentTime = Math.floor(this.question.fallBack);
          this.toBeQuestioned.next(false);
          this.play();
        } else {
          this.canContinue.next(true);
          this.toBeQuestioned.next(false);
          this.play();
        }
      }
    }
  }
  elapsed = new BehaviorSubject<string>('00:00:00');
  total = new BehaviorSubject<string>('00:00:00');
  sound = new BehaviorSubject<number>(0);
  xFactor = new BehaviorSubject<number>(0);
  canContinue = new BehaviorSubject<boolean>(true);
  private _question: any;
  get question() {
    return this._question;
  }
  set question(any) {
    var question: any = {};
    // console.log(any);

    var questionBody: any[] = any.question.question.split(' ');
    for (let x = 0; x < questionBody.length; x++) {
      for (let i = 0; i < any.answers.length; i++) {
        var elements = any.answers[i];
        // console.log(element + " SSS "  + elements.answer);

        if (questionBody[x].includes(elements.answer)) {
          questionBody[x] = "X";
        }
      }
    }
    // console.log(questionBody);

    question.questionbody = questionBody;
    question.question = any;
    question.isAnswered = false;
    question.answers = any.answers;

    // console.log(question)
    this._question = question;
  }

  get volume() {
    return this.sound.value;
  }
  set volume(volume: number) {
    // console.log(volume);
    this.video.volume = volume;
    this.sound.next(volume)
  }

  toBeQuestioned = new BehaviorSubject<boolean>(false);
  toBeBookMarked = new BehaviorSubject<boolean>(false);

  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };
  player: any = videojs.Player;
  private _soundHovered = true;
  get soundHovered() {
    return this._soundHovered;
  }
  set soundHovered(any) {
    this._soundHovered = any;
    this.releaseVolume();
  }

  private _controlbtnsHovers = true;
  get controlbtns() {
    return this._controlbtnsHovers;
  }
  set controlbtns(any) {
    this._controlbtnsHovers = any;
    this.OnVideoRelease();
  }

  // didAnswer = false;

  constructor(private _service: PlayerService, private cd: ChangeDetectorRef) {
    // this.link = 'https://d37no3h285pbq4.cloudfront.net/media/newtestfortest.m3u8?Expires=1600764745&Signature=YnXhcNMzSYISm~kavs8nd5tBFEXBgCYvVBOcgBKEhSr5pr-X3zsj3x3l936TIua8I2vHlZewgeZ3SoxVjH05xTOG~60nuZTy7k7eej4TP7rBrPvGBKvpCWM3Z-TCYaFcQr17RhqXU~9yvfCJC3VIzWgGMnYiuLl5IJuJ-tlKGiP52dnRV3vvmHjLdsixlVMRrKUnoZuUSCLOxMebvXhlSxdJ9WUfCLaAAaHWWADZ3RQqQDf2E5el8JWxGkwgLuh8PR~mjnhrJSrslPfW9k87kYU7vWc3S8pqzvnaU6Z41es9DBPjguvYBATqkjgZeg4zI6u2Lq-lEpHIWdszXedcBw__&Key-Pair-Id=APKAID5WEEPQO2ISQPTA';
    this.id = 1;
    videojs.options.hls.overrideNative = true;
    videojs.options.html5.nativeAudioTracks = false;
    videojs.options.html5.nativeTextTracks = false;

  }
  ngOnChanges(changes: SimpleChanges): void {

    for (let change in changes) {
      if (change == 'link') {

        this.cd.markForCheck();
        this.reloadVideo();
      }
    }
  }




  ngOnDestroy(): void {
    this.video = document.getElementById('video-player-' + this.id);
    videojs(this.video).dispose();
    if (this.player) {
      this.player.dispose();
    }
  }


  ngAfterViewInit(): void {
    this.reloadVideo();
  }


  reloadVideo() {
    // this.video = document.getElementById('video-player-' + this.id);

    const videoControls = document.getElementById('video-controls');
    // var urlTpl = environment.APIURL + '/api/settings/key';
    const videoWorks = !!document.createElement('video').canPlayType;
    const loader = $('.vjs-loading-spinner').style = { opacity: 0 }
    var link1 = "https://d37no3h285pbq4.cloudfront.net/media/50xb1ix9hrt3uvhl73oduv6uf.m3u8?Expires=1591458228&Signature=GUGiIH~XcHeKY4YXOsJ5eB~aCV9ZVfVlD2FaXvYSGGtTZOmMup0T5yceBR-DLvq7RxLQhxa-L8iJjKCx5eRhNF9-SYLIZsa-Pkw7pAXedAzb9LYEka1zS-LbugAAtDFc6Jo9JWcDsnHruQKP5z~z87snQXExJc6bzjPBS6-QDxM64TBnURS3sF8xstOej1K2AJSAfvtRnAURkhrEsKJbcH4LS0UHPkt0Ca65O54mn1dsKirbhlJJ78P9jEw0jsyPlfPO2eNaDXMj9YDTfYJNAKiBgs1SYnd5eJOnwibbtmSjigWtNWk8KEwpzy7-ysJVvjApp23SYF0pMnt8SZgkTg__&Key-Pair-Id=APKAID5WEEPQO2ISQPTA";
    var link2 = 'https://d37no3h285pbq4.cloudfront.net/media/i1fhw4mrsmtk02feftaypm5a3.m3u8?Expires=1592492987&Signature=Cax0hDK~OoVZUaAUDU63nBHPJNhR0gJ9~PTC5lKWzw7Lrs6MJ1FFS3m4Lf6SkY9wcIK9lLxE1weOtVGAAKZoK7sROhJN~UdHcxIM1vOlJM2hyDjWfhmbJCUC4BBL39QRQvn6-yrXaC0DrmbBTdKlwb1mRWgbdkjERC1Onx7vNO1jB7rhL6FCJIGXNFjl~UPlJ7yi2SdJz0sDTk6XexsJRA0oBVBoZIPc6kxfEkSP791Vn4jbJXFx2TkaWmQesvLkW4xM1sLFoxrYtA4hFUVOAafpmT6Dc2TH0LbW1fRjzD8~tYyHRct1BNjRpqgAOLxGNN8Al0YCMYKwW7V6mGUr8g__&Key-Pair-Id=APKAID5WEEPQO2ISQPTA';
    var link3 = 'https://bitmovin-a.akamaihd.net/content/playhouse-vr/mpds/105560.mpd';
    var link4 = 'https://s3.amazonaws.com/_bc_dml/example-content/sintel_dash/sintel_vod.mpd';
    var overrideNative = true;

    let self = this;

    // Useful if you need to specify labels in a sparce list
    var labelsObject = {
      0: '1080',
      8: 'جودة متوسطة',
      16: 'جودة منخفضة',
      24: 'جودة منخفضة جدا'
    };

    var labelsArray = ['جودة متوسطة', 'جودة عالية', 'جودة منخفضة'];
    // console.log(document.getElementById('video-player-' + this.id));

    this.video = this.player = videojs(document.getElementById('video-player-' + this.id), {
      html5: {
        hls: {
          overrideNative: !videojs.browser.IS_SAFARI,
          smoothQualityChange: true,
          withCredentials: true,
          handleManifestRedirects: true

        },
        nativeVideoTracks: !overrideNative,
        nativeAudioTracks: !overrideNative,
        nativeTextTracks: !overrideNative,
        withCredentials: true
      },
      playbackRates: [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0],
      sources: {
        // src: "https://d37no3h285pbq4.cloudfront.net/media/nourlatestone.m3u8?Expires=1600776283&Signature=GvMR7apds4Fr06L8gTCoF1lb8kDZSjCHBuRhLMHBJK6aHp0gj-XpSAWDXNytT6qQYC1R36-MH5n6SLOwIwSzCojNOzNkieFAIZQe-D-dPBtpYkUSFk-mVB~~vsj1QivXIOnNARlN4v1opx6NOifJnV18davAHH-HV9jIaFoph0Cz4YP0FCnqPkXXDYiSqBfopR0a6TOSS7xib00dISalQ0QzBKjufefFj~RE1RteLtKT30wOqHYX1Pa61a0mEFy1W4-WHWOkUoHnoT3sNIqBnDlJb-LvbR-6VKUsWERO4fCSgNk~BkNQR8eux9UQch5D~VhXkxs2tYmj7DdIUaTR3w__&Key-Pair-Id=APKAID5WEEPQO2ISQPTA",
        src: this.link,
        // src: "https://d37no3h285pbq4.cloudfront.net/media/ninolatest.m3u8?Expires=1600784700&Signature=H5ISLV6Ko-4KeuKYTh2hiZzzG8CjR5OuzTvqWwi80RxdUY9U7uBeqa2lR0efAkYGleEShVKWOPHH4l7GaMKzawZTaqiNBGBXkfPL28BqBrZ4t8Vbc9pTqpRU6jQAehEizTpT9-OMzuRfLTpcdQJzIduVaJw18vrqCPHr4VpaHtgarpxwn8t7lAmI4JDdPRAbmXG0304UkE7kLRqLYrwphl-80BKPLshLKTqclZgeeL0A2TjcxFgZVsrCnZS1~WTzC-ssB7RUmcNK5MfuDIO77HNj7kSY0qL45QZh1gQICU9gePxxHbbwSSo7yBKIddoEV7PxVzj0ObZeOYBwTeqAiQ__&Key-Pair-Id=APKAID5WEEPQO2ISQPTA",
        type: "video/mp4",
        withCredentials: true
        // type: "application/dash+xml"
      },

    }, function onPlayerReady() {

      // console.log('WE WE WE WE WEWEWE');

      // videojs.Hls.xhr.beforeRequest = function (options) {
      //   var profile = JSON.parse(localStorage.getItem('mprofile'));
      //   let token = profile.token;
      //   options.headers = {
      //     'Authorization': 'Bearer ' + token,
      //     'Content-Type': 'application/json'
      //   }
      //   // console.log(options);
      //   return options;
      // };


      // TweenMax.to('.sm-player-seeker-done', .1, { width: '0px', transformOrigin: "100% 50%" })
      let that = self;
      self.sound.next(this.volume())
      this.on('loadedmetadata', () => {
        // console.log(self.bookmarks);


        // if (self.bookmarks.length != 0) {
        //   for (let i = 0; i < self.bookmarks.length; i++) {
        //     const element = self.bookmarks[i];
        //     var container: any = document.getElementById('sm-player-container');
        //     var poisition = (((element.atTime + that.xFactor.value) * container.offsetWidth) / this.duration());
        //     // console.log(poisition);

        //     TweenMax.set('.sm-question-' + i, {
        //       css: {
        //         left: poisition + 'px'
        //       }
        //     })

        //   }
        // }

        // console.log('THE DURATION' + this.duration());

        that.totalTime.emit(this.duration());
        const result = new Date(this.duration() * 1000).toISOString().substr(11, 8);
        const time = {
          hours: result.substr(0, 2),
          minutes: result.substr(3, 2),
          seconds: result.substr(6, 2),
        };
        that.total.next(time.hours + ':' + time.minutes + ':' + time.seconds);
      });
      // Follow The Time Progress
      this.on('timeupdate', () => {


        // console.log(self.player.videoHeight());
        // console.log(self.player.videoWidth());


        const videoDuration = Math.round(this.currentTime());
        // that.totalTime.emit(videoDuration);
        var result = ''


        if (videoDuration && videoDuration > 0) {
          result = new Date(videoDuration * 1000).toISOString().substr(11, 8);
        }
        // console.log(result);
        if (videoDuration % 1 == 0) {
          // console.log(videoDuration);


        }
        that.currentTime.emit(videoDuration);
        // console.log('WE ARE RIGHT HERE ' + videoDuration);

        const time = {
          hours: result.substr(0, 2),
          minutes: result.substr(3, 2),
          seconds: result.substr(6, 2),
        };
        var container: any = document.getElementById('sm-player-container');
        that.elapsed.next(time.hours + ':' + time.minutes + ':' + time.seconds);
        var current = Math.floor(this.currentTime());
        var currentPosition = (((current + that.xFactor.value) * container.offsetWidth) / this.duration());
        if (that.questions.length != 0) {
          if (that.questions.find(x => Math.floor(x.question.atTime) == current) != null && !that.didAnswer) {
            // console.log('WE ARE NOT HERE');
            this.pause();
            // TweenMax.set('.icon-control-pause', { autoAlpha: 0 });
            // TweenMax.set('.icon-control-play', { autoAlpha: 1 });
            that.canContinue.next(false);
            that.toBeQuestioned.next(true);
            that.question = that.questions.find(x => Math.floor(x.question.atTime) == current);
          } else {
            // console.log(that.question.question.question.atTime);

            if (that.question && current > that.question.question.question.atTime && that.didAnswer == true) {
              // console.log('WE ARE HERE');
              this.play();
              that.canContinue.next(true);
              that.toBeQuestioned.next(false);
              that.didAnswer = false;
            }
          }
        }
        // TweenMax.to('.sm-player-seeker-done', .5, { width: currentPosition + 'px', transformOrigin: "100% 50%" })
        // if (current == videoDuration) {
        //   TweenMax.to('.sm-player-seeker-done', .1, { width: '0px', transformOrigin: "100% 50%" })
        // }
        that.cd.markForCheck();
      });
      this.on('timeupdate', () => {


        // console.log(self.player.videoHeight());
        // console.log(self.player.videoWidth());


        const videoDuration = Math.round(this.currentTime());
        var result = ''


        if (videoDuration && videoDuration > 0) {
          result = new Date(videoDuration * 1000).toISOString().substr(11, 8);
        }
        // console.log(result);
        if (videoDuration % 1 == 0) {
          // console.log(videoDuration);
          that.currentTime.emit(videoDuration);

        }

        that.cd.markForCheck();
      });
      this.on('loadeddata', (e) => {
        // const duration = Math.round();
        console.log(e);

        that.xFactor.next(this.duration() / screen.width);

      })

      // this.on("loadstart", function (e) {
      //   self.player.tech().hls.xhr.beforeRequest = function (options) {
      //     console.log(options);

      //   };
      // });
    });
    // console.log(this.player.maxQualitySelector.getLevelNames());

    // this.player.maxQualitySelector({
    //   'autoLabel': 'تلقائي', // Change the label from 'Auto' (default) to 'ABR'.
    //   'defaultQuality': 1
    // });

    this.player.seekButtons({
      forward: 30,
      back: 10
    });

    $('.vjs-progress-holder').click((e) => {

      // WE STOPPED IT FOR THE DIPLOMA THING !
      // this.click(e)

    })

    // this.player.hlsQualitySelector({ displayCurrentQuality: true, });
  }

  // X DurationOfVideo
  // Y
  private findPosition(x, y, z) { }

  unMute() {
    this.volume = (this.volume == 0) ? this.volume = 0.25 : 0;
  }

  async extractFramesFromVideo(videoUrl, fps = 25) {
    return new Promise(async (resolve) => {

      // fully download it first (no buffering):
      let videoBlob = await fetch(videoUrl).then(r => r.blob());
      let videoObjectUrl = URL.createObjectURL(videoBlob);
      let video = document.createElement("video");

      let seekResolve;
      video.addEventListener('seeked', async function () {
        if (seekResolve) seekResolve();
      });

      video.src = videoObjectUrl;

      // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
      while ((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {

        await new Promise(r => setTimeout(r, 1000));
        video.currentTime = 10000000 * Math.random();
      }
      let duration = video.duration;

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let [w, h] = [video.videoWidth, video.videoHeight]
      canvas.width = w;
      canvas.height = h;

      let frames = [];
      let interval = 1 / fps;
      let currentTime = 0;

      while (currentTime < duration) {
        video.currentTime = currentTime;
        await new Promise(r => seekResolve = r);

        context.drawImage(video, 0, 0, w, h);
        let base64ImageData = canvas.toDataURL();
        frames.push(base64ImageData);

        currentTime += interval;
      }
      resolve(frames);
    });
  }


  ngOnInit(): void {



  }



  hoverVolume() {
    // TweenMax.set('.sm-player-sound-control', { css: { display: 'block' } });
    // TweenMax.from('.sm-player-sound-control', .5, { y: 20, autoAlpha: 0 });
    this.soundHovered = true;
  }

  releaseVolume() {
    if (this.soundHovered) {

    } else {
      setTimeout(() => {
        // TweenMax.to('.sm-player-sound-control', .5, { y: 0, autoAlpha: 0 });
        // TweenMax.set('.sm-player-sound-control', { css: { display: 'none' }, delay: .5 });
        setTimeout(() => {
          // TweenMax.set('.sm-player-sound-control', { css: { opacity: 1 } })
        }, 500);
      }, 1000);
    }

  }

  onMouseDown($event) {
    if ($event.which == 3) {
      this.top = $event.y;
      this.left = $event.x;
      $('#video-player-' + this.id).bind('contextmenu', function () { return false; });
    } else {
      this.top = 0;
      this.left = 0;
    }
  }


  fullScreen() {
    // this.video = document.getElementById('video-player-' + this.id);
    // e.preventDefault();
    if (this.video.paused) {
      $('.vjs-play-control').click();
    }
    $('.vjs-fullscreen-control').click();

  }

  play() {
    if (this.canContinue.value) {
      if (this.video.paused || this.video.ended) {
        // TweenMax.set('.icon-control-play', { autoAlpha: 0 });
        // TweenMax.set('.icon-control-pause', { autoAlpha: 1 });

        this.player.play();
        setTimeout(() => {
          this.OnVideoRelease();
        }, 3000);
      } else {
        this.player.pause();
        // TweenMax.set('.icon-control-pause', { autoAlpha: 0 });
        // TweenMax.set('.icon-control-play', { autoAlpha: 1 });
      }
    } else {
      //   if (this.video.paused || this.video.ended) {
      //     TweenMax.set('.icon-control-play', { autoAlpha: 0 });
      //     TweenMax.set('.icon-control-pause', { autoAlpha: 1 });

      //     this.player.play();
      //     setTimeout(() => {
      //       this.OnVideoRelease();
      //     }, 3000);
      //   } else {
      //     this.player.pause();
      //     TweenMax.set('.icon-control-pause', { autoAlpha: 0 });
      //     TweenMax.set('.icon-control-play', { autoAlpha: 1 });
      //   }
    }
  }

  hoverPlayBtn() {
    // TweenMax.to('#sm-play-btn', .5, { scale: 1.3, autoAlpha: 1, transformOrigin: "50% 50%" })
    // setTimeout(() => {
    //   $('#video-player-' + this.id).unbind('mouseenter mouseleave');
    //   $('#video-player-' + this.id).bind('mouseenter mouseleave');
    // }, 100);
  }

  releasePlayBtn() {
    // TweenMax.to('#sm-play-btn', .5, { scale: 1, autoAlph: 1 })
    // setTimeout(() => {
    //   $('#video-player-' + this.id).unbind('mouseenter mouseleave');
    //   $('#video-player-' + this.id).bind('mouseenter mouseleave');
    // }, 100);
  }



  onVideoHover(event) {

    // console.log(event);
    // TweenMax.to('#sm-play-btn', .5, { autoAlpha: 1 });
    // TweenMax.to('.sm-player-control', .5, { autoAlpha: 1 });
    if (!this.video.paused && !this.video.ended) {
      setTimeout(() => {

        this.OnVideoRelease()
      }, 5000);
    }
  }
  OnVideoRelease() {

    // TweenMax.to('#sm-play-btn', .5, { autoAlpha: 0 });

    if (this.controlbtns) {

    }
    else {
      // TweenMax.to('.sm-player-control', .5, { autoAlpha: 0 });
    }
  }


  enterMouseOnVideo() {
    // TweenMax.set('.sm-player-control', { css: { display: 'block' } })

  }

  click(event) {
    var container: any = document.getElementById('sm-player-container');
    var progressBar: any = document.getElementById('sm-player-seeker-done');

    if (this.isAdmined) {
      // console.log(this.video.currentTime);
      var currentPosition = ((event.x) / container.offsetWidth) * this.video.duration;
      this.video.currentTime = (this.video.currentTime != 0) ? Math.floor(currentPosition) : 0;
      if (parseInt(progressBar.style.width) > event.x) {
        // TweenMax.to('.sm-player-seeker-done', .1, { width: event.x + 'px', transformOrigin: "100% 50%" })
      } else {
        // TweenMax.to('.sm-player-seeker-done', .1, { width: event.x + 'px', transformOrigin: "100% 50%" })
      }

      // this.play();

      if (this.video.paused || this.video.ended) {
        // TweenMax.set('.icon-control-play', { autoAlpha: 0 });
        // TweenMax.set('.icon-control-pause', { autoAlpha: 1 });

        this.player.pause();
        // setTimeout(() => {
        //   this.OnVideoRelease();
        // }, 3000);
      } else {
        this.player.play();
        // TweenMax.set('.icon-control-pause', { autoAlpha: 0 });
        // TweenMax.set('.icon-control-play', { autoAlpha: 1 });
      }


    } else {
      if (this.canContinue.value) {

        var currentPosition = ((event.clientX) / container.offsetWidth) * this.video.duration;

        if (this.questions.length != 0) {
          for (let i = 0; i < this.questions.length; i++) {
            const element = this.questions[i];
            if (Math.floor(element.question.atTime) < Math.floor(currentPosition)) {
              // console.log(this.question);
              if (this.question) {
                // console.log('WE ARRIVED YABA');
                // console.log(Math.floor(element.question.atTime) + 'WE ARRIVED YABA' + Math.floor(this.question.question.question.atTime));
                if (Math.floor(element.question.atTime) == Math.floor(this.question.question.question.atTime)) {
                  if (this.question.isAnswered) {
                    continue;
                  } else {
                    this.video.currentTime = Math.floor(element.question.atTime);
                    // var x = (((Math.floor(element.question.atTime) + this.xFactor.value) * screen.width) / this.video.duration);
                    // console.log(x);
                    // TweenMax.to('.sm-player-seeker-done', .01, { width: x + 'px', transformOrigin: "100% 50%" })
                  }
                }
              }
              if (element.isAnswered) {
                continue;
              } else {
                this.video.currentTime = Math.floor(element.question.atTime);
                // var x = (((Math.floor(element.question.atTime) + this.xFactor.value) * screen.width) / this.video.duration);
                // console.log(x);
                // TweenMax.to('.sm-player-seeker-done', .01, { width: x + 'px', transformOrigin: "100% 50%" })
              }

            } else {
              // if (parseInt(progressBar.style.width) > event.x) {
              //   TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
              // } else {
              //   TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
              // }
              this.video.currentTime = Math.floor(currentPosition);
            }
            return;
          };

        } else {

          this.video.currentTime = Math.floor(currentPosition);
          // if (parseInt(progressBar.style.width) > event.x) {
          //   TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
          // } else {
          //   TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
          // }
        }
        // this.play();
        this.player.play();

      } else {

      }
    }
  }

  // click(event) {
  //   var container: any = document.getElementById('sm-player-container');
  //   var progressBar: any = document.getElementById('sm-player-seeker-done');

  //   if (this.isAdmined) {
  //     // console.log(this.video.currentTime);
  //     var currentPosition = ((event.x) / container.offsetWidth) * this.video.duration;
  //     this.video.currentTime = (this.video.currentTime != 0) ? Math.floor(currentPosition) : 0;
  //     if (parseInt(progressBar.style.width) > event.x) {
  //       TweenMax.to('.sm-player-seeker-done', .1, { width: event.x + 'px', transformOrigin: "100% 50%" })
  //     } else {
  //       TweenMax.to('.sm-player-seeker-done', .1, { width: event.x + 'px', transformOrigin: "100% 50%" })
  //     }

  //     // this.play();

  //     if (this.video.paused || this.video.ended) {
  //       // TweenMax.set('.icon-control-play', { autoAlpha: 0 });
  //       // TweenMax.set('.icon-control-pause', { autoAlpha: 1 });

  //       this.player.pause();
  //       // setTimeout(() => {
  //       //   this.OnVideoRelease();
  //       // }, 3000);
  //     } else {
  //       this.player.play();
  //       // TweenMax.set('.icon-control-pause', { autoAlpha: 0 });
  //       // TweenMax.set('.icon-control-play', { autoAlpha: 1 });
  //     }


  //   } else {
  //     if (this.canContinue.value) {

  //       var currentPosition = ((event.x) / container.offsetWidth) * this.video.duration;

  //       if (this.questions.length != 0) {
  //         for (let i = 0; i < this.questions.length; i++) {
  //           const element = this.questions[i];
  //           if (Math.floor(element.question.atTime) < Math.floor(currentPosition)) {
  //             // console.log(this.question);
  //             if (this.question) {
  //               // console.log('WE ARRIVED YABA');
  //               // console.log(Math.floor(element.question.atTime) + 'WE ARRIVED YABA' + Math.floor(this.question.question.question.atTime));
  //               if (Math.floor(element.question.atTime) == Math.floor(this.question.question.question.atTime)) {
  //                 if (this.question.isAnswered) {
  //                   continue;
  //                 } else {
  //                   this.video.currentTime = Math.floor(element.question.atTime);
  //                   var x = (((Math.floor(element.question.atTime) + this.xFactor.value) * screen.width) / this.video.duration);
  //                   // console.log(x);
  //                   TweenMax.to('.sm-player-seeker-done', .01, { width: x + 'px', transformOrigin: "100% 50%" })
  //                 }
  //               }
  //             }
  //             if (element.isAnswered) {
  //               continue;
  //             } else {
  //               this.video.currentTime = Math.floor(element.question.atTime);
  //               var x = (((Math.floor(element.question.atTime) + this.xFactor.value) * screen.width) / this.video.duration);
  //               // console.log(x);
  //               TweenMax.to('.sm-player-seeker-done', .01, { width: x + 'px', transformOrigin: "100% 50%" })
  //             }

  //           } else {
  //             if (parseInt(progressBar.style.width) > event.x) {
  //               TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
  //             } else {
  //               TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
  //             }
  //             this.video.currentTime = Math.floor(currentPosition);
  //           }
  //           return;
  //         };

  //       } else {

  //         this.video.currentTime = Math.floor(currentPosition);
  //         if (parseInt(progressBar.style.width) > event.x) {
  //           TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
  //         } else {
  //           TweenMax.to('.sm-player-seeker-done', .01, { width: event.x + 'px', transformOrigin: "100% 50%" })
  //         }
  //       }
  //       // this.play();
  //       this.player.play();

  //     } else {

  //     }
  //   }
  // }



  getAnswer($event) {
    if ($event) {
      this.question.isAnswered = true;
      this.didAnswer = true;
    } else {
      if (!this.question.fallBack) {
        this.question.isAnswered = true;
        this.didAnswer = true;
      }
    }
    this.didAnswer = $event;
  }



  public speedcontrol = [
    0.5, 0.75, 1, 1.5, 2
  ]
  speedUpDown(ev) {
    // console.log(ev);

    if (this.canContinue.value) {
      if (this.video.paused || this.video.ended) {

      } else {
        this.video.playbackRate = ev;
        this.playback.next(ev + 'x');
        // TweenMax.staggerTo('.sm-control-speed-btn', .4, { autoAlpha: 0, y: 20, scale: 0, transformOrigin: 'bottom' }, .05);
        // TweenMax.set('.sm-control-speed-menu', { css: { display: 'none' } })
        var menu = document.getElementById('sm-control-speed-menu');
        menu.dataset.clicked = "false";
      }
    }
  }



  onSpeedHover() {
    var menu = document.getElementById('sm-control-speed-menu');
    // console.log(menu.dataset);

    if (menu.dataset.clicked == 'true') {
      // TweenMax.staggerFrom('.sm-control-speed-btn', .4, { autoAlpha: 0, y: 20, scale: 0, transformOrigin: 'bottom' }, .05)
      menu.dataset.clicked = "false";
    } else {
      // TweenMax.set('.sm-control-speed-menu', { css: { display: 'grid' } })
      // TweenMax.staggerFrom('.sm-control-speed-btn', .4, { autoAlpha: 0, y: 20, scale: 0, transformOrigin: 'bottom' }, -.05)
      menu.dataset.clicked = "true";
    }

  }

}
