import { loadScriptFB, SettingsService } from './../../../core/services';
import { TranslationService } from '../../../core/services/translation.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { SystemService } from './../../../core/services';
import { first } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
declare var $: any;



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private _settings: SettingsService,
    private permissionsService: NgxPermissionsService,
    private _translate: TranslationService,
    private _store: Store<any>,
    private authenticationService: AuthenticationService) {

    // redirect to home if already logged in
    if (this.authenticationService.userValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {

    this._store.dispatch({ type: '[SYSTEM] We Are Out Of Loading' });
    this.authenticationService.logout();
    // reset login status
    //

    // get return url from route parameters or default to '/'
    // console.log(this.route.snapshot.queryParams['returnUrl']);
    if (localStorage.getItem('language') == null) {

      localStorage.setItem('language', 'ar');
    } else {
      this.lang = localStorage.getItem('language')
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }





  login() {
    this.loading = true;
    let self = this;
    this.authenticationService.logout();
    this.authenticationService.login(this.model.loginName, this.model.password)
      .pipe(first())
      .subscribe({


        next: (value) => {
          self.router.navigate(['/2facheck'], { queryParams: { email: value.email, returnUrl: self.returnUrl, userId: value.userId } })
          // this.router.navigate([this.returnUrl]);
          // this.getMyPermissions();
        },
        error(err) {
          console.log(err);
          if (err.error.error == 'No User found') {
            self.alertTitle = 1;
            self.alertMessage = "هذا البريد غير مسجل في الموقع";
            self.loading = false;
            self.cd.markForCheck();
          } else if (err.error.error == 'Failed') {
            self.alertTitle = 1;
            self.alertMessage = "كلمة المرور غير صحيحة يرجى كتابة كلمة المرور الصحيحة او اعادة تعيين كلمة المرور";
            self.loading = false;
            self.cd.markForCheck();
          } else if (err.error.error == 'Not Activated') {
            self.alertTitle = 1;
            self.alertMessage = "لم يتم تفعيل الحساب, تم إرسال رسالة تفعيل إلى بريدكم المسجل يمكن ان تكون الرسالة في الـ spam / Junk ";
            self.loading = false;
            self.cd.markForCheck();
          }
        },

      });

    return;
    this.authenticationService.login(this.model.loginName, this.model.password).subscribe((response: any) => {


      let user = response;
      if (user && user.token) {
        if (user.groupId != 5 && user.groupId != 6) {
          this.alertTitle = "ليست لديك الصلاحية";
          this.alertMessage = "لا توجد لديك صلاحية للدخول إلى النظام";
          this.cd.markForCheck();
          $('#alert').modal({ backdrop: 'static' });
          this.loading = false;
          return;
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (typeof window !== 'undefined') {
          localStorage.setItem('mprofile', JSON.stringify(user));
          this._store.dispatch({ type: '[Reports] Get My Coupons', userId: user.userId });
          this._store.dispatch({ type: '[Profile] User Is Logged In' })
          this.router.navigate([this.returnUrl]);


        }

      }


    }, error => {

      this.alertTitle = "بيانات خاطئة";
      this.alertMessage = "الرجاء التحقق من اسم المستخدم وكلمة المرور ";
      this.cd.markForCheck();
      $('#alert').modal({ backdrop: 'static' });
      this.loading = false;
    });
  }



  getMyPermissions() {

  }

  lang = 'ar'


  setLang(lang: string) {
    console.log(lang);
    if (lang === 'ar') {
      this.lang = 'en';
      localStorage.setItem('language', 'en');
      this._translate.setLanguage('en');
      loadScriptFB('en')
    } else {
      this.lang = 'ar'
      localStorage.setItem('language', 'ar');
      this._translate.setLanguage('ar');
      loadScriptFB('ar')
    }


    this.cd.markForCheck();
  }

}
