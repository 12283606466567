import { ReportsService } from './../../services/reports.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, exhaustMap } from 'rxjs/operators';



@Injectable()
export class ReportEffect {
  constructor(
    private actions$: Actions,
    private _reports: ReportsService,
  ) {
  }


  getMyCoupons$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get My Coupons'),
    mergeMap((action: any) => this._reports.getMyCoupons(action.userId)
      .pipe(
        map(courses => ({ type: '[Reports] Get My Coupons Success', coupons: courses })
        ))
    )));

  getMyTickets$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get My Tickets'),
    mergeMap((action: any) => this._reports.getMyTickets(action.userId)
      .pipe(
        map(courses => ({ type: '[Reports] Get My Tickets Success', coupons: courses })
        ))
    )));



  getTheMostPopularCourse$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get The Most Popular Course'),
    mergeMap(() => this._reports.getTheMostPopularCourse()
      .pipe(
        map(courses => ({ type: '[Reports] Get The Most Popular Success', course: courses })
        ))
    )));

  getTotalCourses$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Courses'),
    mergeMap(() => this._reports.getTotalCourses()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Courses Success', total: courses })
        ))
    )));

  getTotalUnpublishedCourses$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Unpublished Courses'),
    mergeMap(() => this._reports.getTotalUnpublishedCourses()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Unpublished Courses Success', total: courses })
        ))
    )));

  getTotalPublishedCourses$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total published Courses'),
    mergeMap(() => this._reports.getTotalPublishedCourses()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total published Courses Success', total: courses })
        ))
    )));


  getTotalNoOfVideos$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total No Of Videos'),
    mergeMap(() => this._reports.getTotalNoOfVideos()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total No Of Videos Success', total: courses })
        ))
    )));


  getTotalUsedCoupons$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Used Coupons'),
    mergeMap(() => this._reports.getTotalUsedCoupons()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Used Coupons Success', total: courses })
        ))
    )));


  getTotalFiles$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Files'),
    mergeMap(() => this._reports.getTotalFilesUsed()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Files Success', total: courses })
        ))
    )));


  // getTotalDiscountOfCoupons$ = createEffect(() => this.actions$.pipe(
  //   ofType('[Reports] Get Total Amount Of Coupon Discount'),
  //   mergeMap(() => this._reports.getTotalAmountOfCouponDiscount()
  //     .pipe(
  //       map(courses => ({ type: '[Reports] Get Total Amount Of Coupon Discount Success', total: courses })
  //       ))
  //   )));


  getTotalLengthOfVideos$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Videos Length'),
    mergeMap(() => this._reports.getTotalVideosLength()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Videos Length Success', total: courses })
        ))
    )));



  getTotalUnconfirmedUsers$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Unconfirmed Users'),
    mergeMap(() => this._reports.getTotalUnconfirmedUsers()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Unconfirmed Users Success', total: courses })
        ))
    )));


  getTotalConfirmedUsers$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Confirmed Users'),
    mergeMap(() => this._reports.getTotalConfirmedUsers()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Confirmed Users Success', total: courses })
        ))
    )));



  getTotalTrainees$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Trainees'),
    mergeMap(() => this._reports.getTotalTrainees()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Trainees Success', total: courses })
        ))
    )));


  getTotalPayments$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Payments'),
    mergeMap(() => this._reports.getTotalPayments()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Payments Success', total: courses })
        ))
    )));



  getTotalPaidOrders$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Paid Orders'),
    mergeMap(() => this._reports.getTotalPaidOrders()
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Paid Orders Success', total: courses })
        ))
    )));


  getTotalEnrollmentsInFreeCourses$ = createEffect(() => this.actions$.pipe(
    ofType('[Reports] Get Total Free Course Enrollments'),
    exhaustMap((action: any) => this._reports.getTotalFreeEnrollment(action.courseId)
      .pipe(
        map(courses => ({ type: '[Reports] Get Total Free Course Enrollments', total: courses })
        ))
    )));


}
