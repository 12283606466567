import { Affiliate } from './../_models/affiliates';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AffiliateService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string,) {
  }



  private currentAffiliate = new BehaviorSubject<Affiliate>(null);


  getCurrentAffiliate() {
    return this.currentAffiliate;
  }

  updateCurrentAffiliate(affiliateId) {
    this.getAffiliateProfileById(affiliateId).subscribe((data) => {
      this.currentAffiliate.next(data);
    })
  }

  getAllMyCoupons(userId) {
    return this.http.get(this.apiUrl + '/v1/mycoupons/' + userId, this.jwt());
  }

  getTotalPaidCommissions(attrs) {
    return this.http.get(this.apiUrl + '/v1/paidcomissions/' + attrs, this.jwt());
  }


  getTotalUnPaidCommissions(attrs) {
    return this.http.get(this.apiUrl + '/v1/unpaidcomissions/' + attrs, this.jwt());
  }

  getMyPaymentRequests(attrs) {
    return this.http.get(this.apiUrl + '/v1/paymentrequests/' + attrs, this.jwt());
  }


  getCurrentPaymentRequest(attrs) {
    return this.http.get(this.apiUrl + '/v1/currentpaymentrequest/' + attrs, this.jwt());
  }

  UpdatePaymentRequest(attr) {
    return this.http.post(this.apiUrl + '/api/affiliates/paymentrequest', attr, this.jwt());
  }


  // subscribe
  SubscribeForAffiliate(attr): Observable<Affiliate> {
    return this.http.post<Affiliate>(this.apiUrl + '/api/affiliates/subscribe', attr, this.jwt());
  }
  // myprofile/{userId}
  getMyAffiliateProfile(userId): Observable<Affiliate> {
    return this.http.get<Affiliate>(this.apiUrl + '/api/affiliates/myprofile/' + userId, this.jwt());
  }
  // profile/{affiliateId}
  getAffiliateProfileById(affiliateId): Observable<Affiliate> {
    return this.http.get<Affiliate>(this.apiUrl + '/api/affiliates/profile/' + affiliateId, this.jwt());
  }
  // delete/{affiliateId}
  deleteAffiliateProfile(affiliateId) {
    return this.http.delete(this.apiUrl + '/api/affiliates/delete/' + affiliateId, this.jwt());
  }
  // all
  getAllAffiliateAccounts(): Observable<Affiliate[]> {
    return this.http.get<Affiliate[]>(this.apiUrl + '/api/affiliates/all', this.jwt());
  }
  // remove
  removeAllDeletedAffiliates() {
    return this.http.post(this.apiUrl + '/api/affiliates/remove', this.jwt());
  }
  // updatestatus
  updateAffiliateStatus(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/updatestatus', attrs, this.jwt());
  }
  // bankinfo
  updateBankInfoForAffiliate(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/bankinfo', attrs, this.jwt());
  }
  // commission
  addCommissionToProduct(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/commission', attrs, this.jwt());
  }

  deleteCommission(attrs) {
    return this.http.delete(this.apiUrl + '/api/affiliates/commission/' + attrs, this.jwt());
  }

  allCommissions() {
    return this.http.get(this.apiUrl + '/api/affiliates/commissions', this.jwt());
  }


  getAllActiveAffiliates(): Observable<Affiliate[]> {
    return this.http.get<Affiliate[]>(this.apiUrl + '/api/affiliates/acaff', this.jwt());
  }
  getAllPaymentRequests() {
    return this.http.get(this.apiUrl + '/api/affiliates/paymentrequests', this.jwt());
  }
  // confirmed
  // getAllConfirmedCommissionsFroAnAffiliate(attrs) {
  //   return this.http.post(this.apiUrl + '/api/affiliates/confirmed', attrs, this.jwt());
  // }

  getAllConfirmedCommissionsFroAnAffiliate(attrs) {
    return this.http.post(this.apiUrl + '/v1/confirmedcommissions', attrs, this.jwt());
  }


  addPolicy(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/policy', attrs, this.jwt());
  }


  resortPolicies(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/sortpolicies', attrs, this.jwt());
  }

  deletePolicy(attrs) {
    return this.http.delete(this.apiUrl + '/api/affiliates/policy/' + attrs, this.jwt());
  }

  getAllPolicies() {
    return this.http.get(this.apiUrl + '/api/affiliates/policies', this.jwt());
  }






  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


  private jwt() {
    var profile = JSON.parse(localStorage.getItem('mprofile'));

    let token = profile.token;
    // console.log(token);

    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }


}
