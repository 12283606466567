import { ReportsService } from './../../../core/services/reports.service';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import * as reducer from './../../../core/state/_reports/reports.reducer';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadScriptFB, TranslationService } from './../../../core/services';
@Component({
  selector: 'sm-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit {


  myCoupons: Observable<any[]>
  myTickets: Observable<any[]>
  profile;
  lang = 'ar';

  constructor(private _report: ReportsService, private _translate: TranslationService, private cd: ChangeDetectorRef, private _store: Store<any>) {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('mprofile'));
    }
    var body = document.body;
    this.lang = localStorage.getItem('language');
    if(this.lang == 'ar') {
      body.classList.toggle('menu-side-right');
    }else {
      body.classList.toggle('menu-side-left');
    }
  }

  ngOnInit(): void {

    this.myCoupons = this._store.pipe(select(reducer.myCoupons));
    this.myTickets = this._store.pipe(select(reducer.myTickets));

  }


  // lang = 'ar'


  // setLang(lang: string) {
  //   // console.log(lang);
  //   var body = document.body;
  //   var menu = document.getElementById('main-menu');
  //   if (lang === 'ar') {
  //     this.lang = 'en';
  //     localStorage.setItem('language', 'en');
  //     this._translate.setLanguage('en');
  //     body.classList.toggle('menu-side-right');
  //     body.classList.toggle('menu-side-left');
  //     menu.classList.toggle('menu-side-right');
  //     menu.classList.toggle('menu-side-left');
  //   } else {
  //     this.lang = 'ar'
  //     localStorage.setItem('language', 'ar');
  //     this._translate.setLanguage('ar');
  //     body.classList.toggle('menu-side-right');
  //     body.classList.toggle('menu-side-left');

  //     menu.classList.toggle('menu-side-right');
  //     menu.classList.toggle('menu-side-left');


  //   }
  //   this.cd.markForCheck();
  // }


  setLang(lang: string) {
    var body = document.body;
    var menu = document.getElementById('main-menu');
    if (lang === 'ar') {
      this.lang = 'en';
      localStorage.setItem('language', 'en');
      this._translate.setLanguage('en');
      loadScriptFB('en')
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
      menu.classList.toggle('menu-side-right');
      menu.classList.toggle('menu-side-left');
    } else {
      this.lang = 'ar'
      localStorage.setItem('language', 'ar');
      this._translate.setLanguage('ar');
      loadScriptFB('ar')
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');

      menu.classList.toggle('menu-side-right');
      menu.classList.toggle('menu-side-left');
    }


    this.cd.markForCheck();
  }


}
