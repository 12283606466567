
export class CourseTrainers {
  courseId?: number;
  trainerId?: number;
}

export class Course {
  Id?: number;
  id?: number;
  name: string;
  description: string;
  categoryId: number;
  categoryName: any;
  banner: string;
  intro: string;
  type: CourseType;
  languageId?: number;
  linkName: string;
  keywords: string;
  certificateSample: string;
  discussed: boolean;
  squareImage: string;
  notificationEmail: string;
  price?: number;
  isDeploma: boolean;
  active: boolean;
  image: string;
  // trainerId?: number;
  notes: string;
  addedBy?: number;
  addedDate: Date;
  trainerAvatar: string;
  trainerName: string;
  currentPrice: string;
  updateAt?: Date;
}


export enum CourseType {
  Recorded = 1,
  Live = 2,
  RecordedDated = 3,
  Mixed = 4,
  Internal = 5
}

export class CourseInfo {
  Id?: number;
  id?: number;
  courseId: number;
  videoId: number;
  header: string;
  body: string;
  video: any;
  order: number;
}


import { SafeStyle } from '@angular/platform-browser';
export class courseCard {
  id: number;
  courseName: string;
  trainerName: string;
  courseDescription: string;
  courseCover: any;
  courseCoverx: SafeStyle;
  courseVideo: any;
  trainer: trainerCard;
  // coursePrices: priceCard[];
  mainPrice: number;
  currentPrice?: number;
  courseUnits: unitCard[];
  quizz: quizzCard;
  courseInfo: infoCard[];

}

export class infoCard {
  header: string;
  body: string;
  videoLink: string;
}

export class unitCard {
  id: number;
  unitName: string;
  unitDesc: string;
  unitBanner: any;
  materials: materialCard[];
  quizz: quizzCard;

}

export class quizzCard {
  quizzId: number;
  quizzName: string;

}

export class materialCard {
  materialId: number;
  materialName: string;
  materialLink: any;
  materialLength: string;

}

export class priceCard {
  priceId: number;
  priceName: string;
  typeOfPrice: boolean;
  from?: Date
  to?: Date;
  coursePrice: number;

}

export class trainerCard {
  id: number;
  trainerName: string;
  trainerInfo: string;
  trainerAvatar: any;
  trainerMoreInfo: string;
  trainerCourses: courseCard[];
}


export class creditCard {
  name: string;
  cNumber: string;
  cMonth: string;
  cYear: string;
  cvv: string;
  address: string;
  addressc: string;
  postal: string;
  country: string;
  city: string;
  state: string;
}
