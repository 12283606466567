import { FileBankDTO } from '../../_models';
import { createReducer, on, Action, createSelector, createFeatureSelector, ActionReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as filesActions from './courses.actions';
import { appState } from '../app.state';


export interface fileBankState extends EntityState<FileBankDTO> {
  selectedFileId?: number;
  isLoading: boolean;
  selectedFile: FileBankDTO;
}

export function selectItemId(a: FileBankDTO): string {
  //In this case this would be optional since primary key is id
  return a.id.toString();
}


export function sortByName(a: FileBankDTO, b: FileBankDTO): number {
  return a.fileName.localeCompare(b.fileName);
}

export const filesAdapter: EntityAdapter<FileBankDTO> = createEntityAdapter<FileBankDTO>();

// export const defaultItems: cartState = {
//   ids: [],
//   entities: {},
//   selectedItemId: null,
//   total: 0,
// }

const initialState = filesAdapter.getInitialState({
  selectId: selectItemId,
  sortComparer: sortByName,
  isLoading: false,
  selectedFileId: null,
  selectedFile: undefined,
});


const _reducer = createReducer(
  initialState,
  on(filesActions.updateFile, (state, { file }) => {
    return filesAdapter.updateOne(file, state);
  }),
  on(filesActions.LoadFilesSuccess, (state, { files }) => {
    return filesAdapter.setAll(files, state)
  }),
  on(filesActions.GetSelectedFile, (state, { file }) => {
    return { ...state, selectedFile: file }
  }),
  on(filesActions.addFileSuccess, (state, { files }) => {
    return filesAdapter.setOne(files, state)
  }),
  on(filesActions.removeFileSuccess, (state, { id }) => {
    return filesAdapter.removeOne(id, state)
  }),
  on(filesActions.removeFile, (state, { id }) => {
    return filesAdapter.removeOne(id, state);
  }),
)





export const filesReducerFeatureKey = 'files';
export const filesFeature = createFeatureSelector<fileBankState>(filesReducerFeatureKey);


export const getSeletedItemId = (state: fileBankState) => state.selectedFileId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = filesAdapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectEntities;

// select the total user count
export const selectUserTotal = selectTotal;


export const getFileBankDTO = createSelector(
  filesFeature, selectAll
)


export const loadingStatus = createSelector(
  filesFeature,
  (state: fileBankState) => state.isLoading
)

export const getSelectedFileBankDTO = createSelector(
  filesFeature,
  (state: fileBankState) => state.selectedFile
)



export const totalItems = createSelector(
  filesFeature,
  (state: fileBankState) => {
    const allItems = Object.values(state.entities);
    return allItems.length
  })


export function persistStateReducer(_reducer: ActionReducer<fileBankState>) {
  const localStorageKey = '__files';
  return (state: fileBankState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}


export function filesReucer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<fileBankState>) {
  return (state: fileBankState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}


