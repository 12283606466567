import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CoursesService } from '../../../core/services';
import { QuizReviewDialogComponent } from '../quiz-review-dialog/quiz-review-dialog.component';

export interface QuizzReviewData {
  quizzId: number;
  quizz: any;
}


@Component({
  selector: 'sm-quiz-attendance-review',
  templateUrl: './quiz-attendance-review.component.html',
  styleUrls: ['./quiz-attendance-review.component.scss']
})
export class QuizAttendanceReviewComponent implements OnInit, AfterViewInit {

  lang = 'ar'
  evaluate: UntypedFormGroup;
  submitted = false;
  quizzId: number;
  quizz: any;
  loading = false;
  //New Table
  dataSourceQuizzes: any = [];
  @ViewChild('quizzes') paginatorQuizzes: MatPaginator;
  @ViewChild(MatSort) sortQuizzes: MatSort;
  resultsQuizzesLength = 0;
  //End New Table

  quizzInfo: any;

  applyFilterQuizzes(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceQuizzes.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceQuizzes.paginatorQuizzes) {
      this.dataSourceQuizzes.paginatorQuizzes.firstPage();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuizzReviewData, private dialogRef: MatDialogRef<QuizAttendanceReviewComponent>,
    private _dialog: MatDialog, private formBuilder: UntypedFormBuilder, private _course: CoursesService) {
    this.quizzId = data.quizzId;
    this.quizz = data.quizz;
  }

  ngAfterViewInit(): void {
    this.dataSourceQuizzes.paginatorQuizzes = this.paginatorQuizzes;
    this.dataSourceQuizzes.sortQuizzes = this.sortQuizzes;
  }

  ngOnInit(): void {
    this.evaluate = this.formBuilder.group({
      email: [{ value: '', disabled: this.loading }, Validators.required],
      quizzId: this.quizzId
    })
  }


  onSubmitEvaluate() {
    this.submitted = true;
    if (this.evaluate.invalid) {
      return;
    }

    this._course.getQuizzAttendanceCardForTrainee(this.evaluate.value).subscribe((data: any) => {
      // console.log(data);
      this.quizzInfo = data;
      this.dataSourceQuizzes = new MatTableDataSource(data.myAttempts)
      this.resultsQuizzesLength = data.myAttempts.length;
      this.dataSourceQuizzes.paginatorQuizzes = this.paginatorQuizzes;
      this.dataSourceQuizzes.sortQuizzes = this.sortQuizzes;

    })

  }


  ViewQuizzResult(e) {
    this._dialog.open(QuizReviewDialogComponent, {
      direction: 'rtl',
      maxHeight: '650px',
      data: { quizz: this.quizz, attempt: e }
    })
  }

}
