import { TranslationService } from './core/services/translation.service';

import { TrainersService } from './core/services/trainers.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CookieService } from 'ngx-cookie-service';
import { MailModule } from './views/mail/mail.module';
import { ReportEffect } from './core/state/_reports/reports.effect';
import { PreviewComponent } from './views/courses/preview/preview.component';
import { environment } from './../../src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ConsultancyService } from './core/services/consultancy.service';
import { ConnectionService } from './core/services/channel.service';
import { SettingsModule } from './views/settings/settings.module';
import { UserService } from './core/services/users.service';
import { CategoriesComponent } from './views/courses/categories/categories.component';
import { HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from './views/components/components.module';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, HammerGestureConfig } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, Inject, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/auth/auth.guard';
import { BaseComponent } from './base/base.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module

import { DecimalPipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localar from '@angular/common/locales/ar';
import { SystemService, CoursesService, AuthInterceptor, AffiliateService, B2BService, NotfiyService, loadScriptFB } from './core/services';
import { SettingsService } from './core/services/settings.service';
import { SortablejsModule } from 'ngx-sortablejs';
import { StoreModule } from '@ngrx/store';
import { CoursesEffect } from './core/state/_courses/course.effect';
import { ReportsService } from './core/services/reports.service';
// import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
// import * as reducer from './core/state/_auth/_reducers/auth.reducer'
// import * as Hammer from 'hammerjs';
import { NgrxRouterStoreModule } from './core/state/_router/ngrx-router.module';
import { LoaderModule } from './views/components/loader/loader.module';
import { AuthenticationService } from './core/auth/authentication.service';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DragulaModule } from 'ng2-dragula';
import { ChartsModule } from 'ng2-charts';
import { JoditAngularModule } from 'jodit-angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPermissionsModule } from 'ngx-permissions';
import { JwtInterceptor } from './core/services/jwtintercepetor';
import { ErrorInterceptor } from './core/services/errorhandler';
import { ChangepasswordComponent } from './views/components/changepassword/changepassword.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';


import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

// import { TourTuiDropdownModule } from 'ngx-ui-tour-tui-dropdown';
import { DialogsModule } from './views/dialogs/dialogs.module';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { UppyAngularDashboardModule, UppyAngularDragDropModule, UppyAngularProgressBarModule, UppyAngularStatusBarModule } from '@uppy/angular'

// New Functions

export function appInitializer(authenticationService: AuthenticationService, translationService: TranslationService) {
  return () => new Promise(resolve => {
    // attempt to refresh token on app start up to auto authenticate
    const lang = localStorage.getItem('language')
    const prfile = JSON.parse(localStorage.getItem('mprofile'));
    if (!lang || lang == 'ar') {
      translationService.setLanguage('ar');
      loadScriptFB('ar')
    } else {
      translationService.setLanguage(lang);
      loadScriptFB(lang)
    }
    authenticationService.refreshToken()
      .subscribe()
      .add(resolve);
  });
}




//End New Functions


export class tokenClass {


  // const user = this.authenticationService.userValue;
  // const isLoggedIn = user && user.jwtToken;


  _token: any = JSON.parse(localStorage.getItem('mprofile'));

  get token() {
    return this._token;
  }

  set token(any) {
    this._token = JSON.parse(localStorage.getItem('mprofile'));
  }
}

var myModule = new tokenClass();



const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: environment.API + '/api/courses/video/',
  // headers: {
  //   "Authorization": "Bearer " + (myModule) ? myModule.token : ''
  // },
  maxFilesize: null,
  chunking: true,
  chunkSize: 60000000,
  timeout: 3000000
};





// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localar);

@NgModule({
  declarations: [
    AppComponent, PreviewComponent, ChangepasswordComponent,
    UnauthorizedComponent,
    BaseComponent
  ],
  imports: [
    HammerModule, ChartsModule, NgxPaginationModule, DialogsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    UppyAngularDashboardModule,
    UppyAngularDragDropModule,
    UppyAngularProgressBarModule,
    UppyAngularStatusBarModule,
    NgrxRouterStoreModule, LoaderModule,
    DragulaModule.forRoot(), 
    FormsModule, CoreModule, ReactiveFormsModule,
    FormsModule, 
    // MailModule,
     NgxSpinnerModule,
    NgxChartsModule,
    AppRoutingModule, NgxDropzoneModule,
    ComponentsModule,
    // IntercomModule.forRoot({
    //   appId: "znw3q79e", // from your Intercom config
    //   updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    // }),

    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      name: 'Mastery Academy',
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([CoursesEffect]),
    EffectsModule.forRoot([ReportEffect]),
    JoditAngularModule, NgxIntlTelInputModule,
    ScrollingModule,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    BrowserAnimationsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    DropzoneModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,

    // for Router use:
    LoadingBarRouterModule,

    // for Core use:
    LoadingBarModule,

    // Tour JS
    // TourTuiDropdownModule.forRoot()
  ],
  providers: [
    AuthenticationService, AuthGuard, SystemService, CoursesService, TrainersService,
    AuthInterceptor, AffiliateService, B2BService, UserService, ReportsService,
    SettingsService, ConsultancyService, NotfiyService,
    ConnectionService, CookieService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (ds: DictionaryService, ps: NgxPermissionsService) => function () { return ds.load().then((data) => { return ps.loadPermissions(data) }) },
    //   deps: [LoadService, NgxPermissionsService],
    //   multi: true
    // },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, TranslationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig,
    // },


    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
